/**
 *
 * @deprecated
 * Use instead: currentUser?.hasPermission?.()
 *
 * Checks if a user has update permissions for a specific resource.
 * It considers both 'can_read flags' within the user's permissions.
 *
 * @param {string} resource - The resource for which delete permissions are being checked.
 * @param {Object} user - The user object, which should contain a 'permissions' property.
 * @returns {boolean} True if the user has update permissions for the resource, false otherwise.
 */

const hasUpdatePermission = (resource, user) => {
  const permissionResource = user?.permissions?.[resource];

  if (!permissionResource || !user) {
    return false;
  }

  const canUpdateCriteria = permissionResource?.can_update === true;

  return canUpdateCriteria;
};

export default hasUpdatePermission;
