import { toast } from "react-toastify";
import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import timesheetKeys from "./timesheetKeys";
import { APPROVED, FAILED_TS_FETCH } from "../../constants";

const fetchApprovedTimesheetsByUser = async (userRef) => {
  const query = {
    payrollStatus: APPROVED,
    userRef,
  };

  const {
    data: { entries },
  } = await TimesheetAPI.get({ params: query });

  const approvedTimesheets = entries
    ?.map((entry) => entry.resource)
    .filter(
      (timesheet) =>
        timesheet.payrollStatus === APPROVED && timesheet.userRef === userRef
    );

  return approvedTimesheets.map((timesheet) => {
    const { periodStart, periodEnd } = timesheet;

    return {
      periodStart,
      periodEnd,
    };
  });
};

/**
 * Custom hook to fetch and manage payroll approved timesheets for the specified user.
 * This hook utilizes the user's reference from the specified user context to query and filter
 * approved timesheets from the API.
 *
 * @returns {Object} - An object containing the approved timesheets for the specified user.
 * @property {Array} timeSheetsAlreadyApproved - An array of objects each containing
 *                                               periodStart and periodEnd of the approved timesheets.
 */

function usePayrollApprovedTimesheetsForUser(userRef) {
  const { data: timeSheetsAlreadyApproved } = useAuthenticatedQuery({
    queryKey: timesheetKeys.approvedTsByUser(userRef),
    enabled: !!userRef,
    queryFn: () => fetchApprovedTimesheetsByUser(userRef),
    onError: () => {
      toast.error(FAILED_TS_FETCH);
    },
    staleTime: 0,
  });

  return { timeSheetsAlreadyApproved };
}

export default usePayrollApprovedTimesheetsForUser;
