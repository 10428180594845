import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import IconButton from "../Buttons/IconButton";
import "./WidgetStyles.css";
import EditIcon from "../EditIcon/EditIcon";
import Tab from "../Tab/Tab";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import CommentButton from "../CommentButton";

const containerCN = (className) => cntl`
  bg-white
  px-5
  py-4
  rounded-md
  w-full
  border
  border-gray-200
  ${className}
  transition-all
  relative
  `;

const childCN = (childClass) => cntl`
relative
${childClass}
`;

const tabsContainerCN = (tabBorder) => cntl`
  flex
  items-start
  justify-between
  ${tabBorder && "border-b border-gray-200"}
`;

const contentContainerCN = () => cntl`
  flex-1
  w-full
  relative
`;

const WidgetContainer = ({
  className,
  childClassName,
  children,
  fullRow,
  overflow,
  handleEditClick,
  tabs,
  loading,
  onTabClick,
  onFinishEditing,
  activeTab,
  isEditing,
  disableEditing,
  resetResourceState,
  actionsButtons,
  onOpenComment,
  tabBorder,
  disableSaveBtn,
  hideBottomCancelSaveButtons,
  customStyle,
  showEditButton = true,
}) => {
  const wrapperRef = useRef(null);

  const handleTabClick = useCallback(
    (i, id) => {
      // Id = Tab_Title
      if (!isEditing) {
        onTabClick(i, id);
      }
    },
    [isEditing, onTabClick]
  );

  const widgetTabs = useMemo(() => {
    return tabs
      ?.filter((tab) => !tab.isHidden)
      ?.map(({ title, pill, id }) => {
        return (
          <Tab
            pill={pill}
            key={title}
            title={title}
            className="mb-4"
            isSelected={activeTab === id}
            onClick={() => handleTabClick(id)}
          />
        );
      });
  }, [activeTab, handleTabClick, tabs]);

  const handleSave = useCallback(async () => {
    // Awaiting the onFinish, because Stripe components are unmounted when screen moves back to !isEditing
    await onFinishEditing();
    handleEditClick();
  }, [handleEditClick, onFinishEditing]);

  const handleCancel = useCallback(() => {
    handleEditClick();
    resetResourceState();
  }, [handleEditClick, resetResourceState]);

  return (
    <div
      className={containerCN(className)}
      ref={wrapperRef}
      style={customStyle}
    >
      <div className={`flex w-full mb-4 justify-end ${!tabs && "hidden"} px-3`}>
        <div className="flex flex-col w-full mt-3" id="WidgetContainer-actions">
          {tabs && (
            <>
              <div className={tabsContainerCN(tabBorder)}>
                <div className="pb-5 flex flex-wrap overflow-y-hidden w-full">
                  {widgetTabs}
                </div>
                {/* Comment Button */}
                <div className="flex flex-row items-center">
                  {onOpenComment && (
                    <CommentButton onClick={() => onOpenComment()} />
                  )}
                </div>
                <div
                  className={`flex ${isEditing && "pb-2 "} ${
                    disableEditing && "hidden"
                  }`}
                >
                  {/* Edit Button */}
                  {!actionsButtons &&
                    !isEditing &&
                    handleEditClick &&
                    showEditButton && (
                      <IconButton
                        wrapperClassName={`${
                          !isEditing && !loading && "icon-transition"
                        } pt-2`}
                        imgClassName="w-6 h-6"
                        iconClassName="w-6 h-6"
                        onClick={
                          isEditing || loading || disableEditing
                            ? () => {}
                            : handleEditClick
                        }
                        iconLeft={
                          <EditIcon
                            disabled={isEditing || loading || disableEditing}
                          />
                        }
                      />
                    )}
                  {isEditing && handleEditClick && (
                    <div className="flex">
                      <TertiaryButton
                        title="Cancel"
                        className="mr-2 rounded-lg"
                        cancelButton
                        onClick={handleCancel}
                      />
                      <PrimaryButton
                        disabled={disableSaveBtn || loading}
                        saveButton
                        onClick={handleSave}
                      />
                    </div>
                  )}
                  {/* Edit Button */}
                  {actionsButtons && !isEditing && (
                    <div className="flex w-full justify-end">
                      {actionsButtons}
                    </div>
                  )}
                </div>
              </div>
              {tabs && (
                <div className={contentContainerCN()}>
                  {
                    tabs
                      .filter((tab) => !tab.isHidden)
                      .find(({ id }) => activeTab === id)?.content
                  }
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!hideBottomCancelSaveButtons && tabs && isEditing && (
        <div className="flex w-full justify-end">
          <TertiaryButton
            title="Cancel"
            className="mr-2"
            cancelButton
            onClick={handleCancel}
          />
          {loading ? null : (
            <PrimaryButton
              disabled={disableSaveBtn || loading}
              saveButton
              onClick={handleSave}
            />
          )}
        </div>
      )}

      {!tabs && (
        <div
          className={childCN(childClassName, overflow)}
          style={fullRow ? { height: "450px" } : {}}
        >
          {children}
        </div>
      )}
    </div>
  );
};

WidgetContainer.propTypes = {
  /**
   * className to pass to the container
   */
  className: PropTypes.string,
  /**
   * WidgetContainer Children
   */
  children: PropTypes.arrayOf(PropTypes.element),
  childClassName: PropTypes.string,
  /**
   * Full row flag
   */
  fullRow: PropTypes.bool,
  /**
   * Show Overflow
   */
  overflow: PropTypes.bool,
  /**
   * Embedded Edit Toggle
   */
  handleEditClick: PropTypes.func,
  /**
   * Editing State
   */
  isEditing: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onTabClick: PropTypes.func,
  onFinishEditing: PropTypes.func,
  activeTab: PropTypes.string,
  disableEditing: PropTypes.bool,
  resetResourceState: PropTypes.func,
  actionsButtons: PropTypes.element,
  onOpenComment: PropTypes.func,
  tabBorder: PropTypes.bool,
  disableSaveBtn: PropTypes.bool,
  hideBottomCancelSaveButtons: PropTypes.bool,
  customStyle: PropTypes.shape({}),
  showEditButton: PropTypes.bool,
};

WidgetContainer.defaultProps = {
  customStyle: undefined,
  className: undefined,
  onOpenComment: undefined,
  children: undefined,
  childClassName: undefined,
  fullRow: false,
  overflow: false,
  handleEditClick: undefined,
  isEditing: false,
  tabs: undefined,
  loading: true,
  onTabClick: () => {},
  onFinishEditing: () => {},
  activeTab: "details",
  disableEditing: true,
  resetResourceState: () => {},
  actionsButtons: undefined,
  tabBorder: false,
  disableSaveBtn: false,
  hideBottomCancelSaveButtons: false,
  showEditButton: true,
};

export default WidgetContainer;
