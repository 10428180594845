import Api from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import assetKeys from "./assetsKeys";
import {
  expenseKeys,
  filePaginatedKeys,
  ticketsKeys,
} from "../../config/reactQuery/queryKeyFactory";
import taskKeys from "../api/tasks/taskKeys";
import calendarKeys from "../api/calendar/calendarKeys";

const useAssetsDelete = () => {
  const queryClient = useQueryClient();

  /**
   * Api call to delete an array of assets
   * @param ids - an array of assets ids
   */
  const deleteAssetsBulk = useCallback((ids) => {
    return Api.post("/api/Asset/$bulkdelete", {
      assets: ids,
    });
  }, []);

  const mutation = useMutation({
    mutationFn: deleteAssetsBulk,
    onMutate: async (ids) => {
      const queryKey = assetKeys.assets;

      // Cancel any query for assets in the meantime
      await queryClient.cancelQueries(queryKey);

      // Save reference of all query data that matches the query key ["assets"]
      // This is going to be use in case of a possible error.
      const previousAllAssets = queryClient.getQueriesData(queryKey);

      const idsToRemove = new Set(ids);

      if (previousAllAssets?.length) {
        /**
         * Remove assets(s) from all posible queries but not assetsOverview
         */
        queryClient.setQueriesData(assetKeys.assets, (current) => {
          if (Array.isArray(current)) {
            return current.filter((report) => !idsToRemove.has(report.id));
          }

          if (typeof current === "object" && idsToRemove.has(current.id)) {
            return undefined;
          }

          return current;
        });
      }

      // Returning context in case of any error
      return { previousAllAssets };
    },
    onError: (error, _, context) => {
      const { previousAllRequest } = context ?? {};

      // Rollback assets to previous state
      previousAllRequest.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      console.error("useAssetsDelete: ", error);
    },
    onSettled: () => {
      (async () => {
        // Invalidate matching queries
        await queryClient.invalidateQueries(assetKeys.overview);
        // invalidate related tasks, expenses, tickets, files
        await queryClient.invalidateQueries(expenseKeys.expenses);
        await queryClient.invalidateQueries(ticketsKeys.tickets);
        await queryClient.invalidateQueries(taskKeys.taskList);
        await queryClient.invalidateQueries(calendarKeys.calendarList);
        await queryClient.invalidateQueries(filePaginatedKeys.allFiles);
      })();
    },
  });

  const deleteAssets = (ids) => {
    return mutation.mutateAsync(ids);
  };

  return {
    deleteAssets,
    isDeleting: mutation.isLoading,
  };
};

export default useAssetsDelete;
