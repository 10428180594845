import React from "react";
import * as yup from "yup";
import Input from "../../Input/Input";
import { truncateLabel } from "../../../../helpers/Tag";
import useBillingContact from "./useBillingContact";
import { firstNameValidation } from "../../../../helpers/FormValidations";
import {
  formatPhoneNumber,
  phoneRegExp,
  yupEmailRegExp,
} from "../../../../helpers/Formatters";
import InfoTooltipSignup from "../../InfoTooltip/InfoTooltipSignup";

const containerCN = "border border-es-light-grey p-6 flex flex-col w-full";
const containerStyle = { borderRadius: "10px" };
const labelCN = "text-es-dark-grey text-es-normal font-es-semibold";
const valueCN = "text-es-normal font-es-normal text-es-dark-grey";
const columnCN = "flex flex-col gap-4 w-full";
const rowCN = "flex flex-row grid-cols-2 col-span-2 gap-6 w-full";
const columnOneCN = "flex flex-col gap-2 col-start-1 w-full";
const columnTwoCN = "flex flex-col gap-2 col-start-2 w-full";
const headerCN = "text-es-dark-grey text-es-lg font-es-semibold";
const headerStyle = { letterSpacing: "1.13px" };

const isPhoneNumber = (value, key) => {
  if (key !== "contactPhone") return value;
  return formatPhoneNumber(value);
};

const formInput = ({
  isEditing,
  billingFormData,
  onChange,
  key,
  validation,
}) => {
  return isEditing ? (
    <Input
      validation={validation}
      value={isPhoneNumber(billingFormData?.[key], key)}
      onChange={(val) => onChange(val, key)}
    />
  ) : (
    <p className={valueCN}>{truncateLabel(billingFormData?.[key], 20)}</p>
  );
};

const BillingContact = ({ isEditing, billingFormData, dispatchBilling }) => {
  const { onChange } = useBillingContact({ dispatchBilling });
  return (
    <div className={containerCN} style={containerStyle}>
      <div className="flex flex-col gap-6">
        <p className={headerCN} style={headerStyle}>
          Contact Information
        </p>
        {/* Container Column */}
        <div className={columnCN}>
          {/* Row will contain 2 columns of label && input/value */}
          <div className={rowCN}>
            {/* Column for label input/value */}
            <div className={columnOneCN}>
              <p className={labelCN}>First Name</p>
              {formInput({
                isEditing,
                billingFormData,
                onChange,
                key: "contactFirstName",
                validation: firstNameValidation,
              })}
            </div>
            <div className={columnTwoCN}>
              <p className={labelCN}>Last Name</p>
              {formInput({
                isEditing,
                billingFormData,
                onChange,
                key: "contactLastName",
                validation: firstNameValidation, // min length 1, required
              })}
            </div>
          </div>
          {/* Row will contain 2 columns of label && input/value */}
          <div className={rowCN}>
            {/* Column for label input/value */}
            <div className={columnOneCN}>
              <div className="flex flex-row items-center relative">
                <p className={labelCN}>Workspace Name</p>
                <InfoTooltipSignup title="This name is used for push notifications on mobile and web" />
              </div>
              {formInput({
                isEditing,
                billingFormData,
                onChange,
                key: "contactWorkspaceName",
                validation: firstNameValidation, // min length 1, required
              })}
            </div>
            <div className={columnTwoCN}>
              <p className={labelCN}>Company</p>
              {formInput({
                isEditing,
                billingFormData,
                onChange,
                key: "contactCompany",
                validation: firstNameValidation,
              })}
            </div>
          </div>
          {/* Row will contain 2 columns of label && input/value */}
          <div className={rowCN}>
            {/* Column for label input/value */}
            <div className={columnOneCN}>
              <p className={labelCN}>Email</p>
              {formInput({
                isEditing,
                billingFormData,
                onChange,
                key: "contactEmail",
                validation: yup
                  .string()
                  .trim()
                  .required()
                  .matches(yupEmailRegExp, "Must be valid email address."),
              })}
            </div>
            <div className={columnTwoCN}>
              <p className={labelCN}>Mobile Phone Number</p>
              {formInput({
                isEditing,
                billingFormData,
                onChange,
                key: "contactPhone",
                validation: yup
                  .string()
                  .required()
                  .matches(phoneRegExp, "Phone number is not valid"),
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingContact;
