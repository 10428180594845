/* Framework Tools */
import PropTypes from "prop-types";
import React from "react";

/* Hooks */

/* Components */
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import Widget from "../../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import WorkflowTable from "../../../stories/Components/WorkflowTableNew";
import useWorkflowOverviewData from "./useWorkflowOverviewData";

const WorkflowOverview = ({ associatedResource }) => {
  const { currentUser, handleAddWorkflow } = useWorkflowOverviewData();

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Workflows"
        buttonTitle="Workflow"
        customButton={
          currentUser?.hasPermission?.("workflow", "can_create") && (
            <PrimaryButton
              resourceName="Workflow"
              onClick={handleAddWorkflow}
              addButton
            />
          )
        }
      />
      <Widget title={null} draggable={false} backToTop>
        <WorkflowTable associatedResource={associatedResource} />
      </Widget>
    </>
  );
};

WorkflowOverview.propTypes = {
  /**
   * only shows WF with this associated Resource
   */
  associatedResource: PropTypes.string,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      submittal: PropTypes.shape({
        types: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
};

WorkflowOverview.defaultProps = {
  associatedResource: undefined,
  managementConfiguration: undefined,
  currentUser: undefined,
};
export default WorkflowOverview;
