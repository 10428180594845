import { useMutation, useQueryClient } from "react-query";
import addToTaskListStore from "../../../helpers/Task/addToTaskListStore";
import addTaskHandler from "./handlers/addTaskHandler";
import addTaskResponder from "./responders/addTaskResponder";

const useAddToTaskList = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    addTaskHandler,
    addTaskResponder(queryClient)
  );

  /**
   * Adds task to calendar, can specify whether to make a new request or not
   *
   * @param {Props} task
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const addToTaskList = async (props, newRequest = true) => {
    if (newRequest === false) {
      return addToTaskListStore({ queryClient, task: props });
    }

    const response = await createMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: addToTaskListLoading,
    isError: hasAddToTaskListError,
    error: addToTaskListError,
    isSuccess: addToTaskListSuccess,
  } = createMutation;

  return {
    addToTaskList,
    addToTaskListLoading,
    hasAddToTaskListError,
    addToTaskListError,
    addToTaskListSuccess,
  };
};

export default useAddToTaskList;
