import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";
import { useSingleTask } from "../../../hooks/api/tasks";
import useSopVersionForTask from "../../../hooks/useSopVersionForTask";
import useURLQueryParameter from "../../../hooks/useURLQueryParameter";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";
import { PAGES } from "../../../constants";

const useTaskSingleData = () => {
  const [currentTask, setCurrentTask] = useState(null);
  const history = useHistory();
  const { taskId } = useParams();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  const instanceStartDateParam = useURLQueryParameter("instanceStartDate");

  const { singleTask, singleTaskLoading, singleTaskError } = useSingleTask(
    taskId,
    instanceStartDateParam
  );

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error: singleTaskError });

  const { usedSopDict } = useSopVersionForTask({ currentTask });

  useEffect(() => {
    if (singleTask) {
      setCurrentTask(singleTask);
    }
  }, [singleTask]);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/tasks/123", currentPage will be "tasks"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.TASKS) {
      setCurrentResourceScreen(PAGES.TASKS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  const clearCurrentTask = () => {
    history.push("/tasks");
  };

  return {
    currentTask,
    singleTaskLoading,
    setCurrentTask,
    usedSopDict,
    clearCurrentTask,
  };
};

export default useTaskSingleData;
