import { useCallback, useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useReportsTableFormat from "../../../hooks/useReportsTableFormat";
import { useModalState } from "../../../state/modalState";
import { ADD_OPEN_MODAL, REPORT_CREATE_MODAL, PAGES } from "../../../constants";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const useReportListData = () => {
  const [{ modals }, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  // Query selector to get all reports in table fomat
  const { data: reports, isLoading } = useReportsTableFormat();

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("report", "can_write"),
    [currentUser]
  );

  const isDisabledButton = modals?.find(
    (item) => item.modalType === REPORT_CREATE_MODAL
  );

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/reports/123", currentPage will be "reports"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.REPORTS) {
      setCurrentResourceScreen(PAGES.REPORTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  const handleOpenModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "new-report-modal" },
      modalData: {},
      modalType: REPORT_CREATE_MODAL,
    });
  }, [modalDispatch]);

  return {
    isDisabledButton,
    canCreate,
    reports,
    isLoading,
    handleOpenModal,
  };
};

export default useReportListData;
