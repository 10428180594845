/* Framework Tools */
import { useMemo, useState, useEffect, useCallback } from "react";

import { useHistory } from "react-router";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import { useAppState } from "../../../state/appState";
import {
  PAGES,
  SET_WORKFLOW_MODAL_STATUS,
  SUBMITTAL_FORMATED_STATUS,
  WORKFLOW_TABS,
} from "../../../constants";
import { formatTableData } from "../../../helpers/WorkflowHelpers";
import useDeleteWorkflows from "../../../hooks/useWorkflowDelete";
import useWorkflow from "../../../hooks/useWorkflow";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useProjectsOverview } from "../../../hooks/projects";
import useRemoveTaskFromCache from "../../../helpers/Workflow/removeTasksFromWorkflow";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const useWorkflowTableData = ({
  associatedResource,
  setButtonActions,
  isTabView,
}) => {
  const history = useHistory();
  const { data: rqData } = useWorkflow({ association: associatedResource });
  const { mutateAsync: deleteWorkflows } = useDeleteWorkflows();
  const { getActiveTab, getPageSettings } = useAppPersistence();
  const pageSettings = getPageSettings(PAGES.WORKFLOWS);

  const workflows = useMemo(() => rqData?.workflows ?? [], [rqData?.workflows]);
  const [templatesConfiguration, updateTemplate, , deleteTemplate] =
    useTemplatesConfiguration();

  const [{ userDict, currentUser }, dispatch] = useAppState();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const [templateSettings, setTemplateSettings] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allowDelete, setAllowDelete] = useState(true);
  const [commentsAssociation, setCommentsAssociation] = useState({
    resourceReference: undefined,
    association: undefined,
  });

  useEffect(() => {
    // optionally stup CTA to Add workflow
    if (isTabView && setButtonActions) {
      setButtonActions((prev) => {
        if (!prev.find((opt) => opt.title === "Add Workflow")) {
          return [
            {
              title: "Add Workflow",
              onClick: () =>
                dispatch({
                  type: SET_WORKFLOW_MODAL_STATUS,
                  association: associatedResource,
                  open: true,
                }),
              tabAction: true,
            },
            ...prev,
          ];
        }
        return prev;
      });
    }
  }, [associatedResource, dispatch, isTabView, setButtonActions]);

  useEffect(() => {
    const canDelete = selectedRows?.every(
      (item) =>
        // TODO: make them general status types
        item?.status !== SUBMITTAL_FORMATED_STATUS?.COMPLETE_OPEN &&
        item?.status !== SUBMITTAL_FORMATED_STATUS?.COMPLETE_RESOLVED &&
        item?.status !== SUBMITTAL_FORMATED_STATUS?.PENDING_COMPLETION &&
        item?.status !== SUBMITTAL_FORMATED_STATUS?.IN_PROGRESS &&
        item?.status !== SUBMITTAL_FORMATED_STATUS?.VOID
    );
    setAllowDelete(canDelete && currentUser?.permissions?.workflow?.can_delete);
  }, [
    selectedRows,
    selectedRows.length,
    currentUser?.permissions?.workflow?.can_delete,
  ]);

  useEffect(() => {
    if (templatesConfiguration) {
      setTemplateSettings(
        templatesConfiguration?.templates?.workflow?.map((template) => ({
          ...template,
          isAdmin: !template.custom,
        })) || []
      );
    }
  }, [templatesConfiguration]);

  useEffect(() => {
    const isReady =
      !!projectDict &&
      !!propertiesDict &&
      !!Object.keys(userDict)?.length &&
      workflows?.length;
    if (!isReady) return;

    const data = formatTableData(
      workflows.filter(
        (item) => item.isDefaultTemplate !== true && !item.isTemplate
      ),
      userDict,
      projectDict,
      propertiesDict
    );

    setTableData(data);
  }, [projectDict, propertiesDict, workflows, userDict]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      updateTemplate({
        key: "workflow",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [updateTemplate, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "workflow",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const handleRowClick = useCallback(
    (val) => {
      const activeTab = getActiveTab({
        page: PAGES.WORKFLOWS,
        resourceId: val?.id,
      });
      const isCurrentPersistedResource = pageSettings?.resource?.id === val?.id;
      const activeTabOrDefault = isCurrentPersistedResource
        ? activeTab
        : WORKFLOW_TABS.DETAILS_ID;
      const path = getSingleResourcePath(`Workflow/${val?.id}`);
      if (associatedResource) {
        const associationPath = getSingleResourcePath(associatedResource);
        history.push(`${associationPath}${path}?tab=${activeTabOrDefault}`);
      } else history.push(`${path}?tab=${activeTabOrDefault}`);
    },
    [associatedResource, history, pageSettings?.resource?.id, getActiveTab]
  );

  const handleRowChange = useCallback(
    (val) => {
      const ids = val.map(({ id, status, tasks = [] }) => ({
        id,
        status,
        tasks,
      }));
      setSelectedRows(ids);
    },
    [setSelectedRows]
  );

  // WFs [{id, tasks:["uuid"]}, ...]
  // returns array of resource Ids and array of all tasks ids
  const filterWorkflowsToDelete = (workflowArray) => {
    const resourceIds = [];
    const tasksIds = [];

    workflowArray?.forEach(({ id, tasks }) => {
      resourceIds.push(id);
      if (tasks?.length > 0) {
        tasksIds.push(...tasks);
      }
    });

    return { resourceIds, tasksIds };
  };

  const { removeTasksFromCache } = useRemoveTaskFromCache();
  // Param 'workflowsToDelete' is [{id: "", status: "", tasks: [...tasksRefs]}, ...]
  const handleDeleteWorkflows = (workflowsToDelete) => {
    const { resourceIds, tasksIds } =
      filterWorkflowsToDelete(workflowsToDelete);
    try {
      deleteWorkflows(resourceIds);
      removeTasksFromCache(tasksIds);
    } catch (e) {
      console.error(e, "Error deleting Workflows from table view");
    }
  };

  return {
    handleRowChange,
    handleRowClick,
    deleteUserTemplateSettings,
    updateUserTemplateSettings,
    showDeleteModal,
    setShowDeleteModal,
    tableData,
    handleDeleteWorkflows,
    allowDelete,
    commentsAssociation,
    setCommentsAssociation,
    selectedRows,
    templateSettings,
  };
};

export default useWorkflowTableData;
