/**
 * Modifies the settings of a specific tab on a page.
 *
 * @param {Object} params - The parameters for modifying the tab settings.
 * @param {string} params.page - The page that contains the tab.
 * @param {string} params.tab - The tab whose settings are to be modified.
 * @param {Object} params.data - The new settings for the tab.
 * @param {Object} params.userSettings - The current settings of the user.
 * @param {Function} params.editSettings - The function to edit the settings.
 * @returns {Function} A promise that resolves to the result of the modifyPageSettings function.
 */

const modifyTabSettings = async ({
  page,
  tab,
  data,
  userSettings,
  editSettings,
}) => {
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings.web,
    },
  };

  if (!userSettings?.web?.[page]) {
    newSettings.web[page] = {};
  }

  if (!userSettings?.web[page]?.[tab]) {
    newSettings.web[page][tab] = {};
  }

  newSettings.web[page].activeTab = tab;
  newSettings.web[page][tab] = { ...newSettings.web[page][tab], ...data };

  return editSettings(newSettings);
};

export default modifyTabSettings;
