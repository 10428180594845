import React from "react";
import PropTypes from "prop-types";
import { isSafari } from "react-device-detect";
import BaseButton from "./BaseButton";

const IconButton = ({
  disabled,
  className,
  wrapperClassName,
  wrapperStyle,
  imgClassName,
  iconClassName,
  hoverColorCN,
  icon,
  onClick,
  noMaxWidth,
  isIconOnRight,
  ...props
}) => (
  <div style={wrapperStyle} className={wrapperClassName}>
    <BaseButton
      disabled={disabled}
      noMaxWidth={isSafari || noMaxWidth}
      className={`${className} flex items-center justify-center`}
      iconClassName={iconClassName}
      hoverColorCN={hoverColorCN}
      iconLeft={
        icon &&
        !isIconOnRight && <img className={imgClassName} src={icon} alt="" />
      }
      iconRight={
        icon &&
        isIconOnRight && <img className={imgClassName} src={icon} alt="" />
      }
      onClick={onClick}
      {...props}
    />
  </div>
);

IconButton.propTypes = {
  /**
   * boolean to determine if button is disabled
   */
  disabled: PropTypes.bool,

  /**
   * classes to apply to the button
   */
  className: PropTypes.string,
  /**
   * classes to apply to the wrapper div
   */
  wrapperClassName: PropTypes.string,
  /**
   * classes to apply to the icon, by width and height is provided by default but can be overwritten
   */
  imgClassName: PropTypes.string,
  /**
   * classes to apply to the icon
   */
  iconClassName: PropTypes.string,
  /**
   * classes for button hover
   */
  hoverColorCN: PropTypes.string,
  /**
   * icon that will be displayed in the button
   */
  icon: PropTypes.node,
  /**
   * function called when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * boolean to determine if button should use max-width content css property
   */
  noMaxWidth: PropTypes.bool,
  isIconOnRight: PropTypes.bool,
  wrapperStyle: PropTypes.shape({}),
};

IconButton.defaultProps = {
  disabled: false,
  className: null,
  wrapperClassName: null,
  imgClassName: "w-4 h-4",
  iconClassName: undefined,
  hoverColorCN: undefined,
  icon: undefined,
  onClick: undefined,
  noMaxWidth: false,
  isIconOnRight: false,
  wrapperStyle: undefined,
};

export default IconButton;
