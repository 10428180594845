/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  CALENDAR_PATH,
  CONTACTS,
  CONTACT_TABS,
  CREATE_CONTACT_MODAL,
  GET_CONTACT_PATH,
  REMOVE_USER,
  PAGES,
} from "../../../constants";
import { contactsViewColumns } from "../../../stories/Components/ContactView/ContactsViewColumns";
import {
  hasDeletePermission,
  isEmployeeOrAdmin,
} from "../../../helpers/Permissions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useGoBack from "../../../hooks/useGoBack";
import { useMembers, useUsers } from "../../../hooks/useUsers.new";
import useUsersStreamInfo from "../../../hooks/useUsersStreamInfo";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import { useContactSwitchView } from "../../../hooks/useSwitchView";
import useUserDelete from "../../../hooks/useUserDelete";
import {
  formatContactForExport,
  handleCompanyImport,
  handleUserImport,
} from "../../../helpers/User";
import { useModalState } from "../../../state/modalState";
import handleDownloadUsers from "../../../helpers/Users/handleDownloadusers";
import { getUserFirstAndLast } from "../../../helpers/Utilities";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const getCompanyNameFromContact = (user, users) => {
  return user?.kind === "company"
    ? user?.company?.value
    : users?.find((u) => u.reference === user?.company?.value)?.company?.value;
};

const useContactList = () => {
  const [{ modals }, modalDispatch] = useModalState();
  const history = useHistory();
  const { navigateBack } = useGoBack();
  const { data: currentUser } = useCurrentUser();
  const [usersStreamInfo] = useUsersStreamInfo();
  const { data: usersData, isLoading: isFetching } = useUsers();
  const users = usersData?.users;
  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: templatesConfiguration } = useTemplatesConfiguration();
  const { data: companyContacts } = useMembers("company");
  const { mutate: deleteUsers } = useUserDelete();
  const [isShowingTable, setIsShowingTable] = useContactSwitchView();
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { getPageSettings, getActiveTab, setCurrentResourceScreen } =
    useAppPersistence();
  const location = useLocation();

  const nonMembersSelected = !selectedRows.some(
    (user) => user?.kind === "member"
  );

  const allowSelection =
    currentUser?.isAdmin || hasDeletePermission(CONTACTS, currentUser);

  // Allow deletion of non-member users only by admin users or users with can_delete permission
  const canDeleteUsers = nonMembersSelected && allowSelection;

  // prepare to navigaate to Contact view
  const pageSettings = getPageSettings(PAGES.CONTACTS);
  const onContactClick = (id) => {
    const activeTab = getActiveTab({
      page: PAGES.CONTACTS,
      resourceId: id,
    });
    const isCurrentPersistedResource = pageSettings?.resource?.id === id;
    const activeTabOrDefault = isCurrentPersistedResource
      ? activeTab
      : CONTACT_TABS.DETAILS_ID;
    history.push(`${GET_CONTACT_PATH(id)}?tab=${activeTabOrDefault}`);
  };

  if (!isEmployeeOrAdmin(currentUser)) {
    navigateBack(CALENDAR_PATH.split("/")[1]);
  }
  const templateSettings = useMemo(() => {
    if (!templatesConfiguration?.templates?.contact) {
      return [];
    }

    return templatesConfiguration?.templates?.contact
      ?.filter((template) => template.name !== "last_updated_column_view")
      ?.map((template) => ({
        ...template,
        isAdmin: !template.custom,
      }));
  }, [templatesConfiguration]);

  const contacts = useMemo(() => {
    const usersArray = [];
    if (!users?.length) return [];
    users?.forEach((user) => {
      const editableUser = { ...user };
      if (!user.metadata.deletedAt || !user.active) {
        const streamUser = usersStreamInfo?.find((su) => su.userId === user.id);
        editableUser.company = {
          ...user?.company,
          label: getCompanyNameFromContact(user, users),
        };
        editableUser.lastUpdated = user.metadata.lastUpdated;
        editableUser.lastActive = streamUser?.lastActive;
        editableUser.isOnline = streamUser?.isOnline;
        editableUser.fullName = getUserFirstAndLast(editableUser);

        usersArray.push(editableUser);
      }
    });
    return usersArray;
  }, [users, usersStreamInfo]);

  const disciplines = useMemo(() => {
    const list = [];
    // check for disciplines
    if (managementConfiguration?.management?.contact?.disciplines?.length > 0) {
      managementConfiguration?.management?.contact?.disciplines?.forEach(
        (discipline) => {
          // check if discipline is selected if it is add it to the list
          // if (!discipline.isSelected) return;
          list.push({
            label: discipline.display?.toLowerCase().replaceAll(" ", "_"), // force all lower case and all spaces to underscores
            value: discipline.id,
          });
        }
      );
    }

    if (list.length > 1) {
      list.sort(({ label: a }, { label: b }) => a.localeCompare(b));
    }
    return list;
  }, [managementConfiguration?.management?.contact?.disciplines]);

  const columns = useMemo(() => {
    return contactsViewColumns(disciplines);
  }, [disciplines]);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/contacts/123", currentPage will be "contacts"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.CONTACTS) {
      setCurrentResourceScreen(PAGES.CONTACTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  const onImport = useCallback(
    async (data) => {
      const allCompanyContacts = companyContacts;

      for (let i = 0; i < data.rows.length; i += 1) {
        try {
          const contact = data.rows[i];
          switch (contact?.contactType) {
            case "user": {
              // eslint-disable-next-line no-await-in-loop
              await handleUserImport(contact, allCompanyContacts);
              break;
            }
            case "company": {
              // eslint-disable-next-line no-await-in-loop
              await handleCompanyImport(contact, allCompanyContacts);
              break;
            }
            default: {
              console.error("Not a supported user type", contact?.contactType);
            }
          }
        } catch (e) {
          console.error("Error importing row at index", i, ". Error: ", e);
        }
      }
    },
    [companyContacts]
  );

  const downloadContacts = useCallback(() => {
    const formattedContacts = contacts.map((contact) =>
      formatContactForExport(contact)
    );
    handleDownloadUsers(formattedContacts, "ES Contacts");
  }, [contacts]);

  const isDisabledBuuton = modals?.find(
    (item) => item.modalType === CREATE_CONTACT_MODAL
  );

  const buttonActions = [
    ...(!isDisabledBuuton &&
    (currentUser?.isSuperAdmin ||
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("contacts", "can_write"))
      ? [
          {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { contactType: "contact" },
                modalType: CREATE_CONTACT_MODAL,
              });
            },
            title: "Create Contact",
          },
        ]
      : []),
    ...(!isDisabledBuuton &&
    (currentUser?.isSuperAdmin ||
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("contacts", "can_write"))
      ? [
          {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { contactType: "company" },
                modalType: CREATE_CONTACT_MODAL,
              });
            },
            title: "Create Company Contact",
          },
        ]
      : []),
    ...(currentUser?.isSuperAdmin ||
    currentUser?.isAdmin ||
    currentUser?.hasPermission?.("contacts", "can_write")
      ? [
          {
            onClick: () => setShowImportModal(true),
            title: "Import",
          },
        ]
      : []),
    {
      onClick: downloadContacts,
      title: "Download",
    },
  ];

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  // Remove users finalized in removeUserModal
  const onRemoveUser = useCallback(
    (usersToRemove) => {
      // deleteUsers uses the $bulk endpoint and expects an array of userRefs
      const formattedUsers = [...usersToRemove].map((item) => item.reference);
      deleteUsers([...formattedUsers]);
    },
    [deleteUsers]
  );

  // SelectedUsers is original [], onRemoveUser expects updated list of users to remove
  const handleRemoveUser = () => {
    const id = uuidv4();
    modalDispatch({
      type: ADD_OPEN_MODAL,
      position: {
        centered: true,
      },
      ref: { id },
      modalData: {
        id,
        item: {
          id,
          selectedUsers: selectedRows,
          onSave: onRemoveUser,
        },
      },
      modalType: REMOVE_USER,
    });
  };

  return {
    currentUser,
    selectedRows,
    isShowingTable,
    setIsShowingTable,
    isFetching,
    disciplines,
    showImportModal,
    setShowImportModal,
    onImport,
    handleRowSelect,
    handleRemoveUser,
    onContactClick,
    buttonActions,
    canDeleteUsers,
    contacts,
    columns,
    templateSettings,
    allowSelection,
  };
};

export default useContactList;
