import React from "react";
import Spinner from "../../stories/Components/Spinner/Spinner";
import MainContainer from "../../Pages/MainContainer";
import ESChatContext from "../../context/ChatContext/ESChatContext";
import useAuthenticatedContainerData from "./useAuthenticatedContainerData";

const AuthenticatedContainer = () => {
  const { isLoading, currentUser, isRegistered } =
    useAuthenticatedContainerData();

  if (isLoading || !currentUser?.id) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spinner notFullScreen />
      </div>
    );
  }

  // TODO: RealTimeProvider will wrap this container to provided RealTime capabilities only when users is successfully authenticated
  return (
    <ESChatContext>
      <MainContainer isLoading={isLoading} isRegistered={isRegistered} />
    </ESChatContext>
  );
};

export default AuthenticatedContainer;
