import React, { useEffect, useState } from "react";
import useAssets from "../../../hooks/useAssets";
import useEditModal from "../../../hooks/useEditModal";
import {
  useAddFiles,
  useGetFiles,
  useRemoveFile,
  useUpdateFile,
} from "../../../hooks/useGetPaginatedFiles";
import { useSop } from "../../../hooks/useSop";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import { toastError } from "../../../stories/Components/Toast/Toast";
import Widget from "../../../stories/Components/Widget/Widget";

const FileOverview = () => {
  const [, setIsEditModalOpen] = useEditModal();
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const { data } = useGetFiles(page);
  const addFiles = useAddFiles(page);
  const removeFile = useRemoveFile(page);
  const updateFile = useUpdateFile(page);

  // TODO: All resources dict is needed in appState to show the association in File overview table;
  // Project/Property/Assets are preloaded so not fetching those again
  // Once all these hooks are migrated to Reat Query we can call all these hooks here just to be sure
  useAssets();
  useSop();

  useEffect(() => {
    const pages = Object.keys(data?.paginatedFilesDict || {});
    if (pages.length) {
      let allFiles = [];
      pages.forEach((pg) => {
        allFiles = [...allFiles, ...data?.paginatedFilesDict[pg]];
      });
      setFiles(allFiles);
    }
  }, [data]);

  const onUpdatePage = () => {
    setPage((prev) => prev + 1);
  };

  const onUploadFile = (filePayloads) => addFiles.mutateAsync(filePayloads);

  const onRemoveFile = (fileRefs, fileResources) => {
    const hasAssociatedFiles = fileResources.some((file) => file?.association);
    if (hasAssociatedFiles) {
      toastError(
        "Selected file(s) are associated to a resource. Kindly remove the file from the associated resource."
      );
    } else {
      removeFile.mutateAsync(fileRefs);
    }
  };

  const onUpdateFile = ({ originalResource, currentTags, name }) => {
    updateFile.mutateAsync({
      id: originalResource.id,
      prevFile: originalResource,
      updatedFile: {
        ...originalResource,
        tags: currentTags?.map((tag) => tag?.value),
        name,
      },
    });
  };

  return (
    <>
      <PrimaryHeaderItems headerTitle="Files" />
      <Widget draggable={false} title={null} overflow backToTop>
        <FilesTable
          files={files}
          isFileOverview
          hasMoreData={data?.hasMore}
          resourceName="FileOverview"
          hasWritePermission
          hasDeletePermission
          hasEditPermission
          fetchDataOnScroll={onUpdatePage}
          removeFilesAndUpdateApi={() => {}}
          onRemoveFilesCallback={onRemoveFile}
          setIsEditModalOpen={setIsEditModalOpen}
          uploadFileOverwrite={onUploadFile}
          handleUpdateFile={onUpdateFile}
        />
      </Widget>
    </>
  );
};

FileOverview.propTypes = {};

export default FileOverview;
