import { useQueryClient } from "react-query";
import {
  REACTIVATE_SUBSCRIPTION_POPUP,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { useAppState } from "../../../../state/appState";
import SignUpAPI from "../../../../Pages/SignUp/SignUpAPI";

export default function useSubscriptionCancelledWarningData({
  subscriptionDetails,
  dispatchBilling,
}) {
  const queryClient = useQueryClient();
  const [, appStateDispatch] = useAppState();

  const handleSubmit = async () => {
    const res = await SignUpAPI.reactivateSubscription(
      subscriptionDetails?.subscriptionId,
      queryClient
    );
    dispatchBilling({
      type: "cancelSubscription",
      toCancelAtPeriodEnd: res?.cancel_at_period_end,
      expireDate: res?.cancel_at
        ? new Date(res?.cancel_at * 1000).toISOString()
        : undefined,
    });
  };

  const onClick = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        popupWidth: 425,
        handleSubmit,
      },
      popupType: REACTIVATE_SUBSCRIPTION_POPUP,
    });
  };

  return {
    onClick,
  };
}
