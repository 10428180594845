import gatherSheetInfo from "../../../../../helpers/Timesheets/gatherSheetInfo";

/**
 * Processes timesheet entries for viewing and removes unnecessary section data for an entry section of a timesheet.
 *
 * @param {Object} entriesForEditing - An object containing key-value pairs where keys are entry IDs, values contain details about each entry including project information.
 * @param {Object} projectsState - The current state of all projects with relevant rate sheets or codes if applicable.
 * @param {Object} financialsConfiguration - Configuration for the financial system which might include rate sheets to use as fallback sources.
 * @param {Object} projectDict - A dictionary mapping each project reference to its name and other properties such as rate sheet information.
 * @param {HTMLSelectElement[]} projectsDD - Dropdown elements representing available projects from which selections can be made.
 * @param {Array<Object>} filteredCategoriesByProjectRate - An array of categories that have been filtered by the rate-sheet criteria for each project reference provided in `entriesForEditing`.
 * @param {Object} toolTip - an object to keep track of which note is open and to what entry
 * @param {Function} isBeforeHire - a function to tell me if the date goes before the users hire date
 *
 * @returns {{tsEntryData: (null|{totalHours: number|string, multiLine: boolean, hasError: string, key: *})[]}} Returns an object with processed timesheet entry data. Each entry includes total hours worked (`totalHours`), whether there are errors (`hasError`) and if the error is related to missing rates (`multiLine`). The `key` property retains the original entry ID.
 */

const useViewEntrySectionData = ({
  entriesForEditing,
  projectsState,
  projectDict,
  projectsDD,
  financialsConfiguration,
  filteredCategoriesByProjectRate,
  isBeforeHire,
  toolTip,
}) => {
  const tsEntryData = Object.entries(entriesForEditing || {}).map(
    ([key, values]) => {
      if (!values) return null;
      const projectReference = values.find((value) => value.project)?.project;

      const financialCodes = values.find(
        (value) => value.financialCode
      )?.financialCode;

      const { rates, ddErrors } = gatherSheetInfo({
        projectsState,
        projectReference,
        financialsConfiguration,
        projectDict,
        filteredCategoriesByProjectRate,
        projectsDD,
        values,
      });

      const nestedVals = [];

      values.forEach((entry) => {
        nestedVals.push({
          disabled:
            !projectReference ||
            !rates ||
            !financialCodes.division ||
            isBeforeHire(entry?.date),
          entry,
          toolTip,
          key,
        });
      });

      if (ddErrors.length > 0) {
        nestedVals.push({ error: ddErrors[0], key });
      }

      return nestedVals;
    }
  );

  return { tsEntryData };
};

export default useViewEntrySectionData;
