import { useMutation, useQueryClient } from "react-query";
import editSettingsHandler from "./handlers/editSettingsHandler";
import editSettingsResponder from "./responders/editSettingsResponder";

const useEditSettings = () => {
  const queryClient = useQueryClient();

  const editMutation = useMutation(
    editSettingsHandler,
    editSettingsResponder(queryClient)
  );

  const editSettings = async (newSettings) => {
    const response = await editMutation.mutateAsync(newSettings);
    return response;
  };

  const {
    isLoading: editSettingsLoading,
    isError: hasEditSettingsError,
    error: editSettingsError,
    isSuccess: editSettingsSuccess,
  } = editMutation;

  return {
    editSettings,
    editSettingsLoading,
    hasEditSettingsError,
    editSettingsError,
    editSettingsSuccess,
  };
};

export default useEditSettings;
