/* eslint-disable no-param-reassign */
import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { toastError, toastMessage } from "../../../../helpers/Toast";
import PrimaryButton from "../../../../stories/Components/Buttons/PrimaryButton";
import Modal from "../../../../stories/Components/Modal/Modal";
import PayrollTable from "../../../../stories/Components/TimeSheetPayrollTable/PayrollTable";
import whiteCircleCheckIcon from "../../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../../stories/assets/images/whiteExclamationIcon.svg";
import usePayrollData from "./usePayrollViewData";
import Header from "./Header";
import {
  ADD_OPEN_MODAL,
  PAYROLL_CSV_COLUMNS_MODAL,
} from "../../../../constants";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const PayrollView = ({ currentUser, usersDict }) => {
  const {
    handlePayrollToCsv,
    submitTimeSheets,
    timesheetsLoading,
    financials,
    payrollHistory,
    timesheetsToDisplay,
    pto,
    selectedRows,
    setSelectedRows,
    warnings,
    confirmModal,
    setConfirmModal,
    modalDispatch,
    primaryButtonActionsDisabled,
    employeeCount,
    approvedTimesheetCount,
  } = usePayrollData({ currentUser, usersDict });

  const primaryActions = useMemo(() => {
    let options = [];

    if (
      selectedRows.length !== 0 &&
      selectedRows?.find((item) => item.payrollStatus !== "approved")
    ) {
      options = [
        {
          title: "Approve",
          onClick: () => setConfirmModal(true),
        },
      ];
    }

    if (timesheetsToDisplay?.length !== 0) {
      options.push({
        title: "Download CSV",
        onClick: () => {
          modalDispatch({
            type: ADD_OPEN_MODAL,
            modalData: {
              onClick: async (val) => {
                try {
                  await handlePayrollToCsv(false, val);
                } catch (err) {
                  console.error("Error creating CSV");
                }
              },
            },
            ref: { id: uuidv4() },
            modalType: PAYROLL_CSV_COLUMNS_MODAL,
          });
        },
      });
      options.push({
        title: " Download CSV By Project",
        onClick: () => {
          modalDispatch({
            type: ADD_OPEN_MODAL,
            ref: { id: uuidv4() },
            modalData: {
              onClick: async (val) => {
                try {
                  await handlePayrollToCsv(true, val);
                  toastMessage(
                    "Successfully downloaded payroll",
                    toastIcon,
                    toastCloseIcon
                  );
                } catch (err) {
                  console.error("Error creating CSV");
                  toastError(
                    "Error downloading payroll",
                    toastErrorIcon,
                    toastCloseIcon
                  );
                }
              },
            },
            modalType: PAYROLL_CSV_COLUMNS_MODAL,
          });
        },
      });
    }
    return options;
  }, [
    handlePayrollToCsv,
    timesheetsToDisplay,
    modalDispatch,
    setConfirmModal,
    selectedRows,
  ]);

  return (
    <div className={PageCN}>
      <PayrollTable
        financials={financials}
        currentUser={currentUser}
        timesheets={timesheetsToDisplay}
        payrollHistory={payrollHistory}
        loading={timesheetsLoading}
        pto={pto}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        warnings={warnings}
        handleEditRow={() => setConfirmModal(true)}
        customSiteHeader={() => (
          <Header
            className="items-center h-full w-1/2"
            buttons={
              <>
                {!timesheetsLoading && (
                  <div className="flex flex-row border border-gray-450 rounded-md mr-4 justify-center items-center">
                    <p className="flex text-gray-450 w-full text-base font-semibold px-3">
                      {`${approvedTimesheetCount} / ${employeeCount} Approved`}
                    </p>
                  </div>
                )}
                <PrimaryButton
                  title={
                    selectedRows.length === 0
                      ? "Actions"
                      : `(${selectedRows?.length}) Selected`
                  }
                  large
                  dropdownItems={primaryActions}
                  disabled={primaryButtonActionsDisabled}
                  className="whitespace-nowrap"
                />
              </>
            }
          />
        )}
      />
      <Modal
        title="Approve?"
        isOpen={confirmModal}
        alert
        primaryButtonTitle="Approve Payroll"
        primaryButtonOnClick={async () => {
          try {
            await submitTimeSheets();
            toastMessage(
              "Successfully saved payroll",
              toastIcon,
              toastCloseIcon
            );
          } catch (err) {
            console.error("Error submitting timesheets", err);
            toastError("Error saving payroll", toastErrorIcon, toastCloseIcon);
          } finally {
            setConfirmModal(false);
          }
        }}
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={() => setConfirmModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            This action cannot be undone, are you sure?
          </p>
        </>
      </Modal>
    </div>
  );
};

PayrollView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
    reference: PropTypes.string,
  }),
  usersDict: PropTypes.shape({}),
};

PayrollView.defaultProps = {
  currentUser: undefined,
  usersDict: undefined,
};

export default PayrollView;
