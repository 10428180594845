import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import deleteGreenIcon from "../../../assets/images/deleteGreenIcon.svg";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import Widget from "../../Widget/Widget";
import IconButton from "../../Buttons/IconButton";
import { handleLinkClick } from "../../../../helpers/Utilities";
import LinkInput from "../LinkInput";
import { truncateLabel } from "../../../../helpers/Tag";
import useLinksView from "./useLinksView";

const linkCN = () => cntl`
  flex 
  justify-center 
  items-center 
  flex-row
  w-full
`;
const containerStyle = (editing) => {
  return {
    minHeight: "100px",
    maxHeight: editing ? "380px" : "292px",
    minWidth: "100px",
  };
};

const LinksView = ({
  editing,
  resource,
  dispatch,
  disableEditing,
  widgetWidth,
  loading,
  className,
}) => {
  const {
    addLinksIsOpen,
    setAddLinksIsOpen,
    linkName,
    linkUrl,
    validUrl,
    handleDeleteClick,
    confirmEdit,
    handleChange,
    setLinkName,
    setLinkUrl,
  } = useLinksView({ dispatch });

  return (
    <Widget
      className={className}
      draggable={false}
      loading={loading}
      title="Links"
      width={widgetWidth}
    >
      <div className="flex flex-col" style={containerStyle(editing)}>
        <div
          className={`flex flex-col h-full ${
            editing ? "overflow-y-scroll" : "overflow-y-auto"
          }`}
        >
          {!!resource?.links?.length &&
            !loading &&
            resource?.links?.reduce((linkList, link, index) => {
              if (link?.url) {
                linkList.push(
                  <div className={linkCN()}>
                    <button
                      key={link?.id}
                      type="button"
                      className="flex w-full items-start cursor-pointer select-none"
                      onClick={() => handleLinkClick(link)}
                    >
                      <div
                        className={`flex ${
                          index === 0 ? "pb-2" : "py-2"
                        } items-start w-full gap-4`}
                      >
                        <div className="flex w-full">
                          <p
                            className="text-es-green text-es-normal font-es-normal"
                            style={{
                              textDecorationLine: "underline",
                              textDecorationColor: "#027d61",
                              lineHeight: "22px",
                            }}
                          >
                            {truncateLabel(link?.name || link?.url, 30)}
                          </p>
                        </div>
                      </div>
                    </button>
                    <IconButton
                      wrapperClassName={editing ? "pr-2" : "hidden pr-2"}
                      onClick={() => handleDeleteClick(link)}
                      icon={deleteGreenIcon}
                      disabled={disableEditing}
                    />
                  </div>
                );
              }
              return linkList;
            }, [])}
        </div>

        {/* Page is editing, Add Link, not yet pressed */}
        {editing && !addLinksIsOpen ? (
          <PlusCircleButton
            title="Add Link"
            onClick={() => setAddLinksIsOpen(!addLinksIsOpen)}
            disabled={disableEditing}
            className="flex w-full self-start pb-2"
            titleClassName="tracking-es-wide font-es-semibold text-es-normal text-es-green"
            style={{ color: "#027D61", fontSize: 16 }}
            noHover
          />
        ) : null}

        {/* Page is editing, Add link pressed */}
        {addLinksIsOpen && editing && (
          <LinkInput
            onSave={confirmEdit}
            onClose={() => setAddLinksIsOpen(false)}
            linkName={linkName}
            setLinkName={setLinkName}
            linkUrl={linkUrl}
            setLinkUrl={setLinkUrl}
            handleChange={handleChange}
            disableEditing={disableEditing}
            isUrlValid={validUrl}
          />
        )}
      </div>
    </Widget>
  );
};

LinksView.propTypes = {
  editing: PropTypes.bool,
  resource: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    additionalInfo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  disableEditing: PropTypes.bool,
  dispatch: PropTypes.func,
  widgetWidth: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

LinksView.defaultProps = {
  editing: false,
  resource: undefined,
  disableEditing: false,
  dispatch: () => {},
  widgetWidth: undefined,
  loading: true,
  className: undefined,
};

export default LinksView;
