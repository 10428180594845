import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { PAGES } from "../../constants";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";
import SpacesList from "../../stories/Components/SpacesList";

const Spaces = ({ setActions, className }) => {
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/spaces/123", currentPage will be "spaces"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.SPACES) {
      setCurrentResourceScreen(PAGES.SPACES, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  return <SpacesList setActions={setActions} className={className} />;
};

Spaces.propTypes = {
  setActions: PropTypes.func,
  className: PropTypes.string,
};

Spaces.defaultProps = {
  setActions: undefined,
  className: undefined,
};

export default Spaces;
