import { useEffect, useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import Auth from "../../helpers/Auth";

const useAuthenticatedContainerData = () => {
  const { data: currentUser } = useCurrentUser();

  const tenant = Auth.getTenant();

  const [isLoading, setIsLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    const checkRegisteredFlag = async () => {
      const isReg = await Auth.isRegistered(currentUser?.email);
      setIsRegistered(isReg);
      setIsLoading(false);
    };

    (async () => {
      if (currentUser?.email) {
        await checkRegisteredFlag();
      }
    })();
  }, [currentUser?.email]);

  return {
    isLoading,
    isRegistered,
    currentUser,
    tenant,
  };
};

export default useAuthenticatedContainerData;
