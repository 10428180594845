/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatCountriesDropdown } from "../../../../helpers/Address";

const countryList = require("country-list");

const usePaymentInformation = ({ billingFormData, dispatchBilling }) => {
  const onChange = useCallback(
    (val, field) => {
      dispatchBilling({
        type: field,
        value: val,
      });
    },
    [dispatchBilling]
  );
  const billingInfoComplete = useMemo(() => {
    let status = true;
    if (billingFormData?.cardElement) {
      status =
        !!billingFormData?.nameOnCard &&
        !!billingFormData?.nameOnCard?.length &&
        !!billingFormData?.cardNumber &&
        !!billingFormData?.expiration &&
        !!billingFormData?.cvc &&
        !!billingFormData?.billingCountry &&
        !!billingFormData?.billingCountry?.value?.length &&
        !!billingFormData?.billingZipCode &&
        !!billingFormData?.billingZipCode?.length;
    } else {
      status =
        !!billingFormData?.billingCountry &&
        !!billingFormData?.billingCountry?.value?.length &&
        !!billingFormData?.billingZipCode &&
        !!billingFormData?.billingZipCode?.length;
    }
    return status;
  }, [billingFormData]);

  const [dropdownCountries, setDropdownCountries] = useState([]);

  useEffect(() => {
    const countries = countryList.getNameList();

    const list = formatCountriesDropdown(countries);

    setDropdownCountries(list);
  }, []);

  const onChangeAddress = useCallback(
    (val) => {
      const billingAddress = Object.entries(val)?.reduce((obj, [key, item]) => {
        obj[`billing${key.charAt(0).toUpperCase() + key.slice(1)}`] = item;
        return obj;
      }, {});

      dispatchBilling({
        type: "addressField",
        value: {
          ...billingAddress,
          billingZipCode: billingAddress?.billingZipCode || "00000",
          billingCountry: {
            label:
              billingAddress?.billingCountry?.label ??
              billingAddress.billingCountry,
            value:
              billingAddress?.billingCountry?.value ??
              billingAddress.billingCountry,
            countryCode: dropdownCountries.find((country) =>
              billingAddress?.billingCountry?.label
                ? billingAddress?.billingCountry?.label === country.label
                : billingAddress?.billingCountry?.includes(country.label)
            ),
          },
          ...val,
        },
      });
    },
    [dispatchBilling, dropdownCountries]
  );

  const formAddress = useMemo(() => {
    return Object.entries(billingFormData ?? {})?.reduce((obj, [key, item]) => {
      if (key?.includes("billing")) {
        const strippedKey = key?.replace("billing", "");
        obj[`${strippedKey.charAt(0).toLowerCase() + strippedKey.slice(1)}`] =
          item;
      }
      return obj;
    }, {});
  }, [billingFormData]);

  return {
    onChange,
    billingInfoComplete,
    formAddress,
    onChangeAddress,
  };
};

export default usePaymentInformation;
