/* eslint-disable prefer-destructuring */
import { isEqual } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  RECURRENCE_FORM_POPUP,
  TOGGLE_POSITIONED_POPUP,
  recurrenceOptions,
} from "../../../../constants";
import { isFullDay } from "../../../../helpers/Calendar";
import describeRruleFromString from "../../../../helpers/Calendar/describeRruleFromString";
import getParentAssociationTimezone from "../../../../helpers/Calendar/getParentAssociationTimezone";
import generateRruleFromSelection from "../../../../helpers/Date/generateRruleFromSelection";
import updateTimeValueOnDate from "../../../../helpers/Date/updateTimeValueOnDate";
import { calendarEventSchema } from "../../../../helpers/FormValidations";
import { getUserFirstAndLast } from "../../../../helpers/Utilities";
import { useAssetsOverview } from "../../../../hooks/assets";
import { useProjectsOverview } from "../../../../hooks/projects";
import { usePropertiesOverview } from "../../../../hooks/properties";
import useAssociatedMembersAndContactsForEventTask from "../../../../hooks/useAssociatedMembersAndContactsForEventTask";
import useFormAssociation from "../../../../hooks/useFormAssociation";
import { useAppState } from "../../../../state/appState";

const useTaskFormData = ({
  dispatch,
  form,
  recurrenceDropDownRef,
  // sets a default value for functions if item is currently undefined
  setInputValid = () => {},
  setChangesMade = () => {},
  initialTask,
  setAssociation,
  associationLock,
  assetLock,
  spaceLock,
}) => {
  // Destructure and extract necessary items from appState
  const [{ userDict }, appStateDispatch] = useAppState();

  const { assetsDD } = useAssetsOverview();
  const { propertiesDict, propertiesDD } = usePropertiesOverview();
  const { projectDict, projectsDD: projectDD } = useProjectsOverview();
  const [originalForm] = useState(initialTask);
  const initialDayDif = moment(originalForm.endDate).diff(
    moment(originalForm.startDate),
    "days"
  );
  const [durationDays, setDurationDays] = useState(initialDayDif || 0);
  const [durationMinutes, setDurationMinutes] = useState(30);

  // focus state on last new step added
  const [focus, setFocus] = useState(false);

  const canAddStep = useMemo(() => {
    // only if no steps exist or last step is not new step
    return (
      form?.steps?.length === 0 ||
      !!(
        form?.steps?.[form?.steps?.length - 1]?.description ||
        form?.steps?.[form?.steps?.length - 1]?.sop
      )
    );
  }, [form]);

  // const [originalForm] = useState(form);
  const [fullOptions, setFullOptions] = useState([]);
  // hook to manage the association state
  const association = useFormAssociation({
    dispatch,
    form,
    associationLock,
    assetLock,
    spaceLock,
  });

  // `true` If `task` is new
  const isNewTask = !form?.id;

  const isOverdueTask = useMemo(() => {
    return (
      form?.previousTask?.endDate &&
      moment(form?.previousTask?.endDate)
        .clone()
        .endOf("day")
        .isBefore(moment()) &&
      !isNewTask
    );
  }, [form?.previousTask?.endDate, isNewTask]);

  const isSingleTask = useMemo(() => {
    return !form?.previousTask?.recurrence;
  }, [form?.previousTask?.recurrence]);

  // `true` if task has tags
  const taskHasTags = form?.currentTags?.length > 0;

  // Task's author name if existing task
  const createdBy = useMemo(() => {
    const authorRef = form?.metadata?.createdBy;
    if (!authorRef || !userDict) {
      return undefined;
    }

    const user = userDict[authorRef];
    return getUserFirstAndLast(user);
  }, [form?.metadata?.createdBy, userDict]);

  // Get association members and all system contacts
  const inviteesDD = useAssociatedMembersAndContactsForEventTask({
    eventForm: form,
    isTask: true,
  });

  useEffect(() => {
    setAssociation(association?.reference);
    // set timezone
    const parentTimezone = getParentAssociationTimezone(
      association?.reference,
      propertiesDict,
      projectDict
    );

    if (parentTimezone) {
      dispatch({
        type: "timezone",
        value: parentTimezone,
      });
    }
  }, [association, dispatch, projectDict, propertiesDict, setAssociation]);

  // This handler sets the priority based on the provided value.
  const handlePriorityUpdate = ({ value }) => {
    dispatch({
      type: "update",
      value: {
        priority: value,
      },
    });
  };

  // useState hook to manage the recurrence string state
  const [recurrenceString, setRecurrenceString] = useState(
    describeRruleFromString(form?.recurrence) || ""
  );

  // useState hook to manage the allDay state
  const [allDay, setAllDay] = useState(
    isFullDay(form?.startDate, form?.endDate, form?.allDay)
  );

  const INITIAL_FILES = {
    mediaFilesToAdd: [],
    nonMediaFilesToAdd: [],
  };

  const [filesState, setFilesState] = useState(INITIAL_FILES);

  useEffect(() => {
    // set the full options for the association dropdown
    setFullOptions(propertiesDD.concat(projectDD).concat(assetsDD));
  }, [projectDD, propertiesDD, assetsDD]);

  // function to help tell the difference in days
  const calculateDaysDifference = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "days");
  };

  // function to help tell the difference in minutes
  const calculateMinutesDifference = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "minutes");
  };

  const handleFilesToAdd = (files, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = [...prev.mediaFilesToAdd, ...files];
      } else {
        updatedFilesState.nonMediaFilesToAdd = [
          ...prev.nonMediaFilesToAdd,
          ...files,
        ];
      }

      return updatedFilesState;
    });

    dispatch({
      type: "update",
      value: {
        files: [...form?.files, ...files],
      },
    });
  };

  // handle removing files from media selector
  const handleFilesToRemove = (fileToRemove, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = prev.mediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      } else {
        updatedFilesState.nonMediaFilesToAdd = prev.nonMediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      }

      if (fileToRemove?.reference) {
        const newFormFiles = form?.files.filter(
          (file) => file?.reference !== fileToRemove?.ref
        );

        dispatch({
          type: "update",
          value: {
            files: newFormFiles,
          },
        });
      }

      return updatedFilesState;
    });

    dispatch({
      type: "update",
      value: {
        files: form?.files.filter((file) => file !== fileToRemove),
      },
    });
  };

  // Form validation
  // Track unsaved changes
  const checkValidation = useCallback(async () => {
    const isValid = await calendarEventSchema.isValid(form);
    setInputValid(isValid);
    setChangesMade(!isEqual(originalForm, form));
  }, [form, originalForm, setChangesMade, setInputValid]);

  // Validate form everytime a field changes
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  function handleEventName(value) {
    dispatch({
      type: "name",
      value,
    });
  }

  // This handler sets the association based on the provided value.
  const handleAssociation = (value) => {
    // Extract the association reference from the value object
    const associationReference = value.value;

    // set timezone
    const parentTimezone = getParentAssociationTimezone(
      associationReference,
      propertiesDict,
      projectDict
    );

    // Dispatch an action to update the association in the state or store
    dispatch({
      type: "removeAssociatedSpacesAssets",
    });
    dispatch({
      type: "association",
      value: associationReference,
    });

    dispatch({
      type: "timezone",
      value: parentTimezone,
    });
  };

  // This handler sets the description based on the provided value.
  const handleDescriptionChange = (value) => {
    dispatch({
      type: "description",
      value,
    });
  };

  // This handler sets the start date. The useCallback hook ensures that the function doesn't get
  // recreated unless the dependencies [allDay, dispatch] change.
  const handleStartDate = useCallback(
    (value) => {
      // preserve the time value already selected by user
      const updatedValue = updateTimeValueOnDate(
        value,
        form.startDate,
        form.timezone
      );

      const newOffset = moment.tz(updatedValue, form.timezone).utcOffset();
      const formattedDate = moment(updatedValue)
        .utcOffset(newOffset, true)
        .format();
      const formattedEndDate = moment(updatedValue)
        .utcOffset(newOffset, true)
        .add(durationDays, "days")
        .add(durationMinutes, "minutes")
        .format();

      // Dispatch an action to update the startDate and endDate in the state or store.
      // The date is formatted using moment.js before dispatching.
      dispatch({
        type: "startDate",
        value: formattedDate,
        allDay,
        endDate: formattedEndDate,
      });
    },
    [
      allDay,
      dispatch,
      durationDays,
      durationMinutes,
      form.startDate,
      form.timezone,
    ]
  );

  /**
   * Sets the selected end date
   */
  const handleEndDate = useCallback(
    (value) => {
      // preserve the time value already selected by user
      const updatedValue = updateTimeValueOnDate(
        value,
        form.endDate,
        form.timezone
      );
      const newOffset = moment.tz(updatedValue, form.timezone).utcOffset();
      const formattedDate = moment(updatedValue)
        .utcOffset(newOffset, true)
        .format();

      setDurationDays(calculateDaysDifference(form?.startDate, formattedDate));

      dispatch({
        type: "endDate",
        value: formattedDate,
        allDay,
      });
    },
    [allDay, dispatch, form?.startDate, form.endDate, form.timezone]
  );

  /**
   * Sets the selected end time
   */
  const handleEndTime = useCallback(
    (value) => {
      const newOffset = moment.tz(value, form.timezone).utcOffset();
      const formattedDate = moment(value).utcOffset(newOffset, true).format();

      setDurationMinutes(
        calculateMinutesDifference(form.startDate, formattedDate)
      );

      dispatch({
        type: "endDate",
        value: formattedDate,
      });
    },
    [dispatch, form.startDate, form.timezone]
  );

  // This handler updates the allDay status of an event. It ensures the event starts
  // at the beginning of the day and ends at the end of the day.
  const handleAllDay = useCallback(
    (value) => {
      // If value isn't provided, default to form.startDate
      const dateToUse = value ?? form?.startDate;

      // Dispatch an action to set the start and end times for an all-day event
      dispatch({
        type: "allDay",
        start: moment.tz(dateToUse, form.timezone).startOf("day").format(),
        end: moment.tz(dateToUse, form.timezone).endOf("day").format(),
      });
    },
    [dispatch, form?.startDate, form.timezone]
  );

  // This function toggles the allDay status
  const toggleAllDay = useCallback(() => {
    if (allDay) {
      // If currently set as all day, change it to false and dispatch the start date.
      setAllDay(false);
      dispatch({
        type: "allDay",
        value: false,
      });
    } else {
      // If currently not set as all day, change it to true and handle all-day setting.
      setAllDay(true);
      dispatch({
        type: "allDay",
        value: true,
      });
      // handleAllDay();
    }
  }, [allDay, dispatch]);

  // This handler sets the start time of an event based on the provided value.
  const handleStartTime = (value) => {
    // Dispatch an action to update the start time in the state or store.

    const newOffset = moment.tz(value, form.timezone).utcOffset();
    const formattedDate = moment(value).utcOffset(newOffset, true).format();

    const formattedEndDate = moment(value)
      .utcOffset(newOffset, true)
      .add(durationMinutes, "minutes")
      .format();

    dispatch({
      type: "startDate",
      value: formattedDate,
      endDate: formattedEndDate,
    });
  };

  // This handler updates the recurrence pattern of an event based on the provided value.
  const handleRecurrenceChange = (value) => {
    if (value?.label === "Custom Repetition") {
      const rect = recurrenceDropDownRef.current.getBoundingClientRect();
      const { left, top } = rect;

      appStateDispatch({
        type: TOGGLE_POSITIONED_POPUP,
        position: {
          x: left - 400,
          y: top - 400,
        },
        popupData: {
          dispatch,
          noMaxHeight: true,
          setRecurrenceString,
          item: form,
        },
        popupType: RECURRENCE_FORM_POPUP,
      });
    } else {
      setRecurrenceString("");
      dispatch({
        type: "recurrence",
        value: {
          value: generateRruleFromSelection(value),
        },
      });
    }
  };

  // This handler sets the association based on the provided value.
  const handleResourceDDValue = () => {
    return fullOptions.find((item) => item?.value === form?.association);
  };

  /**
   * Invitees
   */
  const [inviting, setInviting] = useState({
    state: false,
    set: (v) => setInviting((prev) => ({ ...prev, state: v })),
  });

  // Handles the action of adding a new invitee.
  function handleAddInvitee({ reference }) {
    // Dispatches an action to add the invitee to the state or store.
    dispatch({
      type: "addInvite",
      value: reference,
    });
  }

  // Handles the action of removing an invitee.
  function handleRemoveInvitee(value) {
    // Dispatches an action to remove the specified invitee from the state or store.
    dispatch({
      type: "removeInvite",
      value,
    });
  }

  // Current Members Invited to Task
  const currentInvitees = useMemo(() => {
    return form.invitees.map((item) => userDict?.[item]);
  }, [form.invitees, userDict]);

  // Handles the action of adding a new step.
  const handleAddStep = () => {
    // Dispatches an action to add a new step to the state or store,
    if (canAddStep) {
      // focus on the newly added step
      setFocus(true);

      dispatch({
        type: "addStep",
      });
    }
  };

  // Handles the action of editing an existing step.
  const handleStepEdit = (index, key, value, sopVersion) => {
    // Dispatches an action to edit the specified step in the state or store.
    dispatch({
      type: "editStep",
      key,
      value,
      index,
      version: sopVersion,
    });
  };

  // Handles the action of removing a step.
  const handleStepRemove = (index) => {
    // Dispatches an action to remove the specified step from the state or store.
    dispatch({
      type: "removeStep",
      index,
    });
  };

  // Handles updates to the list of attached files.
  const handleFilesUpdated = (updatedFiles) => {
    // Dispatches an action to update the attachments in the state or store.
    dispatch({
      type: "addAttachment",
      value: updatedFiles,
    });
  };

  // Handles the action of removing an attachment.
  const removeAttachment = (id, key) => {
    // Dispatches an action to remove the specified attachment from the state or store.
    dispatch({
      type: "removeAttachment",
      id,
      key,
    });
  };

  const handleChangeDuration = (key, value) => {
    dispatch({
      type: "duration",
      key,
      value,
    });
  };

  /**
   * Locations Handlers
   */
  const spacesOrAssetsAvailable = useMemo(() => {
    return (
      form?.association?.includes("Project") ||
      form?.association?.includes("Property")
    );
  }, [form?.association]);

  const handleSelectSpaces = useCallback(
    (value) => {
      dispatch({
        type: "selectSpaces",
        value,
      });
    },
    [dispatch]
  );
  const handleSelectAssets = useCallback(
    (value) => {
      dispatch({
        type: "selectAssets",
        value,
      });
    },
    [dispatch]
  );

  const handleRemoveSpaces = (space) => {
    dispatch({
      type: "removeSpaces",
      value: space,
    });
  };
  const handleRemoveAssets = (asset) => {
    dispatch({
      type: "removeAssets",
      value: asset,
    });
  };
  /**
   * Locations Handlers
   */

  useEffect(() => {
    // Retrieves the "formCalendarDate" item from the browser's local storage.
    const formCalendarDate = localStorage.getItem("formCalendarDate");
    // Checks if formCalendarDate exists (i.e., it's not null or undefined).
    if (formCalendarDate) {
      // Dispatches an action to update the startDate in a reducer (Not State).
      dispatch({
        type: "startDate",
        value: moment(formCalendarDate).format(),
      });
    }

    // When the component unmounts, it removes the "formCalendarDate"
    // item from the local storage.
    return () => {
      localStorage.removeItem("formCalendarDate");
    };
  }, [dispatch]);

  const removeFormFileDuplicates = useCallback(() => {
    // get all objects from files state
    const allFilesObjects = [
      ...filesState.nonMediaFilesToAdd,
      ...filesState.mediaFilesToAdd,
    ];

    // get all ids from files state
    const allFilesIds = allFilesObjects.map((file) => file.id);

    // filter out files from form that are in files state
    const newFiles = form?.files?.filter(
      (file) => !allFilesIds.includes(file.id)
    );

    // return form with new files
    return {
      ...form,
      files: newFiles,
    };
  }, [filesState, form]);

  // Return the structured data related to the association, and a boolean indicating if there's an association or not
  return {
    associationData: {
      id: association?.id,
      // if the item is a property it will have a name, if it's a project it will have a title
      label: association?.name || association?.title,
      data: association?.reference,
    },
    inviteesDD,
    inviting,
    allDay,
    projectDD,
    propertiesDD,
    recurrenceString,
    currentInvitees,
    filesState,
    isNewTask,
    isOverdueTask,
    isSingleTask,
    taskHasTags,
    spacesOrAssetsAvailable,
    createdBy,
    recurrenceOptions,
    removeFormFileDuplicates,
    handlePriorityUpdate,
    handleDescriptionChange,
    handleFilesToAdd,
    handleFilesToRemove,
    setOpenInvite: (val) => inviting.set(val),
    handleAddInvitee,
    handleAllDay,
    handleResourceDDValue,
    handleRemoveInvitee,
    handleRecurrenceChange,
    handleStartDate,
    handleStartTime,
    handleEndDate,
    handleEndTime,
    handleEventName,
    toggleAllDay,
    handleAssociation,
    handleAddStep,
    handleStepEdit,
    handleStepRemove,
    handleFilesUpdated,
    removeAttachment,
    handleChangeDuration,
    handleSelectSpaces,
    handleRemoveSpaces,
    handleSelectAssets,
    handleRemoveAssets,
    focus,
    canAddStep,
  };
};

export default useTaskFormData;
