import React, { useMemo } from "react";
import useMyProfile from "./useMyProfile";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import FormAvatar from "../../stories/Components/Avatar/FormAvatar";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import MyProfileDetails from "../../stories/Components/MyProfileDetails";
import ChangePasswordModal from "../../stories/Components/ChangePasswordModal/ChangePasswordModal";

const widgetContainerCN =
  "p-2 border-es-light-grey shadow-lg border rounded-md";

const MyProfile = () => {
  const {
    userEmail,
    userPhoneNumber,
    userDate,
    userSocial,
    changeName,
    changeEmail,
    changePhoneNumber,
    changeDate,
    changeSocial,
    changeProfileImage,
    firstNameValidation,
    phoneNumberValidation,
    removeField,
    editableUser,
    activeIndex,
    setActiveIndex,
    isEditing,
    handleEditClick,
    isCompany,
    handleResetState,
    handleSave,
    changePasswordModalOpen,
    setChangePasswordModalOpen,
    saveUpdatedPassword,
    isFormValid,
    changePronunciation,
    changeTimezone,
    timezoneOptions,
  } = useMyProfile();

  const tabs = useMemo(() => {
    const tabsArr = [
      {
        title: "My Details",
        content: (
          <MyProfileDetails
            isEditing={isEditing}
            editableUser={editableUser}
            changeName={changeName}
            firstNameValidation={firstNameValidation}
            userPhoneNumber={userPhoneNumber}
            phoneNumberValidation={phoneNumberValidation}
            changePhoneNumber={changePhoneNumber}
            userEmail={userEmail}
            changeEmail={changeEmail}
            changeDate={changeDate}
            userDate={userDate}
            removeField={removeField}
            userSocial={userSocial}
            changeSocial={changeSocial}
            setChangePasswordModalOpen={setChangePasswordModalOpen}
            changePronunciation={changePronunciation}
            changeTimezone={changeTimezone}
            timezoneOptions={timezoneOptions}
          />
        ),
      },
    ];
    return tabsArr;
  }, [
    changeDate,
    changeEmail,
    changeName,
    changePhoneNumber,
    changePronunciation,
    changeSocial,
    changeTimezone,
    editableUser,
    firstNameValidation,
    isEditing,
    phoneNumberValidation,
    removeField,
    setChangePasswordModalOpen,
    timezoneOptions,
    userDate,
    userEmail,
    userPhoneNumber,
    userSocial,
  ]);

  const contactHeader = () => (
    <div className="flex flex-row items-center min-w-max">
      <FormAvatar
        user={!isCompany}
        isEditing={isEditing}
        image={editableUser?.avatar}
        onChange={changeProfileImage}
        disabled={!isEditing}
      />
      <div className="flex flex-col min-w-full">
        <p
          style={{ fontSize: "38px" }}
          className="text-es-black font-es-bold ml-6 mb-1 min-w-max"
        >
          {!isCompany
            ? `${editableUser?.name?.firstName || ""} ${
                editableUser?.name?.lastName || ""
              }`
            : editableUser?.company?.value}
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <SiteHeader
        title={contactHeader()}
        buttons={
          <PrimaryButton
            title="Edit Profile"
            className="hover:shadow-medium-lift"
            onClick={handleEditClick}
            disabled={isEditing}
          />
        }
      />
      <WidgetContainer
        isEditing={isEditing}
        handleEditClick={handleEditClick}
        showEditButton={false}
        onFinishEditing={handleSave}
        className={widgetContainerCN}
        tabs={tabs}
        activeIndex={activeIndex}
        onTabClick={(index) => setActiveIndex(index)}
        loading={false}
        disableSaveBtn={!isFormValid}
        disableEditing={false}
        resetResourceState={handleResetState}
        customStyle={{ minWidth: "1360px" }} // Slightly larger min width than Details container to cover padding
      />
      {changePasswordModalOpen && (
        <ChangePasswordModal
          isModalOpen={changePasswordModalOpen}
          setModalOpen={setChangePasswordModalOpen}
          onSaveNewPassword={saveUpdatedPassword}
        />
      )}
    </div>
  );
};

export default MyProfile;
