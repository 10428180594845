import React from "react";
import ModalWrapper from "../EsModal/ModalWrapper";
import ReportForm from "./ReportForm";
import PrimaryButton from "../Buttons/PrimaryButton";
import useReportsCreateModalData from "./useReportsCreateModalData";
import TertiaryButton from "../Buttons/TertiaryButton";

const ReportsCreateModal = ({ modalData }) => {
  const {
    showConfirm,
    modalTitle,
    reportForm,
    members,
    disableForm,
    disableAssociationDropdown,
    isDistroSelectOpen,
    associationType,
    isMultiAssociation,
    humanReadableRecurrence,
    assetsCategoriesData,
    includeInformationData,
    templateCheckbox,
    setTemplateCheckbox,
    setHumanReadableRecurrence,
    setIncludeInformationData,
    setAssetsCategoriesData,
    setDistroSelectOpen,
    handleCreateReport,
    handleChangeForm,
    handleTags,
    handleDistroMembers,
    handleDateRange,
    handleApplyTemplate,
  } = useReportsCreateModalData({ modalData });

  return (
    <ModalWrapper
      width="65rem"
      modalData={modalData}
      title={modalTitle}
      resourceName="Report Creation"
      showConfirm={showConfirm}
      disabled={disableForm}
      buttons={({ closeModal }) => (
        <div className="flex flex-row items-center gap-4">
          <PrimaryButton
            saveButton
            disabled={disableForm}
            onClick={handleCreateReport}
          />
          <TertiaryButton
            className="text-es-green"
            title="cancel"
            onClick={closeModal}
          />
        </div>
      )}
    >
      <ReportForm
        reportForm={reportForm}
        members={members}
        disableAssociationDropdown={disableAssociationDropdown}
        isDistroSelectOpen={isDistroSelectOpen}
        associationType={associationType}
        isMultiAssociation={isMultiAssociation}
        humanReadableRecurrence={humanReadableRecurrence}
        assetsCategoriesData={assetsCategoriesData}
        includeInformationData={includeInformationData}
        templateCheckbox={templateCheckbox}
        setTemplateCheckbox={setTemplateCheckbox}
        setHumanReadableRecurrence={setHumanReadableRecurrence}
        setDistroSelectOpen={setDistroSelectOpen}
        setIncludeInformationData={setIncludeInformationData}
        setAssetsCategoriesData={setAssetsCategoriesData}
        handleChangeForm={handleChangeForm}
        handleTags={handleTags}
        handleDistroMembers={handleDistroMembers}
        handleDateRange={handleDateRange}
        handleApplyTemplate={handleApplyTemplate}
      />
    </ModalWrapper>
  );
};

export default ReportsCreateModal;
