import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import Dropdown from "../../Dropdown/Dropdown";
import Modal from "../../Modal/Modal";
import { getInitials } from "../../../../helpers/User";
import useChangeStateOfEmploymentModalData from "./useChangeStateOfEmploymentModalData";

const avatarCN = cntl`
  bg-brandGreen
  rounded-full
  w-20
  h-20
  flex
  items-center
  justify-center
  mr-6
`;

const headerCN = cntl`
  text-lg
  font-semibold
`;

const ChangeStateOfEmploymentModal = ({
  user,
  setUser,
  onCancel,
  onSave,
  changeUserStateOfEmployment,
  isSaving,
  states,
  isPtoGeneral,
}) => {
  const { data, origUser, isChanged } = useChangeStateOfEmploymentModalData({
    user,
  });

  return (
    <Modal
      title="CHANGE STATE OF EMPLOYMENT"
      isOpen
      onRequestModalClose={onCancel}
      hideFooter
      primaryButtonTitle="Save"
      primaryButtonOnClick={() => onSave(user?.id)}
      tertiaryButtonTitle="Cancel"
      disabled={isSaving || isPtoGeneral || !isChanged}
    >
      <div className="flex flex-row">
        {(data?.thumbnailUrl && (
          <div className="bg-gray-200 flex justify-center items-center w-20 h-20 rounded-full mr-6 relative">
            <img
              src={data?.thumbnailUrl}
              className="w-full h-full rounded-full"
              alt="userAvatarIcon"
            />
          </div>
        )) || (
          <div className={avatarCN}>
            <p className="font-semibold uppercase text-white text-4xl">
              {getInitials(user?.name)}
            </p>
          </div>
        )}
        <div className="px-2 pt-3">
          <h2 className={headerCN}>
            {user?.name?.firstName} {user?.name?.lastName}
          </h2>
          <h2 className="text-sm text-gray-300">{user?.company}</h2>
        </div>
      </div>
      <div className="pt-5 h-72">
        <Dropdown
          label="State of Employment"
          options={states}
          value={states?.find(
            (state) => state.value === user?.employeeInfo?.pto?.locationId
          )}
          // onChange={changeUserStateOfEmployment}
          onChange={(val) => {
            if (!val.value) {
              setUser({
                ...user,
                employeeInfo: origUser.employeeInfo,
              });
            } else changeUserStateOfEmployment(val);
          }}
          defaultMenuIsOpen
        />
      </div>
    </Modal>
  );
};

ChangeStateOfEmploymentModal.propTypes = {
  onCancel: PropTypes.func,
  setUser: PropTypes.func,
  user: PropTypes.shape({
    employeeInfo: PropTypes.shape({
      pto: PropTypes.shape({
        locationId: PropTypes.string,
      }),
    }),
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    company: PropTypes.string,
  }),
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  changeUserStateOfEmployment: PropTypes.func,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  isPtoGeneral: PropTypes.bool,
};

ChangeStateOfEmploymentModal.defaultProps = {
  onCancel: undefined,
  user: undefined,
  setUser: () => {},
  onSave: undefined,
  isSaving: false,
  states: [],
  changeUserStateOfEmployment: undefined,
  isPtoGeneral: false,
};

export default ChangeStateOfEmploymentModal;
