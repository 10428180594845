import React from "react";
import { CONTACT_KIND_MAP } from "../../constants";
import { getFullName } from "../../helpers/Formatters";
import ContactCard from "../../stories/Components/ContactCard";
import AlphabetIndex from "./AlphabetIndex";
import useCardGrid from "./useCardGrid";
import CardSection from "./CardSection";

const scrollContainer = {
  height: "970px", // Figma Height
  scrollbarColor: "#585858",
  scrollBehavior: "smooth",
};
const sectionTitle = "my-2 text-es-large text-es-medium-grey font-es-bold";

const CardGrid = ({ currentUser, row = [], onContactClick }) => {
  const { orderedObj, getCompanyName, isActive, setIsActive } = useCardGrid({
    row,
  });
  return (
    <div className="flex flex-row justify-between w-full h-full gap-4 cardGridContainer">
      <div
        className="flex flex-col overflow-y-scroll gap-6"
        style={scrollContainer}
      >
        {Object.keys(orderedObj)?.map((contact) => (
          <CardSection
            key={contact}
            contact={contact}
            setIsActive={setIsActive}
          >
            <p className={sectionTitle}>{contact}</p>
            <div className="flex flex-wrap gap-6">
              {orderedObj &&
                orderedObj[contact]?.map((item) => (
                  <div key={`card-${item?.id}`}>
                    <ContactCard
                      currentUser={currentUser}
                      id={item.id}
                      title={
                        item?.kind === "company"
                          ? item?.company?.value
                          : getFullName(item?.name)
                      }
                      detail={
                        item?.kind !== "company"
                          ? getCompanyName(item?.company?.value)
                          : ""
                      }
                      tag={CONTACT_KIND_MAP[item?.kind]}
                      imageReference={item?.avatar}
                      resourceName={
                        item?.kind === "company" ? "Company" : "Contact"
                      }
                      onClick={() => onContactClick(item.id)}
                      isInactive={!item?.active}
                    />
                  </div>
                ))}
            </div>
          </CardSection>
        ))}
      </div>
      <div
        className="w-fit h-full flex flex-col"
        style={{ scrollbarWidth: "none" }}
      >
        <AlphabetIndex
          sortedElements={orderedObj}
          showIndex
          flexDirection="col"
          isActive={isActive}
        />
      </div>
    </div>
  );
};

export default CardGrid;
