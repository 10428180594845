import editTaskListStore from "../../../../helpers/Task/editTaskListStore";

const editTaskListResponder = (queryClient) => {
  return {
    onSuccess: (returnedData, variables) => {
      return editTaskListStore({
        queryClient,
        item: returnedData.data,
        variables,
      });
    },
    onError: (err) => {
      console.error("Error editing task list", err);
    },
  };
};
export default editTaskListResponder;
