/* eslint-disable no-param-reassign */
import moment from "moment";
import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  FEEDBACK_MODAL,
  FILTER_TYPES,
  PTO_TYPE_DISPLAY,
} from "../../../../constants";
import { getFullName } from "../../../../helpers/Formatters";
import getDateRange from "../../../../helpers/Timesheets/getDateRange";
import hideEditButtons from "../../../../helpers/Timesheets/hideEditButtons";
import editIcon from "../../../assets/images/editDarkenedGreenIcon.svg";
import deleteIcon from "../../../assets/images/trashDarkenedGreenIcon.svg";
import IconButton from "../../Buttons/IconButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Pill from "../../Pill/Pill";
import PureTimeOffTable from "../PureTimeOffTable";
import useTimeOffTableData from "./useTimeOffTableData";

const TimeOff = () => {
  const {
    approvedTsByUser,
    isFetching,
    data,
    isLoading,
    loadingMutation,
    userDict,
    selectedRows,
    modalDispatch,
    searchOverRide,
    handleRequestClick,
    setSearchOverRide,
    openCancelRequestModal,
    handleTableSelect,
    patch,
    handleBulkReview,
    // openGiveTimeOffModal,
  } = useTimeOffTableData();

  const columns = [
    {
      Header: "User Reference",
      accessor: "user",
      isHidden: true,
      show: false,
    },
    {
      Header: "canSelect",
      accessor: "hideSelection",
      isHidden: true,
      show: false,
    },
    {
      Header: "Employee",
      id: "employee.fullname",
      accessor: (row) => {
        return (
          userDict?.[row?.user]?.name &&
          getFullName(userDict?.[row?.user]?.name)
        );
      },
      Cell: ({ value }) => {
        return <p className="hover:text-primaryGreen truncate">{value}</p>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        if (value === "approved") {
          return (
            <Pill
              alt="approved"
              value="Approved"
              className="h-5"
              padding="p-2"
              textSize="text-xxs"
              background="bg-darkenedGreen"
              border="border-darkenedGreen"
            />
          );
        }
        if (value === "declined") {
          return (
            <Pill
              alt="rejected"
              value="Rejected"
              className="h-5"
              textColor="text-brandRed"
              textSize="text-xxs"
              background="bg-transparent"
              border="border border-brandRed"
              padding="p-2"
            />
          );
        }

        if (value === "cancelled") {
          return (
            <Pill
              alt="draft"
              value="Cancelled"
              className="h-5"
              textColor="text-pillGray"
              textSize="text-xxs"
              border="border border-pillGray"
              background="bg-transparent"
              padding="p-2"
            />
          );
        }
        return <p className="flex flex-row w-16 justify-start">--</p>;
      },
    },
    {
      Header: "Approve / Reject",
      accessor: "approvalStatus",
      Cell: ({ row, value }) => {
        const originalBody = {
          metadata: {
            lastUpdated: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          },
        };

        const { parent: id, id: requestId } = row?.original ?? {
          parent: 0,
          id: 0,
        };
        const extras = {
          params: { requestId },
        };

        if (value === "pending") {
          return (
            <div className="flex flex-row items-center justify-evenly rounded-full w-44 hover:shadow-medium-lift">
              <div className="w-1/2 bg-white hover:bg-backgroundGreen border-l border-t border-b border-pillGray rounded-l-2xl justify-center">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    patch({
                      op: "$approve",
                      id,
                      extras,
                      originalBody,
                    });
                  }}
                  className="text-xs text-pillGray w-full h-8 flex justify-center items-center"
                >
                  Approve
                </button>
              </div>
              <div className="w-1/2 bg-white hover:bg-backgroundRed border border-pillGray rounded-r-2xl justify-center">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    modalDispatch({
                      type: ADD_OPEN_MODAL,
                      ref: { id: uuidv4() },
                      modalData: {
                        resource: "Time Off",
                        submit: (feedback) =>
                          patch({
                            op: "$decline",
                            id,
                            extras,
                            originalBody,
                            body: { feedback },
                          }),
                      },
                      modalType: FEEDBACK_MODAL,
                    });
                  }}
                  className="text-xs text-pillGray w-full h-8 flex justify-center items-center"
                >
                  Deny
                </button>
              </div>
            </div>
          );
        }
        return <p className="w-32 flex flex-row justify-start">--</p>;
      },
      filterOptions: {
        filterType: FILTER_TYPES.isOrNot,
      },
    },
    {
      Header: "Type",
      id: "pto-type",
      minWidth: 200,
      accessor: ({ type }) => {
        return PTO_TYPE_DISPLAY[type];
      },
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Date",
      id: "date-requested",
      minWidth: 250,
      accessor: (row) => {
        return moment(row?.dateRequested).format("LL");
      },
      Aggregated: () => null,
      Cell: ({ value }) => (
        <span className="truncate" title={value}>
          {value}
        </span>
      ),
    },
    {
      Header: "Date Range",
      className: "",
      id: "date-range",
      minWidth: 390,
      accessor: ({ dates }) => getDateRange(dates),
      Cell: ({ value }) => {
        return (
          <p className="truncate" title={value}>
            {value}
          </p>
        );
      },
    },
    {
      Header: "Amount",
      accessor: "total",
      aggregate: "sum",
      Cell: ({ value }) => <span>{`${value} hours`}</span>,
      Aggregated: ({ row }) => {
        const summary = row?.subRows?.reduce((a, b) => {
          a += parseFloat(b?.original?.total);
          return a;
        }, 0);
        return <p className="w-full text-center">{`${summary} hours`}</p>;
      },
    },
    {
      Header: "Balance",
      id: "balance",
      accessor: (row) => {
        const summary = data?.timeoffDict[row?.user]?.original?.pto[row?.type];
        return summary?.available;
      },
      Aggregated: () => null,
      Cell: ({ value }) => (
        <span className="truncate text-center" title={value}>
          {value}
        </span>
      ),
    },
    {
      Header: "Action",
      className: "",
      accessor: "note",
      maxWidth: 80,
      minWidth: 80,
      Cell: ({ row }) => {
        if (
          !row?.values?.user ||
          !row?.original ||
          !approvedTsByUser ||
          hideEditButtons(row?.original, approvedTsByUser[row?.values?.user])
        ) {
          return null;
        }

        return (
          <div className="flex gap-5 w-full justify-center mr-2">
            <IconButton
              type="button"
              onClick={() => handleRequestClick(row, data)}
              icon={editIcon}
            >
              Modify
            </IconButton>
            <IconButton
              type="button"
              icon={deleteIcon}
              onClick={() => openCancelRequestModal(row)}
            >
              Cancel
            </IconButton>
          </div>
        );
      },
    },
  ];

  const customSiteHeader = useCallback(
    ({ viewOptions, search }) => {
      return (
        <div className="flex justify-between my-8">
          {/* Search Bar and Quick View Drop down Left side header */}
          <div className="flex">
            <div className="flex mr-4">{search}</div>
            <div className="flex">{viewOptions}</div>
          </div>
          {/* Future Give Time Off Button and Action Button right side */}
          <div className="flex gap-4">
            {/* 
            Opening form this scopes gives us an empty dropdown due to changes in the requests and where we get this data
            hiding buttons for now - can still give timeoff by selecting a user and clicking Give Time Off from there

            <SaveButton
              saveButtonTitle="Give Time Off"
              onClick={openGiveTimeOffModal}
              className="whitespace-nowrap cursor-pointer"
            /> 
            */}
            <PrimaryButton
              title={
                selectedRows <= 0 ? "Actions" : `(${selectedRows}) Selected`
              }
              large
              dropdownItems={[
                {
                  title: "Deny",
                  onClick: () => handleBulkReview("$decline"),
                },
                {
                  title: "Approve",
                  onClick: () => handleBulkReview("$approve"),
                },
              ]}
              disabled={selectedRows <= 0 || isFetching || loadingMutation}
              className="whitespace-nowrap pr-7"
            />
          </div>
        </div>
      );
    },
    [
      handleBulkReview,
      isFetching,
      loadingMutation,
      // openGiveTimeOffModal,
      selectedRows,
    ]
  );

  return (
    <div className="relative w-full h-full mt-3">
      <PureTimeOffTable
        searchOverRide={searchOverRide}
        setSearchOverRide={setSearchOverRide}
        originalEntries={data?.timeoff}
        loading={isLoading}
        columns={columns}
        selectedRows={selectedRows}
        handleTableSelect={handleTableSelect}
        isLoading={isFetching || loadingMutation}
        customSiteHeader={customSiteHeader}
      />
    </div>
  );
};

export default TimeOff;
