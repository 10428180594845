import { useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { debounce } from "lodash";
import { getFullAddressFromGoogleAutocompleteResults } from "../../../helpers/Address";
import { toastError } from "../Toast/Toast";

const { GOOGLE_GEOCODING_API_KEY: apiKey } = window.runConfig || {};

export default (value, handleSelect) => {
  const [placesService, setPlacesService] = useState();
  const [geoCoder, setGeoCoder] = useState();
  const [sessionToken, setSessionToken] = useState();

  // Initialize Google Maps API
  const initializeService = () => {
    if (!window.google)
      throw new Error(
        "[react-google-places-autocomplete]: Google script not loaded"
      );
    if (!window.google.maps)
      throw new Error(
        "[react-google-places-autocomplete]: Google maps script not loaded"
      );
    if (!window.google.maps.places)
      throw new Error(
        "[react-google-places-autocomplete]: Google maps places script not loaded"
      );

    // Set Google Maps Places Service
    setPlacesService(new window.google.maps.places.AutocompleteService());

    // Set Session Token
    // eslint-disable-next-line no-undef
    setSessionToken(new google.maps.places.AutocompleteSessionToken());

    setGeoCoder({
      coder: new window.google.maps.Geocoder(),
      status: window.google.maps.GeocoderStatus?.OK,
    });
  };

  // Load Google Services
  const init = useCallback(async () => {
    if (!apiKey) {
      return;
    }
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      await new Loader({
        apiKey,
        ...{ libraries: ["places"] },
      }).load();
    }
    initializeService();
  }, []);

  useEffect(() => {
    if (apiKey) init();
    else initializeService();
  }, [init]);

  /**
   * Reference to fetch from google services api
   * @summary - used to cancel call
   */
  const fetchCall = useRef(null);

  // References to select options
  const optionRefs = useRef({});

  /**
   * Results State Object
   * @param {String} - value
   * @param {Boolean} - loading
   * @param {Array} - Options
   * @param {Function} - list (set options returned from api)
   * @param {Function} - fetching (currently fetching loading = true, options = [userInput])
   * @param {Function} - clear (cancel fetch & clear input)
   * @summary - Consice definition of hook functionality
   */
  const [results, setResults] = useState({
    value,
    loading: false,
    options: [],
    request: null,
    list: (res) => {
      fetchCall.current = null;
      setResults((prev) => ({
        ...prev,
        loading: false,
        options: [{ description: prev.value }, ...(res?.predictions ?? [])],
      }));
    },
    fetching: (input) =>
      setResults((prev) => ({
        ...prev,
        loading: true,
        value: input,
        options: [{ description: input }],
      })),
    clear: () => {
      // Cancel fetch
      if (fetchCall.current) fetchCall.current.cancel();
      // Clear Option Refs
      optionRefs.current = {};
      setResults((prev) => ({
        ...prev,
        value: "",
        loading: false,
        options: [],
        request: null,
      }));
      handleSelect("");
    },
    reset: () => {
      if (fetchCall.current) fetchCall.current.cancel();
      // Clear Option Refs
      optionRefs.current = {};
      setResults((prev) => ({
        ...prev,
        options: [],
        loading: false,
        request: null,
      }));
    },
    // Update value after option select
    updateValue: (val) => setResults((prev) => ({ ...prev, value: val })),
  });

  // Debounced call to GooglePlacesAPI
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchOptions = useCallback(
    debounce(async (input) => {
      try {
        const response = await placesService?.getPlacePredictions({
          input,
          sessionToken,
        });
        // List Response
        results.list(response);
      } catch (e) {
        toastError("Error fetching address suggestions");
      }
    }, 3000),
    [placesService]
  );

  /**
   *
   * @param {String} input
   * @summary - On input change set value & reference to debounced call
   */
  const onChange = async (input) => {
    if (placesService) {
      // Clear Option Refs
      optionRefs.current = {};
      results.fetching(input.target.value);
      // Set reference to debounced fetch
      fetchCall.current = fetchOptions;
      // Call debounced fetch from reference
      fetchCall.current(input.target.value);
    }
  };

  const getByPlaceId = useCallback(
    (option) => {
      return new Promise((resolve, reject) => {
        geoCoder?.coder?.geocode(
          { placeId: option.place_id },
          (res, status) => {
            if (status !== geoCoder?.status) {
              return reject(status);
            }
            return resolve(res);
          }
        );
      });
    },
    [geoCoder?.coder, geoCoder?.status]
  );

  const onOptionClick = useCallback(
    async (option, index) => {
      // Closes Dropdown
      document.activeElement.blur();
      // Reset options
      results.reset();
      // Handle selection of custom user input(always index = 0)
      if (index === 0)
        return {
          street: option.description,
        };
      try {
        // Get full address from GoogleGeoCode
        let fullAddress = await getByPlaceId(option);
        fullAddress = getFullAddressFromGoogleAutocompleteResults(fullAddress);
        results.updateValue(fullAddress.street);
        return {
          street: fullAddress.street,
          street2: fullAddress.street2,
          city: fullAddress.city,
          country: fullAddress.country,
          state: fullAddress.state,
          zipCode: fullAddress.zipCode,
        };
      } catch (e) {
        // If Error return user input
        toastError("Error selecting address option.");
        return {
          street: option.description,
        };
      }
    },
    [getByPlaceId, results]
  );

  const handleKeyPress = useCallback(
    (e) => {
      const activeElementId = document.activeElement.id;
      const activeOption = optionRefs.current[activeElementId];
      const [, index] = activeElementId.split("address-option-");
      const nextRef = optionRefs.current?.[`address-option-${+index + 1}`];
      const prevRef = optionRefs.current?.[`address-option-${+index - 1}`];

      switch (e.code) {
        case "ArrowDown":
          e.preventDefault();
          if (activeOption && nextRef) {
            nextRef.focus();
          } else {
            optionRefs.current?.["address-option-0"].focus();
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          if (activeOption && prevRef) {
            prevRef.focus();
          } else {
            optionRefs.current?.[
              `address-option-${results.options.length - 1}`
            ].focus();
          }
          break;
        case "Enter":
          e.preventDefault();
          if (activeOption) {
            activeOption.click();
            activeOption.blur();
          } else {
            optionRefs.current?.[`address-option-0`].click();
            document.activeElement.blur();
          }
          break;
        case "Tab":
          if (optionRefs.current?.[`address-option-0`])
            optionRefs.current?.[`address-option-0`].click();
          break;
        default:
      }
    },
    [results.options.length, optionRefs]
  );

  const onSelect = useCallback(
    async (option, index) => {
      const selection = await onOptionClick(option, index);
      handleSelect(selection);
    },
    [handleSelect, onOptionClick]
  );

  return {
    onChange,
    options: results.options,
    value: results.value,
    loading: results.loading,
    clear: results.clear,
    onOptionClick,
    optionRefs: optionRefs.current,
    handleKeyPress,
    onSelect,
  };
};
