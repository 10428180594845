import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import * as yup from "yup";
import { phoneRegExp } from "../../../../helpers/Formatters";
import { getTimeZoneOptions } from "../../../../helpers/Time";

const useProfileDetailsForm = ({
  editableUser,
  setEditableUser,
  setTmpAvatarImg,
}) => {
  const [isHidden, setIsHidden] = useState({ one: "", two: "", three: "" });
  const timezoneOptions = getTimeZoneOptions();

  // Variables ->
  const userEmail = useMemo(() => {
    return editableUser?.contactPoint?.find((item) => item?.system === "Email")
      ?.value;
  }, [editableUser?.contactPoint]);

  const userDate = useCallback(
    (occasion) => {
      return editableUser?.dates?.find((date) => date?.occasion === occasion)
        ?.value;
    },
    [editableUser?.dates]
  );

  const userSocial = useMemo(() => {
    return editableUser?.social?.find((item) => item.platform === "LinkedIn")
      ?.value;
  }, [editableUser?.social]);

  const userPhoneNumber = useMemo(() => {
    return editableUser?.contactPoint?.find((item) => item?.system === "Phone")
      ?.value;
  }, [editableUser?.contactPoint]);

  /**
   * @param {String} value input value for the appropriate name field
   * @param {String} key "firstName" || "lastName";
   */
  const changeName = (value, key) => {
    setEditableUser((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        [key]: value, // firstName || lastName
      },
    }));
  };

  /**
   * @param {String} value Replace users "Home" email
   * Email user is signed up with (user.email) is not changed.
   */
  const changeEmail = (value) => {
    const replaceHomeEmail = editableUser?.contactPoint?.map((item) => {
      if (item?.system === "Email") {
        const newEmailValue = { ...item };
        newEmailValue.value = value;
        return newEmailValue;
      }
      return item;
    });

    setEditableUser((prev) => ({
      ...prev,
      contactPoint: [...replaceHomeEmail],
    }));
  };

  /**
   * @param {String} value Replace user "Home" PhoneNumber
   */
  const changePhoneNumber = (value) => {
    const replaceHomePhone = editableUser?.contactPoint?.map((item) => {
      if (item?.system === "Phone") {
        const newPhoneValue = { ...item };
        newPhoneValue.value = value;
        return newPhoneValue;
      }
      return item;
    });

    setEditableUser((prev) => ({
      ...prev,
      contactPoint: [...replaceHomePhone],
    }));
  };

  /**
   * @param {String} value date value from <DatePicker />
   * @param {String} occasion "Start Date" || "Birthday"
   */
  const changeDate = (value, occasion) => {
    const newDates = editableUser?.dates?.map((date) => {
      if (date?.occasion === occasion) {
        const newDate = { ...date };
        newDate.value = moment(value).utc().format();
        return newDate;
      }
      return date;
    });
    setEditableUser((prev) => ({
      ...prev,
      dates: [...newDates],
    }));
  };

  const changeSocial = (value) => {
    const socials = editableUser?.social?.map((item) => {
      if (item?.platform === "LinkedIn") {
        const newItem = { ...item };
        newItem.value = value;
        return newItem;
      }
      return item;
    });
    setEditableUser((prev) => ({
      ...prev,
      social: [...socials],
    }));
  };

  const changeProfileImage = (tempImageFile) => {
    const tempImageURL = URL.createObjectURL(tempImageFile[0].original);
    setTmpAvatarImg((prev) => ({
      ...prev,
      image: tempImageURL,
      tempImageFile: tempImageFile[0],
    }));
  };

  const changeTimezone = (val) => {
    setEditableUser((prev) => ({
      ...prev,
      timezone: val,
    }));
  };

  const changePronunciation = (val) => {
    setEditableUser((prev) => ({
      ...prev,
      pronunciation: val,
    }));
  };

  const removeField = (index) => {
    const removeStartDate = editableUser?.dates?.filter(
      (item) => item.occasion !== "Start Date"
    );
    const removeBirthday = editableUser?.dates?.filter(
      (item) => item.occasion !== "Birthday"
    );
    const removeSocial = editableUser?.social?.filter(
      (item) => item.platform !== "LinkedIn"
    );

    switch (index) {
      case "one":
        setEditableUser((prev) => ({
          ...prev,
          dates: [...removeStartDate],
        }));
        setIsHidden((prev) => ({
          ...prev,
          one: "hidden",
        }));
        break;
      case "two":
        setEditableUser((prev) => ({
          ...prev,
          dates: [...removeBirthday],
        }));
        setIsHidden((prev) => ({
          ...prev,
          two: "hidden",
        }));
        break;
      case "three":
        setEditableUser((prev) => ({
          ...prev,
          social: [...removeSocial],
        }));
        setIsHidden((prev) => ({
          ...prev,
          three: "hidden",
        }));
        break;
      default:
    }
  };

  const phoneNumberValidation = userPhoneNumber?.trim()?.length
    ? yup.string().matches(phoneRegExp, "Phone number is not valid")
    : null;

  return {
    userEmail,
    userPhoneNumber,
    userDate,
    userSocial,
    changeName,
    changeEmail,
    changePhoneNumber,
    changeDate,
    changeSocial,
    changeProfileImage,
    changeTimezone,
    changePronunciation,
    phoneNumberValidation,
    isHidden,
    setIsHidden,
    removeField,
    timezoneOptions,
  };
};

export default useProfileDetailsForm;
