import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SettingsAndConfigurationList from "../../../../Pages/Admin/SettingsAndConfigurationList";
import MemberManagement from "../../../../Pages/Admin/MemberManagement";
import RolesAndPermissions from "../../../../Pages/Admin/RolesAndPermissions";
import WidgetContainer from "../../Widget/WidgetContainer";
import useAccountAndAdminContent from "./useAccountAndAdminContent";
import BillingDashboard from "../../BillingDashboard";

const AccountAndAdminContent = ({
  isSaving,
  setIsSaving,
  isCancel,
  setIsCancel,
  activeIndex,
  setActiveIndex,
  onSave,
  onCancel,
  isEditing,
  setIsEditing,
  setIsExportingMemberList,
  isExportingMemberList,
}) => {
  const {
    handleEditClick,
    navigateToHolidaysSection,
    noChangesMade,
    isLoadingData,
    isValid,
    setIsValid,
    managementSettings,
    dispatchManagementSettings,
    attributeSettings,
    dispatchAttributeSettings,
    systemSettings,
    dispatchSystemSettings,
    financialsSettings,
    dispatchFinancialsSettings,
    documentsSettings,
    dispatchDocumentsSettings,
    tagsSettings,
    dispatchTagsSettings,
    setSettingDetails,
    settingDetails,
    resetSystemSettings,
    widgetContainerSave,
    billingFormData,
    dispatchBilling,
    isLoadingBilling,
    canSaveBilling,
    subscriptionDeactivated,
  } = useAccountAndAdminContent({
    currentIndex: activeIndex,
    isSaving,
    setIsSaving,
    setIsCancel,
    isCancel,
    onSave,
    isEditing,
    setIsEditing,
    setActiveIndex,
  });

  const tabs = useMemo(() => {
    return [
      {
        title: "Settings & Configuration",
        content: (
          // TODO: When updating designs for other tabs pls remove prop drilling and call the hook/reducer dispatch in the component
          <SettingsAndConfigurationList
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
            attributeSettings={attributeSettings}
            dispatchAttributeSettings={dispatchAttributeSettings}
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
            documentsSettings={documentsSettings}
            dispatchDocumentsSettings={dispatchDocumentsSettings}
            tagsSettings={tagsSettings}
            dispatchTagsSettings={dispatchTagsSettings}
            navigateToHolidaysSection={navigateToHolidaysSection}
            onSave={onSave}
            onCancel={onCancel}
            isSaving={isSaving}
            isLoading={isLoadingData}
            settingDetails={settingDetails}
            setSettingDetails={setSettingDetails}
            noChangesMade={noChangesMade}
            isValid={isValid}
            setIsValid={setIsValid}
            resetSystemSettings={resetSystemSettings}
            isEditing={isEditing}
          />
        ),
      },
      {
        title: "Member Management",
        content: (
          <MemberManagement
            setIsExportingMemberList={setIsExportingMemberList}
            isExportingMemberList={isExportingMemberList}
            noChangesMade={noChangesMade}
          />
        ),
      },
      {
        title: "Roles & Permissions",
        content: <RolesAndPermissions />,
      },
      {
        title: "Billing",
        content: (
          <BillingDashboard
            isEditing={isEditing}
            dispatchBilling={dispatchBilling}
            billingFormData={billingFormData}
            isLoading={isLoadingBilling}
          />
        ),
      },
    ];
  }, [
    attributeSettings,
    billingFormData,
    dispatchAttributeSettings,
    dispatchBilling,
    dispatchDocumentsSettings,
    dispatchFinancialsSettings,
    dispatchManagementSettings,
    dispatchSystemSettings,
    dispatchTagsSettings,
    documentsSettings,
    financialsSettings,
    isEditing,
    isExportingMemberList,
    isLoadingBilling,
    isLoadingData,
    isSaving,
    isValid,
    managementSettings,
    navigateToHolidaysSection,
    noChangesMade,
    onCancel,
    onSave,
    resetSystemSettings,
    setIsExportingMemberList,
    setIsValid,
    setSettingDetails,
    settingDetails,
    systemSettings,
    tagsSettings,
  ]);

  return (
    <WidgetContainer
      className="p-4 border-es-light-grey shadow-lg border rounded-md"
      loading={isSaving || isLoadingBilling} // Reference: {isSaving} is used to run the onSave() for non billing windows
      isEditing={isEditing}
      resetResourceState={() => setIsCancel(true)} // Reference: {isCancel} is used to run the reset() for all screens
      handleEditClick={handleEditClick}
      onFinishEditing={widgetContainerSave}
      disableSaveBtn={!canSaveBilling}
      tabs={tabs}
      activeIndex={activeIndex}
      // Currently only index 3 (Billing) supports container controlled editing
      disableEditing={
        activeIndex !== 3 || (activeIndex === 3 && subscriptionDeactivated)
      }
      onTabClick={(index) => setActiveIndex(index)}
      hideBottomCancelSaveButtons
      customStyle={{ minWidth: "1200px" }}
    />
  );
};

AccountAndAdminContent.propTypes = {
  isSaving: PropTypes.bool,
  setIsSaving: PropTypes.func,
  isCancel: PropTypes.bool,
  setIsCancel: PropTypes.func,
  activeIndex: PropTypes.bool,
  setActiveIndex: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  isExportingMemberList: PropTypes.bool,
  setIsExportingMemberList: PropTypes.func,
};

AccountAndAdminContent.defaultProps = {
  isSaving: false,
  setIsSaving: undefined,
  isCancel: false,
  setIsCancel: undefined,
  activeIndex: false,
  setActiveIndex: undefined,
  onSave: undefined,
  onCancel: undefined,
  isExportingMemberList: false,
  setIsExportingMemberList: undefined,
  isEditing: undefined,
  setIsEditing: () => {},
};
export default AccountAndAdminContent;
