import { isEmpty } from "lodash";
import React from "react";
import { PRIMARY_DARK_GREEN } from "../../../../constants";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import PtoRow from "./PtoRow";
import TotalsRow from "./TotalsRow";
import useEmployeeTimesheet from "./useEmployeeTimesheet";
import TsViewDDSection from "./TsViewDDSection";
import TsViewEntrySection from "./TsViewEntrySection";
import TsViewTotalAndRemoveSection from "./TsViewTotalAndRemoveSection";

const EmployeeTimesheet = ({
  isLoading,
  ptoEntryState,
  ptoTotal,
  entriesForEditing,
  timesheetEntryTotal,
  overtimeTotal,
  handleProjectChange,
  handleRateChange,
  handleCategoryChange,
  handleEntryChange,
  handleEntryNoteChange,
  handleBillableChange,
  addTimesheetRow,
  removeTimesheetRow,
  timesheetIsLocked,
}) => {
  const {
    userCanWriteTimesheet,
    dropDownStyles,
    toolTip,
    wrapperRef,
    projectsDD,
    csiCodesMap,
    projectsState,
    projectDict,
    financialsConfiguration,
    isBeforeHire,
    setToolTip,
    formatEntryValue,
    filterRatesByProject,
    filteredCategoriesByProjectRate,
    roundEntryToNearestQuarter,
    showActiveIcon,
  } = useEmployeeTimesheet({ handleEntryChange });

  if (isLoading)
    return (
      <h3 className="h-10 text-center relative w-10">
        <span className="inline-block loadingInline" />
      </h3>
    );

  return (
    <>
      <div className="grid grid-cols-11 px-4" ref={wrapperRef}>
        {!isEmpty(entriesForEditing) && (
          <div className="grid col-span-4 grid-cols-3 gap-3">
            {["Project", "Rate", "Categories"].map((item) => (
              <h1
                key={item}
                style={{
                  fontWeight: 600,
                  fontSize: "medium",
                  color: "#6a6a6a",
                }}
              >
                {item}
              </h1>
            ))}
          </div>
        )}
      </div>
      <div className="grid grid-cols-11 px-4" ref={wrapperRef}>
        <div className="grid col-span-4 grid-cols-3 gap-3">
          {entriesForEditing && (
            <TsViewDDSection
              entriesForEditing={entriesForEditing}
              projectsState={projectsState}
              projectDict={projectDict}
              projectsDD={projectsDD}
              financialsConfiguration={financialsConfiguration}
              filteredCategoriesByProjectRate={filteredCategoriesByProjectRate}
              csiCodesMap={csiCodesMap}
              dropDownStyles={dropDownStyles}
              handleProjectChange={handleProjectChange}
              handleRateChange={handleRateChange}
              handleCategoryChange={handleCategoryChange}
              filterRatesByProject={filterRatesByProject}
            />
          )}
        </div>

        <div className="col-span-6 grid grid-cols-7 gap-3 ml-4">
          {entriesForEditing && (
            <TsViewEntrySection
              entriesForEditing={entriesForEditing}
              projectsState={projectsState}
              projectDict={projectDict}
              projectsDD={projectsDD}
              financialsConfiguration={financialsConfiguration}
              filteredCategoriesByProjectRate={filteredCategoriesByProjectRate}
              isBeforeHire={isBeforeHire}
              setToolTip={setToolTip}
              formatEntryValue={formatEntryValue}
              toolTip={toolTip}
              handleEntryNoteChange={handleEntryNoteChange}
              handleBillableChange={handleBillableChange}
              roundEntryToNearestQuarter={roundEntryToNearestQuarter}
              showActiveIcon={showActiveIcon}
              handleEntryChange={handleEntryChange}
            />
          )}
        </div>

        <div className="text-gray-700 font-light flex flex-col gap-4">
          {!timesheetIsLocked && (
            <TsViewTotalAndRemoveSection
              entriesForEditing={entriesForEditing}
              projectsState={projectsState}
              financialsConfiguration={financialsConfiguration}
              projectDict={projectDict}
              projectsDD={projectsDD}
              filteredCategoriesByProjectRate={filteredCategoriesByProjectRate}
              removeTimesheetRow={removeTimesheetRow}
            />
          )}
        </div>

        <div className="col-span-11">
          {!timesheetIsLocked && userCanWriteTimesheet && (
            <PlusCircleButton
              title="Add Project"
              className="mt-4 mb-1"
              onClick={() => addTimesheetRow()}
              style={{ color: PRIMARY_DARK_GREEN }}
            />
          )}
        </div>

        <div className="col-span-11 border-b border-gray-200 border-solid mt-5 mb-5" />

        <TotalsRow entriesForEditing={entriesForEditing} />

        {timesheetEntryTotal === 0 ? (
          <p className="col-start-11 text-gray-450 font-semibold">-- </p>
        ) : (
          <p className="col-start-11 text-gray-450 font-semibold">
            {timesheetEntryTotal} hrs
          </p>
        )}

        <div className="col-span-11 border-b border-gray-200 border-solid mt-5 mb-5" />

        <PtoRow
          entriesForEditing={entriesForEditing}
          ptoEntryState={ptoEntryState}
        />

        {ptoTotal === 0 ? (
          <p className="col-start-11 text-gray-450 font-semibold">-- </p>
        ) : (
          <p className="col-start-11 text-gray-450 font-semibold">
            {ptoTotal} hrs
          </p>
        )}

        <div className="col-span-11 border-b border-gray-200 border-solid mt-5 mb-5" />

        <div className="col-start-9 mt-10">
          <h2 className="text-lg text-gray-450" style={{ width: "200px" }}>
            Overtime Hours
          </h2>
          <h2 className="text-lg text-gray-450" style={{ width: "200px" }}>
            Time Off
          </h2>
          <h2
            className="text-lg text-gray-450 font-semibold"
            style={{ width: "200px" }}
          >
            Total Hours
          </h2>
        </div>

        <div className="col-start-11 mt-10">
          <h2 className="text-lg text-gray-450">{overtimeTotal} hrs</h2>
          <h2 className="text-lg text-gray-450">{ptoTotal} hrs</h2>
          <h2 className="text-lg text-gray-450 font-semibold">
            {" "}
            {timesheetEntryTotal + ptoTotal} hrs
          </h2>
        </div>
      </div>
    </>
  );
};

export default EmployeeTimesheet;
