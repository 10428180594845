/**
 * Modifies the settings of a specific tab on a page.
 *
 * @param {Object} params - The parameters for modifying the tab settings.
 * @param {string} params.page - The page that contains the tab.
 * @param {string} params.tab - The tab whose settings are to be modified.
 * @param {Object} params.data - The new settings for the tab.
 * @param {Object} params.userSettings - The current settings of the user.
 * @param {Function} params.editSettings - The function to edit the settings.
 * @returns {Function} A promise that resolves to the result of the modifyTabSettings function.
 */

const modifyTabSettings = async ({
  page,
  tab,
  resourceId,
  data,
  userSettings,
  stableEditSettings,
}) => {
  // Create a new copy of userSettings to avoid mutating the original object
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings?.web,
      [page]: {
        ...userSettings?.web?.[page],
      },
    },
  };

  const pageSettings = newSettings?.web[page];

  // If resourceId exists, modify resource tab settings
  if (resourceId) {
    pageSettings.resource = {
      ...pageSettings.resource,
      id: resourceId,
      tab: {
        ...pageSettings.resource?.tab,
        [tab]: {
          ...pageSettings.resource?.tab?.[tab],
          ...data,
        },
      },
    };
  } else {
    // Modify non-resource tab settings
    pageSettings.tab = {
      ...pageSettings.tab,
      [tab]: {
        ...pageSettings?.tab?.[tab],
        ...data,
      },
    };
  }

  return stableEditSettings(newSettings);
};

export default modifyTabSettings;
