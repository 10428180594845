import { useEffect, useMemo } from "react";

import { useHistory, useParams } from "react-router";
import bell from "../../../assets/images/bell.svg";
import workflowIcon from "../../../assets/images/workflowsGreenIcon.svg";
import useServiceRequests from "../../../../hooks/useServiceRequests";
import useWorkflow from "../../../../hooks/useWorkflow";
import {
  GET_PROJECT_WORKFLOW_VIEW_PATH,
  GET_PROPERTY_WORKFLOW_VIEW_PATH,
  GET_TICKETS_VIEW_PATH,
  GET_WORKFLOW_VIEW_PATH,
} from "../../../../constants";

const useTaskRelatedTo = ({ taskRef, setDisableEditRecurrence }) => {
  const history = useHistory();
  const params = useParams();
  // TODO(Parker): Replace when relatedTo API endpoint is Available
  const { data: serviceRequests } = useServiceRequests({
    tasks: [taskRef],
  });
  const { data: workflows } = useWorkflow({ tasks: [taskRef] });
  const data = useMemo(() => {
    return [...(serviceRequests || []), ...(workflows?.workflows || [])];
  }, [serviceRequests, workflows]);

  useEffect(() => {
    // set state to disable editing task recurrence if task related to SR or WF
    // effect upadate state value because data is asych
    setDisableEditRecurrence(data?.length > 0);
  }, [setDisableEditRecurrence, data]);

  const onPress = (item) => {
    switch (item?.resource) {
      case "Ticket":
        return history.push(GET_TICKETS_VIEW_PATH(item?.id));
      case "Workflow":
        if (params?.propertyId?.length) {
          return history.push(
            GET_PROPERTY_WORKFLOW_VIEW_PATH(params?.propertyId, item?.id)
          );
        }
        if (params?.projectId?.length) {
          return history.push(
            GET_PROJECT_WORKFLOW_VIEW_PATH(params?.projectId, item?.id)
          );
        }
        return history.push(GET_WORKFLOW_VIEW_PATH(item?.id));
      default:
        return () => {};
    }
  };

  const getIcon = (item) => {
    const icons = {
      Ticket: bell,
      Workflow: workflowIcon,
    };
    return icons?.[item?.resource];
  };

  const getName = (item) => item?.issue || item?.name;

  return { data, onPress, getIcon, getName };
};

export default useTaskRelatedTo;
