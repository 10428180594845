import PropTypes from "prop-types";
import React from "react";
import AttributeWidget from "../../../stories/Components/Attributes/AttributeWidget";
import SpaceOverviewWidget from "../../../stories/Components/Locations/SpaceOverviewWidget";
import LinksView from "../../../stories/Components/Links/LinksView";

const SpaceDetailView = ({
  associationInfo,
  space,
  dispatch,
  disableEditing,
  spaceConfiguration,
  setNewAttributes,
  newAttributes,
  onFinishEditing,
  editing,
  isLoading,
  handleEditClick,
}) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 pb-2.5">
        <SpaceOverviewWidget
          associationInfo={associationInfo}
          isLoading={isLoading}
          space={space}
          dispatch={dispatch}
          disableEditing={disableEditing}
          configuration={spaceConfiguration}
          onFinishEditing={onFinishEditing}
          isEditing={editing}
          handleEditClick={handleEditClick}
        />
        <LinksView
          dispatch={dispatch}
          editing={editing}
          resource={space}
          disableEditing={disableEditing}
          loading={isLoading}
        />
        <AttributeWidget
          resource={space}
          dispatch={dispatch}
          disableEditing={disableEditing}
          setNewAttributes={setNewAttributes}
          newAttributes={newAttributes}
          onFinishEditing={onFinishEditing}
          style={{ height: "275px" }}
          editing={editing}
          loading={isLoading}
        />
      </div>
    </>
  );
};

SpaceDetailView.propTypes = {
  space: PropTypes.shape({
    id: PropTypes.string,
    images: PropTypes.arrayOf(Object),
  }),
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
  spaceConfiguration: PropTypes.shape({}),
  setNewAttributes: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  onFinishEditing: PropTypes.func,
  isLoading: PropTypes.bool,
  editing: PropTypes.bool,
  handleEditClick: PropTypes.func,
  associationInfo: PropTypes.shape({}),
};

SpaceDetailView.defaultProps = {
  space: undefined,
  dispatch: undefined,
  disableEditing: false,
  spaceConfiguration: undefined,
  setNewAttributes: undefined,
  newAttributes: [],
  onFinishEditing: () => {},
  isLoading: true,
  editing: false,
  handleEditClick: () => {},
  associationInfo: {},
};

export default SpaceDetailView;
