import {
  TIMESHEET_NO_CATEGORIES_ERROR,
  TIMESHEET_NO_PROJECTS_ERROR,
  TIMESHEET_NO_RATES_ERROR,
} from "../../../constants";

/**
 * Gather sheet information based on provided parameters.
 *
 * @param {Object} state - The current project state object containing details about projects and rates.
 * @param {string} projectReference - Reference to a specific project within `state`.
 * @param {Object} financialsConfiguration - Configuration for the financial system, including rate sheets if applicable.
 * @param {Object} projectDict - Dictionary mapping project references to their names or other properties.
 * @param {Function} filteredCategoriesByProjectRate - Filtered categories by project and rates.
 * @param {HTMLSelectElement} projectsDD - A dropdown element for selecting a project from the list of available options.
 * @param {Object[]} values - An array containing rate information to be used in calculations or comparisons.
 *
 * @returns {{ddErrors: Array<string>, rates: Object | undefined}} Returns an object with two properties:
 *   ddErrors contains error messages if any required data is missing,
 *   and `rates` holds the calculated rate value based on provided inputs, possibly including fallbacks from configuration.
 */

const gatherSheetInfo = ({
  projectsState,
  projectReference,
  financialsConfiguration,
  projectDict,
  filteredCategoriesByProjectRate,
  projectsDD,
  values,
}) => {
  const rates = values.find((value) => value.rate)?.rate;
  let ratesArr =
    projectsState[projectReference]?.codes?.map((code) => code.rate) || [];

  if (
    ratesArr.length === 0 &&
    projectsState[projectReference]?.useall === false
  ) {
    ratesArr = financialsConfiguration?.financials?.rateSheet?.rates || [];
  }

  if (projectsState[projectReference]?.useall === true) {
    ratesArr = projectDict[projectReference]?.rateSheet?.rates || [];
  }

  let rateValue = rates
    ? {
        label: ratesArr?.find((r) => r?.id === rates)?.category,
        value: rates,
      }
    : undefined;

  if (projectsState[projectReference]?.useall === true) {
    const foundRate = ratesArr?.find((r) => r?.id === rates);

    rateValue = rates
      ? {
          label: foundRate?.category,
          value: foundRate?.id,
        }
      : undefined;
  }

  const projectsValue = projectReference
    ? {
        label: projectDict[projectReference]?.name,
        value: projectReference,
      }
    : undefined;

  const ddErrors = [];

  if (projectsDD?.length === 0) {
    ddErrors.push(TIMESHEET_NO_PROJECTS_ERROR);
  }

  if (projectsValue && ratesArr?.length === 0) {
    ddErrors.push(TIMESHEET_NO_RATES_ERROR);
  }

  const categoryDD =
    filteredCategoriesByProjectRate(projectReference, rates) || [];

  if (rateValue && categoryDD?.length === 0) {
    ddErrors.push(TIMESHEET_NO_CATEGORIES_ERROR);
  }

  return {
    ddErrors,
    rates,
  };
};

export default gatherSheetInfo;
