import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { PermissionAPI, RoleAPI } from "@griffingroupglobal/eslib-api";

import Dropdown from "../../stories/Components/Dropdown/Dropdown";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import Input from "../../stories/Components/Input/Input";
import SecondaryButton from "../../stories/Components/Buttons/SecondaryButton";
import Checkbox from "../../stories/Components/Checkbox/Checkbox";
import { ADMIN_ROLES_PERMISSIONS_PATH } from "../../constants";
import RolePermissionsList from "./RolePermissionsList";
import CreateRolePayload from "../../props/CreateRolePayload";
import RoleDropdownItemProps from "../../props/RoleDropdownItemProps";
import isRoleValid from "../../helpers/Role";
import {
  handlePermissionsSelected,
  isPermissionSelected,
  extractAdministrativePermissions,
  restoreAdministrativePermissions,
} from "../../helpers/Permissions";

/**
 * Renders the "Create Role" page for Roles & Permissions.
 */
function CreateRole() {
  const history = useHistory();
  const [roleName, setRoleName] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [allResourcePermissions, setAllResourcePermissions] = useState({});
  const [expandedResources, setExpandedResources] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRoleToApply, setSelectedRoleToApply] = useState();

  /**
   * When a resource is toggled, add or remove it from the expandedResources list.
   */
  const onResourceExpansionToggled = (resource) => {
    if (expandedResources.indexOf(resource) < 0) {
      setExpandedResources([...expandedResources, resource]);
    } else {
      setExpandedResources(expandedResources.filter((res) => res !== resource));
    }
  };

  /**
   * Click handler for the checkbox next to a permission. Toggles it in the
   * selectedPermissions object.
   */
  const onPermissionSelected = (resource, permission) => {
    const newPermissions = handlePermissionsSelected(
      selectedPermissions,
      resource,
      permission
    );

    setSelectedPermissions(newPermissions);
  };

  /**
   * Check whether all permissions have been checked/selected. Used for the
   * "Select All" checkbox.
   */
  const isAllPermissionsChecked = useCallback(() => {
    if (Object.keys(allResourcePermissions).length === 0) {
      return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const resource of Object.keys(allResourcePermissions)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const permission of Object.keys(allResourcePermissions[resource])) {
        if (!isPermissionSelected(selectedPermissions)(resource, permission)) {
          // for tasks, only one of ["can_read", "can_only_read_assigned"] needs to be checked
          if (resource === "task") {
            if (permission === "can_read") {
              return isPermissionSelected(selectedPermissions)(
                resource,
                "can_only_read_assigned"
              );
            }
            if (permission === "can_only_read_assigned") {
              return isPermissionSelected(selectedPermissions)(
                resource,
                "can_read"
              );
            }
          }
          return false;
        }
      }
    }
    return true;
  }, [allResourcePermissions, selectedPermissions]);

  /**
   * Click handler for the "Select All" checkbox.
   */
  const onSelectAllChecked = () => {
    if (selectAll) {
      setSelectedPermissions({});
    } else {
      const allPermissionsSelected = Object.fromEntries(
        Object.keys(allResourcePermissions).map((resource) => {
          const resourcePermissions = allResourcePermissions[resource];

          return [
            resource,
            Object.fromEntries(
              Object.keys(resourcePermissions).map((permission) => {
                const isTaskCanReadOnlyAssignedpermission =
                  resource === "task" &&
                  permission === "can_only_read_assigned";
                return [permission, !isTaskCanReadOnlyAssignedpermission];
              })
            ),
          ];
        })
      );

      setSelectedPermissions(allPermissionsSelected);
    }
  };

  /**
   * Click Handler for the "Apply Permissions From" dropdown. selectedRoleToApply
   * has a permissions prop. When the button is clicked, apply those permissions.
   */
  const onApplyPermissionClicked = () => {
    if (selectedRoleToApply) {
      setSelectedPermissions(selectedRoleToApply.permissions);
    }
  };

  /**
   * Click handler for the "Create" button. Post the CreateRolePayload, then
   * navigate back to the Roles & Permissions overview page on success.
   */
  const onCreateClicked = () => {
    const payload = new CreateRolePayload({
      name: roleName,
      permissions: selectedPermissions,
    });

    const updateRoleWithAdminPermissions =
      restoreAdministrativePermissions(payload);

    RoleAPI.post(updateRoleWithAdminPermissions, {})
      .then(() => history.push(ADMIN_ROLES_PERMISSIONS_PATH))
      .catch((err) =>
        console.error(`CreateRole#onCreateClicked - error:${err}`)
      );
  };

  /**
   * When the selected permissions change, check to see if the Select All
   * checkbox should be checked.
   */
  useEffect(() => {
    setSelectAll(isAllPermissionsChecked());
  }, [selectedPermissions, isAllPermissionsChecked]);

  /**
   * When the page loads, fetch the master permissions and all roles, and use
   * that to populate the Permissions List and the Apply From dropdown.
   */
  useEffect(() => {
    Promise.all([PermissionAPI.getWOP("$master"), RoleAPI.get()])
      .then(([allPermissionsResponse, allRolesResponse]) => {
        setAllResourcePermissions(
          extractAdministrativePermissions(allPermissionsResponse.data)
        );

        setAllRoles(
          RoleDropdownItemProps.fromGetRolesResponse(allRolesResponse.data)
        );
      })
      .catch((err) => console.error(`CreateRole#useEffect() - error:${err}`));
  }, []);

  return (
    <div className="roles-permission-create-role flex flex-col">
      <h1 className="text-3xl pb-4 font-semibold tracking-widest border-b-2 border-gray-150">
        Create Role
      </h1>

      <div className="flex flex-row mt-8 space-x-2 items-end justify-end">
        <p className="text-base font-normal tracking-wide flex-1">
          For all features, members may only see what they’re associated with.
          Members may only see entities, projects, and properties they have been
          invited to.
        </p>
        <Dropdown
          className="max-w-xs"
          placeholder="Select role"
          label="Apply Permissions"
          options={allRoles}
          value={selectedRoleToApply}
          onChange={setSelectedRoleToApply}
          onRequestDropdownClear={() => setSelectedRoleToApply(undefined)}
        />
        <PrimaryButton
          title="Apply"
          minWidth={120}
          disabled={!selectedRoleToApply?.value}
          onClick={onApplyPermissionClicked}
        />
      </div>

      <Input
        className="max-w-xs mt-6"
        placeholder="Name"
        label="Custom Role Name"
        labelClassName="text-base font-bold mb-1"
        type="input"
        inputClassName=""
        value={roleName}
        onChange={setRoleName}
      />

      <div className="flex flex-row justify-end space-x-2 pb-4 mt-8 border-b-2 border-gray-100">
        <Checkbox
          label="Select All"
          labelClassName=""
          className="flex-grow"
          checked={selectAll}
          onChange={onSelectAllChecked}
        />
        <SecondaryButton
          title="Expand All"
          onClick={() =>
            setExpandedResources(Object.keys(allResourcePermissions))
          }
        />
        <SecondaryButton
          title="Collapse All"
          onClick={() => setExpandedResources([])}
        />
      </div>

      <RolePermissionsList
        allResourcePermissions={allResourcePermissions}
        expandedResources={expandedResources}
        onResourceExpansionToggled={onResourceExpansionToggled}
        isPermissionSelected={isPermissionSelected(selectedPermissions)}
        onPermissionSelected={onPermissionSelected}
      />

      <div className="flex flex-row justify-end space-x-2 mt-6">
        <SecondaryButton
          title="Cancel"
          minWidth={120}
          onClick={() => history.push(ADMIN_ROLES_PERMISSIONS_PATH)}
        />
        <PrimaryButton
          title="Create"
          minWidth={120}
          disabled={!isRoleValid({ roleName })}
          onClick={onCreateClicked}
        />
      </div>
    </div>
  );
}

CreateRole.propTypes = {};

export default CreateRole;
