/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, isEqual, uniq } from "lodash";
import { AssetAPI } from "@griffingroupglobal/eslib-api";
import PureAssetTable from "./PureAssetTable";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import { useUsers } from "../../../hooks/useUsers.new";
import { getFullName } from "../../../helpers/Formatters";
import { getDetailValue } from "../../../helpers/Asset";
import { ASSET, SHOW_COLUMNS_SETTING_RIGHT } from "../../../constants";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import { useAssetSwitchView } from "../../../hooks/useSwitchView";
import AssetDeleteModal from "../../../Pages/Overviews/Asset/DeleteModal";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useAssetsPatch, useAssetsTableFormat } from "../../../hooks/assets";
import { hasDeletePermission } from "../../../helpers/Permissions";

const AssetTable = ({
  originalAssets,
  reference,
  isLoading,
  reloadAssets,
  className,
  onRowClick,
  propertyRef,
  projectRef,
  locationId,
  tableName,
  categoryOptionsList,
  subCatOptionsMap,
  hideSiteHeaderTitle,
  spaceId,
}) => {
  const { mutate: patchAsset } = useAssetsPatch();
  const { data: currentUser } = useCurrentUser();
  const { data: assetsAsTableData } = useAssetsTableFormat(reference, spaceId);
  const { data: { users } = {} } = useUsers();

  // TODO (RQ-Perf) - use queryhook instead
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();

  const [isShowingAssetsTable] = useAssetSwitchView();

  const [editCount, setEditCount] = useState(0);
  const [additionalDetailsColumnNames, setAdditionalDetailsColumnNames] =
    useState([]);
  const [assets, setAssets] = useState(originalAssets);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const categoryOptions = React.useMemo(
    () =>
      subCatOptionsMap
        ? Object.entries(subCatOptionsMap).map(([key, val]) => {
            return {
              label: key,
              options: val.map((item) => {
                return { ...item, category: key };
              }),
            };
          })
        : [],
    [subCatOptionsMap]
  );

  const templateSettings = useMemo(() => {
    if (!templatesConfiguration?.templates?.asset) {
      return [];
    }

    return templatesConfiguration?.templates?.asset
      ?.filter((template) => template.name !== "last_updated_column_view")
      ?.map((template) => ({
        ...template,
        isAdmin: !template.custom,
      }));
  }, [templatesConfiguration]);

  useEffect(() => {
    if (editCount === 0) {
      setAssets(
        cloneDeep(
          originalAssets?.map((asset) => {
            const insuranceBroker = users?.find(
              (user) => user.reference === asset?.broker
            )?.name;
            const insuranceAgent = users?.find(
              (user) => user.reference === asset?.agent
            )?.name;

            if (insuranceBroker) {
              asset.insuranceBroker = getFullName(insuranceBroker);
            }
            if (insuranceAgent) {
              asset.insuranceAgent = getFullName(insuranceAgent);
            }
            asset.additionalInfo?.map((info) => {
              if (getDetailValue(info) !== "") {
                asset[info.detail] = getDetailValue(info);

                setAdditionalDetailsColumnNames((prev) =>
                  uniq([...prev, info.detail])
                );
              }
              return info;
            });

            return asset;
          })
        )
      );
    }
  }, [editCount, originalAssets, users]);

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "asset",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "asset",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const getUniqueTableName = () => {
    let rv = "assets";
    if (tableName) {
      rv = tableName;
    } else if (propertyRef) {
      rv = `${propertyRef}-assets`;
    } else if (projectRef) {
      rv = `${projectRef}-assets`;
    } else if (locationId) {
      rv = `${locationId}-assets`;
    }
    return rv;
  };

  const onSaveRowClick = async (index) => {
    const updatedAsset = assets[index];
    const current = originalAssets[index];

    if (!isEqual(current, updatedAsset)) {
      patchAsset({ updatedAsset, asset: current });
    }
  };

  const onEditSaveCallback = useCallback(
    async (row, asset) => {
      const current = originalAssets[row?.index];

      const association = {};

      const editedAsset = {
        ...JSON.parse(JSON.stringify(current)),
        ...asset,
        category: asset?.categoryId,
        subcategory: asset?.subcategoryId,
      };

      /* ** replace editedAsset .property or .project name with project or property reference ** */
      if (editedAsset.project) association.project = current?.project;
      if (editedAsset.property) association.property = current?.property;

      if (!isEqual(current, editedAsset)) {
        await AssetAPI.patch(
          editedAsset.id,
          { ...editedAsset, ...association },
          current
        );
        reloadAssets();
      }
    },
    [originalAssets, reloadAssets]
  );

  const onAddSaveCallback = useCallback(
    async (row, asset) => {
      const createdAsset = {
        ...asset,
        category: asset?.categoryId,
        subcategory: asset?.subcategoryId,
        id: uuidv4(),
        addAnother: false,
      };

      await AssetAPI.post(createdAsset);
      reloadAssets();
    },
    [reloadAssets]
  );

  const onDeleteRowClick = async (assetIdToDelete) => {
    await AssetAPI.delete(assetIdToDelete);
    reloadAssets();
  };

  const onEditRowCancelClick = (row) => {
    const { index: resetIndex } = row;
    setAssets((prevAssets) =>
      prevAssets.map((asset, index) => {
        if (index === resetIndex) {
          return originalAssets[index];
        }
        return asset;
      })
    );
  };

  const onStartRowEditing = () => {
    setEditCount((prev) => prev + 1);
  };

  const onEndRowEditing = () => {
    setEditCount((prev) => prev - 1);
  };

  return (
    <>
      <PureAssetTable
        allowSelection
        handleRowSelect={handleRowSelect}
        className={className}
        data={assetsAsTableData}
        isLoading={isLoading}
        name={getUniqueTableName()}
        setAssets={setAssets}
        onRowClick={onRowClick}
        onSaveRowClick={onSaveRowClick}
        onEditRowCancelClick={onEditRowCancelClick}
        onDeleteRowClick={onDeleteRowClick}
        onStartRowEditing={onStartRowEditing}
        onEndRowEditing={onEndRowEditing}
        onEditSaveCallback={onEditSaveCallback}
        onAddSaveCallback={onAddSaveCallback}
        projectRef={projectRef}
        propertyRef={propertyRef}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        categoryOptionsList={categoryOptionsList}
        subCatOptionsMap={subCatOptionsMap}
        additionalDetailsColumnNames={additionalDetailsColumnNames}
        categoryOptions={categoryOptions}
        showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
        showConstantRowIndex
        fileActionsIcons={
          <TableActionsIconsGroup
            style={{ height: "100%" }}
            handleShowDelete={() => {
              setShowDeleteModal(true);
            }}
            canDelete={
              isShowingAssetsTable && hasDeletePermission(ASSET, currentUser)
            }
            disabled={selectedRows?.length === 0}
            showColumnSettingsRight={
              SHOW_COLUMNS_SETTING_RIGHT && isShowingAssetsTable
            }
            selectedRows={selectedRows}
            disabledColumnSettings={!isShowingAssetsTable}
          />
        }
        hideSiteHeaderTitle={hideSiteHeaderTitle}
      />
      <AssetDeleteModal
        selectedAssets={selectedRows}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

AssetTable.propTypes = {
  /**
   * className to pass to the table
   */
  className: PropTypes.string,
  /**
   * function called when a row is clicked
   */
  onRowClick: PropTypes.func,
  /**
   * property reference to filter aassets
   */
  propertyRef: PropTypes.string,
  projectRef: PropTypes.string,
  /**
   * location id to filter aassets
   */
  locationId: PropTypes.string,
  /**
   * optional name for the table being displayed.
   * This is used to perserve state for this specific table
   */
  tableName: PropTypes.string,
  /**
   * array of the possible categories for an asset, formatted for the DDL
   */
  // eslint-disable-next-line react/forbid-prop-types
  categoryOptionsList: PropTypes.arrayOf(PropTypes.object),
  /**
   * object mapping category codes to the array of possible subcategories, formatted for the DDL
   */
  subCatOptionsMap: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),

  reference: PropTypes.string,
  reloadAssets: PropTypes.func,
  originalAssets: PropTypes.arrayOf(
    PropTypes.shape({ project: PropTypes.string, property: PropTypes.string })
  ),
  isLoading: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
  spaceId: PropTypes.string,
};

AssetTable.defaultProps = {
  className: undefined,
  onRowClick: undefined,
  propertyRef: undefined,
  projectRef: undefined,
  locationId: undefined,
  tableName: undefined,
  categoryOptionsList: [],
  subCatOptionsMap: {},
  reference: undefined,
  reloadAssets: undefined,
  originalAssets: [],
  isLoading: false,
  hideSiteHeaderTitle: false,
  spaceId: undefined,
};

export default AssetTable;
