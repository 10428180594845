import React, { useRef } from "react";
import Input from "../../Input/Input";
import SaveButton from "../../Buttons/SaveButton";
import crossIconWhite from "../../../assets/images/crossIconWhite.svg";
import { validUrlSchema } from "../../../../helpers/FormValidations";

const LinkInput = ({
  onSave,
  onClose,
  linkName,
  linkUrl,
  setLinkName,
  setLinkUrl,
  handleChange,
  disableEditing,
  customStyle,
  isUrlValid,
}) => {
  const inputRefs = useRef({});
  const handleSubmit = () => {
    onSave();
    inputRefs.current.urlName.focus();
  };
  const handleChangeSubmit = (e) => {
    handleChange(e);
    inputRefs.current.urlName.focus();
  };

  return (
    <div className="flex pr-2 mr-1">
      <div
        className="flex flex-col rounded-xl bg-es-extra-light-grey pt-6 mt-4 pb-4 self-start w-full"
        style={customStyle ?? { height: "186px" }}
      >
        <div className="flex flex-col gap-2 justify-center">
          <div
            className="flex flex-row items-center justify-evenly"
            style={{ paddingY: "9px", marginLeft: "18px" }}
          >
            <p className="justify-self-center text-es-sm text-es-medium-grey font-es-semibold">
              URL
            </p>
            <div
              className="w-full"
              style={{
                marginRight: "18px",
                marginLeft: "18px",
                minWidth: "40px",
              }}
            >
              <Input
                className="w-full"
                value={linkUrl}
                placeholder="Url"
                onChange={(val) => setLinkUrl(val)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                // show validation * w/o validation message
                validation={validUrlSchema()}
                disableClear
                forwardedRef={(el) => {
                  inputRefs.current.urlName = el;
                }}
              />
            </div>
          </div>
          <div
            className="flex flex-row items-center justify-evenly"
            style={{ paddingY: "9px", marginLeft: "18px" }}
          >
            <p className="justify-self-center text-es-sm text-es-medium-grey font-es-semibold">
              Title
            </p>
            <div
              className="w-full"
              style={{
                marginRight: "18px",
                marginLeft: "18px",
                minWidth: "40px",
              }}
            >
              <Input
                className="w-full"
                value={linkName}
                name="title"
                placeholder="Title"
                onChange={(val) => setLinkName(val)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleChangeSubmit(e);
                  }
                }}
                forwardedRef={(el) => {
                  inputRefs.current.name = el;
                }}
                disableClear
              />
            </div>
          </div>
          <div className="flex flex-row items-center text-start pl-2 h-fit w-fit rounded-md">
            <SaveButton
              onClick={!disableEditing || isUrlValid ? handleSubmit : () => {}}
              background="bg-none"
              className={`${
                disableEditing || !isUrlValid
                  ? "text-es-medium-grey bg-transparent"
                  : "text-es-green"
              } w-fit border-none bg-transparent`}
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        className="relative justify-self-end"
        style={{
          minWidth: 16,
          minHeight: 16,
        }}
        onClick={() => onClose()}
      >
        <img
          alt="delete tag"
          className="top-2 right-2 absolute rounded-xl bg-darkenedGreen p-1 shadow-light-lift"
          src={crossIconWhite}
        />
      </button>
    </div>
  );
};

export default LinkInput;
