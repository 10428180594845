import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { getTimezonesByCountry } from "../../../../helpers/Time";
import IconButton from "../../Buttons/IconButton";
import crossIcon from "../../../assets/images/Close.svg";
import SearchBar from "../../SearchBar/SearchBar";
import searchIcon from "../../../assets/images/magnoliaSearchIcon.svg";
import useCalendarTimezone from "../../../../hooks/useCalendarTimezone";

const list = getTimezonesByCountry();

const TimezonePopup = ({ close }) => {
  const allParams = useParams();
  const { calendarTimezone, updateTimezone } = useCalendarTimezone();
  const [timezones, setTimezones] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    if (search) {
      const filteredList = list.reduce((acc, tzGroup) => {
        const filteredOptions = tzGroup?.options.filter((tz) =>
          tz.toLowerCase().includes(search?.toLowerCase())
        );

        if (filteredOptions?.length) {
          acc.push({
            label: tzGroup?.label,
            options: filteredOptions,
          });
        }

        return acc;
      }, []);
      setTimezones(filteredList);
    } else {
      setTimezones(list);
    }
  }, [search]);

  const handleSearch = (val) => {
    setSearch(val);
  };

  const onChangeTimezone = (val) => {
    const propertyRef =
      allParams?.propertyId && `Property/${allParams?.propertyId}`;
    const projectRef =
      allParams?.projectId && `Project/${allParams?.projectId}`;
    const assetRef = allParams?.assetId && `Asset/${allParams?.assetId}`;

    const reference = propertyRef || projectRef || assetRef || "overview";

    updateTimezone(reference, val);
  };

  return (
    <div
      className="flex absolute shadow-1 bg-white rounded-lg p-8 -top-1 right-1.5 z-10"
      style={{ width: "371px" }}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between pb-4">
          <div className="es-dark-grey text-es-normal font-es-bold">
            Timezone
          </div>
          <IconButton icon={crossIcon} imgClassName="w-3 h-3" onClick={close} />
        </div>
        <SearchBar
          onSearchChange={(val) => handleSearch(val)}
          searchIcon={searchIcon}
          globalFilter={search}
          placeholder="Search"
          className="pb-4"
          disableClear
        />
        <div className="max-h-52 overflow-y-scroll">
          {timezones?.map((tzGroup, idx) => (
            <div key={tzGroup?.label}>
              <p
                className={`font-es-semibold text-sm text-es-dark-grey uppercase ${
                  idx !== tzGroup?.length - 1 && "pb-4"
                }`}
              >
                {tzGroup?.label}
              </p>
              {tzGroup?.options?.map((tz) => (
                <div
                  className={`flex justify-between pb-4 text-sm ${
                    calendarTimezone === tz && "font-es-semibold text-es-green"
                  }`}
                  onClick={() => {
                    if (calendarTimezone === tz) {
                      onChangeTimezone(null);
                    } else {
                      onChangeTimezone(tz);
                    }
                  }}
                  tabIndex={0}
                  onKeyDown={() => {}}
                  role="button"
                  key={tz}
                >
                  <p className="w-48 truncate">{tz}</p>
                  <p>{moment.tz(new Date(), tz).format("h:mm a")}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TimezonePopup.propTypes = {
  close: PropTypes.func,
};

TimezonePopup.defaultProps = {
  close: () => {},
};

export default TimezonePopup;
