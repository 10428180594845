import removeFromCalendarStore from "../../../../helpers/Calendar/removeFromCalendarStore";

const removeFromCalendarResponder = (queryClient, calendarData) => {
  return {
    onSuccess: (returnedData, variables) => {
      return removeFromCalendarStore({
        queryClient,
        calendarData,
        variables,
        data: returnedData,
      });
    },
    onError: (err) => {
      console.error("Failed to delete event.", err);
    },
  };
};

export default removeFromCalendarResponder;
