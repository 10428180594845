import { useCallback, useMemo, useRef, useState } from "react";
import { TimeoffAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../../../../state/appState";
import useTimesheetList from "../../../../hooks/timesheets/useTimesheetList";
import combineTsWithUser from "../../../../helpers/Timesheets/combineTsWithUser";
import { useUsers } from "../../../../hooks/useUsers.new";
import usePayroll from "../../../../hooks/usePayroll";
import useEditTimesheetsList from "../../../../hooks/timesheets/useEditTimesheetsList";
import useEmployeeTimeSheets from "../../../../hooks/useEmployeeTimeSheets";
import { useProjectsOverview } from "../../../../hooks/projects";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import useSettings from "../../../../hooks/useSettings";
import { SETTINGS_PAYROLL_CSV_COLUMNS } from "../../../../constants";
import { useModalState } from "../../../../state/modalState";
import findEmployeesWithoutTimesheets from "../../../../helpers/Users/findEmployeesWithoutTimesheets";
import createNonSubmittedTimesheet from "../../../../helpers/Users/createNonSubmittedTimesheet";
import removeDuplicateRef from "../../../../helpers/Utilities/removeDuplicateRef";

const usePayrollData = ({ currentUser, usersDict }) => {
  const [
    {
      userDict,
      pageState,
      timesheetstate: { approvalDate: date },
    },
  ] = useAppState();
  const { timesheetsList, timesheetsLoading } = useTimesheetList();

  /* NERD: Normally I wouldn't want to format at this scope
   *   but it was like this before, and the child components
   *   were built to handle it the way */
  const timeSheets = useMemo(
    () => combineTsWithUser(timesheetsList, userDict),
    [timesheetsList, userDict]
  );

  const getPTO = useCallback(async () => {
    const queries = timeSheets?.map(
      (item) => () =>
        TimeoffAPI.getWOP(`?user=${item?.userRef}&status=approved`)
    );
    return queries ? Promise.all(queries) : [];
  }, [timeSheets]);

  const { periodStart, periodEnd } = pageState.timesheet;

  const payPeriod = useMemo(() => {
    return { periodStart, periodEnd };
  }, [periodStart, periodEnd]);

  const { data } = useUsers();
  const employees = data?.employees;

  const { financials, payrollHistory, reloadHistory } = usePayroll({
    users: usersDict,
    permission: currentUser?.hasPermission("corporate", "can_approve_payroll"),
    currentUser,
  });

  const { editTimesheetsListStatus } = useEditTimesheetsList();
  const { pto } = useEmployeeTimeSheets(undefined, date);
  const [{ ptoLocationsLibrary }] = useAppState();
  const { projectDict } = useProjectsOverview();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const [selectedRows, setSelectedRows] = useState([]);
  const warnings = useRef([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [, update] = useSettings(SETTINGS_PAYROLL_CSV_COLUMNS);
  const [, modalDispatch] = useModalState();

  const csiCodes = financialsConfiguration?.financials?.csiCodeMappingObject;

  const timesheetsToDisplay = useMemo(() => {
    if (timesheetsLoading) return [];

    const employeesWithoutTimesheets = findEmployeesWithoutTimesheets(
      employees,
      timeSheets
    );

    const convertedToTimesheets =
      employeesWithoutTimesheets?.map((employee) =>
        createNonSubmittedTimesheet(employee)
      ) || [];

    const tsWithoutDuplicates = removeDuplicateRef(timeSheets);

    const timesheets = [...tsWithoutDuplicates, ...convertedToTimesheets].map(
      (sheet, index) => {
        return {
          ...sheet,
          index,
          id: index,
        };
      }
    );

    return timesheets;
  }, [timesheetsLoading, employees, timeSheets]);

  const primaryButtonActionsDisabled = useMemo(() => {
    return [timesheetsToDisplay]?.length === 0 || timesheetsLoading;
  }, [timesheetsToDisplay, timesheetsLoading]);

  const numberOfApproved = timeSheets?.filter(
    (item) => item.payrollStatus === "approved"
  );

  const approvedTimesheets = timeSheets?.filter(
    (item) => item.payrollStatus === "approved"
  );

  const approvedTimesheetCount = approvedTimesheets?.length || 0;
  const employeeCount = employees?.length || 0;

  const submitTimeSheets = useCallback(async () => {
    const onlyApprovedEntries = selectedRows?.filter(
      (item) => item.payrollStatus !== "status"
    );

    const editTsStatusProps = {
      items: onlyApprovedEntries,
      action: "$approvePayroll",
    };

    try {
      await editTimesheetsListStatus(editTsStatusProps);
      reloadHistory();
      setSelectedRows([]);
    } catch (error) {
      console.error("Error saving payroll ==>", error);
    }
  }, [editTimesheetsListStatus, reloadHistory, selectedRows, setSelectedRows]);

  const handlePayrollToCsv = useCallback(
    async (byProject = false, selectedCsvColumns) => {
      if (timesheetsLoading) return;

      try {
        const res = await getPTO();
        const requestsByUserMap = {};

        res.forEach((ptoResult) => {
          const { data: resultData } = ptoResult;
          if (resultData?.entries.length) {
            requestsByUserMap[resultData?.entries[0]?.resource?.user] =
              resultData?.entries.map((e) => e.resource);
          }
        });

        // lazy load the convertToCsv module so it only runs when needed
        const module = await import(
          "../../../../helpers/Timesheets/convertPayrollToCsv"
        );

        // extract the convertPayrollToCsv function from module default
        const convertPayrollToCsv = module.default;

        convertPayrollToCsv({
          timesheets: timesheetsToDisplay,
          csvColumns: selectedCsvColumns,
          payrollMap: payrollHistory,
          csiCodes,
          userDict,
          projectDict,
          requestsByUserMap,
          payPeriod,
          ptoLocationsLibrary,
          byProject,
        });

        // Run the update function to update the settings in a non-blocking way
        (async () => {
          try {
            await update({
              key: SETTINGS_PAYROLL_CSV_COLUMNS,
              value: selectedCsvColumns,
            });
          } catch (err) {
            /**
             * launching this asynchronous code in an IIFE lets us update the
             * settings in a non-blocking way, without sacrificing error handling
             */
            console.error(err);
          }
        })();
      } catch (err) {
        console.error(err);
      }
    },
    [
      timesheetsLoading,
      getPTO,
      timesheetsToDisplay,
      payrollHistory,
      csiCodes,
      userDict,
      projectDict,
      payPeriod,
      ptoLocationsLibrary,
      update,
    ]
  );

  return {
    handlePayrollToCsv,
    submitTimeSheets,
    timesheetsLoading,
    employees,
    financials,
    payrollHistory,
    timesheetsToDisplay,
    pto,
    selectedRows,
    setSelectedRows,
    warnings,
    confirmModal,
    setConfirmModal,
    modalDispatch,
    primaryButtonActionsDisabled,
    numberOfApproved,
    employeeCount,
    approvedTimesheetCount,
  };
};

export default usePayrollData;
