import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { PAGES } from "../../../constants";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";
import useGoBack from "../../../hooks/useGoBack";
import useReportSelectorById from "../../../hooks/useReportSelectorById";

const useReportSinglePageData = ({
  reportId,
  association,
  onBackButtonPressed,
}) => {
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  // Get report id from URL
  const { reportId: reportIdFromUrl } = useParams();
  // @id represent the report id to display details
  // This value could be taken from url (page) or component (P/P/A)
  const id = reportId || reportIdFromUrl;

  const { navigateBack } = useGoBack();

  // Query hook to fetch report by id
  const { data: report, isLoading } = useReportSelectorById(id);

  /**
   * Handles the navigation back action. It checks if an @association is provided
   * and invokes it to conditionally render a Report single view. If it is not provided,
   * it performs a standard navigation back.
   */
  const handleNavigateBack = () => {
    if (association) {
      onBackButtonPressed();
    } else {
      navigateBack();
    }
  };

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/reports/123", currentPage will be "reports"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.REPORTS) {
      setCurrentResourceScreen(PAGES.REPORTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  return {
    report,
    isLoading,
    handleNavigateBack,
  };
};

export default useReportSinglePageData;
