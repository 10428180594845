import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import SopTable from "../../../stories/Components/SopTable/SopTable";
import { useSop } from "../../../hooks/useSop";
import Widget from "../../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import {
  ADD_OPEN_MODAL,
  CREATE_SOP_MODAL,
  SHOW_COLUMNS_SETTING_RIGHT,
  PAGES,
  SOP,
} from "../../../constants";
import { useAppState } from "../../../state/appState";
import TableActionsIconsGroup from "../../../stories/Components/Table/TableActionsIconsGroup";
import SopDeleteModal from "./SopDeleteModal";
import { useGetTags } from "../../../hooks/useTags";
import { useModalState } from "../../../state/modalState";
import { hasWritePermission } from "../../../helpers/Permissions";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const SopList = () => {
  const { data: sopData, isLoading } = useSop();
  const { data: tagsData } = useGetTags();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  const [{ currentUser, userDict }] = useAppState([]);
  const [{ modals }, modalDispatch] = useModalState();

  const [selectedRows, setSelectedRows] = useState([]);
  const [canDeleteSop, setCanDeleteSop] = useState(false);
  const [someSopInUse] = useState(false);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/sops/123", currentPage will be "sops"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.SOPS) {
      setCurrentResourceScreen(PAGES.SOPS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  const sopEditData = useMemo(() => {
    return sopData?.length > 0
      ? sopData?.map((sop) => {
          const createdBy = sop?.metadata?.createdBy;
          const lastUpdatedBy = sop?.lastUpdatedBy;
          const sopUser = userDict?.[lastUpdatedBy || createdBy];

          return {
            ...sop,
            categoryFilter: sop?.category?.display,
            sopUser,
            creator: {
              avatar: sopUser?.avatar,
              name: sopUser
                ? `${sopUser?.name?.firstName} ${sopUser?.name?.lastName}`
                : "",
              id: sopUser?.id,
            },
            isActive: undefined,
            currentTags: sop?.tags?.reduce((acc, tagRef) => {
              const found = tagsData?.tagsDict[tagRef];
              if (found) {
                acc.push({
                  label: found?.label,
                  value: found?.value,
                });
              }
              return acc;
            }, []),
          };
        })
      : [];
  }, [sopData, tagsData?.tagsDict, userDict]);

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Standard Operating Procedures"
        buttonTitle="SOP"
        customButton={
          hasWritePermission(SOP, currentUser) && (
            <PrimaryButton
              resourceName="SOP"
              onClick={() => {
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: "create-sop-modal" },
                  modalData: {
                    id: "create-sop-modal",
                  },
                  modalType: CREATE_SOP_MODAL,
                });
              }}
              disabled={modals.find(
                (item) => item.modalType === CREATE_SOP_MODAL
              )}
              addButton
            />
          )
        }
      />
      <Widget draggable={false} title={null} backToTop>
        <SopTable
          handleRowSelect={handleRowSelect}
          allowSelection
          data={sopEditData}
          isLoading={isLoading}
          hideSiteHeaderTitle
          showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
          fileActionsIcons={
            <TableActionsIconsGroup
              style={{ height: "100%" }}
              handleShowDelete={() => setCanDeleteSop(true)}
              canDelete={
                currentUser?.isAdmin ||
                currentUser?.hasPermission?.("sop", "can_delete")
              }
              disabled={selectedRows?.length === 0}
              showColumnSettingsRight={SHOW_COLUMNS_SETTING_RIGHT}
              selectedRows={selectedRows}
            />
          }
        />
      </Widget>
      <SopDeleteModal
        someSopInUse={someSopInUse}
        sopArray={selectedRows}
        canDeleteSop={canDeleteSop}
        setCanDeleteSop={setCanDeleteSop}
      />
    </>
  );
};

export default SopList;
