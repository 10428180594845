import { useMutation, useQueryClient } from "react-query";
import editTaskStore from "../../../helpers/Task/editTaskStore";
import editTaskHandler from "./handlers/editTaskHandler";
import editTaskResponder from "./responders/editTaskResponder";

const useTaskEdit = () => {
  const queryClient = useQueryClient();

  const editMutation = useMutation(
    editTaskHandler,
    editTaskResponder(queryClient)
  );

  const editTask = async (props, newRequest = true) => {
    if (newRequest === false) {
      return editTaskStore({
        queryClient,
        returnedData: props,
      });
    }

    if (!props.args.originalItem) {
      console.error(
        "props.args.originalItem is required: populate with the original version of the item you are updating"
      );

      return null;
    }

    if (!props.args.editedItem) {
      console.error(
        "props.args.editedItem is required: populate with item you are updating"
      );

      return null;
    }

    const response = await editMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: editTaskLoading,
    isError: hasEditTaskError,
    error: editTaskError,
    isSuccess: editTaskSuccess,
  } = editMutation;

  return {
    editTask,
    editTaskLoading,
    hasEditTaskError,
    editTaskError,
    editTaskSuccess,
  };
};

export default useTaskEdit;
