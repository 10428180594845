/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Table from "../Table/Table";
import displayTime, { MinutesToHours } from "../../../helpers/TimeSheet";
import TimeSheetDatePicker from "../TimeSheetTable/TimeSheetDatePicker";
import { PAGES, WEEKLY_TIME_LIMIT } from "../../../constants";
import { getFullName } from "../../../helpers/Formatters";
import { useAppState } from "../../../state/appState";
import Pill from "../Pill/Pill";
import underIcon from "../../assets/images/tsUnderIcon.svg";
import overIcon from "../../assets/images/tsOverIcon.svg";
import exemptIcon from "../../assets/images/exemptIcon.svg";
import usePagePersistence from "../../../hooks/usePagePersistence";
import getTimeoffForUser from "../../../helpers/Timesheets/getTimeoffForUser";

const dash = "items-center w-full justify-start";
const PurePayrollTable = ({
  data,
  historyData,
  showNextWeek,
  showPrevWeek,
  showThisWeek,
  loading,
  setSelectedRows,
  pto,
  customSiteHeader,
}) => {
  const [{ userDict }] = useAppState();
  const { pageState, setPersistentPageItem } = usePagePersistence();

  const parseReturnRow = (sheet) => {
    let total = 0;
    if (sheet?.entryCount) {
      Array(sheet?.entryCount + 1)
        .fill(1)
        .forEach((_, idx) => {
          const val = sheet?.[idx > 0 ? `entries${idx}` : "entries"];
          const sum = Object.values(val ?? {})?.reduce(
            (a, b) => a + b?.value,
            0
          );
          total += sum;
        });
      total = MinutesToHours(total);
      return total;
    }

    return Object.values(sheet?.entries ?? {})?.reduce(
      (a, b) => a + MinutesToHours(b?.value),
      0
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "employee.fullName",
        Cell: ({
          row: {
            original: { userRef },
          },
          value,
        }) => (
          <div className="capitalize truncate" title={value}>
            {value.includes("undefined")
              ? getFullName(userDict?.[userRef]?.name)
              : value}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 160,
        minWidth: 150,
        Cell: ({ value, row }) => {
          let newVal = value;
          if (row?.original?.timeOff && row?.original?.status === "approved") {
            newVal = "payrollApproved";
          }

          switch (newVal) {
            case "approved":
              return (
                <Pill
                  alt="approved"
                  value="Approved"
                  className="h-5"
                  padding="p-2"
                  textSize="text-xxs"
                  background="bg-darkenedGreen"
                  border="border-darkenedGreen"
                />
              );
            case "submitted":
              return (
                <Pill
                  alt="submitted"
                  value="Submitted"
                  className="h-5"
                  padding="p-2"
                  textSize="text-xxs"
                  textColor="text-darkenedGreen"
                  background="bg-transparent"
                  border="border border-darkenedGreen"
                />
              );
            case "rejected":
              return (
                <Pill
                  alt="rejected"
                  value="Rejected"
                  className="h-5"
                  textSize="text-xxs"
                  textColor="text-brandRed"
                  background="bg-transparent"
                  border="border border-brandRed"
                  padding="p-2"
                />
              );
            case "payrollApproved":
              return (
                <Pill
                  alt="payroll approved"
                  value="Payroll Approved"
                  className="h-5"
                  textSize="text-xxs"
                  textColor="text-white"
                  background="bg-pillBlue"
                  border="border border-pillBlue"
                  padding="p-1"
                />
              );
            case "locked":
              return (
                <Pill
                  alt="locked"
                  value="locked"
                  className="h-5"
                  textColor="text-pillGray"
                  textSize="text-xxs"
                  border="border border-pillGray"
                  background="bg-transparent"
                  padding="py-2 px-2"
                />
              );
            case "Not Submitted":
              return (
                <Pill
                  alt="locked"
                  value="Not Submitted"
                  className="h-5"
                  textColor="text-pillGray"
                  textSize="text-xxs"
                  border="border border-pillGray"
                  background="bg-transparent"
                  padding="py-2 px-2"
                />
              );

            default:
              return "--";
          }
        },
      },
      {
        Header: "Exempt",
        accessor: "employee.employeeInfo.isExempt",
        id: "exempt",
        maxWidth: 100,
        minWidth: 100,
        Cell: ({ value }) =>
          value ? (
            <img src={exemptIcon} alt="under-time" className="mr-1 h-6 w-6" />
          ) : (
            <p className={dash}>--</p>
          ),
      },
      {
        Header: "Under",
        accessor: "summary",
        id: "under",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ row, value }) => {
          let under = MinutesToHours(
            Object.values(value ?? {})?.reduce((a, b) => a + b, 0)
          );

          under = WEEKLY_TIME_LIMIT - under;
          under -= getTimeoffForUser(row?.original?.userRef, pto, pageState);
          return (
            <div className="flex flex-row items-center w-full">
              {under > 0 ? (
                <>
                  <img
                    src={underIcon}
                    alt="under-time"
                    className="mr-1 h-5.5 w-4.5"
                  />
                  <p className="">{displayTime(under)}</p>
                </>
              ) : (
                <p className={dash}>--</p>
              )}
            </div>
          );
        },
      },
      {
        Header: "Over",
        accessor: "summary",
        id: "over",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ value }) => {
          let over = MinutesToHours(
            Object.values(value ?? {})?.reduce((a, b) => a + b, 0)
          );

          over -= WEEKLY_TIME_LIMIT;
          return (
            <div className="flex flex-row items-center w-full">
              {over > 0 ? (
                <>
                  <img
                    src={overIcon}
                    alt="over-time"
                    className="mr-1 h-5.5 w-4.5"
                  />
                  <p>{displayTime(over)}</p>
                </>
              ) : (
                <p className={dash}>--</p>
              )}
            </div>
          );
        },
      },
      {
        Header: "Time Off",
        accessor: "userRef",
        Cell: ({ value }) => {
          const total = getTimeoffForUser(value, pto, pageState);

          return total ? (
            <div className="flex flex-row items-center">
              <p className="truncate">{displayTime(total)}</p>
            </div>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Total Worked",
        accessor: "entries",
        id: "totalWorked",
        Cell: ({ row, value }) => {
          if (row?.original?.resource === "Timesheet") {
            let total = MinutesToHours(
              Object.values(value)?.reduce((a, b) => a + b?.value, 0)
            );

            if (row?.original?.entryCount) {
              total = parseReturnRow(row?.original);
            }
            return (
              <div className="flex flex-row">
                <>
                  <p className="truncate" title={displayTime(total)}>
                    {displayTime(total)}
                  </p>
                </>
              </div>
            );
          }
          return <p className={dash}>--</p>;
        },
      },
      {
        Header: "Cumulative",
        accessor: "summary",
        Cell: ({ value, row }) => {
          const timeoff = getTimeoffForUser(
            row?.original?.userRef,
            pto,
            pageState
          );
          const total = value
            ? MinutesToHours(Object.values(value)?.reduce((a, b) => a + b, 0))
            : 0;
          return total || timeoff ? (
            <div className="flex flex-row items-center">
              <>
                <p className="truncate">{displayTime(total + timeoff)}</p>
              </>
            </div>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
      {
        Header: "Submitted",
        accessor: (row) => {
          if (historyData?.[row?.reference]?.submitted?.when) {
            return moment(
              historyData?.[row?.reference]?.submitted?.when
            ).format("MMM Do, YYYY");
          }
          return "Not submitted";
        },
        Cell: ({ value }) => {
          return (
            value && (
              <p className="truncate" title={value}>
                {value}
              </p>
            )
          );
        },
      },
      {
        Header: "Approved",
        accessor: (row) => {
          if (historyData?.[row?.reference]?.approved?.when) {
            return moment(historyData?.[row?.reference]?.approved?.when).format(
              "MMM Do, YYYY"
            );
          }
          return "Not Submitted";
        },
        Cell: ({ value }) => (
          <p className="truncate" title={value}>
            {value}
          </p>
        ),
      },
      {
        Header: "Approver",
        accessor: (row) => {
          if (row?.resource === "Timesheet" && !row?.timeOff) {
            return (
              <p className="truncate">
                {getFullName(
                  historyData?.[row?.reference]?.approved?.user?.[0]?.name
                )}
              </p>
            );
          }
          return (
            <p className="truncate">
              {getFullName(userDict[row?.reviewedBy]?.name)}
            </p>
          );
        },
        Cell: ({ value }) => {
          return value ? (
            <p className="truncate" title={value}>
              {value}
            </p>
          ) : (
            <p className={dash}>--</p>
          );
        },
      },
    ],
    [historyData, pto, userDict, pageState]
  );

  const handleTableSelect = useCallback(
    (arr) => {
      const shownRows = arr.filter((info) => !info.hideSelection);
      setSelectedRows(shownRows);
    },
    [setSelectedRows]
  );

  const handleRowClick = (id) => {
    setPersistentPageItem(PAGES.TIMESHEETS, {
      ...pageState?.timesheet,
      userId: id,
      tab: "approve-payroll",
    });
  };

  const hideLockedData = useMemo(() => {
    return [...(data || [])]?.map((item) => {
      if (
        item?.status === "locked" ||
        item?.status === "Not Submitted" ||
        item?.status === "submitted" ||
        item?.status === "rejected" ||
        item?.payrollStatus === "approved" ||
        item?.timeOff
      ) {
        return { ...item, hideSelection: true };
      }
      return item;
    });
  }, [data]);

  return (
    <div className="flex flex-col mt-3">
      <Table
        isLoading={loading}
        disableSorting
        disableDragging
        allowSelection
        leftSearch
        showSearch
        pagination
        hideCreateNewButton
        hideGroupedFooter
        showConstantRowIndex
        disableCreateTemplate
        onSelectedRowChange={handleTableSelect}
        columns={tableColumns}
        data={hideLockedData}
        onRowClick={({ original: row }) =>
          handleRowClick(row?.userRef?.split("User/")[1], row?.periodStart)
        }
        hideEditRowButton
        hideDeleteRowButton
        customSiteHeader={() => (
          <div className="items-center pb-5 pt-3 w-full flex flex-row justify-between">
            <TimeSheetDatePicker
              approvalView
              showNextWeek={showNextWeek}
              showPrevWeek={showPrevWeek}
              showThisWeek={showThisWeek}
              loading={loading}
            />
            {customSiteHeader()}
          </div>
        )}
      />
    </div>
  );
};

export default PurePayrollTable;

PurePayrollTable.propTypes = {
  /**
   * classes to apply to the container div
   */
  data: PropTypes.string,
  /**
   * function called that displays next weeks timesheets
   */
  // eslint-disable-next-line react/forbid-prop-types
  /**
   * function called that displays next weeks timesheets
   */
  showNextWeek: PropTypes.func,
  /**
   * function called that displays previous weeks timesheets
   */
  showPrevWeek: PropTypes.func,
  /**
   * function called that displays this weeks timesheets
   */
  showThisWeek: PropTypes.func,
  /**
   * HistoryAPI data associated with timecards
   */
  historyData: PropTypes.shape({}),
  /**
   * Loading state of payroll hook
   */
  loading: PropTypes.bool,
  /**
   * handles table select functionality
   */
  setSelectedRows: PropTypes.func,
  /**
   * Used to set Custom Site Header From Parent
   * (Outside the Table Component But maintaining Functionality)
   */
  customSiteHeader: PropTypes.func,
  /**
   * Pto data
   */
  pto: PropTypes.arrayOf(PropTypes.shape({})),
};

PurePayrollTable.defaultProps = {
  data: [],
  showNextWeek: undefined,
  showPrevWeek: undefined,
  showThisWeek: undefined,
  historyData: undefined,
  loading: true,
  setSelectedRows: undefined,
  customSiteHeader: undefined,
  pto: [],
};
