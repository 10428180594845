import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import ShareIcon from "../../../assets/images/shareIcon.svg";
import deleteGreenIcon from "../../../assets/images/deleteGreenIcon.svg";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import IconButton from "../../Buttons/IconButton";
import { handleLinkClick } from "../../../../helpers/Utilities";
import useLinksModalWidget from "./useLinksModalWidget";
import LinkInput from "../LinkInput";

const linkCN = (index, isModal) => cntl`
  flex 
  justify-start 
  items-center 
  flex-row
  ${isModal && index && "border-t"}
`;

const LinksModalWidget = ({
  editing,
  resource,
  dispatch,
  disableEditing,
  loading,
  textCN,
  // If Using FormState instead of reducer
  handleAddLink,
  handleRemoveLink,
}) => {
  const {
    handleDeleteClick,
    addLinksIsOpen,
    setAddLinksIsOpen,
    linkName,
    linkUrl,
    setLinkName,
    setLinkUrl,
    handleChange,
    confirmEdit,
  } = useLinksModalWidget({
    dispatch,
    handleAddLink,
    handleRemoveLink,
  });
  return (
    <>
      <p className="font-semibold text-gray-450">Links</p>
      <div className="overflow-auto max-h-72">
        {!!resource?.links?.length &&
          !loading &&
          resource?.links?.reduce((linkList, link, index) => {
            if (link?.url) {
              linkList.push(
                <div key={link?.id} className={linkCN(index)}>
                  <button
                    type="button"
                    className="flex w-full items-center cursor-pointer select-none"
                    onClick={() => handleLinkClick(link)}
                  >
                    <div className="flex py-1 items-center w-full gap-4">
                      <img src={ShareIcon} alt="link open icon" />
                      <p
                        className={`text-gray-400 underline font-normal ${textCN}`}
                      >
                        {link?.name || link?.url}
                      </p>
                    </div>
                  </button>
                  <IconButton
                    wrapperClassName={editing ? "pr-2" : "hidden pr-2"}
                    onClick={() => handleDeleteClick(link)}
                    icon={deleteGreenIcon}
                    disabled={disableEditing}
                  />
                </div>
              );
            }
            return linkList;
          }, [])}
      </div>
      {addLinksIsOpen && editing && (
        <LinkInput
          onSave={confirmEdit}
          onClose={() => setAddLinksIsOpen(false)}
          linkName={linkName}
          setLinkName={setLinkName}
          linkUrl={linkUrl}
          setLinkUrl={setLinkUrl}
          handleChange={handleChange}
          disableEditing={disableEditing}
        />
      )}
      {editing && !addLinksIsOpen && (
        <PlusCircleButton
          title="Add Link"
          onClick={() => setAddLinksIsOpen(!addLinksIsOpen)}
          disabled={disableEditing}
          className="flex w-full items-center h-16 text-darkenedGreen"
          style={{ fontSize: 16 }}
        />
      )}
    </>
  );
};

LinksModalWidget.propTypes = {
  editing: PropTypes.bool,
  resource: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    additionalInfo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  textCN: PropTypes.string,
  disableEditing: PropTypes.bool,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  handleAddLink: PropTypes.func,
  handleRemoveLink: PropTypes.func,
};

LinksModalWidget.defaultProps = {
  editing: false,
  resource: undefined,
  disableEditing: false,
  dispatch: () => {},
  loading: true,
  textCN: undefined,
  handleAddLink: undefined,
  handleRemoveLink: undefined,
};

export default LinksModalWidget;
