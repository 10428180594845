import cntl from "cntl";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import TrashIconEnabled from "../../../assets/images/trashDarkenedGreenIcon.svg";

import IconButton from "../../Buttons/IconButton";

import RoleMembersAvatarList from "../../../../Pages/Admin/RoleMembersAvatarList";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  CREATE_TASK_MODAL,
  DELETE_RECURRENCE_POPUP,
  MARK_COMPLETE_POPUP,
  TASK,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { toastError } from "../../../../helpers/Toast";
import useEventModalData from "../ViewEventModal/useEventModalData";
import getDateTimeInTimezone from "../../../../helpers/Calendar/getDateTimeInTimezone";
import { getFullName } from "../../../../helpers/Formatters";
import {
  hasDeletePermission,
  hasUpdatePermission,
} from "../../../../helpers/Permissions";
import formatTasks, {
  statusPillCN,
} from "../../../../helpers/ServiceRequest/formatTasks";
import { useRemoveFromCalendar } from "../../../../hooks/api/calendar";
import { useRsvp } from "../../../../hooks/rsvp";
import useRemoveFromTaskList from "../../../../hooks/api/tasks/useRemoveFromTaskList";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useAppState } from "../../../../state/appState";
import circleCheckGreenIcon from "../../../assets/images/circleCheckGreenIcon.png";
import whiteCrossIcon from "../../../assets/images/whiteCrossIcon.svg";
import whiteExclamationIcon from "../../../assets/images/whiteExclamationIcon.svg";
import AddSpacesAssetsWidget from "../../AddSpacesAssetsWidget";
import AssociationLink from "../../AssociationLink";
import AttachmentsHorizontalList from "../../AttachmentsHorizontalList";
import CompleteButton from "../../Buttons/CompleteButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Checkbox from "../../Checkbox/Checkbox";
import DescriptionField from "../../DescriptionField";
import EditIcon from "../../EditIcon/EditIcon";
import LinksModalWidget from "../../Links/LinksModalWidget";
import ModalMediaWidget from "../../ModalMediaWidget";
import Pill from "../../Pill/Pill";
import FormatedPriorityLevelLabel from "../../PriorityLevelLabel";
import TaskRelatedTo from "../../RelatedTo/TaskRelatedTo";
import RsvpRow from "../../RsvpRow";
import TagList from "../../Tags/TagList";
import TaskStepsSection from "../../TaskTable/SingleTaskView/TaskSteps";
import useViewTaskModalData from "./useViewTaskModalData";
import ModalWrapper from "../ModalWrapper";
import useEsModalData from "../useEsModalData";
import Divider from "./Divider"; // icon images to be used with toast

// icon images to be used with toast
const toastCloseIcon = <img src={whiteCrossIcon || ""} alt="Close notice" />;
const toastErrorIcon = (
  <img src={whiteExclamationIcon || ""} alt="Error icon" />
);
const labelCN = "text-base font-semibold text-gray-400";
const labelWidth = { minWidth: "9vw" };
const rowCN = "flex flex-row items-center";
const widgetCN = "flex flex-col gap-4 border rounded-md p-4 mt-2";
const leftContainer = "flex flex-row w-1/2 items-center";
const rightContainer = "flex flex-row w-1/2 ml-4 items-center";
const textCN = "text-sm text-gray-400 text-start";

const statusCN = (status) => cntl`
border 
flex 
items-center 
rounded-full
font-medium
text-sm
py-2 
px-3
cursor-default
self-end
${status}
`;

const ViewTaskModal = ({ modalData }) => {
  const history = useHistory();

  const [currentTask, setCurrentTask] = useState({ ...modalData?.item });
  // state for disabling editing recurrence if task related to WF or SR
  const [disableEditRecurrence, setDisableEditRecurrence] = useState(false);

  const {
    recurrenceString,
    allInviteesInfo,
    createdBy,
    modalStateDispatch,
    currentTags,
    associationData,
  } = useEventModalData(modalData);

  const {
    allDayData,
    steps,
    sopOptions,
    isTaskComplete,
    handleStepAdd,
    handleStepEdit,
    handleStepRemove,
  } = useViewTaskModalData({ currentTask });

  const { removeFromCalendar } = useRemoveFromCalendar();
  const { deleteFromTaskList } = useRemoveFromTaskList();

  const [, appStateDispatch] = useAppState();
  const { closeModal: close } = useEsModalData(null, modalData);
  const { data: currentUser } = useCurrentUser();
  const [form, setForm] = useState(modalData?.item);
  const { name } = form;
  const { data: rsvpData } = useRsvp(
    modalData?.item?.reference,
    modalData?.item?.instanceStartDate
  );

  const showRSVP = useMemo(() => {
    return modalData?.item?.invitees?.find(
      (userRef) => userRef === currentUser?.reference
    );
  }, [modalData?.item?.invitees, currentUser?.reference]);

  // Holds if is an all day task
  const isAllDay = allDayData?.isAllDay;

  // If `recurrenceString` text is long
  const alignmentCN =
    recurrenceString?.length > 38 ? "items-stretch" : "items-center";

  const handleEditClick = useCallback(() => {
    modalStateDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: `${modalData.item.id}-edit` },
      position: modalData.position,
      modalData: { ...modalData, recurrenceLock: disableEditRecurrence },
      modalType: CREATE_TASK_MODAL,
    });
    close();
  }, [modalStateDispatch, modalData, close, disableEditRecurrence]);

  const handleDeleteEvent = useCallback(async () => {
    const taskForm = modalData.item;
    if (taskForm?.recurrence) {
      close();
      appStateDispatch({
        type: TOGGLE_POSITIONED_POPUP,
        position: {
          centered: true,
        },
        popupData: { item: modalData.item, popupWidth: 444 },
        popupType: DELETE_RECURRENCE_POPUP,
      });

      return;
    }

    try {
      const paramsForRemoval = { args: taskForm, operation: "$non-recurring" };
      await removeFromCalendar(paramsForRemoval);
      deleteFromTaskList(paramsForRemoval, false);
    } catch (err) {
      toastError(
        `Error Deleting Event: ${err.message}`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  }, [
    appStateDispatch,
    close,
    removeFromCalendar,
    deleteFromTaskList,
    modalData.item,
  ]);

  const handleDeleteClick = () => {
    modalStateDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: `${form.id}-confirm-delete` },
      modalData: {
        item: {
          prompt:
            "Are you sure you want to delete this task, this action cannot be undone?",
          confirm: "Yes",
          cancel: "No",
          title: "Delete Task",
          onConfirm: handleDeleteEvent,
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  const complete = useMemo(() => {
    return form?.status === "done";
  }, [form?.status]);

  const handleCompleteClick = () => {
    close();
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: { centered: true },
      popupData: {
        item: currentTask,
        popupWidth: 500,
        handleCleanExit: () => {},
      },
      popupType: MARK_COMPLETE_POPUP,
    });
  };

  const handleNavigateToTaskPage = () => {
    const taskId = currentTask.id;
    const taskRecurrence = currentTask?.instanceStartDate;

    const location = !taskRecurrence
      ? `/tasks/${taskId}`
      : `/tasks/${taskId}?instanceStartDate=${taskRecurrence}`;

    history.push(location);
    close();
  };

  return (
    <ModalWrapper
      width="1000px"
      modalData={modalData}
      resourceName="Task"
      className="pl-6 pr-4 pb-2 pt-0 gap-5"
      buttons={
        <div className="flex items-center gap-5">
          {complete ? (
            <img
              src={circleCheckGreenIcon || ""}
              alt="approved"
              title="approved"
              className="w-5 h-5"
            />
          ) : (
            <CompleteButton
              disabled={!hasUpdatePermission(TASK, currentUser)}
              onClick={handleCompleteClick}
            />
          )}
          {!complete && hasDeletePermission(TASK, currentUser) && (
            <IconButton
              icon={TrashIconEnabled}
              imgClassName="w-5 h-5"
              onClick={() => handleDeleteClick()}
            />
          )}
        </div>
      }
    >
      <>
        <div className="flex flex-row items-center justify-between w-full">
          <p
            className="text-xl font-semibold tracking-wider text-gray-400 truncate ml-2"
            title={name}
          >
            {name}
          </p>
          <div className="flex flex-row gap-6">
            <PrimaryButton
              buttonTitle="View Full Task"
              addButton
              onClick={handleNavigateToTaskPage}
            />

            {!complete && (
              <button
                type="button"
                onClick={() => handleEditClick()}
                aria-label="edit button"
                disabled={!hasUpdatePermission(TASK, currentUser)}
              >
                <EditIcon disabled={!hasUpdatePermission(TASK, currentUser)} />
              </button>
            )}
          </div>
        </div>

        <div className="flex justify-start">
          <Pill
            value={formatTasks(form)?.formattedStatus}
            background="" // Pill component sets bg to bg-grand if no prop is found
            className={statusCN(
              statusPillCN(formatTasks(form)?.formattedStatus)
            )}
          />
        </div>

        {showRSVP && (
          <RsvpRow
            reference={modalData?.item?.reference}
            instanceStartDate={modalData?.item?.instanceStartDate}
            isRecurring={!!modalData?.item?.recurrence}
            isEvent={modalData?.item?.resource === "Event"}
          />
        )}

        <div className={widgetCN}>
          <div className="flex flex-col gap-3 mt-2">
            {/* Association and Timezone */}
            <div className={rowCN}>
              <div className={leftContainer}>
                <p className={labelCN} style={labelWidth}>
                  Association
                </p>
                {associationData && (
                  <AssociationLink
                    associationName={
                      associationData.title || associationData.name
                    }
                    associationRef={currentTask?.association}
                  />
                )}
              </div>

              <div className={rightContainer}>
                <p className={labelCN} style={labelWidth}>
                  Timezone
                </p>
                <p className={textCN}>{associationData?.timezone}</p>
              </div>
            </div>

            <Divider />

            {/* Start and End */}
            <div className={rowCN}>
              <div className={leftContainer}>
                <div className="flex flex-row flex-grow">
                  <p style={labelWidth} className={labelCN}>
                    Start
                  </p>
                  <p className={textCN}>
                    {getDateTimeInTimezone({
                      date: form.startDate,
                      timezone: form.timezone,
                      format: isAllDay
                        ? "MMMM DD, YYYY"
                        : "MMMM DD, YYYY hh:mmA",
                    })}
                  </p>
                </div>
                {isAllDay && (
                  <div className="flex flex-row mr-6">
                    <Checkbox
                      checked={isAllDay}
                      inputClassName="calendar-form-tick"
                      label="All Day"
                      size="5"
                      disabled
                      labelClassName={textCN}
                    />
                  </div>
                )}
              </div>

              <div className={rightContainer}>
                <p style={labelWidth} className={labelCN}>
                  End
                </p>

                <p className={textCN}>
                  {getDateTimeInTimezone({
                    date: form.endDate,
                    timezone: form.timezone,
                    format: isAllDay ? "MMMM DD, YYYY" : "MMMM DD, YYYY hh:mmA",
                  })}
                </p>
              </div>
            </div>

            <Divider />

            {/** Repeat and Created By */}
            <div className={`flex flex-row ${alignmentCN}`}>
              <div className={`flex flex-row w-1/2 ${alignmentCN}`}>
                <p className={labelCN} style={labelWidth}>
                  Repeat
                </p>
                {recurrenceString && (
                  <p className={textCN}>{recurrenceString}</p>
                )}
              </div>

              <div className={`flex flex-row w-1/2 ml-4 ${alignmentCN}`}>
                <p className={labelCN} style={labelWidth}>
                  Created By
                </p>

                <p className={textCN}>{getFullName(createdBy?.name)}</p>
              </div>
            </div>

            <Divider />

            {/** Priority and description */}
            <div className={rowCN}>
              <div className={leftContainer}>
                <p className={labelCN} style={labelWidth}>
                  Priority
                </p>

                <FormatedPriorityLevelLabel
                  priority={form.priority}
                  textCN={textCN}
                />
              </div>

              <div className={rightContainer}>
                <p className={labelCN} style={labelWidth}>
                  Description
                </p>

                <DescriptionField
                  className={textCN}
                  text={modalData?.item?.description}
                />
              </div>
            </div>

            <Divider />

            {/** Tags */}
            <div className={rowCN}>
              <div className={leftContainer}>
                <p style={labelWidth} className={labelCN}>
                  Related To
                </p>
                <TaskRelatedTo
                  taskRef={currentTask?.reference}
                  setDisableEditRecurrence={setDisableEditRecurrence}
                />
              </div>
              <div className={rightContainer}>
                <p className={labelCN} style={labelWidth}>
                  Tags
                </p>
                {!!form?.tags?.length && (
                  <TagList
                    tags={currentTags}
                    className="flex-wrap gap-2"
                    tagClassName="py-1 px-2"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-1/2 flex flex-col gap-3">
            <div className={widgetCN}>
              <div>
                <p className={labelCN} style={labelWidth}>
                  Assigned To
                </p>

                {form?.invitees.length > 0 && (
                  <div className="flex justify-start pt-2">
                    <RoleMembersAvatarList
                      showRsvpStatus
                      rsvpData={rsvpData}
                      members={allInviteesInfo}
                      membersToShowCount={3}
                      className="space-x-3"
                      viewAllClassName="pl-0 ml-0"
                      viewAllButton={
                        <div
                          style={{ minWidth: "32px", minHeight: "32px" }}
                          className="rounded-3xl mr-3 bg-backgroundGrey flex justify-center items-center shadow-light-lift"
                        >
                          <p className="text-darkenedGreen flex justify-center w-5 text-xs font-semibold">
                            +{allInviteesInfo?.length - 3}
                          </p>
                        </div>
                      }
                    />
                  </div>
                )}
              </div>

              <Divider fullLength />

              <div>
                <p className={`${labelCN} self-start`} style={labelWidth}>
                  Spaces
                </p>
                <AddSpacesAssetsWidget
                  form={form}
                  setForm={setForm}
                  isEditing={false}
                  isModal
                  noTitle
                  checkbox
                  resource="spaces"
                  currentTask={currentTask}
                  disabled={isTaskComplete}
                  setCurrentTask={setCurrentTask}
                />
              </div>

              <div>
                <p className={`${labelCN} self-start`} style={labelWidth}>
                  Assets
                </p>
                <AddSpacesAssetsWidget
                  form={form}
                  setForm={setForm}
                  isEditing={false}
                  isModal
                  noTitle
                  checkbox
                  resource="assets"
                  currentTask={currentTask}
                  disabled={isTaskComplete}
                  setCurrentTask={setCurrentTask}
                />
              </div>
            </div>

            {/** Media */}
            <div className={widgetCN}>
              <p className={labelCN} style={labelWidth}>
                Media
              </p>

              <ModalMediaWidget
                requestForm={form}
                filesState={{ mediaFilesToAdd: [] }}
                isEditing={false}
                handleFilesToAdd={() => {}}
                handleFilesToRemove={() => {}}
                className="min-w-full"
                noTitle
              />
            </div>

            {/** Attachments */}
            <div className={widgetCN}>
              <p className={labelCN} style={labelWidth}>
                Attachments
              </p>

              <div className="flex flex-col flex-wrap gap-2">
                <AttachmentsHorizontalList
                  files={form?.files?.filter(
                    (item) => item.category === "Documents"
                  )}
                  isEditing={false}
                  textCN="text-sm"
                />
              </div>
            </div>
          </div>

          <div className="w-1/2 flex flex-col gap-3">
            <div className={widgetCN}>
              <div>
                <p className={labelCN} style={labelWidth}>
                  Steps
                </p>

                <div className="flex flex-col w-full -mt-2">
                  <TaskStepsSection
                    editingMode={false}
                    isModal
                    sopsForTask={steps}
                    taskForm={modalData?.item}
                    currentTask={currentTask}
                    setCurrentTask={setCurrentTask}
                    sopOptions={sopOptions}
                    handleStepAdd={handleStepAdd}
                    handleStepEdit={handleStepEdit}
                    handleStepRemove={handleStepRemove}
                    noTitle
                  />
                </div>
              </div>
            </div>

            {/** Links */}
            <div className={widgetCN}>
              <LinksModalWidget
                resource={form}
                editing={false}
                loading={false}
                textCN="text-sm"
              />
            </div>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};

export default ViewTaskModal;
