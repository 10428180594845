import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetTags } from "../../../hooks/useTags";
import useURLSetQueryParameters from "../../../hooks/useURLSetQueryParameters";
import useEditModal from "../../../hooks/useEditModal";
import getTableResourceName from "../../../helpers/Table/getTableResourceName";

const useTableData = ({
  resourceName = "",
  openEditTableColumn,
  showScrollShadow = true,
}) => {
  const { data: allTags } = useGetTags();

  // Hook to set query parameters in url
  const { handleAddQueryParam, handleRemoveQueryParam } =
    useURLSetQueryParameters();

  const [isEditModalOpen, setIsEditModalctionslOpen] = useEditModal(false);
  const [canShowScrollShadow, setCanShowScrollShadow] =
    useState(showScrollShadow);

  // resource name to use for `templates` and column `settings`
  const resource = getTableResourceName(resourceName);

  const tagsDict = useMemo(() => allTags?.tagsDict || {}, [allTags?.tagsDict]);

  const isEditTableColumn = openEditTableColumn || isEditModalOpen;

  useEffect(() => {
    setCanShowScrollShadow(showScrollShadow);
  }, [showScrollShadow]);

  // This prevents the edit columns popover from persisting across navigation pages
  useEffect(() => {
    setIsEditModalctionslOpen(false);
  }, [setIsEditModalctionslOpen]);

  /**
   *
   * @param {Object} rows array
   * @param {string} columnId column id (tags)
   * @returns {Object} object where keys are tag combinations and values are arrays of grouped rows
   */
  const handleGroupByTags = useCallback(
    (rows, columnId) => {
      // Object with tags as keys and resource data as value
      return Object.keys(rows).reduce((groupMap, key) => {
        const updatedGroupMap = { ...groupMap };

        rows[key].forEach((row) => {
          const tagValues = row.original[columnId];

          if (Array.isArray(tagValues)) {
            const tagLabels = tagValues.map(
              (tagRef) => tagsDict[tagRef]?.label
            );
            const tagKey = tagLabels.sort().join(", ");
            updatedGroupMap[tagKey] = (updatedGroupMap[tagKey] || []).concat(
              row
            );
          }
        });

        return updatedGroupMap;
      }, {});
    },
    [tagsDict]
  );

  /**
   * When items are selected/unselected in a table, add/remove the references in the URL
   */
  const updateAssociationsQueryOnShowReportURL = useCallback(
    (rows = []) => {
      const supportedResources = ["property", "project", "assets"];

      if (!supportedResources.includes(resourceName)) return;

      const associationMap = {
        property: "Property",
        project: "Project",
        assets: "Asset",
      };

      const association = associationMap[resourceName];

      const selectedAssociations = rows.map(
        (item) => `${association}/${item.id}`
      );

      // If there are selected properties, add references to the 'associations' query parameter
      if (selectedAssociations.length > 0) {
        handleAddQueryParam("associations", selectedAssociations);
      } else {
        handleRemoveQueryParam("associations");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceName]
  );

  return {
    canShowScrollShadow,
    isEditTableColumn,
    resource,
    handleGroupByTags,
    updateAssociationsQueryOnShowReportURL,
    setIsEditModalctionslOpen,
    setCanShowScrollShadow,
  };
};

export default useTableData;
