import { useReducer } from "react";
import { cloneDeep } from "lodash";
import {
  PROJECT_RESET,
  PROJECT_NAME,
  PROJECT_TYPE,
  PROJECT_DURATION,
  PROJECT_DURATION_TYPE,
  PROJECT_UPDATE_DURATION,
  PROJECT_DESCRIPTION,
  PROJECT_GOALS,
  PROJECT_CHALLENGES,
  PROJECT_CONTRACT_DATE,
  PROJECT_START_DATE,
  PROJECT_END_DATE,
  PROJECT_AMOUNT,
  PROJECT_MAILING_SAME_AS_PHYSICAL,
  PROJECT_ADDRESS,
  PROJECT_BUDGET_TYPE,
  PROJECT_ADD_RETAINAGE,
  PROJECT_REMOVE_RETAINAGE,
  PROJECT_UPDATE_RETAINAGE,
  PROJECT_VENDOR,
  PROJECT_ADD_INSURANCE,
  PROJECT_REMOVE_INSURANCE,
  PROJECT_INSURANCE_TYPE,
  PROJECT_PREMIUM,
  PROJECT_FINANCES_MASTER_LIST,
  PROJECT_FEE_TYPE,
  PROJECT_FEE,
  PROJECT_WARRANTY,
  PROJECT_DAY_TYPE,
  PROJECT_DELAY,
  PROJECT_UNFORSEEN_CONDITIONS,
  PROJECT_DISCREPANCIES,
  PROJECT_ALLOWANCE,
  PROJECT_ADD_QUALIFICATION,
  PROJECT_REMOVE_QUALIFICATION,
  PROJECT_CONTRACTS_MASTER_LIST,
  PROJECT_SELECT_DATES,
  PROJECT_HOURS_OF_OPERATION,
  PROJECT_ADD_MEMBER,
  PROJECT_REMOVE_MEMBER,
  PROJECT_UPDATE_RATE,
  PROJECT_STATUS,
  PROJECT_TIMEZONE,
  PROJECT_PROPERTY,
} from "../constants";

export const defaultState = {
  id: null,
  name: "",
  resource: null,
  reference: null,
  description: "",
  projectType: undefined,
  metadata: null,
  goals: "",
  challenges: "",
  contractDate: null,
  startDate: null,
  endDate: null,
  contractAmount: null,
  rateSheet: {
    rates: [],
    premiumDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      holidays: false,
    },
  },
  retainage: [],
  hoursOfOperation: undefined,
  insurance: {
    requirements: [],
  },
  contractualFee: {},

  yearsOfWarranty: null,
  timeToNotify: {},

  allowanceFinePrint: "",
  qualificationsAndExclusions: [],
  members: [],
  weather: null,
  property: null,
  budget: null,
  schedule: null,
  address: [
    {
      use: "Physical",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    {
      use: "Mailing",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  ],
  mailingSameAsPhysical: true,
  threats: [],
  notifications: [],
  tasks: [],
  workflows: [],
  inventory: [],
  files: [],
  approvals: [],
  staffing: [],
  duration: "",
  durationType: "",

  relationships: null,
  vendor: null,
  budgetType: null,
  financesMasterList: false,
  contractsMasterList: false,
  isArchived: false,
  status: false,

  selectDates: true,
  images: [],
};

const reducer = (state, action) => {
  const project = { ...state };
  let newValue;

  switch (action.type) {
    case PROJECT_RESET:
      return cloneDeep(action.value) ?? defaultState;
    case "discard":
      return defaultState;
    case PROJECT_NAME:
      newValue = { name: action.value };
      break;
    case PROJECT_TYPE:
      newValue = { projectType: action.value };
      break;
    case PROJECT_PROPERTY:
      newValue = { property: action.value };
      break;
    case PROJECT_TIMEZONE:
      newValue = { timezone: action.value };
      break;
    case PROJECT_DURATION:
      newValue = { duration: action.value };
      break;
    case PROJECT_DURATION_TYPE:
      newValue = { durationType: action.value };
      break;
    case PROJECT_UPDATE_DURATION:
      newValue = {
        duration: action.duration,
        durationType: action.durationType,
      };
      break;
    case PROJECT_DESCRIPTION:
      newValue = { description: action.value };
      break;
    case PROJECT_GOALS:
      newValue = { goals: action.value };
      break;
    case PROJECT_CHALLENGES:
      newValue = { challenges: action.value };
      break;
    case PROJECT_CONTRACT_DATE:
      newValue = { contractDate: action.value };
      break;
    case PROJECT_START_DATE:
      newValue = { startDate: action.value };
      break;
    case PROJECT_END_DATE:
      newValue = { endDate: action.value };
      break;
    case PROJECT_AMOUNT:
      newValue = { contractAmount: action.value };
      break;
    case PROJECT_ADDRESS:
      newValue = { address: action.value };
      break;
    case PROJECT_MAILING_SAME_AS_PHYSICAL:
      newValue = { mailingSameAsPhysical: action.value };
      break;
    case PROJECT_BUDGET_TYPE:
      newValue = { budgetType: action.value };
      break;
    case PROJECT_ADD_RETAINAGE:
      newValue = {
        retainage: [
          ...project.retainage.filter((line) => !(line.id === action.value.id)),
          action.value,
        ],
      };
      break;
    case PROJECT_REMOVE_RETAINAGE:
      newValue = {
        retainage: [
          ...project.retainage.filter((line) => !(line.id === action.value.id)),
        ],
      };
      break;
    case PROJECT_UPDATE_RETAINAGE:
      newValue = {
        retainage: action.value,
      };
      break;
    case PROJECT_VENDOR:
      newValue = { vendor: action.value };
      break;
    case PROJECT_ADD_INSURANCE:
      newValue = {
        insurance: {
          ...project.insurance,
          requirements: [
            ...project.insurance.requirements.filter(
              (qual) => !(qual.id === action.value.id)
            ),
            action.value,
          ],
        },
      };
      break;
    case PROJECT_REMOVE_INSURANCE:
      newValue = {
        insurance: {
          ...project.insurance,
          requirements: [
            ...project.insurance.requirements.filter(
              (qual) => !(qual.id === action.value.id)
            ),
          ],
        },
      };
      break;
    case PROJECT_INSURANCE_TYPE:
      newValue = {
        insurance: {
          ...project.insurance,
          insuranceType: action.value,
        },
      };
      break;
    case PROJECT_PREMIUM:
      newValue = {
        insurance: {
          ...project.insurance,
          premiumRate: action.value,
        },
      };
      break;
    case PROJECT_FINANCES_MASTER_LIST:
      newValue = { financesMasterList: action.value };
      break;
    case PROJECT_FEE_TYPE:
      newValue = {
        contractualFee: { ...project.contractualFee, feeType: action.value },
      };
      break;
    case PROJECT_FEE:
      newValue = {
        contractualFee: { ...project.contractualFee, amount: action.value },
      };
      break;
    case PROJECT_WARRANTY:
      newValue = { yearsOfWarranty: action.value };
      break;
    case PROJECT_DAY_TYPE:
      newValue = {
        timeToNotify: {
          ...project.timeToNotify,
          dayType: action.value,
        },
      };
      break;
    case PROJECT_DELAY:
      newValue = {
        timeToNotify: {
          ...project.timeToNotify,
          delays: action.value,
        },
      };
      break;
    case PROJECT_UNFORSEEN_CONDITIONS:
      newValue = {
        timeToNotify: {
          ...project.timeToNotify,
          unforseenConditions: action.value,
        },
      };
      break;
    case PROJECT_DISCREPANCIES:
      newValue = {
        timeToNotify: {
          ...project.timeToNotify,
          discrepancies: action.value,
        },
      };
      break;
    case PROJECT_ALLOWANCE:
      newValue = { allowanceFinePrint: action.value };
      break;
    case PROJECT_ADD_QUALIFICATION:
      newValue = {
        qualificationsAndExclusions: [
          ...project.qualificationsAndExclusions.filter(
            (qual) => !(qual.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case PROJECT_REMOVE_QUALIFICATION:
      newValue = {
        qualificationsAndExclusions: [
          ...project.qualificationsAndExclusions.filter(
            (qual) => !(qual.id === action.value.id)
          ),
        ],
      };
      break;
    case PROJECT_CONTRACTS_MASTER_LIST:
      newValue = { contractsMasterList: action.value };
      break;
    case PROJECT_SELECT_DATES:
      newValue = { selectDates: action.value };
      break;
    case PROJECT_HOURS_OF_OPERATION:
      newValue = { hoursOfOperation: action.value };
      break;
    case PROJECT_ADD_MEMBER:
      newValue = {
        members: [
          ...project.members.filter(
            (member) => !(member.id === action.value.id)
          ),
          action.value,
        ],
      };
      break;
    case PROJECT_REMOVE_MEMBER:
      newValue = {
        members: [
          ...project.members.filter((member) => !(member.id === action.value)),
        ],
      };
      break;
    case PROJECT_UPDATE_RATE:
      newValue = {
        rateSheet: action.value,
      };
      break;
    case PROJECT_STATUS:
      newValue = {
        status: action.value,
      };
      break;
    default:
      break;
  }
  return { ...state, ...newValue };
};

export default () => {
  return useReducer(reducer, defaultState);
};
