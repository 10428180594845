/* Framework Tools */
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";

import {
  ADD_OPEN_MODAL,
  PAGES,
  WORKFLOW_CREATE_MODAL,
} from "../../../constants";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useModalState } from "../../../state/modalState";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const useWorkflowOverviewData = () => {
  const { data: currentUser } = useCurrentUser();
  const [{ modals }, modalDispatch] = useModalState();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  const isButtonDisabled = modals.find(
    (item) => item.modalType === WORKFLOW_CREATE_MODAL
  );

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/workflow/123", currentPage will be "workflow"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.WORKFLOWS) {
      setCurrentResourceScreen(PAGES.WORKFLOWS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  const handleAddWorkflow = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "create-workflow-modal" },
      modalData: {
        id: "create-workflow-modal",
        isQuickAdd: false,
      },
      modalType: WORKFLOW_CREATE_MODAL,
    });
  }, [modalDispatch]);

  return { currentUser, isButtonDisabled, handleAddWorkflow };
};

export default useWorkflowOverviewData;
