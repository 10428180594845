import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import {
  ADD_OPEN_MODAL,
  VIEW_EVENT_MODAL,
  VIEW_TASK_MODAL,
} from "../../../constants";
import {
  getNestedResourcePath,
  getSingleResourcePath,
} from "../../../helpers/Navigation";

const MODAL_POSITION = {
  x: 131,
  y: 689,
};

/**
 * Return jsx component to show the toast success message
 * and a link to navigate to the created resource
 * @param {Object} editingResource - resource name and editing status
 * @param {Object} resource - Asset, Project, Property... resource
 * @param {Function} modalStateDispatch - dispatcher
 * @return {Element} jsx component
 */
const ToastMessageAndLink = ({
  editingResource = {},
  resource = {},
  modalStateDispatch,
}) => {
  const history = useHistory();

  const { editing = false } = editingResource;
  let resourcePath;
  const resourceArr = resource?.reference?.split("/");
  const resourceName = resourceArr[0] ?? "Resource";

  if (resourceName === "Task") {
    // path to navigate to newly created path, only when NOT on Calendar view page
    const { id, instanceStartDate } = resource;
    resourcePath = instanceStartDate
      ? `/tasks/${id}?instanceStartDate=${instanceStartDate}`
      : `/tasks/${id}`;
  } else if (resourceName === "Space") {
    // path to navigate to newly cretaed Space
    resourcePath = getNestedResourcePath(
      resource?.association,
      resource?.reference
    );
  } else {
    // path to navivagate to any other newly created resource
    resourcePath = getSingleResourcePath(resource.reference);
  }

  const handleLinkClick = () => {
    if (resourceName === "Event" && modalStateDispatch) {
      // open Event view modal
      modalStateDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: resource.id },
        position: MODAL_POSITION,
        modalData: { item: resource },
        modalType: VIEW_EVENT_MODAL,
      });
    } else if (
      resourceName === "Task" &&
      history.location.pathname.includes("calendar")
    ) {
      // open task view modal only if task careated while on Calendar view
      modalStateDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: resource.id },
        position: MODAL_POSITION,
        modalData: { item: resource },
        modalType: VIEW_TASK_MODAL,
      });
    } else if (resourceName === "Space") {
      // navigate to newly created space page

      // use window.location navigation as bandaid solution pending Space hook refactor
      // TODO: replace window.location navigation with history.location when Space refactor complete
      window.location.assign(resourcePath);
    } else {
      // navigate to newly created resource page
      history.push(
        resourcePath,
        resourceName === "Workflow" && { activeTabIndex: 0 }
      );
    }
  };

  return (
    <p className="font-es-normal">
      Successfully created {resourceName}.{" "}
      {!editing && (
        <button
          type="button"
          className="underline font-es-semibold"
          onClick={handleLinkClick}
        >
          Click to see
        </button>
      )}
    </p>
  );
};

ToastMessageAndLink.propType = {
  history: PropTypes.shape({
    location: PropTypes.string,
    push: PropTypes.func,
  }),
  resource: PropTypes.shape({
    reference: PropTypes.string,
    association: PropTypes.string,
    id: PropTypes.string,
    instanceStartDate: PropTypes.string,
  }),
  modalStateDispatch: PropTypes.func,
  editingResource: PropTypes.shape({ editing: PropTypes.bool }),
};

ToastMessageAndLink.defaultProps = {
  history: {},
  editingResource: {},
  resource: {},
  modalStateDispatch: undefined,
};

export default ToastMessageAndLink;
