import { useCallback } from "react";

const useProjectRateSheetData = ({ project, isEditing, dispatch }) => {
  // Allow edit ONLY if Project has not been activated
  const canEditRateSheet = isEditing && project?.status !== "active";
  const onAdd = useCallback(() => {
    dispatch({
      type: "addRateSheet",
    });
  }, [dispatch]);
  const onChange = useCallback(
    (index, field, value) => {
      dispatch({
        type: "changeRateSheet",
        index,
        value,
        field,
      });
    },
    [dispatch]
  );

  const onRemove = useCallback(
    (index) => {
      dispatch({
        type: "removeRateSheet",
        index,
      });
    },
    [dispatch]
  );
  const onSelectPremiumRateDay = useCallback(
    (day) => {
      dispatch({
        type: "selectRateSheetPremiumRateDays",
        day,
      });
    },
    [dispatch]
  );

  return {
    canEditRateSheet,
    onAdd,
    onChange,
    onRemove,
    onSelectPremiumRateDay,
  };
};

export default useProjectRateSheetData;
