import { useCallback, useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";
import useCurrentUser from "../../hooks/useCurrentUser";
import useServiceRequestTableFormat from "../../hooks/useServiceRequestTableFormat";
import {
  ADD_OPEN_MODAL,
  SERVICE_REQUEST_CREATE_MODAL,
  PAGES,
} from "../../constants";
import { useModalState } from "../../state/modalState";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";

const useServiceRequestListData = () => {
  const [{ modals }, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  // Query selector to get all service requests
  const { data: serviceRequestTickets, isLoading } =
    useServiceRequestTableFormat({ association: false, asset: false });

  const isButtonDisabled = modals?.find(
    (item) => item.modalType === SERVICE_REQUEST_CREATE_MODAL
  );

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("ticket", "can_create"),
    [currentUser]
  );

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/tickets/123", currentPage will be "tickets"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.SERVICE_REQUESTS) {
      setCurrentResourceScreen(PAGES.SERVICE_REQUESTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  const handleOpenModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: SERVICE_REQUEST_CREATE_MODAL,
      ref: { id: uuidv4() },
      modalData: {},
    });
  }, [modalDispatch]);

  return {
    isButtonDisabled,
    canCreate,
    serviceRequestTickets,
    isLoading,
    handleOpenModal,
  };
};

export default useServiceRequestListData;
