import React, { useRef } from "react";
import PropTypes from "prop-types";
import DropdownWithCheckbox from "../DropdownWithCheckbox";
import useRecurrenceDropdown from "./useRecurrenceDropdownData";

const RecurrenceDropdown = ({
  form,
  recurrence,
  setRecurrence,
  setHumanReadableCustomRecurrence,
  menuPlacement,
}) => {
  const containerRef = useRef();

  const { recurrenceValue, recurrenceOptions, handleSetRecurrence } =
    useRecurrenceDropdown({
      form,
      containerRef,
      recurrence,
      setRecurrence,
      setHumanReadableCustomRecurrence,
    });

  return (
    <>
      <div ref={containerRef} />
      <DropdownWithCheckbox
        options={recurrenceOptions}
        value={recurrenceValue}
        setValue={handleSetRecurrence}
        isMulti={false}
        menuPlacement={menuPlacement}
      />
    </>
  );
};

RecurrenceDropdown.defaultProps = {
  form: {},
  recurrence: undefined,
  menuPlacement: "bottom",
  setRecurrence: () => {},
  setHumanReadableCustomRecurrence: () => {},
};

RecurrenceDropdown.propTypes = {
  form: PropTypes.shape({}),
  recurrence: PropTypes.string,
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  setRecurrence: PropTypes.func,
  setHumanReadableCustomRecurrence: PropTypes.func,
};

export default RecurrenceDropdown;
