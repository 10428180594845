/**
 * Sets the current page in the user settings.
 *
 * @param {Object} params - The parameters for setting the current page.
 * @param {string} params.page - The page to set as the current page.
 * @param {Function} params.editSettings - The function to edit the user settings.
 * @param {Object} params.userSettings - The current user settings.
 * @returns {Promise} A promise that resolves when the settings have been edited.
 */

const setCurrentPage = async ({ page, editSettings, userSettings }) => {
  const newSettings = {
    ...userSettings,
    web: { ...userSettings.web, activePage: page },
  };

  return editSettings(newSettings);
};

export default setCurrentPage;
