import React from "react";
import useEmployeeNameStatusData from "./useEmployeeNameStatusData";
import StatusPill from "../../Pill/StatusPill";

const EmployeeNameStatus = ({ timesheet, isLoading }) => {
  const { userName } = useEmployeeNameStatusData();

  return (
    <div className="mt-10 mb-4 pl-2 flex items-center h-4">
      {isLoading ? (
        <h3 className="text-center relative">
          <span className="inline-block loadingInline" />
          Loading...
        </h3>
      ) : (
        <>
          <h2 className="capitalize font-semibold text-2xl mr-8">{userName}</h2>
          <StatusPill size="large" value={timesheet?.resource?.status} />
        </>
      )}
    </div>
  );
};

export default EmployeeNameStatus;
