/* eslint-disable no-alert */
import cntl from "cntl";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PrimaryButton from "../../Buttons/PrimaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import CloseButton from "../../Modal/Buttons/CloseButton";
import ExpandButton from "../../Modal/Buttons/ExpandButton";
import HideButton from "../../Modal/Buttons/HideButton";
import "../../Modal/ModalStyles.css";
import useEsModalData from "../useEsModalData";
import { useModalState } from "../../../../state/modalState";
import { UPDATE_MODAL_INSTANCE } from "../../../../constants";
import "./styles.css";

const overlayCN = (expanded, alert) => cntl`
${(expanded || alert) && "es-modal-overlay-expanded"}
`;

const wrapperCN = (positioned, state, alert, wrapperClassName) => cntl`
  overflow-hidden
  ${wrapperClassName}
  ${alert ? "rounded-md" : "rounded-t-md"}
  ${(!state || state?.minimized) && "max-w-250"}
  ${!positioned ? "modal-overlay-class" : "rounded-b-md shadow-md"}
  ${!positioned && !state?.minimized && "modal-content-class-open"}
  ${state && !state?.minimized ? "wrapper-shown" : "wrapper-hide"}
  ${state && !state?.minimized && !alert && "min-h-400"}
  flex
  flex-col
  bg-white
  w-full
`;

const contentContainerClass = (state, className) => cntl`
  ${
    !state?.minimized && state
      ? (className && `${className} pt-8 pb-4 overflow-y-auto`) ??
        "pt-8 pb-4 px-8 overflow-y-auto"
      : ""
  }
  flex
  flex-col
  gap-4
`;

const titleCN = (minimized) => cntl`
  font-medium
  text-lg
  text-white
  truncate
  ${minimized && "truncate"}
`;

// The ModalWrapper component is a wrapper for all modals
// It is responsible for positioning the popup and
// closing the popup when the user clicks the close button.

const ModalWrapper = ({
  formData,
  modalData,
  resourceName,
  children,
  width,
  title,
  primaryButtonOnClick,
  primaryButtonTitle,
  disabled,
  buttons: customButtons,
  hideActions,
  alert,
  float,
  showConfirm,
  trashCan,
  onRequestClose,
  className,
  wrapperClassName,
  expanded,
}) => {
  const containerRef = useRef(null);
  const {
    modalWrapperStyles,
    closeModal,
    minimize,
    maximize,
    expand,
    contract,
    state,
    modals,
    buttons,
  } = useEsModalData(containerRef, modalData, customButtons);
  const [, modalsDispatch] = useModalState();

  useEffect(() => {
    if (expanded) {
      expand(modalData.id);
    }
    /* This is disabled so this useEffect runs only once on load, and if this modal has the prop expanded */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // find index of the modal for the item im updating
    const modalToUpdateIndex = modals.findIndex((m) => m.id === modalData?.id);
    // if modal not found return
    if (modalToUpdateIndex === -1) return;
    // get form from index
    const modalToUpdate = modals[modalToUpdateIndex];
    // Check if formData has actually changed to prevent unnecessary updates
    if (
      JSON.stringify(modalToUpdate.modalData.formData) ===
      JSON.stringify(formData)
    ) {
      // !important to prevent infinite loops
      return;
    }
    // create a new modal item with the updated form data
    const updatedModal = {
      ...modalToUpdate,
      modalData: {
        ...modalToUpdate.modalData,
        formData,
      },
    };

    // update the modal in the state
    modalsDispatch({
      type: UPDATE_MODAL_INSTANCE,
      id: modalData.id,
      modalData: updatedModal,
    });
  }, [formData, modalData?.id, modals, modalsDispatch]);

  /**
   * Modal Header Action Handlers
   */

  const onMinimizeClick = useCallback(
    (value) => {
      // If minimizing shrink modal
      if (value) {
        minimize(modalData.id);
      } else {
        maximize(modalData.id);
      }
    },
    [maximize, minimize, modalData?.id]
  );

  const onExpandClick = useCallback(
    (value) => {
      // if expanding de-tab modal
      if (value) {
        expand(modalData.id);
      } else {
        contract(modalData.id);
      }
    },
    [contract, expand, modalData?.id]
  );

  const handleClickOutside = useCallback(
    (e) => {
      const { clientX: clickX, clientY: clickY } = e ?? {
        clientX: 0,
        clientY: 0,
      };
      const {
        x,
        y,
        width: containerWidth,
        height: containerHeight,
      } = containerRef?.current?.getBoundingClientRect() ?? {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      // Track if outside click (not including input#file-input usually hidden)
      const isFileInput = e?.target?.id === "file-input";

      // Check if click is outside modal horizontally
      const isClickOutsideHorizontally =
        x > clickX || clickX > x + containerWidth;

      // Check if click is outside modal vertically
      const isClickOutsideVertically =
        y > clickY || clickY > y + containerHeight;

      // Checks to see if click is outside of modal
      const isClickOutside =
        isClickOutsideHorizontally || isClickOutsideVertically;

      // If click is outside modal and not on file input close modal
      if (isClickOutside && !isFileInput && alert && !float) {
        closeModal();
      }
    },
    [alert, closeModal, float]
  );

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    setTimeout(
      () => document.addEventListener("click", handleClickOutside, false),
      10
    );

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  /**
   * Modal Header Action Handlers
   */

  const tabPosition = useMemo(() => {
    const overlap =
      250 * modals?.length > window.innerWidth * 0.5 && state?.index !== 0
        ? (250 * modals?.length - window.innerWidth * 0.5) / modals?.length
        : 0;
    const style = {
      right: `${250 * state?.index - overlap * state?.index}px`,
      zIndex: `${50 + state?.index}`,
    };
    if (!modalData?.position) {
      style.boxShadow = "0px 0px 31px 0px #00000033";
    }

    if (state?.expanded) {
      delete style?.right;
    }
    return style;
  }, [modalData?.position, modals?.length, state?.expanded, state?.index]);
  const modalStyle = useMemo(() => {
    let styles = modalData?.position ? modalWrapperStyles : tabPosition;
    if (width && !state?.minimized) {
      styles = { ...styles, width };
    }
    if (state?.expanded || alert) {
      styles = {
        ...styles,
        position: "relative",
        maxHeight: "80vh",
      };
      delete styles.left;
      delete styles.top;
    }
    return styles;
  }, [
    alert,
    modalData?.position,
    modalWrapperStyles,
    state?.expanded,
    state?.minimized,
    tabPosition,
    width,
  ]);

  const headerTitle = useMemo(() => {
    if (modalData?.position) {
      return resourceName;
    }

    return modalData?.item?.name ?? title;
  }, [modalData?.item?.name, modalData?.position, resourceName, title]);

  /**
   * Confirm close
   */
  const [dialog, setDialog] = useState(false);
  const [confirmFadeOut, setConfirmFadeOut] = useState(false);

  const cancelConfirmCloseTimer = useRef(null);

  /**
   * Open dialog if showConfirm flagged
   */
  const handleClose = useCallback(() => {
    if (!showConfirm) {
      onRequestClose();
      closeModal();
      return;
    }
    setDialog(true);
  }, [closeModal, onRequestClose, showConfirm]);

  /**
   * @summary Cancel close if confirm is provided
   */
  const cancelConfirmClose = useCallback(() => {
    setConfirmFadeOut(true);
    cancelConfirmCloseTimer.current = setTimeout(() => {
      setDialog(false);
      setConfirmFadeOut(false);
    }, 300);
  }, []);

  /**
   * Confirm close
   */

  return (
    <div className={overlayCN(state?.expanded, alert)}>
      <div
        ref={containerRef}
        style={modalStyle}
        className={wrapperCN(
          modalData?.position,
          state,
          alert,
          wrapperClassName
        )}
      >
        <div
          id="confirm-container"
          className={`${
            dialog
              ? "overflow-hidden relative"
              : `${!alert && "min-h-400"} flex flex-col`
          }`}
        >
          {showConfirm && dialog && !alert && !state?.minimized && (
            <div
              id="confirm dialog"
              className={`z-100 absolute flex flex-col justify-center items-center w-full h-full top-0 left-0 confirm-dialog confirm-transition px-2 ${
                confirmFadeOut && "confirm-fade-out"
              }`}
            >
              <p className="text-base mb-2 w-3/4 text-center text-gray-300">
                Are you sure you want to cancel {resourceName ?? "create test"}
                ?
                <br />
                If Yes, all data entered will be lost.
              </p>
              <div className="flex gap-4">
                <TertiaryButton
                  title="No"
                  onClick={cancelConfirmClose}
                  onKeyUp={(event) => {
                    if (event.keyCode === 13) {
                      cancelConfirmClose();
                    }
                  }}
                />
                <PrimaryButton
                  saveButtonTitle="Yes"
                  onClick={closeModal}
                  background="primaryGreen"
                  className="px-7"
                  saveButton
                  onKeyUp={closeModal}
                />
              </div>
            </div>
          )}

          <div className="flex justify-between items-center bg-primaryGreen px-4 py-3">
            <h1
              className={titleCN(state?.minimized)}
              title={modalData?.item?.name}
            >
              {headerTitle}
            </h1>

            <div
              id={`modal-window-actions-${modalData?.id}`}
              className="flex ml-8"
            >
              <HideButton
                className={`h-5 p-1 ${hideActions || alert ? "hidden" : ""}`}
                animated
                onClick={onMinimizeClick}
                value={state?.minimized}
                color="rgb(255, 255, 255)"
              />
              <ExpandButton
                className={`ml-1 h-5 p-1 ${
                  hideActions || alert ? "hidden" : ""
                }`}
                animated
                onClick={onExpandClick}
                value={state?.expanded}
                color="rgb(255, 255, 255)"
              />
              <CloseButton
                onClick={handleClose}
                className="ml-1 h-5 p-1"
                animated
                color="rgb(255, 255, 255)"
              />
            </div>
          </div>

          <div className={contentContainerClass(state, className)}>
            {React.cloneElement(children, {
              close: closeModal,
              modalData,
            })}
          </div>
          <div className="flex gap-4 px-8 py-4 bg-white flex-1 relative fade-out-overflow">
            {buttons && (
              <>
                {buttons}
                {trashCan && (
                  <TertiaryButton
                    title="cancel"
                    onClick={handleClose}
                    trashCan={trashCan}
                  />
                )}
              </>
            )}

            {!buttons && (
              <>
                <PrimaryButton
                  onClick={() => {
                    primaryButtonOnClick();
                    closeModal();
                  }}
                  border="primaryGreen"
                  background="primaryGreen"
                  fontColor="white"
                  saveButton
                  saveButtonTitle={primaryButtonTitle}
                  name="primaryButton"
                  disabled={disabled}
                />
                <TertiaryButton
                  className="rounded-lg"
                  title="cancel"
                  cancelButton={!trashCan}
                  onClick={handleClose}
                  trashCan={trashCan}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ModalWrapper.propTypes = {
  modalData: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.shape({}),
    item: PropTypes.shape({ name: PropTypes.string }),
  }),
  children: PropTypes.element,
  width: PropTypes.string,
  title: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttons: PropTypes.element,
  formData: PropTypes.shape({}),
  /**
   * Name of resource
   */
  resourceName: PropTypes.string,
  /**
   * Hide Window Actions
   */
  hideActions: PropTypes.bool,
  /**
   * Close on outside click
   * Alert Modal
   * Not Meant for minimize
   */
  alert: PropTypes.bool,
  /**
   * Alert style modal that doesn't close on outside click
   */
  float: PropTypes.bool,
  /**
   * Confirm before closing
   */
  showConfirm: PropTypes.bool,
  /**
   * Use trashcan for tertiary button
   */
  trashCan: PropTypes.bool,
  /**
   * Primary Button Title
   */
  primaryButtonTitle: PropTypes.string,
  /**
   * Function called when modal requests close
   */
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  expanded: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  modalData: undefined,
  children: undefined,
  width: undefined,
  title: undefined,
  primaryButtonOnClick: undefined,
  disabled: false,
  buttons: undefined,
  resourceName: "[Resource Name]",
  hideActions: false,
  alert: false,
  float: false,
  showConfirm: false,
  trashCan: false,
  primaryButtonTitle: undefined,
  formData: undefined,
  onRequestClose: () => {},
  className: undefined,
  wrapperClassName: undefined,
  expanded: false,
};

export default ModalWrapper;
