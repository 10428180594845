import { isEqual } from "lodash";
import React from "react";
import * as yup from "yup";
import { array } from "yup";
import requiredIcon from "../stories/assets/images/requiredIcon.svg";
import { phoneRegExp, yupEmailRegExp } from "./Formatters";

// Encapsulates only the data required for continuing/submitting a form

export const signupContactInformationSchema = yup.object().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  title: yup.string(),
  company: yup.string().trim().required(),
  workspaceName: yup.string().trim().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const validUrlSchema = (validationMessage) =>
  yup.string().required(validationMessage ?? "");

export const contactFormSchema = (emails, contactPoints, contactType) =>
  yup.lazy(() => {
    if (contactType !== "company") {
      return yup
        .object()
        .shape({
          name: yup.object().shape({
            firstName: yup.string().min(3, "Must be 3 characters").required(),
          }),
        })
        .when("invite", (invite, contactPoint) => {
          /**
           * First Condition:
           * - Invited
           * - Email Required
           */
          const email = contactPoints?.find((item) => item?.system === "Email");
          if (invite && !email) {
            return contactPoint.test({
              name: "email-check",
              message: "Email Required",
              test: () => false,
            });
          }

          /**
           * Second Condition:
           * - Contact Points > 0
           * - Regex Check Each Value
           */
          if (contactPoints?.length > 0) {
            return contactPoint.test({
              name: "contactpoint-test",
              test: (val) =>
                val.every((point) => {
                  // Phone Case
                  if (point?.system === "Phone") {
                    if (
                      point?.system &&
                      point?.use &&
                      phoneRegExp.test(point?.value)
                    )
                      return true;
                    return false;
                  }
                  // Email Case
                  if (point?.system === "Email") {
                    if (point?.value !== "" && emails.includes(point?.value)) {
                      return false;
                    }
                    return yupEmailRegExp.test(point?.value);
                  }

                  return true;
                }),
            });
          }
          return contactPoint;
        });
    }
    return yup.object().shape({
      companyName: yup.string().min(3, "Must be 3 characters").required(),
      address: yup.array().of(
        yup.object().shape({
          city: yup.string(),
          country: yup.string(),
          state: yup.string(),
          zipCode: yup.string(),
          street: yup.string(),
        })
      ),
      contactPoint: yup
        .array()
        .of(
          yup.object().shape({
            system: yup.string(),
            use: yup.string(),
            value: yup.string(),
          })
        )
        .test({
          name: "email-check",
          message: "Invalid Entry",
          test: (val) =>
            val.every((point) => {
              // Phone Case
              if (point?.system === "Phone") {
                if (
                  point?.system &&
                  point?.use &&
                  phoneRegExp.test(point?.value)
                )
                  return true;
                return false;
              }
              // Email Case
              if (point?.system === "Email") {
                if (point?.value !== "" && emails.includes(point?.value)) {
                  return false;
                }
                return yupEmailRegExp.test(point?.value);
              }

              return true;
            }),
        }),
    });
  });

export const firstNameValidation = yup
  ?.string()
  ?.trim()
  .min(1, "Must be at least 1 character")
  .required();

export const companyNameValidation = yup
  .string()
  .min(1, "Must be at least 1 characters")
  .required();

export const contactDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const companyContactDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
});

export const contactInfoSchema = yup.object().shape({
  emails: array()
    .of(
      yup.object().shape({
        type: yup.string(),
        value: yup.string().email().required(),
      })
    )
    .min(1),
  phoneNumbers: array().of(
    yup.object().shape({
      type: yup.string(),
      value: yup.string().required().matches(phoneRegExp),
    })
  ),
});

export const assetInfoSchema = () =>
  yup.object().shape({
    name: yup.string().trim().required(),
    category: yup.string().required(),
    subcategory: yup.string().required(),
    property: yup.lazy(() =>
      yup.string().when("project", {
        is: undefined,
        then: yup.string().required(),
      })
    ),
    project: yup.lazy(() =>
      yup.string().when("property", {
        is: undefined,
        then: yup.string().required(),
      })
    ),
    links: yup.array().of(
      yup.object().shape(
        {
          url: yup.string().when("name", {
            is: (name) => !name || name.length === 0,
            then: yup.string().required(),
            otherwise: yup.string().required(),
          }),
        },
        ["name", "url"]
      )
    ),
  });

export const calendarEventSchema = yup.object().shape({
  name: yup.string().required(),
  association: yup.string().required(),
});

export const completeTaskSchema = yup.object().shape({
  duration: yup.object().shape({
    value: yup.number().required(),
    typeOfDuration: yup.string().required(),
  }),
});
export const propertyInfoSchema = yup.object().shape({
  title: yup.string().trim().required(),
  propertyType: yup.string().required(),
  timezone: yup.string().required(),
});

export const workflowInfoSchema = yup.string().required();

export const assetMaintenanceScheduleSchema = yup.object().shape({
  name: yup.string().required(),
  startDate: yup.string().required(),
  dueTime: yup.string().required(),
  recurrence: yup.string().required(),
});
export const maintenanceScheduleCompleteSchema = yup.object().shape({
  durationCode: yup.mixed().required(),
  durationValue: yup.number().required(),
});

export const projectBudgetInfoSchema = yup.object().shape({
  budgetType: yup.string().required(),
});

export const budgetLineitemInfoSchema = (isRequiredDisabled) =>
  yup.object().shape({
    costPerUnit: isRequiredDisabled ? yup.string() : yup.string().required(),
    financialCode: yup.object().shape({
      division: isRequiredDisabled ? yup.string() : yup.string().required(),
      code: isRequiredDisabled ? yup.string() : yup.string().required(),
      subcode: isRequiredDisabled ? yup.string() : yup.string().required(),
    }),
    quantity: isRequiredDisabled ? yup.string() : yup.string().required(),
    unitOfMeasure: isRequiredDisabled ? yup.string() : yup.string().required(),
  });

export const sopInfoSchema = yup.object().shape({
  category: yup.string().required(),
  name: yup.string().required(),
});

export const holidayModalSchema = (isAdding) =>
  yup.object().shape({
    display: yup.string().required(),
    date: yup.string().when("dateOption", {
      is: (dateOption) =>
        dateOption?.value === "specificDate" ||
        (!dateOption?.value && isAdding),
      then: yup.string().required(),
    }),
    recurrence: yup.object().when("dateOption", {
      is: (dateOption) => {
        return (
          dateOption?.value === "customRecurrence" ||
          (!dateOption?.value && isAdding)
        );
      },
      then: yup.object().shape({
        calendarDay: yup.number().required(),
        dayOfWeek: yup.string().required(),
        month: yup.string().required(),
      }),
    }),
  });

export const ptoSchema = yup.object().shape({
  location: yup.string().required(),
});

export const selectSectionSchema = ({ selectionItemValue }) =>
  yup.object().shape({
    [selectionItemValue]: yup.string().required(),
  });

export const ptoTypeSchema = yup.object().shape({
  numHours: yup.number().when(["selected", "type"], {
    is: (selected, type) => selected === true && type !== "unlimited",
    then: yup
      .number()
      .min(1, "Minimum atleast 1")
      .max(240, `Allowed maximum is 240`)
      .required(),
  }),
  maxRollover: yup.number().when(["selected", "accrualType", "type"], {
    is: (selected, accrualType, type) =>
      selected === true &&
      accrualType !== "non-accrual" &&
      type !== "unlimited",
    then: yup
      .number()
      .min(1, `Minimum at least 1`)
      .max(240, `Allowed maximum is 240`)
      .required(),
  }),
  availability: yup.object().when("selected", {
    is: (val) => val === true,
    then: yup.object().shape({
      date: yup.date().required(),
      waitPeriod: yup
        .number()
        .min(1, "Minimum atleast 1")
        .max(90, `Allowed maximum is 90`),
    }),
  }),
});

export const systemSettingsSchema = yup.object().shape({
  benefits: yup.array().of(
    yup.object().shape({
      benefit: yup.array(
        yup.object().shape({
          duration: yup.string(),
          plan: yup.string().required(),
        })
      ),
      category: yup.string(),
      custom: yup.boolean(),
    })
  ),
  cities: yup.array().of(
    yup.object().shape({
      city: yup.string().required(),
      cityCode: yup.string(),
      custom: yup.boolean(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  customLengthUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  customQuantityUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  customTimeUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  customVolumeUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  holidays: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      recurrence: yup.object().shape({
        calendarDay: yup.number(),
        dayOfWeek: yup.string(),
        month: yup.string(),
      }),
      selected: yup.boolean(),
    })
  ),
  hoursOfOperation: yup.object().shape({
    friday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
    monday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
    saturday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
    sunday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
    thursday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
    tuesday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
    wednesday: yup.object().shape({
      active: yup.boolean(),
      from: yup.string(),
      to: yup.string(),
    }),
  }),
  lengthUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  quantityUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  sopCategories: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
    })
  ),
  timeUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  unitsOfMeasure: yup.object().shape({
    length_area: yup.array().of(
      yup.object().shape({
        custom: yup.boolean(),
        display: yup.string().required(),
        id: yup.string(),
        selected: yup.boolean(),
      })
    ),
    quantity: yup.array().of(
      yup.object().shape({
        custom: yup.boolean(),
        display: yup.string().required(),
        id: yup.string(),
        selected: yup.boolean(),
      })
    ),
    time: yup.array().of(
      yup.object().shape({
        custom: yup.boolean(),
        display: yup.string().required(),
        id: yup.string(),
        selected: yup.boolean(),
      })
    ),
    volume_weight: yup.array().of(
      yup.object().shape({
        custom: yup.boolean(),
        display: yup.string().required(),
        id: yup.string(),
        selected: yup.boolean(),
      })
    ),
  }),
  volumeUnits: yup.array().of(
    yup.object().shape({
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  worldClock: yup.object().shape({
    cities: yup.array().of(
      yup.object().shape({
        city: yup.string().required(),
        cityCode: yup.string(),
        custom: yup.boolean(),
        id: yup.string(),
        selected: yup.boolean(),
      })
    ),
  }),
});
export const managementSettingsSchema = yup.object().shape({
  RFITemplate: yup.object().shape({
    isTemplate: yup.boolean(),
    name: yup.string(),
    steps: yup.array(),
  }),
  assetCategories: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      definition: yup.string(),
      subcategories: yup.array().of(
        yup.object().shape({
          count: yup.number(),
          custom: yup.boolean(),
          display: yup.string().required(),
          id: yup.string(),
          definition: yup.string(),
        })
      ),
    })
  ),
  contactDisciplines: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  entityInsurance: yup.array().of(
    yup.object().shape({
      category: yup.string(),
      custom: yup.boolean(),
      id: yup.string(),
      policies: yup.array().of(
        yup.object().shape({
          provider: yup.string(),
          duration: yup.string(),
          startDate: yup.date(),
          website: yup.string(),
          expense: yup.object().shape({
            classifications: yup.object().shape({
              selected: yup.boolean(),
              values: yup.array().of(
                yup.object().shape({
                  classification: yup.string(),
                  cost: yup.string(),
                })
              ),
            }),
            cost: yup.object().shape({
              selected: yup.boolean(),
              value: yup.string(),
            }),
            percentage: yup.object().shape({
              selected: yup.boolean(),
            }),
            provider: yup.string(),
            startDate: yup.date(),
            website: yup.string(),
          }),
        })
      ),
    })
  ),
  entityTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  projectInspectionTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  projectInsurance: yup.array().of(
    yup.object().shape({
      category: yup.string(),
      custom: yup.boolean(),
      id: yup.string(),
      policies: yup.array(),
    })
  ),
  projectTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  propertyLevelTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  propertySpaceTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  propertyTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  submittalTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  ticket: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
});
export const financialSettingsSchema = yup.object().shape({
  chartOfAccounts: yup.array().of(
    yup.object().shape({
      accountType: yup.string(),
      code: yup.string(),
      codeType: yup.string(),
      description: yup.string(),
      id: yup.string(),
      selectedForBudgets: yup.boolean(),
      selectedForTimesheets: yup.boolean(),
      subCodes: yup.array().of(
        yup.object().shape({
          code: yup.string(),
          description: yup.string(),
          id: yup.string(),
          selectedForBudgets: yup.boolean(),
          selectedForTimesheets: yup.boolean(),
        })
      ),
    })
  ),
  csiCodeMapping: yup.array().of(
    yup.object().shape({
      csiCodes: yup.array().of(
        yup.object().shape({
          code: yup.string(),
          custom: yup.boolean(),
          description: yup.string(),
          id: yup.string(),
          selectedForBudgets: yup.boolean(),
          selectedForTimesheets: yup.boolean(),
          subCodes: yup.array().of(
            yup.object().shape({
              code: yup.string(),
              custom: yup.boolean(),
              description: yup.string(),
              id: yup.string(),
              selectedForBudgets: yup.boolean(),
              selectedForTimesheets: yup.boolean(),
            })
          ),
        })
      ),
      custom: yup.boolean(),
      description: yup.string(),
      division: yup.string(),
      id: yup.string(),
      selectedForBudgets: yup.boolean(),
      selectedForTimesheets: yup.boolean(),
    })
  ),
  divisionCodes: yup.array().of(
    yup.object().shape({
      code: yup.string(),
      custom: yup.boolean(),
      description: yup.string(),
      id: yup.string(),
      selectedForEntities: yup.boolean(),
    })
  ),
  expense: yup.object().shape({
    categories: yup.array().of(
      yup.object().shape({
        count: yup.number(),
        custom: yup.boolean(),
        display: yup.string(),
        id: yup.string(),
        selected: yup.boolean(),
      })
    ),
  }),
  expenseCategories: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string().required(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  projectBudgetTypes: yup.array().of(
    yup.object().shape({
      count: yup.number(),
      custom: yup.boolean(),
      display: yup.string(),
      id: yup.string(),
      selected: yup.boolean(),
    })
  ),
  rateSheet: yup.array().of(
    yup.object().shape({
      category: yup.string().required(),
      premiumRate: yup.string(),
      rateOver40Hrs: yup.string(),
      ratePerHr: yup.string(),
      selected: yup.boolean(),
    })
  ),
});
export const documentSettingsSchema = yup.object().shape({
  documentType: yup.array().of(
    yup.object().shape({
      category: yup.string(),
      count: yup.number(),
      id: yup.string(),
      premiumRate: yup.string(),
      rateOver40Hrs: yup.string(),
      ratePerHr: yup.string(),
      selected: yup.boolean(),
    })
  ),
  documentTypes: yup.array().of(
    yup.object().shape({
      category: yup.string(),
      count: yup.number(),
      id: yup.string(),
      premiumRate: yup.string(),
      rateOver40Hrs: yup.string(),
      ratePerHr: yup.string(),
      selected: yup.boolean(),
    })
  ),
  qualsAndExceptions: yup.object().shape({
    bid: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string(),
      })
    ),
    changeOrder: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string(),
      })
    ),
    contingencyAuthorization: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string(),
      })
    ),
    purchaseAuthorization: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string(),
      })
    ),
    purchaseOrder: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string(),
      })
    ),
  }),
  submissionReqs: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
});
export const workflowSettingsSchema = yup.object().shape({});

export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const timesheetSchema = yup
  .array(
    yup
      .string()
      .matches(
        /^[a-zA-Z]+\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\|[0-9]{2}-[0-9]{2}-[0-9]{2}\|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
        { message: "Missing value" }
      )
  )
  .test(
    "Unique",
    "Project, Category, & Rate combination must be unique",
    (values) => {
      return new Set(values).size === values.length;
    }
  );

export const timesheetEntryValidation = (entry) =>
  yup.lazy(() => {
    return yup.string().test({
      name: "entry-check",
      message: "Rejected",
      test: () => {
        if (entry?.status === "rejected") {
          return false;
        }
        return true;
      },
    });
  });

export const spaceFormSchema = yup.object().shape({
  name: yup.string().trim().required(),
});

export const strictString = yup
  .string("Must Enter String")
  .nullable()
  .required(
    <div className="flex gap-1">
      <img className="inline-block" alt="required-icon" src={requiredIcon} />
      <span>Required</span>
    </div>
  );

export const dynamicPhoneNumberCheck = (required) =>
  yup.lazy((value) => {
    if (required)
      return yup
        .string()
        .required(
          <div className="flex gap-1">
            <img
              className="inline-block"
              alt="required-icon"
              src={requiredIcon}
            />
            <span>Required</span>
          </div>
        )
        .matches(phoneRegExp, "Enter 10 Digit Number");

    if (value) {
      return yup
        .string()
        .matches(phoneRegExp, "Enter 10 Digit Number")
        .min(13, "Enter 10 Digit Number");
    }
    return yup.string();
  });
export const strictEmailCheck = (emails, required) =>
  yup.lazy(() => {
    if (required) {
      return yup
        .string()
        .required(
          <div className="flex gap-1">
            <img
              className="inline-block"
              alt="required-icon"
              src={requiredIcon}
            />
            <span>Required</span>
          </div>
        )
        .email("Must Enter Valid Email")
        .test({
          name: "email-check",
          message: "Email Exists",
          test: (val) => {
            if (emails.includes(val) && val !== "") {
              return false;
            }
            return true;
          },
        });
    }
    return yup
      .string()
      .nullable(true)
      .email("Must Enter Valid Email")
      .test({
        name: "email-check",
        message: "Email Exists",
        test: (val) => {
          if (emails.includes(val) && val !== "") {
            return false;
          }
          return true;
        },
      });
  });

export const strictEmailDropdownCheck = (emails) =>
  yup
    .mixed()
    .required(
      <div className="flex gap-1">
        <img className="inline-block" alt="required-icon" src={requiredIcon} />
        <span>Required</span>
      </div>
    )
    .test({
      name: "email-check",
      message: "Email Exists",
      test: (val) => {
        if (emails.includes(val?.value) && val?.value !== "") {
          return false;
        }
        return true;
      },
    });

export const magnoliaUrl = yup.string("Must Enter Url").url(
  <div className="flex gap-1">
    <img className="inline-block" alt="required-icon" src={requiredIcon} />
    <span>https://example.com</span>
  </div>
);

export const simpleUrl = yup.string().trim().required();

export const existingTimeoffRequestValidation = (val) =>
  yup.lazy(() => {
    return yup
      .mixed()
      .required(
        <div className="flex gap-1">
          <img
            className="inline-block"
            alt="required-icon"
            src={requiredIcon}
          />
          <span>Required</span>
        </div>
      )
      .test({
        name: "timeoff-request-check",
        message: "Existing request for days entered of this type.",
        test: () => {
          if (val) {
            return false;
          }
          return true;
        },
      });
  });

/**
 * Payroll Settings
 */
export const timesheetSettingsValidation = (locked) =>
  yup.lazy(() => {
    return yup
      .mixed()
      .required(
        <div className="flex gap-1 pb-1">
          <img
            className="inline-block"
            alt="required-icon"
            src={requiredIcon}
          />
        </div>
      )
      .test({
        name: "timeoff-request-check",
        message: "Must set before timesheets are accessible.",
        test: () => {
          if (!locked) {
            return false;
          }
          return true;
        },
      });
  });

/**
 * Payroll Settings
 */

// show the difference 2 objects. it only shows the key
export const getObjectDiff = (obj1, obj2, compareRef = false) => {
  return Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        // eslint-disable-next-line no-param-reassign
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};

export const billingContactInfoIsValid = (billingFormData) => {
  return (
    billingFormData?.contactFirstName?.trim()?.length > 0 &&
    billingFormData?.contactLastName?.trim()?.length > 0 &&
    billingFormData?.contactWorkspaceName?.trim()?.length > 0 &&
    billingFormData?.contactCompany?.trim()?.length > 0 &&
    billingFormData?.contactEmail?.match(yupEmailRegExp) &&
    billingFormData?.contactPhone?.match(phoneRegExp)
  );
};

export const billingAddressIsValid = (billingFormData) => {
  return (
    !!billingFormData?.billingCountry &&
    !!billingFormData?.billingCountry?.value?.length &&
    !!billingFormData?.billingZipCode &&
    !!billingFormData?.billingZipCode?.length
  );
};

export const billingCardIsValid = (billingFormData) => {
  return (
    !!billingFormData?.nameOnCard &&
    !!billingFormData?.nameOnCard?.length &&
    !!billingFormData?.cardNumber &&
    !!billingFormData?.expiration &&
    !!billingFormData?.cvc
  );
};
