import React from "react";
import ReportManagementTemplatesDropdown from "../ReportManagementTemplatesDropdown";

const TableHeader = ({ canWrite, search, filter, fileActionsIcons }) => {
  return (
    <div className="pb-1">
      <div className="flex justify-between items-center mb-6">
        {
          <div className="flex justify-between gap-3">
            {search} {canWrite && <ReportManagementTemplatesDropdown />}
          </div>
        }
        {fileActionsIcons}
      </div>
      <div>{filter}</div>
    </div>
  );
};

export default TableHeader;
