import React from "react";
import PropTypes from "prop-types";
import Input from "../Input/Input";

const SearchBar = ({
  className,
  placeholder,
  onSearchChange,
  onKeyPress,
  searchIcon,
  globalFilter,
  label,
  disableClear,
}) => {
  return (
    <Input
      inputClassName="bg-backgroundGreen w-full text-gray-450 font-base pl-7"
      className={className}
      placeholder={placeholder}
      label={label}
      onChange={onSearchChange}
      onKeyPress={onKeyPress}
      icon={searchIcon}
      value={globalFilter}
      disableClear={disableClear}
      clearStyle={{ width: "12px", height: "12px" }}
      autoFocus
    />
  );
};

SearchBar.propTypes = {
  /**
   * text that appears in the textbox when no user-entered text is preset
   */
  placeholder: PropTypes.string,
  /**
   * function called when the input value changes, provides the input value
   */
  onSearchChange: PropTypes.func,
  /**
   * function called when press the key
   */
  onKeyPress: PropTypes.func,
  /**
   * url to an image to display on the left side of the input
   */
  searchIcon: PropTypes.string,
  /**
   * applies this value to the input
   */
  globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * className passed to Input
   */
  className: PropTypes.string,
  /**
   * text that appears above the input box
   */
  label: PropTypes.string,
  /**
   * if true, prevents X from appearing for resetting the value to an empty string
   */
  disableClear: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholder: undefined,
  onSearchChange: null,
  onKeyPress: null,
  globalFilter: "",
  searchIcon: null,
  className: undefined,
  label: undefined,
  disableClear: false,
};

export default SearchBar;
