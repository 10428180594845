import { ClientAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import SignUpAPI from "../Pages/SignUp/SignUpAPI";
import { formatCountriesDropdown } from "../helpers/Address";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { subscriptionKeys } from "../config/reactQuery/queryKeyFactory";

const countryList = require("country-list");

const reload = async () => {
  const {
    data: { subscriptionId },
  } = await ClientAPI.getWOP("$subscriptionid");
  const countries = countryList.getNameList();
  const countrieslist = formatCountriesDropdown(countries);

  const sub = await SignUpAPI.getSubscription(subscriptionId);
  const customer = await SignUpAPI.getCustomer(sub?.customer);
  const paymentCard = await SignUpAPI.getPaymentCard(sub?.customer);
  const product = await SignUpAPI.getProduct(sub?.plan?.product);
  const stripePlans = await SignUpAPI.handleFetchStripeProducts();

  const planName = sub?.plan?.id.split("-")[0];
  const capitalizedPlanName =
    planName.charAt(0).toUpperCase() + planName.slice(1);

  const countryLabel =
    countrieslist.find(
      (item) => item.countryCode === customer?.address?.country
    )?.label || customer?.address?.country;

  const subDetails = {
    subscriptionId,
    customerId: sub?.customer,
    subscriptionItem: sub?.items?.data[0]?.id,
    contactFirstName: customer.name.split(" ")[0],
    contactLastName: customer.name.split(" ")[1],
    contactTitle: customer.metadata?.title,
    contactCompany: customer.metadata?.company,
    contactEmail: customer.email,
    contactPhone: customer.phone,
    nameOnCard: paymentCard?.data[0]?.billing_details?.name,
    cardType: paymentCard?.data[0]?.card?.brand,
    billingCountry: {
      label: countryLabel,
      value: countryLabel,
    },
    billingStreet: customer?.address?.line1 || "",
    billingStreet2: customer?.address?.line2,
    billingCity: customer?.address?.city,
    billingState: customer?.address?.state,
    billingZipCode: customer?.address?.postal_code,
    userCount: sub.quantity,
    billingCycle: sub.plan.interval === "month" ? "monthly" : "annual",
    nextPaymentDate: moment.unix(sub.current_period_end),
    nextPayment: (
      Number.parseFloat(sub?.plan?.amount / 100) * sub.quantity
    ).toFixed(2),
    planName: capitalizedPlanName,
    planSelection: {
      ...stripePlans?.find((p) => p?.planName === capitalizedPlanName),
    },
    plans: stripePlans,
    userText: product.description,
    minUsers: product.metadata.minUsers,
    maxUsers: product.metadata.maxUsers,
    monthlyCost: Number.parseFloat(
      product.prices.find((price) => price.recurring.interval === "month")
        .unit_amount / 100
    ),
    annualCost: Number.parseFloat(
      product.prices.find((price) => price.recurring.interval === "year")
        .unit_amount /
        (100 * 12)
    ),
    discount: product.metadata.discount,
    features: product.metadata.features.split(","),
    toCancelAtPeriodEnd: sub?.cancel_at_period_end,
    expireDate: sub?.cancel_at
      ? new Date(sub?.cancel_at * 1000).toISOString()
      : undefined,
  };

  return subDetails;
};

const useSubscription = () => {
  return useAuthenticatedQuery({
    queryKey: subscriptionKeys.subscription,
    queryFn: () => reload(),
    onError: (error) => {
      console.error("useSubscription", error);
    },
  });
};

export default useSubscription;
