import React, { useMemo } from "react";
import Widget from "../../../../stories/Components/Widget/Widget";
import useSopActivityData from "./useSopActivityData";
import Table from "../../../../stories/Components/Table/Table";

const SopActivity = ({ sopId }) => {
  const { isLoading, activity, ref } = useSopActivityData(sopId);

  const columns = useMemo(() => {
    return [
      {
        Header: "Activity",
        accessor: "activity",
        isPrimary: true,
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{value}</p>
          </div>
        ),
        isPrimary: true,
        width: 250,
      },
    ];
  }, []);

  return (
    <Widget draggable={false} title={null} loading={isLoading} backToTop>
      <div className="felx flex-col">
        <div className="flex justify-between items-center pb-4">
          <p className="text-gray-500 font-semibold text-xl">Activity Log</p>
        </div>
      </div>

      <Table
        noHeader
        resourceName="activity"
        data={activity}
        columns={columns}
        showConstantRowIndex
        hideSiteHeaderTitle
        hideSiteHeaderActionButtons
        hideSwitchView
        hideSiteHeaderDropdownContainer
        hideSiteHeader
        lastRowReferenceForInfiniteScroll={ref}
      />
    </Widget>
  );
};

export default SopActivity;
