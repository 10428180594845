// Framework Tools
import React, { useCallback, useEffect } from "react";
// Libraries
import cntl from "cntl";
import * as yup from "yup";
// Hooks

// Components
import AssociationDropdown from "../../AssociationDropdown";
import ModalWrapper from "../ModalWrapper";
import PrimaryButton from "../../Buttons/PrimaryButton";
import FormAvatar from "../../Avatar/FormAvatar";
import Input from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
// Constants
import {
  CHANGE_SPACE_AREA,
  CHANGE_SPACE_ASSOCIATION,
  CHANGE_SPACE_LEVEL,
  CHANGE_SPACE_NAME,
  CHANGE_SPACE_TYPE,
} from "../../../../constants";
import useAddSpaceModal from "./useAddSpaceModal";
import TertiaryButton from "../../Buttons/TertiaryButton";

const rowCN = cntl`
  flex
  flex-row
  justify-between
  min-w-full h-20
  items-center
`;

const labelCN = cntl`
  flex
  w-1/3
  text-gray-450
  font-semibold
`;

const asteriskCN = cntl`
  text-brandGreen
`;

const inputCN = cntl`
  flex
  w-2/3
`;

const inputItemCN = cntl`
  bg-green-50
  w-full
  px-4
`;

const formCN = cntl`
  flex
  flex-col
  border
  border-gray-450
  rounded
  py-4
  px-6
  h-full
`;

const dropdownCN = cntl`
  mt-3
  w-2/3
  relative
  self-center
`;

export default function AddSpaceModal({ modalData }) {
  /* Presentation Hook */
  const [
    { initialValue, setInitialValue },
    { state, dispatch },
    dropdownLists,
    actions,
    { tmpAvatarImg, setTmpAvatarImg },
  ] = useAddSpaceModal({ modalData });

  /* Dispatch Handlers */
  const handleChangeName = (payload) => {
    dispatch({
      type: CHANGE_SPACE_NAME,
      payload,
    });
  };

  const handleChangeAssociation = useCallback(
    (payload) => {
      dispatch({
        type: CHANGE_SPACE_ASSOCIATION,
        payload,
      });
    },
    [dispatch]
  );
  const handleChangeLevel = (payload) => {
    dispatch({
      type: CHANGE_SPACE_LEVEL,
      payload,
    });
  };
  const handleChangeType = (payload) => {
    dispatch({
      type: CHANGE_SPACE_TYPE,
      payload,
    });
  };
  const handleChangeArea = (payload) => {
    dispatch({
      type: CHANGE_SPACE_AREA,
      payload,
    });
  };

  useEffect(() => {
    if (modalData?.associationLock) {
      handleChangeAssociation(modalData?.associationLock);
    }
  }, [modalData?.associationLock, handleChangeAssociation]);

  /* Modal */
  return (
    <ModalWrapper
      modalData={modalData}
      formData={{ ...state, initialValue, tmpAvatarImg }}
      width="732px"
      title="Create Space"
      buttons={({ closeModal }) => (
        <div className="flex flex-row items-center gap-4">
          {!modalData?.edit && (
            <PrimaryButton
              disabled={!state.name || !state.association}
              saveButton
              saveButtonTitle="Save"
              onClick={() => {
                actions.handleSave(state);
                closeModal();
              }}
            />
          )}
          {
            <div className="flex items-center hover:border-none">
              <TertiaryButton
                className="text-es-green"
                title="cancel"
                onClick={closeModal}
              />
            </div>
          }
        </div>
      )}
    >
      <form style={{ minWidth: 600, minHeight: 450 }} className="flex flex-col">
        <div className="flex items-center pb-4">
          <FormAvatar
            isEditing
            image={state.imageInfo.blob}
            tmpAvatarImg={tmpAvatarImg}
            setTmpAvatarImg={setTmpAvatarImg}
            resourceName="Space"
          />
          <p className="text-gray-450 font-semibold text-sm ml-2">Add Image</p>
        </div>
        <div className={formCN}>
          <div className={rowCN}>
            <p className={labelCN}>
              Name<p className={asteriskCN}>*</p>
            </p>
            <div className={inputCN}>
              <Input
                placeholder="Name"
                mainWrapperClassName="w-full"
                inputClassName={inputItemCN}
                value={state.name}
                onChange={(val) => handleChangeName(val)}
                validation={yup.string().trim().required()}
                showValidationErrorAtBottom
                // autoFocus
              />
            </div>
          </div>

          <div className={rowCN}>
            <p className={labelCN}>
              Association <p className={asteriskCN}>*</p>
            </p>
            <div className={inputCN}>
              <AssociationDropdown
                setInitialValue={setInitialValue}
                initialValue={modalData?.formData?.initialValue}
                disabledOptions={["Assets"]}
                onChangeAssociation={(val) => handleChangeAssociation(val)}
                association={modalData?.associationLock}
                disabled={modalData?.associationLock}
              />
            </div>
          </div>
          <div className={rowCN}>
            <p className={labelCN}>Level</p>
            <div className={inputCN}>
              <Dropdown
                placeholder="Select"
                className={dropdownCN}
                options={dropdownLists.levelsDropdown}
                value={state.level}
                onChange={(val) => handleChangeLevel(val)}
              />
            </div>
          </div>
          <div className={rowCN}>
            <p className={labelCN}>Type</p>
            <div className={inputCN}>
              <Dropdown
                placeholder="Select"
                className={dropdownCN}
                options={dropdownLists.typesDropdown}
                value={state.type}
                onChange={(val) => handleChangeType(val)}
              />
            </div>
          </div>
          <div className={rowCN}>
            <p className={labelCN}>Square Footage</p>
            <div className={inputCN}>
              <Input
                mainWrapperClassName="w-full"
                type="number"
                minValue={0}
                inputClassName={inputItemCN}
                value={state.area}
                onChange={(val) => handleChangeArea(val)}
                placeholder="(Optional)"
              />
            </div>
          </div>
        </div>
      </form>
    </ModalWrapper>
  );
}
