import { TimeoffAPI, TimesheetAPI } from "@griffingroupglobal/eslib-api";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import getWeekBeforeAndAfter from "../../../../helpers/Timesheets/getWeekBeforeAndAfter";
import organizeEntriesByRow from "../../../../helpers/Timesheets/organizeEntriesToRows";
import removeDuplicateTsEntries from "../../../../helpers/Timesheets/removeDuplicateTsEntryies";
import usePagePersistence from "../../../../hooks/usePagePersistence";
import { useUsers } from "../../../../hooks/useUsers.new";
import useUsersWithPermissions from "../../../../hooks/useUsersWithPermissions";
import { useAppState } from "../../../../state/appState";
import { toastError, toastMessage } from "../../Toast/Toast";

const useTimesheetViewData = () => {
  const [commentsData, setCommentsData] = useState({
    isOpen: false,
    timesheetReference: undefined,
  });

  const [timesheet, setTimesheet] = useState({});
  const [{ currentUser }] = useAppState();

  const { pageState, setPersistentPageItem } = usePagePersistence();

  const [changesDetected, setChangesDetected] = useState(false);
  const [rateOrCatEmpty, setRateOrCatEmpty] = useState(false);

  const [entriesForEditing, setEntriesForEditing] = useState({});

  const [timeSheetLoading, setTimesheetLoading] = useState(false);
  const [ptoLoading, setPtoLoading] = useState(false);

  const [ptoEntryState, setPtoEntryState] = useState({});
  const [ptoTotal, setPtoTotal] = useState(0);

  const [forbiddenCombos, setForbiddenCombos] = useState({});

  const { data: usersData } = useUsers();

  const { data: usersWithPermissions } = useUsersWithPermissions([
    "timesheet.can_approve",
  ]);

  // Allow timesheet owner and users with timesheet.can_approve permission to be mentioned
  const usersToMention = useMemo(() => {
    if (!timesheet?.resource || !usersData?.userDict || !usersWithPermissions) {
      return [];
    }

    const permissionUsers = Object.values(usersWithPermissions).flatMap(
      (users) => users?.map((user) => usersData.userDict[user])
    );

    const timesheetOwner = usersData.userDict[timesheet.resource?.userRef];

    return [...permissionUsers, timesheetOwner];
  }, [timesheet?.resource, usersData?.userDict, usersWithPermissions]);

  const isLoading = useMemo(() => {
    return timeSheetLoading || ptoLoading;
  }, [timeSheetLoading, ptoLoading]);

  const resetTimesheet = useCallback(() => {
    const entriesMapToEdit = organizeEntriesByRow(timesheet);
    setEntriesForEditing(entriesMapToEdit || {});
  }, [timesheet]);

  // this effect is to keep track of forbidden combos
  useEffect(() => {
    const comboMap = {};

    Object.values(entriesForEditing)?.forEach((entry) => {
      const project = entry.find((item) => item?.project !== "")?.project;
      const rate = entry.find((item) => item?.rate !== "")?.rate;
      const financialCode = entry.find(
        (item) =>
          item?.financialCode?.division !== "" &&
          item?.financialCode?.code !== "" &&
          item?.financialCode?.subcode !== ""
      )?.financialCode;

      if (!project || !rate || !financialCode) return;

      const comboKey = `${project}_${rate}_${financialCode?.division}-${financialCode?.code}-${financialCode?.subcode}`;

      if (comboMap[comboKey] === undefined) {
        comboMap[comboKey] = true;
      }
    });

    setForbiddenCombos(comboMap);
  }, [entriesForEditing, setForbiddenCombos]);

  // This effect is used to organize the timesheet entries into a more
  // manageable format for editing.
  useEffect(() => {
    resetTimesheet();
  }, [resetTimesheet]);

  // This effect is used to determine if there are any changes
  useEffect(() => {
    const entriesAsFlatArr = cloneDeep(
      Object.values(entriesForEditing)?.flat()
    );

    // Clearing a project will wipe both the rate and category so we don't need a bool for that
    const isRateEmpty = !!entriesAsFlatArr?.find((entry) => !entry?.rate);
    const isCatEmpty = !!entriesAsFlatArr?.find(
      (entry) => !entry?.financialCode?.division
    );

    // create a deep copy so the original timesheet is not mutated
    const timesheetDeepClone = cloneDeep(timesheet?.resource?.entries || []);

    // An untracked entry is something not saved to the timesheet as an entry on the backend, but
    //  it will be if the value or note is filled out
    const untrackedEntries = entriesAsFlatArr?.filter(
      (entry) => entry?.untracked === true
    );

    // this is monitoring changes in the untracked entries
    // if a change is discovered I should be able to save it
    const untrackedChangesToTimesheet = untrackedEntries?.find(
      (entry) =>
        entry?.value > 0 || (entry?.note !== undefined && entry?.note !== "")
    );

    // If changes were detected to an untracked entry, we know something needs to be saved
    // at this scope we still need to check the rate or category to know if we need
    // to hide the save button
    if (untrackedChangesToTimesheet !== undefined) {
      setChangesDetected(true);
      setRateOrCatEmpty(isRateEmpty || isCatEmpty);
      return;
    }

    // A tracked entry is something saved to the backend in an entry
    const trackedEntries = entriesAsFlatArr?.filter(
      (entry) => entry?.untracked !== true
    );

    const sortedFlatEntries = trackedEntries?.sort((a, b) =>
      JSON.stringify(a) > JSON.stringify(b) ? 1 : -1
    );

    const sortedTimesheetEntries = timesheetDeepClone?.sort((a, b) =>
      JSON.stringify(a) > JSON.stringify(b) ? 1 : -1
    );

    setRateOrCatEmpty(isRateEmpty || isCatEmpty);

    setChangesDetected(
      !isEqual(
        sortedFlatEntries,
        removeDuplicateTsEntries(sortedTimesheetEntries, "id") // I don't understand why im getting duplicate entries from backend
      )
    );
  }, [entriesForEditing, setChangesDetected, timesheet?.resource?.entries]);

  // The project is all the common projectRefs for all the
  // entries with common project, rate, and category get their own row
  const handleProjectChange = (value, entryKey) => {
    const foundEntriesToEdit = entriesForEditing[entryKey];

    const editedEntries = foundEntriesToEdit?.map((entry) => {
      const newItem = {
        ...entry,
        project: value.value,
        rate: "",
        financialCode: {
          division: "",
          code: "",
          subcode: "",
        },
      };

      return newItem;
    });

    setEntriesForEditing({ ...entriesForEditing, [entryKey]: editedEntries });
  };

  // the rate is the common rate for all the entry
  // entries with a common project, rate, and category get their own row
  const handleRateChange = (value, entryKey) => {
    const cancelCriteria = timesheet?.resource?.status === "locked";
    if (cancelCriteria) return;

    const foundEntriesToEdit = entriesForEditing[entryKey];

    const editedEntries = foundEntriesToEdit?.map((entry) => {
      const newItem = {
        ...entry,
        rate: value.value,
        financialCode: {
          division: "",
          code: "",
          subcode: "",
        },
      };

      return newItem;
    });

    setEntriesForEditing({ ...entriesForEditing, [entryKey]: editedEntries });
  };

  // the category is the common category for all the entries.
  // entries with a common project, rate, and category get their own row
  const handleCategoryChange = (value, entryKey) => {
    const cancelCriteria = timesheet?.resource?.status === "locked";
    if (cancelCriteria) return;

    const codesAsArr = value?.value?.split("-") || [];

    const codesObj = {
      division: codesAsArr[0] || "",
      code: codesAsArr[1] || "",
      subcode: codesAsArr[2] || "",
    };

    const foundEntriesToEdit = entriesForEditing[entryKey];
    const comboString = `${foundEntriesToEdit[0]?.project}_${foundEntriesToEdit[0]?.rate}_${value.value}`;

    if (forbiddenCombos[comboString] === true) {
      toastError("This Project, rate, and category combination is not allowed");
      return;
    }

    const editedEntries = foundEntriesToEdit?.map((entry) => {
      return {
        ...entry,
        financialCode: codesObj,
      };
    });

    setEntriesForEditing({ ...entriesForEditing, [entryKey]: editedEntries });
  };

  const handleEntryChange = (value, entry, entryKey) => {
    const valueAsStr = value.toString();
    const valuesAfterDecimal = valueAsStr.split(".")[1];
    const isNegative = valueAsStr.includes("-");
    const isNaNCheck = Number.isNaN(Number(valueAsStr));
    const isValueOver24 = Number(valueAsStr) > 24;
    const hasInvalidCharacters = /[^\d.]/.test(valueAsStr);
    const hasMoreThanTwoDecimals =
      valuesAfterDecimal && valuesAfterDecimal.length > 2;
    const isTimesheetLocked = timesheet?.resource?.status === "locked";

    const cancelCriteria =
      isNegative ||
      isNaNCheck ||
      isValueOver24 ||
      hasInvalidCharacters ||
      hasMoreThanTwoDecimals ||
      isTimesheetLocked;

    if (cancelCriteria) return;

    const entryId = entry?.id;

    const entryToEdit = entriesForEditing[entryKey];
    const indexOfHourToEdit = entryToEdit?.findIndex(
      (item) => item?.id === entryId
    );

    let newEntryValue = value * 60;

    if (value.split("").pop() === ".") {
      newEntryValue = (value * 60).toFixed(1);
    }

    entryToEdit[indexOfHourToEdit] = {
      ...entryToEdit[indexOfHourToEdit],
      value: newEntryValue,
    };

    setEntriesForEditing({ ...entriesForEditing, [entryKey]: entryToEdit });
  };

  const handleEntryNoteChange = (value, entry, entryKey) => {
    const cancelCriteria = timesheet?.resource?.status === "locked";
    if (cancelCriteria) return;

    const entryId = entry?.id;

    const entryToEdit = entriesForEditing[entryKey];
    const indexOfHourToEdit = entryToEdit?.findIndex(
      (item) => item?.id === entryId
    );

    if (value.length === 0) {
      delete entryToEdit[indexOfHourToEdit].note;
    } else {
      entryToEdit[indexOfHourToEdit] = {
        ...entryToEdit[indexOfHourToEdit],
        note: value,
      };
    }

    setEntriesForEditing({ ...entriesForEditing, [entryKey]: entryToEdit });
  };

  const handleBillableChange = (value, entry, entryKey) => {
    const cancelCriteria = timesheet?.resource?.status === "locked";

    if (cancelCriteria) return;

    const entryId = entry?.id;

    const entryToEdit = entriesForEditing[entryKey];
    const indexOfHourToEdit = entryToEdit?.findIndex(
      (item) => item?.id === entryId
    );

    entryToEdit[indexOfHourToEdit] = {
      ...entryToEdit[indexOfHourToEdit],
      isBillable: value,
    };

    setEntriesForEditing({ ...entriesForEditing, [entryKey]: entryToEdit });
  };

  const timesheetEntryTotal = useMemo(() => {
    let total = 0;

    Object.values(entriesForEditing)?.forEach((entry) => {
      entry.forEach((item) => {
        total += item.value;
      });
    });

    return total / 60;
  }, [entriesForEditing]);

  const overtimeTotal = useMemo(() => {
    const overtime = 40;
    const totalOvertimeHours = timesheetEntryTotal - overtime;

    if (totalOvertimeHours < 0) return 0;
    return totalOvertimeHours;
  }, [timesheetEntryTotal]);

  useLayoutEffect(() => {
    (async () => {
      setPtoLoading(true);
      try {
        // this is a check to see if the userId has been populated in the pageState, yet
        // if it has not been populated, we should not make the request.
        if (!pageState?.timesheet?.userId) return;

        const { data } = await TimeoffAPI.get({
          params: {
            user: `User/${pageState?.timesheet?.userId}`,
            status: "approved",
            left: pageState?.timesheet?.periodStart,
            right: pageState?.timesheet?.periodEnd,
          },
        });

        const ptoRequests = data?.entries?.map(
          (item) => item?.resource?.requests
        );

        const ptoRequestsApproved = ptoRequests
          .flat()
          .filter((item) => item?.status === "approved")
          .map((item) => item.dates)
          .flat();

        const ptoEntryMap = {};

        ptoRequestsApproved.forEach((item) => {
          // somehow a timezone offset is being added to the date, so
          //  I need to format the date to match up with the timesheet

          let dateToFormat = item?.date.split("T")[0];
          dateToFormat += "T00:00:00.000Z";

          if (ptoEntryMap[dateToFormat]) {
            const newTotal =
              parseFloat(ptoEntryMap[dateToFormat]?.numHours) +
              parseFloat(item?.numHours);

            const updatedItem = {
              ...ptoEntryMap[dateToFormat],
              numHours: newTotal.toString(),
            };

            ptoEntryMap[dateToFormat] = updatedItem;
          } else {
            ptoEntryMap[dateToFormat] = item;
          }
        });

        let ptoTotalTemp = 0;

        Object.values(ptoEntryMap)?.forEach((entry) => {
          const itemIsBeforePeriodStart = moment
            .utc(entry?.date)
            .isBefore(
              moment(pageState?.timesheet?.periodStart).subtract(1, "day")
            );

          const itemIsAfterPeriodEnd = moment
            .utc(entry?.date)
            .isAfter(moment(pageState?.timesheet?.periodEnd));

          if (itemIsBeforePeriodStart || itemIsAfterPeriodEnd) {
            return;
          }

          ptoTotalTemp += parseFloat(entry?.numHours);
        });

        setPtoEntryState(ptoEntryMap);
        setPtoTotal(ptoTotalTemp);
      } catch (error) {
        toastError("There was an error fetching the PTO");
      } finally {
        setPtoLoading(false);
      }
    })();
  }, [pageState?.timesheet]);

  const getTimesheetForUser = useCallback(async () => {
    setTimesheetLoading(true);
    try {
      const { weekAfter } = getWeekBeforeAndAfter(
        pageState?.timesheet?.periodStart
      );

      const requestParams = {
        userRef: `User/${pageState?.timesheet?.userId}`,
        periodStart: `${pageState?.timesheet?.periodStart}`,
        periodEnd: `${weekAfter}`,
      };

      // this was all the criteria I found that will make a redundant request
      if (
        !requestParams.userRef ||
        requestParams.periodStart === "Invalid date" ||
        requestParams.periodEnd === "Invalid date" ||
        requestParams.periodStart === undefined ||
        requestParams.periodEnd === undefined ||
        pageState?.timesheet?.userId === ""
      )
        return;

      const timeSheetResponse = await TimesheetAPI.get({
        params: requestParams,
      });

      // If nothing comes back from the request, we should set
      // the timesheet to an empty object. Only one timesheet
      // should be returned from the request per user per timeslots.
      setTimesheet(timeSheetResponse?.data?.entries[0] || {});
    } catch (error) {
      toastError("There was an error fetching the timesheet");
    } finally {
      setTimesheetLoading(false);
    }
  }, [pageState?.timesheet?.periodStart, pageState?.timesheet?.userId]);

  useLayoutEffect(() => {
    (async () => {
      try {
        getTimesheetForUser();
      } catch (err) {
        console.error("useTimesheetViewData: silent err ==>", err);
      }
    })();
  }, [getTimesheetForUser]);

  const handleBackClick = () => {
    const timesheetPageState = { ...pageState.timesheet };

    setPersistentPageItem("timesheet", {
      ...timesheetPageState,
      userId: "",
    });
  };

  const handleCommentClick = () => {
    setCommentsData({
      timesheetReference: timesheet?.resource?.reference,
      isOpen: true,
    });
  };

  const handleCloseComments = () => {
    setCommentsData({ timesheetReference: undefined, isOpen: false });
  };

  const removeTimesheetRow = (entryKey) => {
    const cancelCriteria = timesheet?.resource?.status === "locked";

    if (cancelCriteria) return;

    const entriesCopy = { ...entriesForEditing };
    delete entriesCopy[entryKey];
    setEntriesForEditing(entriesCopy);
  };

  const entryDates = useMemo(() => {
    const startDate = pageState?.timesheet?.periodStart;
    const datesNoTimesheet = [];

    // this is the start of a timesheet view date; we want to show 7 days
    for (let i = 0; i < 7; i += 1) {
      datesNoTimesheet.push(
        moment(startDate).add(i, "days").format("YYYY-MM-DD")
      );
    }

    return datesNoTimesheet;
  }, [pageState?.timesheet?.periodStart]);

  const addTimesheetRow = () => {
    const cancelCriteria = timesheet?.resource?.status === "locked";
    if (cancelCriteria) return;

    const newEntry = {
      value: 0,
      project: "",
      rate: "",
      financialCode: {
        division: "",
        code: "",
        subcode: "",
      },
      isBillable: true,
      note: "",
      untracked: true,
    };

    const emptyEntries = entryDates.map((date) => {
      return { ...newEntry, id: uuidv4(), date: `${date}T00:00:00.000Z` };
    });

    const newEntries = {
      ...entriesForEditing,
      [`new-${Date.now()}`]: emptyEntries,
    };

    setEntriesForEditing(newEntries);
  };

  const saveTimesheet = async () => {
    const cancelCriteria = timesheet?.resource?.status === "locked";
    if (cancelCriteria) return;

    const entriesToSave = Object.values(entriesForEditing)?.flat();

    const entriesWithTrimmedUntracked = entriesToSave
      ?.filter((entry) => {
        if (entry?.untracked !== true) {
          return entry;
        }

        if (
          entry?.value > 0 ||
          (entry?.note !== undefined && entry?.note !== "")
        ) {
          return entry;
        }

        return null;
      })
      .map((entry) => {
        if (entry?.untracked) {
          const entryCopy = { ...entry };
          delete entryCopy.untracked;

          if (entryCopy?.note !== undefined && entryCopy?.note === "") {
            delete entryCopy.note;
          }

          return entryCopy;
        }
        return entry;
      });

    const payload = {
      ...timesheet,
      resource: {
        ...timesheet?.resource,
        entries: entriesWithTrimmedUntracked,
      },
    };

    try {
      const { data } = await TimesheetAPI.patch(
        timesheet?.resource?.id,
        payload?.resource,
        timesheet?.resource
      );

      setTimesheet({ ...timesheet, resource: data });
      toastMessage("Timesheet saved successfully");
    } catch (error) {
      if (error?.response?.status === 412) {
        getTimesheetForUser();
        toastError(
          "You are trying to save an outdated timesheet, the timesheet has been updated."
        );
        return;
      }
      toastError("There was an error saving the timesheet");
    }
  };

  const canCreateTimesheet = useMemo(() => {
    const canCreate = Object.values(entriesForEditing)?.some((entry) => {
      return entry?.some((item) => {
        return item?.project !== "" && item?.rate !== "" && item?.value > 0;
      });
    });

    return canCreate;
  }, [entriesForEditing]);

  const createNewTimesheet = async () => {
    setTimesheetLoading(true);
    try {
      const startDate = moment(pageState?.timesheet?.periodStart);
      const oneWeekAfter = startDate
        .clone()
        .add(6, "days")
        .format("YYYY-MM-DD");

      const timeSheetID = uuidv4();
      const flatEntries = Object.values(entriesForEditing)?.flat();

      const onlyEntriesWithValues = flatEntries
        ?.filter((entry) => entry?.value > 0)
        .map((entry) => {
          const entryCopy = { ...entry };
          delete entryCopy.untracked;
          return entryCopy;
        });

      const payload = {
        userRef: `User/${pageState?.timesheet?.userId}`,
        periodStart: `${startDate.format("YYYY-MM-DD")}T00:00:00.000Z`,
        periodEnd: `${oneWeekAfter}T23:59:59.000Z`,
        payrollStatus: "pending",
        status: "open",
        entries: onlyEntriesWithValues,
        id: timeSheetID,
        reference: `Timesheet/${timeSheetID}`,
        new: true,
      };

      const { data } = await TimesheetAPI.post(payload);

      const timeSheetNew = {
        ...payload,
        resource: data,
      };

      setTimesheet(timeSheetNew);
      toastMessage("Timesheet created successfully");
    } catch (error) {
      toastError("There was an error creating the timesheet");
    } finally {
      setTimesheetLoading(false);
    }
  };

  const handleOutdatedTimesheet = useCallback(() => {
    (async () => {
      try {
        await getTimesheetForUser();
        toastError(
          "You are trying to save an outdated timesheet, the timesheet has been updated."
        );
      } catch (err) {
        console.error("Error at useTimesheetViewData:", err);
      }
    })();
  }, [getTimesheetForUser]);

  const handelTimesheetSubmit = async () => {
    if (!timesheet?.resource?.id) return;

    setTimesheetLoading(true);

    try {
      const response = await TimesheetAPI.postWOP(
        `${timesheet?.resource.id}/$submit`
      );

      setTimesheet({ ...timesheet, resource: response?.data });
      toastMessage("Timesheet submitted successfully");
    } catch (error) {
      if (error?.response?.status === 412) {
        handleOutdatedTimesheet();
        return;
      }
      toastError("There was an error submitting the timesheet");
    } finally {
      setTimesheetLoading(false);
    }
  };

  const handleApproveTimesheet = async () => {
    if (!timesheet?.resource?.id) return;
    setTimesheetLoading(true);
    try {
      const response = await TimesheetAPI.postWOP(
        `${timesheet?.resource.id}/$approve`
      );

      setTimesheet({ ...timesheet, resource: response?.data });
      toastMessage("Timesheet approved successfully");
    } catch (error) {
      if (error?.response?.status === 412) {
        handleOutdatedTimesheet();
        return;
      }
      toastError("There was an error approving the timesheet");
    } finally {
      setTimesheetLoading(false);
    }
  };

  const handleRejectTimesheet = async () => {
    if (!timesheet?.resource?.id) return;
    setTimesheetLoading(true);

    try {
      const response = await TimesheetAPI.postWOP(
        `${timesheet?.resource.id}/$decline`
      );

      setTimesheet({ ...timesheet, resource: response?.data });
      toastMessage("Timesheet rejected successfully");
    } catch (error) {
      if (error?.response?.status === 412) {
        handleOutdatedTimesheet();
        return;
      }
      toastError("There was an error rejecting the timesheet");
    } finally {
      setTimesheetLoading(false);
    }
  };

  const isApprover = useMemo(() => {
    return currentUser?.hasPermission("timesheet", "can_approve");
  }, [currentUser]);

  const hideBackButton = useMemo(() => {
    return pageState?.timesheet?.tab === "my-timesheet";
  }, [pageState?.timesheet?.tab]);

  const timesheetIsLocked = useMemo(() => {
    return timesheet?.resource?.status === "locked";
  }, [timesheet?.resource?.status]);

  return {
    timesheet,
    commentsData,
    changesDetected,
    entriesForEditing,
    timesheetEntryTotal,
    overtimeTotal,
    canCreateTimesheet,
    isApprover,
    ptoEntryState,
    ptoTotal,
    isLoading,
    hideBackButton,
    timesheetIsLocked,
    usersToMention,
    rateOrCatEmpty,
    handelTimesheetSubmit,
    handleApproveTimesheet,
    handleRejectTimesheet,
    createNewTimesheet,
    saveTimesheet,
    removeTimesheetRow,
    addTimesheetRow,
    resetTimesheet,
    handleProjectChange,
    handleRateChange,
    handleCategoryChange,
    handleEntryChange,
    handleEntryNoteChange,
    handleBillableChange,
    setChangesDetected,
    handleBackClick,
    handleCloseComments,
    handleCommentClick,
  };
};

export default useTimesheetViewData;
