/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CommentAPI, LineitemAPI } from "@griffingroupglobal/eslib-api";
import PureBudgetTable from "../BudgetTable/PureBudgetTable";
import useBudgetLineItems from "../../../hooks/useBudgetLineItems";
import { COA_CODE_TYPES } from "../../../constants";
import useBudgetFormReducer from "../../../hooks/useBudgetFormReducer";
import {
  getCSICodeDescription,
  isSameFinancialCode,
} from "../../../helpers/Budget";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";
import useCurrentUser from "../../../hooks/useCurrentUser";
import BudgetTableComments from "../BudgetTable/BudgetTableComments";
import { toastError } from "../../../helpers/Toast";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import useUsers from "../../../hooks/useUsers";
import Modal from "../Modal/Modal";
// eslint-disable-next-line import/no-cycle
import DocumentView from "../DocumentView/DocumentView";

const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);

const getUserData = (allUsers, reference) => {
  if (!reference) {
    return "";
  }
  const found = allUsers?.find((user) => user.reference === reference);
  return found;
};

const PendingUpdatesBudgetTable = ({
  budgetId,
  projectId,
  projectData,
  onRowSelect,
  initialSelectedRows,
  documentModalView,
  setCheckedRow,
  checkedRow,
  initialCsiCode,
  initialRate,
}) => {
  const { data: currentUser } = useCurrentUser();
  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: financialsConfiguration } = useFinancialsConfiguration();
  const { data: systemConfiguration } = useSystemConfiguration();
  const [originalBudgetLineItems, budgetGroups, budgetType, reload] =
    useBudgetLineItems(budgetId);
  const [lineItem, dispatch] = useBudgetFormReducer();
  const [users] = useUsers();
  const [budgetLineItems, setBudgetLineItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState(initialSelectedRows);
  const [projectBuildings, setProjectBuildings] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [groupedUnitsOfMeasure, setGroupedUnitsOfMeasure] = useState([]);
  const [csiCodes, setCsiCodes] = useState([]);
  const [revenueAccountingCodes, setRevenueAccountingCodes] = useState([]);
  const [expenseAccountingCodes, setExpenseAccountingCodes] = useState([]);
  const [selectedLineItemForComments, setSelectedLineItemForComments] =
    useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [project, setProject] = useState(projectData);
  const [spaceConfiguration, setSpaceConfiguration] = useState();
  const [rateSheetRates, setRateSheetRates] = useState([]);
  const [openDocument, setOpenDocument] = useState(null);

  useEffect(() => {
    if (!project && projectData?.reference) {
      setProject(projectData);
    }
  }, [project, projectData]);

  useEffect(() => {
    if (project) {
      setRateSheetRates(
        project?.rateSheet?.rates?.map((rate) => ({
          label: rate.category,
          value: rate.id,
          ratePerHr: rate.ratePerHr,
        }))
      );
    }
  }, [project]);

  useEffect(() => {
    setSpaceConfiguration({
      spaces: managementConfiguration?.management?.propertySpace?.types
        ?.filter((type) => type?.selected)
        ?.reduce((dict, item) => {
          // eslint-disable-next-line no-param-reassign
          dict[item?.id] = item;
          return dict;
        }, {}),
      levels: managementConfiguration?.management?.propertyLevel?.types
        ?.filter((type) => type?.selected)
        ?.reduce((dict, item) => {
          // eslint-disable-next-line no-param-reassign
          dict[item?.id] = item;
          return dict;
        }, {}),
    });
  }, [
    managementConfiguration?.management?.propertyLevel?.types,
    managementConfiguration?.management?.propertySpace?.types,
  ]);

  useEffect(() => {
    setBudgetLineItems(originalBudgetLineItems);
  }, [originalBudgetLineItems]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      setRevenueAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[1].value
        )
      );
      setExpenseAccountingCodes(
        financialsConfiguration?.financials?.chartOfAccounts?.filter(
          (code) => code.codeType === COA_CODE_TYPES[0].value
        )
      );
    }
  }, [financialsConfiguration]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      const divisionsFormatted = [];
      const csiCodesFormatted = [];

      financialsConfiguration?.financials?.csiCodeMapping?.map((div) => {
        const division = {
          label: `${div.division} - ${div.description}`,
          value: div.division,
        };
        divisionsFormatted.push(division);

        div.csiCodes.map((csi) => {
          csi.subCodes.map((sub) => {
            const csiCodeFormatted = {
              label: `${div.division} ${csi.code} ${
                sub.code
              } - ${getCSICodeDescription(
                { division: div.division, code: csi.code, subcode: sub.code },
                financialsConfiguration?.financials?.csiCodeMapping,
                revenueAccountingCodes,
                expenseAccountingCodes
              )}`,
              value: `${div.division} ${csi.code} ${sub.code}`,
            };
            csiCodesFormatted.push(csiCodeFormatted);
            return sub;
          });

          return csi;
        });
        return div;
      });
      setCsiCodes(csiCodesFormatted);
    }
  }, [expenseAccountingCodes, financialsConfiguration, revenueAccountingCodes]);

  useEffect(() => {
    if (systemConfiguration?.system?.unitsOfMeasure) {
      const lengthOptions =
        systemConfiguration?.system?.unitsOfMeasure?.length_area
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const qtyOptions = systemConfiguration?.system?.unitsOfMeasure?.quantity
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const timeOptions = systemConfiguration?.system?.unitsOfMeasure?.time
        ?.filter((uom) => uom.selected)
        ?.map((uom) => ({ label: uom.display, value: uom.id }));
      const volumeOptions =
        systemConfiguration?.system?.unitsOfMeasure?.volume_weight
          ?.filter((uom) => uom.selected)
          ?.map((uom) => ({ label: uom.display, value: uom.id }));

      const groupedOptions = [
        {
          label: "Length/Area",
          options: lengthOptions,
        },
        {
          label: "Quantity",
          options: qtyOptions,
        },
        {
          label: "Time",
          options: timeOptions,
        },
        {
          label: "Volume/Weight",
          options: volumeOptions,
        },
      ];

      setUnitsOfMeasure([
        ...lengthOptions,
        ...qtyOptions,
        ...timeOptions,
        ...volumeOptions,
      ]);
      setGroupedUnitsOfMeasure(groupedOptions);
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (users?.length) {
      const tempMembers = users
        .filter((user) => user.kind === "company")
        ?.map((company) => ({
          label: company?.company?.value,
          value: company?.reference,
        }));

      tempMembers.unshift({
        label: "Create New...",
        value: "createNewVendor",
      });

      setVendors(tempMembers);
    }
  }, [users]);

  useEffect(() => {
    if (project?.buildings?.length) {
      const buildings = project.buildings.map((building) => {
        const build = {
          label: building.name,
          value: building.id,
          spaces: [
            {
              label: "Create New...",
              value: "createNewSpace",
            },
          ],
        };

        if (building.spaces.length) {
          building.spaces.map((space) => {
            const buildSpace = {
              label: space.name,
              value: space.id,
            };
            build.spaces.push(buildSpace);
            return space;
          });
        }

        return build;
      });

      setProjectBuildings([
        {
          label: "Create New...",
          value: "createNewBuilding",
          spaces: [],
        },
        ...buildings,
      ]);
    } else {
      setProjectBuildings([
        {
          label: "Create New...",
          value: "createNewBuilding",
          spaces: [],
        },
      ]);
    }
  }, [project]);

  useEffect(() => {
    const getComments = async () => {
      if (selectedLineItemForComments) {
        setIsLoadingComments(true);
        try {
          const { data } = await CommentAPI.get({
            params: {
              association: selectedLineItemForComments,
            },
          });

          const formatedComments =
            data?.map(async (info) => {
              const user = getUserData(users, info?.author);
              const formatedReplies =
                info?.replies?.map((reply) => ({
                  ...reply,
                  userData: getUserData(users, reply?.author),
                })) || [];
              const replies = await Promise.all(formatedReplies);
              return {
                ...info,
                userData: user,
                replies,
              };
            }) || [];
          const allComments = await Promise.all(formatedComments);

          setCommentsData(allComments);
        } catch (err) {
          toastError(
            `Error fetching Comment: ${err?.response?.data?.issues[0]?.detail?.display}`,
            toastErrorIcon,
            toastCloseIcon
          );
        }
        setIsLoadingComments(false);
      }
    };

    if (selectedLineItemForComments) {
      getComments();
    } else {
      setCommentsData([]);
    }
  }, [selectedLineItemForComments, users]);

  const onFavoriteClick = async (rowId) => {
    const current = budgetLineItems?.find((li) => li.id === rowId);
    const budgetLineItem = { ...current };
    budgetLineItem.flag = !budgetLineItem.flag;

    setBudgetLineItems((prev) =>
      prev.map((li) => {
        if (li.id === rowId) {
          return budgetLineItem;
        }
        return li;
      })
    );

    try {
      await LineitemAPI.patch(budgetLineItem.id, budgetLineItem, current);
    } catch (e) {
      console.error(e);
    }

    await reload();
  };

  const handlePostComment = useCallback(
    async (value) => {
      try {
        if (value.trim().length > 0) {
          const commentObj = {
            association: selectedLineItemForComments,
            content: value,
            author: currentUser?.reference,
            context: {
              lineitem: {
                budget: `Budget/${budgetId}`,
              },
            },
          };
          const { data: newComment } = await CommentAPI.post(commentObj);
          newComment.userData = currentUser;
          newComment.replies = [];
          setCommentsData((prev) => [...prev, newComment]);
        }
      } catch (error) {
        toastError(
          `Error posting Comment: ${error?.response?.data?.issues[0]?.detail?.display}`,
          toastErrorIcon,
          toastCloseIcon
        );
      }
    },
    [budgetId, currentUser, selectedLineItemForComments]
  );

  const handlePostReply = useCallback(
    async (value, commentId) => {
      if (value.trim().length > 0) {
        const tempObj = {
          content: value,
          author: currentUser.reference,
        };
        const { data: reply } = await CommentAPI.postWOP(
          `${commentId}/$addreply`,
          {
            ...tempObj,
          }
        );

        const replyComment = {
          ...reply,
          userData: currentUser,
          replies: [],
        };

        const updatedComments = commentsData.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, replies: [...comment.replies, replyComment] };
          }
          return comment;
        });

        setCommentsData((prev) => updatedComments ?? prev);
      }
    },
    [commentsData, currentUser]
  );

  const handleRowSelect = useCallback(
    (val) => {
      if (val) {
        setSelectedRows(val);
        if (onRowSelect) {
          onRowSelect(val);
        }
      }
    },
    [onRowSelect]
  );

  const handleRowCheck = useCallback(
    (val) => {
      if (val && setCheckedRow) {
        setCheckedRow(val);
      }
    },
    [setCheckedRow]
  );

  return (
    <>
      <PureBudgetTable
        data={React.useMemo(() => {
          const data = budgetLineItems?.reduce((acc, li) => {
            if (isSameFinancialCode(li.financialCode, initialCsiCode)) {
              const dataWithVersions = {
                ...li,
                buildingName: projectBuildings?.find(
                  (loc) => loc?.value === li.building
                )?.label,
                vendorName: vendors?.find(
                  (vendor) => vendor?.value === li.vendor
                )?.label,
                spaceName: projectBuildings
                  ?.find((build) => build.value === li?.building)
                  ?.spaces?.find((sp) => sp.value === li?.space)?.label,
              };
              if (initialRate) {
                if (initialRate === li.rate) {
                  acc.push(dataWithVersions);
                }
              } else {
                acc.push(dataWithVersions);
              }
            }

            return acc;
          }, []);
          return data;
        }, [
          budgetLineItems,
          initialCsiCode,
          initialRate,
          projectBuildings,
          vendors,
        ])}
        name="budgetLineItemsPendingUpdates"
        projectBuildings={projectBuildings}
        csiCodes={csiCodes}
        groupedUnitsOfMeasure={groupedUnitsOfMeasure}
        unitsOfMeasure={unitsOfMeasure}
        vendors={vendors}
        rateSheetRates={rateSheetRates}
        lineItem={lineItem}
        dispatch={dispatch}
        setSelectedRows={handleRowSelect}
        initialSelectedRows={selectedRows}
        onFavoriteClick={onFavoriteClick}
        isFixedFirmBudgetType={budgetType?.label === "Fixed Firm"}
        hasEditPermission={currentUser?.hasPermission?.("budget", "can_write")}
        budgetGroups={budgetGroups}
        setSelectedLineItemForComments={setSelectedLineItemForComments}
        projectLocationSpaceConfiguration={spaceConfiguration}
        systemConfiguration={systemConfiguration}
        users={users}
        isDraftProject={project?.status === "draft"}
        documentModalView={documentModalView}
        setOpenDocument={setOpenDocument}
        isPendingUpdatesModal
        handleRowCheck={handleRowCheck}
        checkedRow={checkedRow}
      />
      <BudgetTableComments
        currentUser={currentUser}
        isOpen={!!selectedLineItemForComments}
        onRequestModalClose={() => setSelectedLineItemForComments(null)}
        commentsData={commentsData}
        handlePostComment={handlePostComment}
        handlePostReply={handlePostReply}
        isLoadingComments={isLoadingComments}
        allowNewComment={false}
      />
      <Modal
        isOpen={openDocument}
        onRequestModalClose={() => setOpenDocument(null)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
        inset="4rem 8rem"
        childContainerClassName="overflow-auto"
      >
        <DocumentView
          docRef={openDocument}
          projId={projectId}
          onCancel={() => setOpenDocument(null)}
          hideFloatingActionButtons
        />
      </Modal>
    </>
  );
};

PendingUpdatesBudgetTable.propTypes = {
  /**
   * Budget Id used to pull budget data
   */
  budgetId: PropTypes.string,
  projectId: PropTypes.string,
  projectData: PropTypes.shape({
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        spaces: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string,
            level: PropTypes.string,
          })
        ),
      })
    ),
    members: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.string,
        position: PropTypes.string,
      })
    ),
    contractualFee: PropTypes.shape({
      feeType: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    contractualInsurance: PropTypes.shape({
      insuranceType: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    reference: PropTypes.string,
  }),
  onRowSelect: PropTypes.func,
  initialSelectedRows: PropTypes.arrayOf(PropTypes.string),
  documentModalView: PropTypes.bool,
  setCheckedRow: PropTypes.func,
  checkedRow: PropTypes.string,
  initialCsiCode: PropTypes.shape({
    division: PropTypes.string,
    code: PropTypes.string,
    subcode: PropTypes.string,
  }),
  initialRate: PropTypes.string,
};

PendingUpdatesBudgetTable.defaultProps = {
  budgetId: undefined,
  projectId: undefined,
  projectData: undefined,
  onRowSelect: undefined,
  initialSelectedRows: [],
  documentModalView: false,
  setCheckedRow: undefined,
  checkedRow: undefined,
  initialCsiCode: undefined,
  initialRate: undefined,
};

export default PendingUpdatesBudgetTable;
