import { useMutation, useQueryClient } from "react-query";
import editTaskListStore from "../../../helpers/Task/editTaskListStore";
import editTaskHandler from "./handlers/editTaskHandler";
import editTaskListResponder from "./responders/editTaskListResponder";

const useEditTaskList = () => {
  const queryClient = useQueryClient();
  const editMutation = useMutation(
    editTaskHandler,
    editTaskListResponder(queryClient)
  );

  /**
   * Adds task to task list, can specify whether to make a new request or not
   *
   * @param {Props}
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const editTaskList = async (props, newRequest = true) => {
    if (!props.args.originalItem) {
      return console.error(
        "editTaskList | props.args.originalItem is required: populate with item you are updating"
      );
    }

    if (!props.args.editedItem) {
      return console.error(
        "editTaskList | props.args.editedItem is required: populate with the original version of the item you are updating"
      );
    }

    if (!props.operation) {
      return console.error(
        "editTaskList | props.operation is required: populate with $non-recurring, $all, $single or $future"
      );
    }

    if (newRequest === false) {
      return editTaskListStore({
        queryClient,
        variables: props,
        item: props.args.editedItem,
      });
    }

    const response = await editMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: editTaskListLoading,
    isError: hasEditTaskListError,
    error: editTaskListError,
    isSuccess: editTaskListSuccess,
  } = editMutation;

  return {
    editTaskList,
    editTaskListLoading,
    hasEditTaskListError,
    editTaskListError,
    editTaskListSuccess,
  };
};

export default useEditTaskList;
