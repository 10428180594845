import cntl from "cntl";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "../../Dropdown/Dropdown";
import Modal from "../../Modal/Modal";
import { getInitials } from "../../../../helpers/User";
import RadioButton from "../../Buttons/RadioButton";
import { PTO_CATEGORY } from "../../../../constants";
import useChangeEmploymentTypeModalData from "./useChangeEmploymentTypeModalData";

const avatarCN = cntl`
  bg-brandGreen
  rounded-full
  w-20
  h-20
  flex
  items-center
  justify-center
  mr-6
`;

const headerCN = cntl`
  text-lg
  font-semibold
`;

const ChangeEmploymentTypeModal = ({
  user,
  setUser,
  onCancel,
  onSave,
  changeUserStateOfEmployment,
  onChangeUserEmploymentType,
  isSaving,
  states,
  ptoCategory,
}) => {
  const { data, origUser, isChanged } = useChangeEmploymentTypeModalData({
    user,
  });

  return (
    <Modal
      title="CHANGE EMPLOYMENT TYPE"
      isOpen
      onRequestModalClose={onCancel}
      hideFooter
      primaryButtonTitle="Save"
      primaryButtonOnClick={() => onSave(user?.id)}
      tertiaryButtonTitle="Cancel"
      disabled={isSaving || !isChanged}
    >
      <div className="flex flex-row">
        {(data?.thumbnailUrl && (
          <div className="bg-gray-200 flex justify-center items-center w-20 h-20 rounded-full mr-6 relative">
            <img
              src={data?.thumbnailUrl}
              className="w-full h-full rounded-full"
              alt="userAvatarIcon"
            />
          </div>
        )) || (
          <div className={avatarCN}>
            <p className="font-semibold uppercase text-white text-4xl">
              {getInitials(user?.name)}
            </p>
          </div>
        )}
        <div className="px-2 pt-3">
          <h2 className={headerCN}>
            {user?.name?.firstName} {user?.name?.lastName}
          </h2>
          <h2 className="text-sm text-gray-300">{user?.company}</h2>
        </div>
      </div>
      <div className="flex flex-col pt-4">
        <p className="font-medium">Invite as employee?</p>
        <div className="flex flex-row pt-2">
          <RadioButton
            label="Yes"
            isChecked={user?.isEmployee}
            onChange={() => onChangeUserEmploymentType(true)}
          />
          <RadioButton
            label="No"
            isChecked={!user?.isEmployee}
            onChange={() => onChangeUserEmploymentType(false)}
          />
        </div>
      </div>
      {user?.isEmployee && ptoCategory === PTO_CATEGORY.location && (
        <div className="pt-5 h-72">
          <Dropdown
            label="State of Employment"
            options={states}
            value={states?.find(
              (state) => state.value === user?.employeeInfo?.pto?.locationId
            )}
            onChange={(value) => {
              // reset user info to original when clearing DD value
              if (!value.value) {
                // reset user info to original when clearing DD value
                setUser({
                  ...user,
                  employeeInfo: origUser.employeeInfo,
                });
              } else changeUserStateOfEmployment(value);
            }}
            defaultMenuIsOpen={!user?.stateOfEmployment}
            isDisabled={!!user?.stateOfEmployment}
          />
        </div>
      )}
    </Modal>
  );
};

ChangeEmploymentTypeModal.propTypes = {
  onCancel: PropTypes.func,
  setUser: PropTypes.func,
  user: PropTypes.shape({
    stateOfEmployment: PropTypes.string,
    employeeInfo: PropTypes.shape({
      pto: PropTypes.shape({
        locationId: PropTypes.string,
      }),
    }),
    avatar: PropTypes.shape({
      thumbnailUrl: PropTypes.string,
    }),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    company: PropTypes.string,
    isEmployee: PropTypes.bool,
  }),
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  changeUserStateOfEmployment: PropTypes.func,
  onChangeUserEmploymentType: PropTypes.func,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  ptoCategory: PropTypes.string,
};

ChangeEmploymentTypeModal.defaultProps = {
  onCancel: undefined,
  user: undefined,
  setUser: () => {},
  onSave: undefined,
  isSaving: false,
  states: [],
  changeUserStateOfEmployment: undefined,
  onChangeUserEmploymentType: undefined,
  ptoCategory: undefined,
};

export default ChangeEmploymentTypeModal;
