/**
 * Sets the current page in the user settings.
 *
 * @param {Object} params - The parameters for setting the current page.
 * @param {string} params.url - URL used to determine overview screen
 * @param {Function} params.stableEditSettings - The function to edit the user settings.
 * @param {Object} params.userSettings - The current user settings.
 * @returns {Promise} A promise that resolves when the current screen has been changed.
 */

const modifyCurrentResourceScreen = ({
  page,
  url,
  stableEditSettings,
  userSettings,
}) => {
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings?.web,
      [page]: { ...userSettings?.web?.[page], currentScreen: url },
    },
  };

  return stableEditSettings(newSettings);
};

export default modifyCurrentResourceScreen;
