import { useCallback, useEffect, useMemo } from "react";
import useCurrentUser from "../../../hooks/useCurrentUser";
import usePagePersistence from "../../../hooks/usePagePersistence";
import { useAppState } from "../../../state/appState";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";
import { PAGES } from "../../../constants";

const useTimesheetOverviewData = () => {
  const { data: currentUser } = useCurrentUser();
  const [{ userDict, employees: unparsedEmployees, rolesDict, payrollPeriod }] =
    useAppState();
  const { pageState, storageLoading, setPersistentPageItem } =
    usePagePersistence();

  const { editTabSettings } = useAppPersistence();

  useEffect(() => {
    const pageDataIsEmpty =
      !storageLoading &&
      pageState?.timesheet?.userId === "" &&
      pageState?.timesheet?.tab === "" &&
      pageState?.timesheet?.periodStart === "" &&
      pageState?.timesheet?.periodEnd === "";

    if (currentUser?.isEmployee && pageDataIsEmpty) {
      const startDate = payrollPeriod?.periodStart.split("T")[0];
      const endDate = payrollPeriod?.periodEnd.split("T")[0];

      setPersistentPageItem(PAGES.TIMESHEETS, {
        ...pageState?.timesheet,
        periodStart: startDate,
        periodEnd: endDate,
        userId: currentUser?.id,
        tab: "my-timesheet",
      });
    }

    if (!currentUser?.isEmployee && pageDataIsEmpty) {
      setPersistentPageItem(PAGES.TIMESHEETS, {
        ...pageState?.timesheet,
        userId: "",
        tab: "approve-time",
        periodStart: payrollPeriod?.periodStart.split("T")[0],
        periodEnd: payrollPeriod?.periodEnd.split("T")[0],
      });
    }
  }, [
    currentUser?.id,
    currentUser?.isEmployee,
    pageState?.timesheet,
    payrollPeriod?.periodEnd,
    payrollPeriod?.periodStart,
    setPersistentPageItem,
    storageLoading,
  ]);

  const employees = useMemo(() => {
    return unparsedEmployees?.filter(
      (item) => rolesDict?.[item?.role]?.permissions?.timesheet?.can_submit
    );
  }, [rolesDict, unparsedEmployees]);

  const payrollApproveConditions = useMemo(() => {
    return currentUser?.hasPermission("corporate", "can_approve_payroll");
  }, [currentUser]);

  const timeOffApproveConditions = useMemo(() => {
    return currentUser?.hasPermission("corporate", "can_give_timeoff");
  }, [currentUser]);

  const timesheetApprovalConditions = useMemo(() => {
    return currentUser?.hasPermission(PAGES.TIMESHEETS, "can_approve");
  }, [currentUser]);

  const { activeTab, setActiveTab } = useWidgetTabNavigation({
    page: PAGES.TIMESHEETS,
  });

  const handleTabClick = useCallback(
    async (tab) => {
      let pageData = {
        userId: "",
        tab: "",
        periodStart: "",
        periodEnd: "",
      };

      switch (tab) {
        case "my-timesheet":
          pageData = {
            ...pageState?.timesheet,
            userId: currentUser?.id,
            tab: "my-timesheet",
          };
          break;
        case "user-timeoff":
          pageData = {
            ...pageState?.timesheet,
            userId: currentUser?.id,
            tab: "user-timeoff",
          };
          break;
        default:
          pageData = {
            ...pageState?.timesheet,
            userId: "",
            tab,
          };
          break;
      }

      if (!pageData?.periodStart || !pageData?.periodEnd) {
        pageData.periodStart = payrollPeriod?.periodStart.split("T")[0];
        pageData.periodEnd = payrollPeriod?.periodEnd.split("T")[0];
      }

      setPersistentPageItem(PAGES.TIMESHEETS, pageData);

      try {
        await editTabSettings(PAGES.TIMESHEETS, tab, pageData);
      } catch (err) {
        console.error(err);
      }
      setActiveTab(tab);
    },
    [
      editTabSettings,
      currentUser?.id,
      pageState,
      payrollPeriod?.periodEnd,
      payrollPeriod?.periodStart,
      setPersistentPageItem,
      setActiveTab,
    ]
  );

  return {
    currentUser,
    employees,
    userDict,
    payrollApproveConditions,
    timeOffApproveConditions,
    timesheetApprovalConditions,
    pageState,
    activeTab,
    handleTabClick,
  };
};

export default useTimesheetOverviewData;
