import { WorkflowAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { useAppState } from "../state/appState";
import {
  vendorinvoiceKeys,
  workflowKeys,
} from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import { SET_WORKFLOW } from "../constants";

const postWorkflow = async (workflow) => {
  const { data } = await WorkflowAPI.post(workflow);
  return data;
};
/**
 * Mutation hook to post new workflows
 * @param {string} mutationKey key to track mutation (optional)
 */
const usePostWorkflow = (mutationKey) => {
  const [{ workflowsNew, editingResource }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: postWorkflow,
    onSuccess: (newWorkflow) => {
      queryClient.getQueryData(workflowKeys.workflows, (current) => ({
        ...current,
        workflows: [...(current?.workflows ?? [], newWorkflow)],
        workflowDict: {
          ...current?.workflowDict,
          [newWorkflow.reference]: newWorkflow,
        },
        workflowDD: [
          ...(current?.workflowDD ?? []),
          {
            id: newWorkflow?.id,
            isTemplate: newWorkflow?.isTemplate,
            isDefaultTemplate: newWorkflow?.isDefaultTemplate,
          },
        ],
      }));

      dispatch({
        type: SET_WORKFLOW,
        workflows: [...workflowsNew, newWorkflow],
      });

      const messageAndLinkProps = {
        resource: newWorkflow,
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
    },
    onError: (error) => {
      toastError("Error creating workflow");
      console.error("usePostWorkflow", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(workflowKeys.workflows);
      queryClient.invalidateQueries(vendorinvoiceKeys);
    },
  });
};

export default usePostWorkflow;
