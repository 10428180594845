import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import editSettingsHandler from "./handlers/editSettingsHandler";
import editSettingsResponder from "./responders/editSettingsResponder";

const useEditSettings = () => {
  const queryClient = useQueryClient();

  const stableEditSettingsResponder = useMemo(
    () => editSettingsResponder(queryClient),
    [queryClient]
  );

  const editMutation = useMutation(
    editSettingsHandler,
    stableEditSettingsResponder
  );

  const editSettings = useCallback(
    async (newSettings) => {
      const response = await editMutation.mutateAsync(newSettings);
      return response;
    },
    [editMutation]
  );

  const {
    isLoading: editSettingsLoading,
    isError: hasEditSettingsError,
    error: editSettingsError,
    isSuccess: editSettingsSuccess,
  } = editMutation;

  return {
    editSettings,
    editSettingsLoading,
    hasEditSettingsError,
    editSettingsError,
    editSettingsSuccess,
  };
};

export default useEditSettings;
