import { useRef } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const useTrayContainerData = ({ height, close }) => {
  const { height: windowHeight } = useWindowDimensions();

  // If a specific height is provided, the component uses it.
  // Otherwise, it dynamically adjusts its height to 75% of the screen size,
  // also is listening for window resizing events to ensure responsivenes.
  const dynamicHeigth = height || windowHeight - windowHeight * 0.25;

  const wrapperRef = useRef(null);

  // Detect click outside of the popup to close
  useOutsideAlerter(wrapperRef, () => close());

  return {
    wrapperRef,
    dynamicHeigth,
  };
};

export default useTrayContainerData;
