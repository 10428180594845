import debounceAsync from "../debounceAsync";

/**
 * Modifies the search text of a specific tab on a page.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page where the tab is located.
 * @param {string} params.tab - The tab to modify.
 * @param {string} params.text - The new search text.
 * @param {Object} params.userSettings - The current user settings.
 * @param {Function} params.editSettings - The function to edit the user settings.
 * @returns {Function} A debounced function that modifies the page settings.
 */

const modifyTabSearchText = ({
  page,
  tab,
  text,
  userSettings,
  editSettings,
}) => {
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings.web,
    },
  };

  if (!userSettings?.web?.[page]) {
    newSettings.web[page] = {};
  }

  if (!userSettings?.web?.[page]?.[tab]) {
    newSettings.web[page][tab] = {};
  }

  newSettings.web[page][tab].tabTextSearch = text;

  return debounceAsync(editSettings(newSettings));
};

export default modifyTabSearchText;
