/**
 * Clears all persistence items in PERSISTENCE_RESOURCES
 * Only items discussed in IKT are removed on logout
 *
 * @param {Object} params - The parameters for setting the current page.
 * @param {Object} params.userSettings - The current user settings.
 * @param {Function} params.stableEditSettings - The function to edit the user settings.
 * @returns {Promise} A promise that resolves when the settings have been cleared.
 */

import { PERSISTENCE_ITEMS } from "../../constants";

const clearAllSettings = ({ userSettings, stableEditSettings }) => {
  const newSettings = {
    ...userSettings,
  };

  PERSISTENCE_ITEMS.forEach((item) => {
    delete newSettings?.web?.[item]?.tab;
    delete newSettings?.web?.[item]?.search;
    delete newSettings?.web?.[item]?.resource;
    delete newSettings?.web?.[item]?.filters;
    delete newSettings?.web?.[item]?.groups;
    delete newSettings?.web?.[item]?.template;
    delete newSettings?.web?.[item]?.userId;
    delete newSettings?.web?.[item]?.periodStart;
    delete newSettings?.web?.[item]?.periodEnd;
    delete newSettings?.web?.[item]?.timezone;
    delete newSettings?.web?.[item]?.eventsFilter;
    delete newSettings?.web?.[item]?.tasksFilter;
  });
  return stableEditSettings(newSettings);
};

export default clearAllSettings;
