import React from "react";
import whiteCrossIcon from "../../../assets/images/es-close-white.svg";

const containerCN =
  "flex flex-row items-start tracking-es-wide rounded-lg p-3 bg-es-dark-green w-max text-es-white text-es-normal gap-1";

const MinimumSeatHeader = ({ onClose }) => {
  return (
    <div className={containerCN}>
      <span className="font-semibold">Update:</span>
      <span>Plans now have a minimum seat count. Please visit</span>
      <span>
        <a
          href="https://estatespace.com/pricing"
          className="underline tracking-es-wide"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://estatespace.com/pricing
        </a>
      </span>
      <span>for details.</span>
      <span onClick={onClose} onKeyUp={() => {}} role="button" tabIndex={0}>
        <img src={whiteCrossIcon} alt="Close notice" />
      </span>
    </div>
  );
};

export default MinimumSeatHeader;
