/**
 *
 * @deprecated
 * Use instead: currentUser?.hasPermission?.()
 *
 *  Checks if a user has write permissions for a specific resource.
 * It considers both 'can_write' and 'can_create' flags within the user's permissions.
 *
 * @param {string} resource - The resource for which write permissions are being checked.
 * @param {Object} user - The user object, which should contain a 'permissions' property.
 * @returns {boolean} True if the user has write permissions for the resource, false otherwise.
 */
const hasWritePermission = (resource, user) => {
  const permissionResource = user?.permissions?.[resource];

  if (!permissionResource || !user) {
    return false;
  }

  const hasResourceWritePermissions =
    user?.permissions[resource]?.can_write === true ||
    user?.permissions[resource]?.can_create === true;

  return hasResourceWritePermissions;
};

export default hasWritePermission;
