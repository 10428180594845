/* Framework and Tools */
import { useEffect, useMemo, useReducer, useState } from "react";

import useManagementConfiguration from "../../../../hooks/useManagementConfiguration";
import { useProjectsOverview } from "../../../../hooks/projects";
import { usePropertiesOverview } from "../../../../hooks/properties";
import { toastError } from "../../../../helpers/Toast";

import useSpacesPostToProperty from "../../../../hooks/Spaces/useSpacesPostToProperty";
import {
  CHANGE_SPACE_AREA,
  CHANGE_SPACE_ASSOCIATION,
  CHANGE_SPACE_LEVEL,
  CHANGE_SPACE_NAME,
  CHANGE_SPACE_TYPE,
} from "../../../../constants";
import useSpacesPostToProject from "../../../../hooks/Spaces/useSpacesPostToProject";
import useFilesPost from "../../../../hooks/useFilesPost";
import uploadAvatar from "../../../../helpers/uploadAvatar";

/* initialization */
const initialState = {
  name: null,
  association: null,
  level: null,
  type: null,
  area: null,
  imageInfo: { fileRef: null, blob: null },
};

/* Reducer */
const modalReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_SPACE_NAME:
      return { ...state, name: action.payload };
    case CHANGE_SPACE_ASSOCIATION:
      return { ...state, association: action.payload };
    case CHANGE_SPACE_LEVEL:
      return { ...state, level: action.payload };
    case CHANGE_SPACE_TYPE:
      return { ...state, type: action.payload };
    case CHANGE_SPACE_AREA:
      return { ...state, area: action.payload };
    default:
      return state;
  }
};

const useAddSpaceModal = () => {
  const { mutate: createSpaceInProperty } = useSpacesPostToProperty();
  const { mutate: createSpaceInProject } = useSpacesPostToProject();

  /* Mutation hook to post files */
  const { mutateAsync: postFiles } = useFilesPost();

  /* Primary Reducer Hook */
  const [state, dispatch] = useReducer(modalReducer, initialState);

  /* ManagementConfig Hook fetches Types & Levels */
  const { data: managementConfiguration } = useManagementConfiguration();

  /* Other Hooks */
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  /* Unique Name Control */
  const [isUniqueSpaceName, setIsUniqueSpaceName] = useState(true);

  /* Temporary Avatar Image State */
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});

  /* Dropdown Lists */
  const levelsDropdown = useMemo(() => {
    return managementConfiguration?.management?.propertyLevel?.types?.map(
      (type) => {
        return { label: type.display, value: type.id };
      }
    );
  }, [managementConfiguration?.management?.propertyLevel?.types]);
  const typesDropdown = useMemo(() => {
    return managementConfiguration?.management?.propertySpace?.types?.map(
      (type) => {
        return { label: type.display, value: type.id };
      }
    );
  }, [managementConfiguration?.management?.propertySpace?.types]);

  /* Checks uniqueness of space name against names by resource assocation */
  useEffect(() => {
    const isUniqueName = (ref) => {
      const refType = ref.split("/")[0];

      if (refType && refType === "Project") {
        const project = projectDict[ref];
        const spaceNames = project.spaces.map((item) => item.name);
        const match = spaceNames.some((item) => item === state.name);
        setIsUniqueSpaceName(!match);
      }

      if (refType && refType === "Property") {
        const property = propertiesDict[ref];
        const spaceNames = property.spaces.map((item) => item.name);

        const match = spaceNames.some((item) => item === state.name);
        setIsUniqueSpaceName(!match);
      }
    };
    if (state?.association?.value) {
      isUniqueName(state.association.value);
    }
  }, [
    state.name,
    state.association,
    projectDict,
    propertiesDict,
    isUniqueSpaceName,
  ]);

  /* Form Actions */
  const handleSave = async (postRequestData) => {
    /* data is state object passed in from modal component to maintain immutability of state */

    const newPostRequestData = await uploadAvatar({
      tmpAvatarImg,
      resourceState: postRequestData,
      postFiles,
    });

    const typeAndId = newPostRequestData.association.split("/");
    const type = typeAndId[0];
    const id = typeAndId[1];
    const payload = {
      name: newPostRequestData.name,
      /* if area is null, send 0 instead */
      area: Number.isInteger(parseFloat(newPostRequestData.area, 10))
        ? parseFloat(newPostRequestData.area, 10)
        : 0,
      type: newPostRequestData?.type?.value ?? "",
      level: newPostRequestData?.level?.value ?? "",
      primaryImage: newPostRequestData?.primaryImage,
      files: newPostRequestData?.files,
    };

    if (!isUniqueSpaceName) {
      toastError(`A space with the name ${state.name} already exists.`);
    }

    /* Project API call */
    if (isUniqueSpaceName && type && type === "Project") {
      createSpaceInProject({ payload, id });
    }

    /* Property API call */
    if (isUniqueSpaceName && type && type === "Property") {
      createSpaceInProperty({ payload, id });
    }
  };

  return [
    { state, dispatch },
    {
      levelsDropdown,
      typesDropdown,
    },
    { handleSave },
    { tmpAvatarImg, setTmpAvatarImg },
  ];
};

export default useAddSpaceModal;
