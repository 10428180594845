import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import PureMemberTable from "./PureMemberTable";
import link from "../../assets/images/linkGreen.svg";
import BaseButton from "../Buttons/BaseButton";
import { PTO_CATEGORY } from "../../../constants";
import ChangeRoleModal from "./ChangeRoleModal";
import ChangeActiveStatusModal from "./ChangeActiveStatusModal";
import ReinviteUserModal from "../UserModal/ReinviteUserModal";
import ChangeStateOfEmploymentModal from "./ChangeStateOfEmploymentModal/ChangeStateOfEmploymentModal";
import ChangeEmploymentTypeModal from "./ChangeEmploymentTypeModal/ChangeEmploymentTypeModal";
import ChangeDirectReportsModal from "./ChangeDirectReportsModal";
import ChangeEmployeeIdModal from "./ChangeEmployeeIdModal";
import ChangeEmployeeHireDateModal from "./ChangeEmployeeHireDateModal";
import UserSeatsWarning from "../Warning/UserSeatsWarning/UserSeatsWarning";
import useMemberTableData from "./useMemberTableData";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExclamationIcon from "../../assets/images/whiteExclamationIcon.svg";

const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExclamationIcon} alt="Error icon" />;

const primaryHeaderCN = cntl`
  text-xl
  font-medium
  uppercase
  pb-2
`;

const MemberTable = ({ isExportingMemberList, setIsExportingMemberList }) => {
  const {
    isSaving,
    isLoading,
    states,
    roles,
    changeType,
    directReportOptions,
    activeUsersCount,
    availableSeats,
    members,
    ptoCategory,
    selectedUser,
    originalSubscription,
    clear,
    navigate,
    rowAssociationMenuList,
    onChangeUserEmploymentType,
    onChangeRole,
    onChangeUserStateOfEmployment,
    onChangeUserEmployeeId,
    onChangeUserHireDate,
    onSaveUserStateOfEmployment,
    onSaveUserDirectReports,
    onSaveUserEmploymentType,
    onSaveUserEmploymentId,
    onSaveUserHireDate,
    onSaveUserRole,
    onSaveUserStatus,
    onReinviteUser,
    setSelectedUser,
  } = useMemberTableData({
    isExportingMemberList,
    setIsExportingMemberList,
    toastCloseIcon,
    toastErrorIcon,
  });

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className={primaryHeaderCN}>Members</h1>
          {!!activeUsersCount && !!originalSubscription?.userCount && (
            <div className="flex flex-row">
              <p className="font-semibold">
                {activeUsersCount}/{originalSubscription?.userCount}
              </p>
              <p className="px-2">user seats occupied</p>
              <BaseButton
                iconLeft={<img src={link} alt="link" className="w-5 h-5" />}
                className="pt-1 pl-4"
                onClick={navigate}
              />
            </div>
          )}
        </div>
        {availableSeats === 0 && <UserSeatsWarning />}
      </div>
      <PureMemberTable
        data={members}
        rowAssociationMenuList={(row) => rowAssociationMenuList(row)}
        ptoCategory={ptoCategory}
        isLoading={isLoading}
      />
      {changeType === "role" && (
        <ChangeRoleModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserRole}
          changeUserRole={onChangeRole}
          isSaving={isSaving}
          roles={roles}
        />
      )}
      {changeType === "stateOfEmployment" && (
        <ChangeStateOfEmploymentModal
          user={selectedUser}
          setUser={setSelectedUser}
          onCancel={clear}
          onSave={onSaveUserStateOfEmployment}
          changeUserStateOfEmployment={onChangeUserStateOfEmployment}
          isSaving={isSaving}
          states={states}
          isPtoGeneral={ptoCategory === PTO_CATEGORY.general}
        />
      )}
      {changeType === "directReports" && (
        <ChangeDirectReportsModal
          directReportOptions={directReportOptions}
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserDirectReports}
          isSaving={isSaving}
        />
      )}
      {changeType === "employmentType" && (
        <ChangeEmploymentTypeModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserEmploymentType}
          changeUserStateOfEmployment={onChangeUserStateOfEmployment}
          onChangeUserEmploymentType={onChangeUserEmploymentType}
          isSaving={isSaving}
          states={states}
          ptoCategory={ptoCategory}
          setUser={setSelectedUser}
        />
      )}
      {changeType === "employeeId" && (
        <ChangeEmployeeIdModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserEmploymentId}
          onChangeEmployeeId={onChangeUserEmployeeId}
          isSaving={isSaving}
        />
      )}
      {changeType === "hireDate" && (
        <ChangeEmployeeHireDateModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserHireDate}
          onChangeUserHireDate={onChangeUserHireDate}
          isSaving={isSaving}
        />
      )}
      {changeType === "active" && (
        <ChangeActiveStatusModal
          user={selectedUser}
          onCancel={clear}
          onSave={onSaveUserStatus}
          isSaving={isSaving}
          roles={roles}
        />
      )}
      {changeType === "reinvite" && (
        <ReinviteUserModal
          user={selectedUser}
          onCancel={clear}
          onSave={onReinviteUser}
          isSaving={isSaving}
        />
      )}
    </div>
  );
};

MemberTable.propTypes = {
  isExportingMemberList: PropTypes.bool,
  setIsExportingMemberList: PropTypes.func,
};

MemberTable.defaultProps = {
  isExportingMemberList: false,
  setIsExportingMemberList: undefined,
};

export default MemberTable;
