import PropTypes from "prop-types";
import React from "react";
import searchIcon from "../../assets/images/magnoliaSearchIcon.svg";
import SearchBar from "../SearchBar/SearchBar";

const SiteHeaderSearch = ({ handleSearch, searchKeyword, globalFilter }) => {
  return (
    <>
      <div className="w-72">
        <SearchBar
          placeholder="Search"
          onSearchChange={handleSearch}
          searchKeyword={searchKeyword}
          searchIcon={searchIcon}
          globalFilter={globalFilter}
        />
      </div>
    </>
  );
};

SiteHeaderSearch.propTypes = {
  handleSearch: PropTypes.func,
  searchKeyword: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SiteHeaderSearch.defaultProps = {
  handleSearch: undefined,
  searchKeyword: "",
  globalFilter: undefined,
};

export default SiteHeaderSearch;
