import React from "react";
import { isEmpty } from "lodash";
import cntl from "cntl";
import moment from "moment";
import Dropdown from "../../Dropdown/Dropdown";
import usePlanSelection from "./usePlanSelection";
import planLabel from "./PlanLabel";
import { numbersOnly } from "../../../../helpers/Formatters";

const cycleButtonStyle = {
  width: "180px",
  height: "80px",
  borderRadius: "10px",
  padding: "16px",
  borderWidth: "2px",
};

const cycleCN = (billingCycle, cycle) => cntl`
flex
flex-col
justify-start
${
  billingCycle === cycle
    ? "border border-es-green"
    : "border border-es-light-grey"
}
`;
const containerCN = "border border-es-light-grey p-6 flex flex-col w-full";
const containerStyles = { borderRadius: "10px" };
const headerText = "text-es-dark-grey text-es-lg font-es-semibold";
const headerStyles = { letterSpacing: "1.13px" };
const errorTextCN = "text-es-sm font-es-normal text-es-red";
const errorText = "Please update Plan Level to accommodate the number of users";
const labelCN = "text-es-dark-grey font-es-semibold text-es-normal";
const textNormalCN = "text-es-dark-grey font-es-normal text-es-normal";
const textBoldCN = "text-es-dark-grey font-es-bold text-es-normal";
const textSemiBoldCN = "text-es-dark-grey text-es-normal font-es-semibold";
const colCN = "flex flex-col gap-2";

const PlanSelection = ({ isEditing, billingFormData, dispatchBilling }) => {
  const {
    changePlan,
    billingCycleText,
    changeBillingCycle,
    changeUserCount,
    userCountError,
  } = usePlanSelection({
    isEditing,
    dispatchBilling,
    billingFormData,
  });
  return (
    <div className={containerCN} style={containerStyles}>
      <div className={!userCountError && "pb-6"}>
        <p className={headerText} style={headerStyles}>
          Plan Selection
        </p>
        <p className={errorTextCN}>{userCountError && errorText}</p>
      </div>
      <div className="flex flex-col gap-6">
        <div className={colCN}>
          {isEditing ? (
            <div className="flex flex-col gap-2" style={{ width: "380px" }}>
              <p className={labelCN}>Select Plan Level</p>
              <Dropdown
                options={billingFormData?.plans}
                value={billingFormData?.planSelection}
                onChange={changePlan}
                formatOptionLabel={planLabel}
                disableSort
                disableClear
              />
            </div>
          ) : (
            <div className="flex flex-row gap-2 whitespace-nowrap">
              <p className={labelCN}>Plan Level:</p>
              {!isEmpty(billingFormData?.planSelection) &&
                planLabel(billingFormData?.planSelection, false)}
            </div>
          )}
        </div>
        <div className={colCN}>
          {isEditing ? (
            <>
              <p className={labelCN}>Select Billing Cycle</p>
              <div className="flex flex-row gap-4">
                <button
                  type="submit"
                  className={cycleCN(billingFormData?.billingCycle, "annual")}
                  style={cycleButtonStyle}
                  onClick={() => changeBillingCycle("annual")}
                >
                  <p className={textBoldCN}>
                    ${billingFormData?.planSelection?.annualCost} Annual
                  </p>
                  <p className={textNormalCN}>Per user</p>
                </button>
                <button
                  type="submit"
                  onClick={() => changeBillingCycle("monthly")}
                  className={cycleCN(billingFormData?.billingCycle, "monthly")}
                  style={cycleButtonStyle}
                >
                  <p className={textBoldCN}>
                    ${billingFormData?.planSelection?.monthlyCost} Monthly
                  </p>
                  <p className={textNormalCN}>Per user</p>
                </button>
              </div>
            </>
          ) : (
            <div className="flex flex-row gap-2 whitespace-nowrap">
              <p className={labelCN}>Billing Cycle:</p>
              {!isEmpty(billingFormData?.billingCycle) && (
                <p className={textNormalCN}>{billingCycleText}</p>
              )}
            </div>
          )}
        </div>
        <div className={colCN}>
          {isEditing ? (
            <div className="flex flex-col gap-2" style={{ width: "380px" }}>
              <p className={textSemiBoldCN}>Number of Users</p>
              <input
                className={`ESInputContainer p-2 ${
                  userCountError && "border-es-red"
                }`}
                value={billingFormData?.userCount}
                type="number"
                onChange={(val) =>
                  (numbersOnly(val?.target?.value) || !val?.target?.value) &&
                  changeUserCount(val?.target?.value)
                }
              />
            </div>
          ) : (
            <div className="flex flex-row gap-2 whitespace-nowrap">
              <p className={textSemiBoldCN}>Number of Users:</p>
              {!isEmpty(billingFormData) && (
                <p className={textNormalCN}>{billingFormData?.userCount}</p>
              )}
            </div>
          )}
        </div>
        {!isEditing && (
          <div className={colCN}>
            <div className="flex flex-row gap-2 whitespace-nowrap">
              <p className={textSemiBoldCN}>Next Payment:</p>
              {billingFormData?.toCancelAtPeriodEnd ? (
                <p className={textNormalCN}>N/A</p>
              ) : (
                <>
                  <p className={textNormalCN}>
                    {moment(billingFormData?.nextPaymentDate).format(
                      "MMMM DD, YYYY"
                    )}
                  </p>
                  <p className={textNormalCN}>
                    (${billingFormData?.nextPayment})
                  </p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanSelection;
