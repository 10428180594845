import React, { useCallback, useState } from "react";
import PropTypes, { arrayOf } from "prop-types";
import * as yup from "yup";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import { SpinnerInline } from "../Spinner/Spinner";
import paperclip from "../../assets/images/attachment_icon_updated.svg";
import SimpleFileUpLoad from "../AssetForms/SimpleFileUpLoad";
import DisplayFileNames from "../../../Pages/Overviews/Sop/DisplayFileNames";
import SopStepsContainer from "./SopStepsContainer";
import IconButton from "../Buttons/IconButton";
import TagsContainer from "../Tags/TagsContainer";
import { SOP_ATTACHMENT_EXTENSIONS } from "../../../constants";

const SopInfoForm = ({
  editedSop,
  sopDispatch,
  handleEnter,
  inputRefs,
  categoryOptionsList,
  currentUser,
  filesToUpload,
  handleFilesAdded,
  handleFilesUpdated,
  handleFilesUploaded,
  removeAttachedFile,
}) => {
  const [showAddDocuments, setShowAddDocuments] = useState(false);
  const [stepFocus, setStepFocus] = useState(false);

  const onFieldChange = useCallback(
    (field, type, val) => {
      sopDispatch({
        type,
        payload: val,
      });
    },
    [sopDispatch]
  );

  return (
    <div className="flex flex-col w-full h-full border rounded-lg px-7 pt-9 mb-7">
      <h2 className="text-xl text-gray-500 font-semibold">SOP Information</h2>
      <div className="flex gap-7 w-full mb-6 mt-7">
        {!currentUser && (
          <div className="absolute w-full z-10 mr-auto ml-auto h-full mt-auto mb-auto">
            <SpinnerInline />
          </div>
        )}
        <div className="flex items-end border-b border-gray-200 pb-4 w-1/2">
          <p className="flex items-center text-gray-500 font-semibold w-32 mb-5">
            Name<span className="text-brandGreen">*</span>
          </p>
          <div className="flex flex-col w-52">
            <Input
              autoFocus
              placeholder="Name"
              onChange={(val) => onFieldChange("name", "editName", val)}
              value={editedSop?.name}
              validation={yup.string().required()}
              // eslint-disable-next-line no-return-assign, no-param-reassign
              forwardedRef={(el) => (inputRefs.current.sopName = el)}
              handleEnter={handleEnter}
              name="sopName"
              disableClear
            />
          </div>
        </div>
        <div className="flex justify-between items-end border-b border-gray-200 pb-4 w-1/2">
          <p className="flex items-center text-gray-500 font-semibold mb-5">
            Type<span className="text-brandGreen">*</span>
          </p>
          <div className="flex flex-col w-52">
            <Dropdown
              className=""
              key="Category (required)"
              placeholder="Select"
              options={categoryOptionsList}
              value={categoryOptionsList?.find(
                (opt) => opt.value === editedSop?.category
              )}
              validation={yup.mixed().required()}
              onChange={(val) =>
                onFieldChange("category", "editCategory", val?.value)
              }
              disableClear
            />
          </div>
        </div>
      </div>
      <div className="flex w-full border-b border-gray-200">
        <h2 className="text-gray-500 font-semibold w-32">Steps</h2>
        <div className="flex-1 mb-6">
          <SopStepsContainer
            stepData={editedSop?.steps}
            sopDispatch={sopDispatch}
            setStepFocus={setStepFocus}
            stepFocus={stepFocus}
            editing
          />
        </div>
      </div>

      {/* New attachment */}
      <div className="flex flex-col justify-start w-full mt-6">
        <div className="flex w-full">
          <h2 className="text-gray-500 font-semibold w-32">Description</h2>
          <div className="flex flex-col flex-1">
            <Input
              placeholder="Description"
              className="w-full"
              value={editedSop?.description}
              onChange={(val) =>
                onFieldChange("description", "editDescription", val)
              }
              isTextarea
            />
            <IconButton
              className="my-4"
              innerClassName="text-gray-300 text-sm font-normal ml-2"
              title="Add Attachment"
              icon={paperclip}
              onClick={() => {
                setShowAddDocuments(!showAddDocuments);
              }}
            />

            {showAddDocuments && (
              <div className="flex justify-start mb-4 z-0">
                <SimpleFileUpLoad
                  extensions={SOP_ATTACHMENT_EXTENSIONS}
                  files={filesToUpload}
                  onFilesAdded={handleFilesAdded}
                  onFilesUpdated={handleFilesUpdated}
                  onFilesUploaded={handleFilesUploaded}
                  customUploadAreaStyle={{ marginRight: "0px" }}
                  simple
                />
              </div>
            )}
            {filesToUpload?.length > 0 && (
              <div className="mt-2">
                <DisplayFileNames
                  filesToUpload={filesToUpload}
                  removeAttachedFile={removeAttachedFile}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="border-t border-b border-gray-200 mb-7 pt-6">
        <div className="flex gap-4 mb-7">
          <TagsContainer
            isEditing
            className="flex-col w-full"
            tagsWrapperClassName="mt-6"
            resource={editedSop}
            dispatch={sopDispatch}
          />
        </div>
      </div>
    </div>
  );
};

SopInfoForm.propTypes = {
  editedSop: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    links: arrayOf(PropTypes.shape({})),
    steps: arrayOf(PropTypes.shape({})),
    tags: arrayOf(PropTypes.string),
  }),
  document: PropTypes.shape({}),
  currentUser: PropTypes.shape({}),
  handleEnter: PropTypes.func,
  inputRefs: PropTypes.shape({
    current: PropTypes.shape({
      sopName: PropTypes.string,
    }),
  }),
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({})),
  handleFilesAdded: PropTypes.func,
  handleFilesUpdated: PropTypes.func,
  handleFilesUploaded: PropTypes.func,
  removeAttachedFile: PropTypes.func,
  sopDispatch: PropTypes.func,
  categoryOptionsList: PropTypes.arrayOf(PropTypes.shape({})),
};

SopInfoForm.defaultProps = {
  editedSop: {},
  currentUser: {},
  document: {},
  handleEnter: undefined,
  inputRefs: undefined,
  filesToUpload: undefined,
  handleFilesAdded: undefined,
  handleFilesUpdated: undefined,
  handleFilesUploaded: undefined,
  removeAttachedFile: undefined,
  sopDispatch: undefined,
  categoryOptionsList: [],
};

export default SopInfoForm;
