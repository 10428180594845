import React from "react";
import BillingContact from "./BillingContact";
import PaymentInformation from "./PaymentInformation";
import PlanSelection from "./PlanSelection/index";
import CancelSubscription from "./CancelSubscriptionModal";
import { USER_SETTINGS_KEYS } from "../../../constants";
import SubscriptionCancelledWarning from "../Warning/SubscriptionCancelledWarning";
import useBillingDashboard from "./useBillingDashboard";
import MinimumSeatHeader from "./MinimumSeatHeader";

const containerCN = "flex flex-col w-full h-fit gap-8";
const headerTextCN =
  "text-es-black text-es-lg font-es-semibold tracking-es-wide justify-self-center";
const loadingCN = "loading relative flex flex-col h-fit gap-8 rounded-md";
const rowCN = "flex flex-row h-fit gap-8 justify-between";
const colCN = "gap-8 flex flex-col";

const BillingDashboard = ({
  isEditing,
  billingFormData,
  dispatchBilling,
  isLoading,
}) => {
  const { isOpen, setIsOpen, billingSettings, onClose, onReactivateClick } =
    useBillingDashboard({ billingFormData, dispatchBilling });

  return (
    <div className={containerCN}>
      <div className="flex flex-col gap-4">
        <p className={headerTextCN}>Billing</p>
        {!billingSettings?.[USER_SETTINGS_KEYS.BILLING.SUBKEY_1] && (
          <MinimumSeatHeader onClose={onClose} />
        )}
        {!!billingFormData?.toCancelAtPeriodEnd && (
          <SubscriptionCancelledWarning
            subscriptionDetails={billingFormData}
            dispatchBilling={dispatchBilling}
          />
        )}
      </div>
      <div className={isLoading ? loadingCN : colCN}>
        <div className={rowCN}>
          <PlanSelection
            billingFormData={billingFormData}
            dispatchBilling={dispatchBilling}
            isEditing={isEditing}
          />
          <BillingContact
            billingFormData={billingFormData}
            dispatchBilling={dispatchBilling}
            isEditing={isEditing}
          />
        </div>
        <PaymentInformation
          billingFormData={billingFormData}
          dispatchBilling={dispatchBilling}
          isEditing={isEditing}
        />
      </div>

      {!isLoading && !isEditing && (
        <button
          type="submit"
          onClick={() =>
            billingFormData?.toCancelAtPeriodEnd
              ? onReactivateClick()
              : setIsOpen(true)
          }
          className="flex flex-row items-center p-2 justify-start tracking-es-wide"
          style={{ borderRadius: "10px" }}
        >
          <p className="text-es-normal font-es-semibold text-es-green">
            {billingFormData?.toCancelAtPeriodEnd
              ? "Reactivate Subscription"
              : "Cancel Subscription"}
          </p>
        </button>
      )}

      <CancelSubscription
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        selectedPlan={billingFormData}
        dispatch={dispatchBilling}
      />
    </div>
  );
};

export default BillingDashboard;
