import React from "react";
import useTsViewTotalAndRemoveSectionData from "./useTsViewTotalAndRemoveSectionData";
import IconButton from "../../../Buttons/IconButton";
import crossIconGreen from "../../../../assets/images/crossIconGreen.svg";

const TsViewTotalAndRemoveSection = ({
  entriesForEditing,
  projectsState,
  financialsConfiguration,
  projectDict,
  projectsDD,
  filteredCategoriesByProjectRate,
  removeTimesheetRow,
}) => {
  const { tsEntryData } = useTsViewTotalAndRemoveSectionData({
    entriesForEditing,
    projectsState,
    financialsConfiguration,
    projectDict,
    projectsDD,
    filteredCategoriesByProjectRate,
    removeTimesheetRow,
  });

  return (
    <>
      {tsEntryData.map(({ key, totalHours, multiLine, hasError }) => (
        <React.Fragment key={key}>
          <div
            key={key}
            style={{
              height: "40px",
            }}
            className="flex justify-between text-gray-700 font-light items-center"
          >
            <h2 className="text-lg text-gray-450 font-semibold">
              {totalHours} {"  "}
              <span className="text-gray-300 font-normal">hrs</span>
            </h2>

            <IconButton
              aria-label="remove timesheet"
              className="cursor-pointer p-2"
              icon={crossIconGreen || "X"}
              onClick={() => removeTimesheetRow(key)}
            />
          </div>

          {hasError && (
            <h1
              className="invisible"
              style={{
                height: multiLine ? "80px" : "40px",
              }}
            >
              hi
            </h1>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TsViewTotalAndRemoveSection;
