/* Framework Tools */
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";

/* Hooks */
import { useModalState } from "../../../state/modalState";
import useCurrentUser from "../../../hooks/useCurrentUser";

/* Components */
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import Widget from "../../../stories/Components/Widget/Widget";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import RequestTable from "../../../stories/Components/RequestTable/RequestTable";
import {
  ADD_OPEN_MODAL,
  PAGES,
  SUBMITTAL_CREATE_MODAL,
} from "../../../constants";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const SubmittalsOverview = ({ associatedResource }) => {
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  const handleAddSubmittal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "create-submittal-modal" },
      modalData: {
        id: "create-submittal-modal",
      },
      modalType: SUBMITTAL_CREATE_MODAL,
    });
  }, [modalDispatch]);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/sops/123", currentPage will be "sops"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.SUBMITTALS) {
      setCurrentResourceScreen(PAGES.SUBMITTALS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Submittals"
        buttonTitle="Submittal"
        customButton={
          currentUser?.hasPermission?.("submittal", "can_create") && (
            <PrimaryButton
              resourceName="Submittal"
              onClick={handleAddSubmittal}
              addButton
            />
          )
        }
      />
      <Widget title={null} draggable={false} backToTop>
        <RequestTable associatedResource={associatedResource} />
      </Widget>
    </>
  );
};

SubmittalsOverview.propTypes = {
  /**
   * only shows WF with this associated Resource
   */
  associatedResource: PropTypes.string,
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      submittal: PropTypes.shape({
        types: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
};

SubmittalsOverview.defaultProps = {
  associatedResource: undefined,
  managementConfiguration: undefined,
  currentUser: undefined,
};
export default SubmittalsOverview;
