import React, { useMemo } from "react";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import TimeOff from "../../../stories/Components/TimeOff/TimeOffTable";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import PayrollView from "../../../stories/Components/Timesheets/PayrollView";
import TimeOffUserView from "../../../stories/Components/Timesheets/TimeOffUserView";
import TimeSheetApprovalView from "../../../stories/Components/Timesheets/TimesheetApprovalView";
import TimesheetView from "../../../stories/Components/Timesheets/TimesheetView";
import useTimesheetOverviewData from "./useTimesheetOverviewData";
import { TS_OVERVIEW } from "../../../constants";

const TimesheetOverview = () => {
  const {
    currentUser,
    activeTabIndex,
    userDict,
    employees,
    pageState,
    payrollApproveConditions,
    timeOffApproveConditions,
    timesheetApprovalConditions,
    setTabs,
    handleTabClick,
  } = useTimesheetOverviewData();

  const tabs = useMemo(() => {
    const permissionTabs = [];
    if (currentUser?.isEmployee) {
      permissionTabs.push({
        content: <TimesheetView />,
        title: TS_OVERVIEW.MY_TIMESHEET,
        id: TS_OVERVIEW.MY_TIMESHEET_ID,
      });
    }

    if (currentUser?.isEmployee) {
      permissionTabs.push({
        title: TS_OVERVIEW.REQUEST_TIME_OFF,
        id: TS_OVERVIEW.REQUEST_TIME_OFF_ID,
        content: <TimeOffUserView />,
      });
    }

    if (timesheetApprovalConditions) {
      permissionTabs.push({
        content:
          pageState?.timesheet?.userId &&
          pageState?.timesheet?.tab === TS_OVERVIEW.TIMESHEET_APPROVAL_ID ? (
            <TimesheetView />
          ) : (
            <TimeSheetApprovalView
              currentUser={currentUser}
              usersDict={userDict}
            />
          ),
        title: TS_OVERVIEW.TIMESHEET_APPROVAL,
        id: TS_OVERVIEW.TIMESHEET_APPROVAL_ID,
      });
    }

    if (payrollApproveConditions) {
      permissionTabs.push({
        title: TS_OVERVIEW.PAYROLL_APPROVAL,
        id: TS_OVERVIEW.PAYROLL_APPROVAL_ID,
        content: pageState?.timesheet?.userId ? (
          <TimesheetView />
        ) : (
          <PayrollView
            currentUser={currentUser}
            usersDict={userDict}
            employees={employees}
          />
        ),
      });
    }

    if (timeOffApproveConditions) {
      permissionTabs.push({
        title: TS_OVERVIEW.TIME_OFF_APPROVAL,
        id: TS_OVERVIEW.TIME_OFF_APPROVAL_ID,
        content: pageState?.timesheet?.userId ? (
          <TimeOffUserView />
        ) : (
          <TimeOff currentUser={currentUser} />
        ),
      });
    }
    setTabs(permissionTabs);
    return permissionTabs;
  }, [
    currentUser,
    employees,
    pageState?.timesheet?.tab,
    pageState?.timesheet?.userId,
    payrollApproveConditions,
    timeOffApproveConditions,
    timesheetApprovalConditions,
    userDict,
    setTabs,
  ]);

  return (
    <div>
      <SiteHeader title="Timesheets" />
      <WidgetContainer
        tabs={tabs}
        onTabClick={handleTabClick}
        activeIndex={activeTabIndex}
        className="shadow-lg"
        tabBorder
      />
    </div>
  );
};

export default TimesheetOverview;
