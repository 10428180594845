import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import formatTasks from "../../helpers/ServiceRequest/formatTasks";
import getTicketPermissions from "../../helpers/ServiceRequest/getTicketPermissions";
import getTicketTypesDD from "../../helpers/Types/getTicketTypesDD";
import useCurrentUser from "../../hooks/useCurrentUser";
import useFilesPost from "../../hooks/useFilesPost";
import useGoBack from "../../hooks/useGoBack";
import useManagementConfiguration from "../../hooks/useManagementConfiguration";
import useServiceRequestDelete from "../../hooks/useServiceRequestDelete";
import useServiceRequestDispatcher from "../../hooks/useServiceRequestDispatcher";
import useServiceRequestPatch from "../../hooks/useServiceRequestPatch";
import useServiceRequestSelectorById from "../../hooks/useServiceRequestSelectorById";
import useTasksByServiceRequest from "../../hooks/useTasksByServiceRequest";
import useURLChange from "../../hooks/useURLChange";
import useQueryNotFoundNavigation from "../../hooks/navigation/useQueryNotFoundNavigation";
import useEditingResourceState from "../../hooks/useEditingResourceState";
import useRemoveTaskFromCache from "../../helpers/Workflow/removeTasksFromWorkflow";
import { MARK_AS_COMPLETE_BUTTON_TITLE, PAGES } from "../../constants";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";

const INITIAL_MODAL_COMPLETE_DATA = {
  open: false,
  title: "Mark as Complete",
  message:
    "Do you want to mark this request as complete? Once completed it cannot be updated",
  primaryButton: "Yes, complete",
  tertiaryButtonTitle: "Cancel",
};

const INITIAL_FILES = {
  mediaFilesToAdd: [],
  nonMediaFilesToAdd: [],
};

/**
 * Check that all tasks are complete
 */
const areTasksCompleted = (tasks = []) => {
  return tasks.every((task) => task.status === "done");
};

const useServiceRequestViewData = ({
  requestId,
  association,
  setButtonActions,
  onBackButtonPressed,
}) => {
  // Get ticket id from URL
  const url = useURLChange();
  const requestIdFromUrl = url.split("/").pop();

  // @id represent the request id to display details
  // This value could be taken from url (page) or component (P/P/A)
  const id = requestId || requestIdFromUrl;

  const { navigateBack } = useGoBack();

  const { data: config } = useManagementConfiguration();

  const ticketTypeOptions = useMemo(() => getTicketTypesDD(config), [config]);

  const { data: currentUser } = useCurrentUser();
  const { setCurrentResourceScreen } = useAppPersistence();

  // Query hook to fetch ticket by id
  const {
    data: serviceRequest,
    isLoading,
    error,
  } = useServiceRequestSelectorById(id);

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  // Query hook to fetch tasks by ticket
  const { data: tasks } = useTasksByServiceRequest(`Ticket/${id}`);

  // Dispatcher service hook
  const { requestForm, setRequestForm, handleChangeForm, handleTags } =
    useServiceRequestDispatcher(serviceRequest?.original);

  // Mutation hook to patch service
  const { mutate: patchServiceRequest } = useServiceRequestPatch();

  // Mutation hook to delete service
  const { mutateAsync: deleteServiceRequest } = useServiceRequestDelete();

  // Mutation hook to post files
  const { mutate: postFiles } = useFilesPost();
  const location = useLocation();

  const [isEditing, setIsEditing] = useState(false);
  const [filesState, setFilesState] = useState(INITIAL_FILES);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [serviceRequestTasks, setServiceRequestTasks] = useState([]);
  const [permissions, setPermissions] = useState({
    canUpdate: false,
    canUpdateMarkCompleteAndPriority: false,
    canDelete: false,
    canAddTasks: false,
  });

  // handle editing state of resource being edited
  useEditingResourceState({
    editing: isEditing,
    resource: "Ticket",
  });

  // State to handle complete dialog
  const [markCompleteDialog, setMarkCompleteDialog] = useState(
    INITIAL_MODAL_COMPLETE_DATA
  );

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/tickets/123", currentPage will be "tickets"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.SERVICE_REQUESTS) {
      setCurrentResourceScreen(PAGES.SERVICE_REQUESTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  // This effect keeps state sync with RQ cache
  useEffect(() => {
    if (serviceRequest?.original) {
      setRequestForm(serviceRequest.original);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequest?.original]);

  // This effect sets permissions for Tickets
  useEffect(() => {
    if (!currentUser || !serviceRequest?.original) return;
    setPermissions(getTicketPermissions(currentUser, serviceRequest?.original));
  }, [serviceRequest?.original, currentUser]);

  // This effect add tasks to the state
  useEffect(() => {
    if (tasks) {
      setServiceRequestTasks(tasks.map(formatTasks));
    }
  }, [tasks]);

  /**
   * Handles the navigation back action. It checks if an @association is provided
   * and invokes it to conditionally render a SR detail component. If it is not provided,
   * it performs a standard navigation back.
   */
  const handleNavigateBack = () => {
    if (association) {
      onBackButtonPressed();
    } else {
      navigateBack();
    }
  };

  const handleBeginEditing = () => {
    setIsEditing(true);
  };

  /**
   * Reset to initial state
   */
  const handleCancelEditing = () => {
    setRequestForm(serviceRequest.original);
    setFilesState(INITIAL_FILES);
    setServiceRequestTasks(tasks.map(formatTasks));
    setIsEditing(false);
  };

  /**
   * Patch SR
   */
  const handleFinishEditing = () => {
    // If new files added, post files first then patch SR
    const newFiles = [
      ...filesState.mediaFilesToAdd,
      ...filesState.nonMediaFilesToAdd,
    ];

    if (newFiles.length) {
      const recentFiles = []; // actually cloned files
      const newlyAddedFiles = []; // files that are newly added from user's local system

      // separate added recent (cloned) files because they are already posted to the cloud
      newFiles?.forEach((file) => {
        if (file?.isRecent) recentFiles.push(file);
        else newlyAddedFiles.push(file);
      });

      postFiles(newlyAddedFiles, {
        onSuccess: (response) => {
          const allPostedFiles = [...response, ...recentFiles];
          const filesReferences = allPostedFiles.map(
            ({ reference: ref, category }) => ({ ref, category })
          );

          const files = [...requestForm.files, ...filesReferences];

          patchServiceRequest({
            id: requestForm?.id,
            requestUpdated: { ...requestForm, files },
            request: serviceRequest?.original,
          });

          setFilesState(INITIAL_FILES);
        },
      });
    }

    // Patch SR if there are changes
    else if (!isEqual(serviceRequest?.original, requestForm)) {
      patchServiceRequest({
        id: requestForm?.id,
        requestUpdated: requestForm,
        request: serviceRequest?.original,
      });
    }

    setIsEditing(false);
  };

  const { removeTasksFromCache } = useRemoveTaskFromCache();
  /**
   * Deletes service request
   */
  const handleDeleteTicket = () => {
    const taskIds = serviceRequest?.original?.tasks?.map(
      (ref) => ref?.split("/")[1]
    );
    try {
      deleteServiceRequest([id]);
      if (taskIds?.length > 0) {
        removeTasksFromCache(taskIds);
      }
    } catch (e) {
      console.error(e, "Error deleting ticket, useServiceRequestViewData");
    }
    setShowDeleteDialog(false);
    handleNavigateBack();
  };

  /**
   * Add new files selected to the state
   */
  const handleFilesToAdd = (files, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = [...prev.mediaFilesToAdd, ...files];
      } else {
        updatedFilesState.nonMediaFilesToAdd = [
          ...prev.nonMediaFilesToAdd,
          ...files,
        ];
      }

      return updatedFilesState;
    });
  };

  /**
   * Remove existing and new files from the state
   */
  const handleFilesToRemove = (fileToRemove, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = prev.mediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      } else {
        updatedFilesState.nonMediaFilesToAdd = prev.nonMediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      }

      return updatedFilesState;
    });

    // Remove files from SR state
    setRequestForm((prev) => ({
      ...prev,
      files: prev.files.filter((file) => file.ref !== fileToRemove.ref),
    }));
  };

  /**
   * Add new tasks to the state
   */
  const handleAddTasks = useCallback(
    (task) => {
      setRequestForm((prev) => ({
        ...prev,
        tasks: [...prev.tasks, task.reference],
      }));

      // Add to UI
      setServiceRequestTasks((prev) => [...prev, formatTasks(task)]);
    },
    [setRequestForm]
  );

  /**
   * Remove tasks from the state
   */
  const handleRemoveTasks = useCallback(
    (task) => {
      setRequestForm((prev) => ({
        ...prev,
        tasks: prev.tasks.filter((t) => t !== task.reference),
      }));

      // Remove from UI
      setServiceRequestTasks((prev) => prev.filter((t) => task.id !== t.id));
    },
    [setRequestForm]
  );

  const handleShowCompleteDialog = useCallback(() => {
    const tasksCompleted = areTasksCompleted(tasks);

    if (tasksCompleted) {
      setMarkCompleteDialog({
        ...INITIAL_MODAL_COMPLETE_DATA,
        open: true,
      });
    } else {
      setMarkCompleteDialog((prev) => ({
        ...prev,
        open: true,
        message:
          "Are you sure you want to complete the request before all tasks are complete?",
        primaryButton: "Yes, complete",
        tertiaryButtonTitle: "Cancel",
      }));
    }
  }, [tasks]);

  const handleDissmissCompleteDialog = () => {
    setMarkCompleteDialog((prev) => ({ ...prev, open: false }));
  };

  /**
   * Complete SR
   */
  const handleMarkAsComplete = () => {
    patchServiceRequest({
      id: serviceRequest.original.id,
      isCompletion: true,
      request: serviceRequest.original,
      requestUpdated: {
        ...serviceRequest.original,
        completedBy: currentUser.reference,
        status: "closed",
      },
    });

    handleDissmissCompleteDialog();
  };

  // This effect shows the Mark as Complete button in top
  // header if SR status is open and if SR is rendered inside
  // widget tabs of P/P/A
  useEffect(() => {
    if (!association || !setButtonActions) return;

    setButtonActions((prev) => {
      if (permissions.canUpdateMarkCompleteAndPriority) {
        return [
          {
            title: MARK_AS_COMPLETE_BUTTON_TITLE,
            onClick: handleShowCompleteDialog,
          },
        ];
      }

      // Workaround: Assets uses Actions dropdown for all tabs
      if (association.startsWith("Asset")) {
        return prev.filter((p) => p.title !== MARK_AS_COMPLETE_BUTTON_TITLE);
      }

      return prev.map((button) => ({ ...button, className: "hidden" }));
    });
  }, [
    association,
    setButtonActions,
    permissions.canUpdateMarkCompleteAndPriority,
    handleShowCompleteDialog,
  ]);

  return {
    serviceRequest,
    requestForm,
    isLoading,
    isEditing,
    canUpdate: permissions.canUpdate,
    canDelete: permissions.canDelete,
    canAddTasks: permissions.canAddTasks,
    canComplete: permissions.canUpdateMarkCompleteAndPriority,
    showDeleteDialog,
    filesState,
    serviceRequestTasks,
    markCompleteDialog,
    ticketTypeOptions,
    setShowDeleteDialog,
    handleNavigateBack,
    handleChangeForm,
    handleTags,
    handleDeleteTicket,
    handleBeginEditing,
    handleCancelEditing,
    handleFinishEditing,
    handleFilesToAdd,
    handleFilesToRemove,
    handleAddTasks,
    handleRemoveTasks,
    handleDissmissCompleteDialog,
    handleMarkAsComplete,
    handleShowCompleteDialog,
  };
};

export default useServiceRequestViewData;
