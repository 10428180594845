import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import ESLogo from "../../../assets/images/es-logo.png";
import greenChatIcon from "../../../assets/images/greenChatIcon.svg";
import greenNotificationBell from "../../../assets/images/greenNotificationBell.svg";
import TodoIcon from "../../../assets/images/TodoIcon.svg";
import downArrow from "../../../assets/images/downArrow.svg";
import serviceBell from "../../../assets/images/serviceBell.svg";
import TopNavBarIcon from "../TopNavBarIcon";
import WorldClock from "../WorldClock";
import SimpleAvatar from "../../Avatar/SimpleAvatar";
import useTopNavBar from "./useTopNavBar";
import { getTitle } from "../../../../helpers/Utilities";

const containerCN = cntl`
  flex
  top-navbar
  items-center
  justify-between
  relative
  px-3
`;

const logoParentContainerCN = cntl`
  m-3
  ml-0
  flex
  items-center
  border-r
  border-gray-200
`;

const logoContainerCN = cntl`
  flex
  items-center
  cursor-pointer
  ml-3
  mr-12
`;

const logoCN = cntl`
  rounded-full
  h-12
`;

const estatespaceTextCN = cntl`
  font-semibold
  ml-2
  text-lg
  text-white
  tracking-widest
`;

const actionIconContainerCN = cntl`
  relative
  flex
  items-center
  justify-center
`;

const menuContainerCN = cntl`
  top-nav-user-menu
  bg-white
  py-2
  border 
  border-gray-100
  shadow-md
  rounded
  absolute 
  top-full 
  right-0
  mt-2
  z-50 
`;

const TopNavBar = ({
  onLogoClick,
  showUserMenu,
  closeMenu,
  onShowMenuClick,
  headerMenuItems,
  hideMessages,
  hideNotifications,
  hideTodos,
  hideServiceTicket,
  setShowMessages,
  setShowNotifications,
  setShowTodo,
}) => {
  const {
    onLogoKeyDown,
    systemConfiguration,
    todosCount,
    notificationCount,
    unread,
    client,
    openTicketRequest,
    popupRef,
  } = useTopNavBar({ onLogoClick, closeMenu });

  return (
    <div className={containerCN} style={{ zIndex: 9989 }}>
      <div className={logoParentContainerCN}>
        <div
          className={logoContainerCN}
          onClick={onLogoClick}
          onKeyDown={onLogoKeyDown}
          role="button"
          tabIndex={0}
        >
          <img className={logoCN} alt="ESLogo" src={ESLogo} />
          <p className={estatespaceTextCN}>{getTitle()}</p>
        </div>
      </div>
      <WorldClock systemConfiguration={systemConfiguration} />
      <div className={actionIconContainerCN}>
        <TopNavBarIcon
          className="mr-11"
          icon={serviceBell}
          iconClassName="w-6 h-6"
          amount={0}
          hidden={hideServiceTicket}
          onClick={openTicketRequest}
        />

        <TopNavBarIcon
          className="mr-12"
          icon={TodoIcon}
          iconClassName="w-5 h-5"
          amount={todosCount}
          hidden={hideTodos}
          onClick={() => setShowTodo(true)}
        />

        <TopNavBarIcon
          className="mr-12"
          icon={greenNotificationBell}
          iconClassName="w-5 h-5"
          amount={notificationCount}
          hidden={hideNotifications}
          onClick={() => setShowNotifications(true)}
        />

        <TopNavBarIcon
          icon={greenChatIcon}
          iconClassName="w-5 h-5"
          amount={unread}
          hidden={hideMessages && !client}
          onClick={() => setShowMessages(true)}
        />

        <button
          onClick={onShowMenuClick}
          type="button"
          className="ml-12 border-l border-gray-200"
        >
          <div className="flex items-center mr-3 ml-5">
            <SimpleAvatar topNav />
            <img src={downArrow} alt="chevron" className="ml-2" />
          </div>
        </button>

        {showUserMenu && (
          <div ref={popupRef} className={menuContainerCN}>
            {headerMenuItems}
          </div>
        )}
      </div>
    </div>
  );
};

TopNavBar.propTypes = {
  onLogoClick: PropTypes.func,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    reference: PropTypes.string,
  }),
  showUserMenu: PropTypes.bool,
  onShowMenuClick: PropTypes.func,
  headerMenuItems: PropTypes.element,
  hideMessages: PropTypes.bool,
  hideNotifications: PropTypes.bool,
  hideTodos: PropTypes.bool,
  hideServiceTicket: PropTypes.bool,
  setShowMessages: PropTypes.func,
  setShowNotifications: PropTypes.func,
  setShowTodo: PropTypes.func,
  closeMenu: PropTypes.func,
};

TopNavBar.defaultProps = {
  onLogoClick: null,
  currentUser: undefined,
  showUserMenu: false,
  onShowMenuClick: undefined,
  headerMenuItems: undefined,
  hideMessages: false,
  hideNotifications: false,
  hideTodos: false,
  hideServiceTicket: false,
  setShowMessages: undefined,
  setShowNotifications: undefined,
  setShowTodo: undefined,
  closeMenu: undefined,
};

export default TopNavBar;
