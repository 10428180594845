import React from "react";
import PropTypes from "prop-types";
import Widget from "../../Widget/Widget";
import FilesTable from "../../FilesTable/FilesTable";
import useDocumentFilesData from "./useDocumentFilesData";

const DocumentFiles = ({ currentUser, document, patchDocument }) => {
  const {
    handleUpdateFile,
    handleFileClone,
    associatedFiles,
    updateDocumentFiles,
    onAddFilesCallback,
    removeFilesAndUpdateApi,
  } = useDocumentFilesData({ document, patchDocument });

  return (
    <Widget draggable={false} title={null} overflow backToTop>
      <FilesTable
        files={associatedFiles}
        onAddFilesCallback={onAddFilesCallback}
        removeFilesAndUpdateApi={removeFilesAndUpdateApi}
        onRemoveFilesCallback={updateDocumentFiles}
        resourceName="Document"
        association={`Document/${document?.id}`}
        hasDeletePermission={currentUser?.hasPermission?.(
          "document",
          "can_write"
        )}
        hasWritePermission={currentUser?.hasPermission?.(
          "document",
          "can_write"
        )}
        handleFileClone={handleFileClone}
        hasEditPermission
        handleUpdateFile={handleUpdateFile}
      />
    </Widget>
  );
};

DocumentFiles.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
  document: PropTypes.shape({
    id: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        ref: PropTypes.string,
        category: PropTypes.string,
      })
    ),
  }),
  patchDocument: PropTypes.func,
};

DocumentFiles.defaultProps = {
  currentUser: undefined,
  document: undefined,
  patchDocument: () => {},
};

export default DocumentFiles;
