/**
 * Gets the current page in the user settings for a resource
 *
 * @param {Object} params - The parameters for setting the current page.
 * @param {string} params.page - URL used to determine overview screen
 * @param {Object} params.userSettings - The current user settings.
 * @returns {Promise} A promise that resolves when the current screen has been changed.
 */

const returnCurrentResourceScreen = ({ page, userSettings }) => {
  return userSettings?.web?.[page]?.currentScreen;
};

export default returnCurrentResourceScreen;
