import React, { useEffect } from "react";
import { pdfjs } from "react-pdf";
import { QueryClientProvider } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { setGlobalYupErrorMessages } from "./helpers/Validators";
import "@griffingroupglobal/lumina-react/lumina-styles.css";
import WithAxiosInterceptors from "./context/AxiosProvider/WithAxiosInterceptors";
import { AppStateProvider } from "./state/appState";
import { ModalWindowProvider } from "./state/modalState";
import AppRoutes from "./routes/AppRoutes";
import initApiConfig from "./config/api/initApiConfig";
import { ErrorBoundary } from "./components/ErrorBoundary";
import rqConfig from "./config/reactQuery/queryClient";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

setGlobalYupErrorMessages();

const { STRIPE_PUBLISHABLE_KEY } = window.runConfig || {};

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const App = () => {
  useEffect(() => {
    initApiConfig();
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <QueryClientProvider client={rqConfig}>
          <DndProvider backend={HTML5Backend}>
            <Elements stripe={stripePromise}>
              <AppStateProvider>
                <ModalWindowProvider>
                  <WithAxiosInterceptors>
                    <AppRoutes />
                  </WithAxiosInterceptors>
                </ModalWindowProvider>
              </AppStateProvider>
            </Elements>
            <ToastContainer className="w-80 h-10" />
          </DndProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
