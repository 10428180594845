import settingsKeys from "../settingsKeys";

const editSettingsResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      queryClient.setQueryData(settingsKeys.userSettings, returnedData);
    },
    onError: (error) => {
      console.error("Failed to edit settings", error);
    },
  };
};

export default editSettingsResponder;
