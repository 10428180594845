import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import getReqParams from "../../../helpers/Calendar/getReqParms";
import { useAppState } from "../../../state/appState";
import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import { fetchCalendarHandler } from "./handlers";
import calendarKeys from "./calendarKeys";

const useCalendar = () => {
  const queryClient = useQueryClient();
  const [{ calendarData }] = useAppState();

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: calendarKeys.calendarList(getReqParams(calendarData)),
    queryFn: ({ signal }) =>
      fetchCalendarHandler(getReqParams(calendarData), signal),
    staleTime: 0,
    onError: () => {
      toast.error("Failed to fetch events.");
    },
  });

  const refetchCalendarList = async () => {
    await queryClient.invalidateQueries(
      calendarKeys.calendarList(getReqParams(calendarData))
    );
  };

  const {
    data: calendarList,
    isLoading: calendarLoading,
    isError: isCalendarError,
    error: calendarError,
  } = authenticatedResponse;

  return {
    calendarList,
    calendarLoading,
    isCalendarError,
    calendarError,
    refetchCalendarList,
  };
};

export default useCalendar;
