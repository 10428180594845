import instance from "../../interceptors/SignupInterceptor";
import { SUBSCRIPTION_PLANS } from "../../constants";
import { subscriptionKeys } from "../../config/reactQuery/queryKeyFactory";

async function updateSubscriptionItem(id, data, queryClient) {
  const { price, quantity } = data;
  // creates customerID needed for subscription
  const response = await instance(`/api/Signup/stripe/subscriptionitem/${id}`, {
    method: "POST",
    data: {
      price,
      quantity,
    },
  });
  if (queryClient) {
    queryClient.invalidateQueries(subscriptionKeys.subscription);
  }
  return response.data;
}

const getSubscription = async (id) => {
  // creates customerID needed for subscription
  const response = await instance(`/api/Signup/stripe/subscription/${id}`, {
    method: "GET",
  });
  return response.data;
};

const getProduct = async (id) => {
  // creates customerID needed for subscription
  const response = await instance(`/api/Signup/stripe/product/${id}`, {
    method: "GET",
  });
  return response.data;
};

const getPaymentIntent = async (id) => {
  const url = `/api/Signup/stripe/paymentintent/${id}`;

  // creates customerID needed for subscription
  const response = await instance(url, {
    method: "GET",
  });

  return response.data;
};

const updateCustomer = async (id, data, queryClient) => {
  const url = `/api/Signup/stripe/customer/${id}`;

  const response = await instance(url, {
    method: "POST",
    data,
  });
  if (queryClient) {
    queryClient.invalidateQueries(subscriptionKeys.subscription);
  }

  return response.data;
};

const cancelSubscription = async (
  id,
  feedback = "No feedback",
  queryClient
) => {
  const url = `/api/Signup/stripe/subscription/${id}`;

  // creates customerID needed for subscription
  const response = await instance(url, {
    method: "DELETE",
    data: {
      feedback,
    },
  });

  queryClient.invalidateQueries(subscriptionKeys.subscription);

  return response.data;
};

const reactivateSubscription = async (id, queryClient) => {
  const url = `/api/Signup/stripe/subscription/reactivate/${id}`;

  // creates customerID needed for subscription
  const response = await instance(url, {
    method: "POST",
    data: {},
  });

  queryClient.invalidateQueries(subscriptionKeys.subscription);

  return response.data;
};

const getCustomer = async (id) => {
  const url = `/api/Signup/stripe/customer/${id}`;

  // creates customerID needed for subscription
  const response = await instance(url, {
    method: "GET",
  });

  return response.data;
};

const handleFetchStripeProducts = async () => {
  // fetches products from Stripe
  const plans = [];
  const response = await instance(`/api/Signup/stripe/product`, {
    method: "GET",
  });

  response.data.products.map((prod) => {
    if (
      prod.name === SUBSCRIPTION_PLANS.enterprise ||
      prod.name === SUBSCRIPTION_PLANS.professional ||
      prod.name === SUBSCRIPTION_PLANS.starter
    ) {
      const plan = {
        planName: prod.name,
        userText: prod.description,
        minUsers: Number.parseInt(prod.metadata.minUsers, 10),
        maxUsers: Number.parseInt(prod.metadata.maxUsers, 10),
        monthlyCost: Number.parseFloat(
          prod.prices.find((price) => price.recurring.interval === "month")
            .unit_amount / 100
        ).toFixed(2),
        annualCost: Number.parseFloat(
          prod.prices.find((price) => price.recurring.interval === "year")
            .unit_amount /
            (100 * 12)
        ).toFixed(2),
        discount: prod.metadata.discount,
        features: prod.metadata.features.split(","),
        priceIdMonthly: prod.prices.find(
          (price) => price.recurring.interval === "month"
        ).id,
        priceIdAnnual: prod.prices.find(
          (price) => price.recurring.interval === "year"
        ).id,
        // value/label for DDL, prevent useEffects to format this list
        value: prod.name,
        label: `${prod.name} (${prod.description})`,
      };
      plans.push(plan);
    }

    return prod;
  });
  // starter -> professional -> enterprise
  return plans.sort((a, b) => b.planName.localeCompare(a.planName));
};

const handleCustomerCreation = async (checkoutForm) => {
  // creates customerID needed for subscription
  const response = await instance(`/api/Signup/stripe/customer`, {
    method: "POST",
    data: checkoutForm,
  });
  return response.data;
};

const handleClientSecretCreation = async ({
  customerCreationResponse,
  priceId,
  userCount,
}) => {
  // creates clientSecret for Stripe validation
  const response = await instance(`/api/Signup/stripe/subscription`, {
    method: "POST",
    data: {
      customer: customerCreationResponse.id,
      price: priceId,
      quantity: userCount,
    },
  });
  return response.data;
};

const createTenant = async (body) => {
  // creates clientSecret for Stripe validation
  const response = await instance(`/api/Signup/$addclient`, {
    method: "POST",
    data: body,
  });
  return response.status;
};

const getPaymentCard = async (customerId) => {
  const response = await instance(
    `/api/Signup/stripe/customercards/${customerId}`,
    {
      method: "GET",
    }
  );

  return response.data;
};

const updatePaymentCard = async (customerId, paymentMethodId, queryClient) => {
  const response = await instance(
    `/api/Signup/stripe/customercards/${customerId}`,
    {
      method: "POST",
      data: {
        paymentMethodId,
      },
    }
  );

  if (queryClient) {
    queryClient.invalidateQueries(subscriptionKeys.subscription);
  }

  return response.data;
};

const SignUpAPI = {
  handleFetchStripeProducts,
  handleCustomerCreation,
  handleClientSecretCreation,
  createTenant,
  getProduct,
  getCustomer,
  getPaymentIntent,
  cancelSubscription,
  reactivateSubscription,
  getSubscription,
  updateSubscriptionItem,
  updateCustomer,
  getPaymentCard,
  updatePaymentCard,
};

export default SignUpAPI;
