import { useEffect, useState } from "react";
import { useMembers } from "../../../../hooks/useUsers.new";

const usePlanSelection = ({ billingFormData, dispatchBilling }) => {
  const { data: members } = useMembers("member");
  const [activeMemberCount, setActiveMemberCount] = useState();

  useEffect(() => {
    let count = 0;
    if (members?.length) {
      members?.forEach((member) => {
        if (member?.active) {
          count += 1;
        }
      });
    }
    setActiveMemberCount(count);
  }, [members]);

  const billingCycleMonthlyText = `$${billingFormData?.planSelection?.monthlyCost} Monthly Per user`;
  const billingCycleYearlyText = `$${billingFormData?.planSelection?.annualCost} Annually Per user`;
  const billingCycleText =
    billingFormData?.billingCycle === "monthly"
      ? billingCycleMonthlyText
      : billingCycleYearlyText;

  const changePlan = (val) => {
    dispatchBilling({
      type: "planSelection",
      value: val,
    });
  };

  const changeBillingCycle = (val) => {
    dispatchBilling({
      type: "billingCycle",
      value: val, // "monthly" || "annual"
    });
  };

  const changeUserCount = (val) => {
    dispatchBilling({
      type: "userCount",
      value: val, // number
    });
  };

  // User count must fall in the selected plan, and not be lower than the amount of Active users
  const selectedPlanMinUsers = billingFormData?.planSelection?.minUsers;
  const selectedPlanMaxUsers = billingFormData?.planSelection?.maxUsers;
  const userCountError =
    billingFormData?.userCount > selectedPlanMaxUsers ||
    billingFormData?.userCount < selectedPlanMinUsers ||
    billingFormData?.userCount < activeMemberCount;

  return {
    billingFormData,
    changePlan,
    billingCycleText,
    changeBillingCycle,
    changeUserCount,
    userCountError,
  };
};

export default usePlanSelection;
