import React from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";
import InnerPageSwitcher from "../InnerPageSwitcher/InnerPageSwitcher";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";

const SiteHeader = ({
  fileActionsIcons,
  title,
  isSop,
  dropdownRoutes,
  buttons,
  viewOptions,
  alerts,
  search,
  filter,
  tabs,
  alphabetIndex,
  switchView,
  tempNav,
  customComponent,
  timeOffDisableSort,
  hideDropdownContainer,
  ...props
}) => {
  const { pathname: currentRoute } = useLocation();
  const history = useHistory();

  return (
    <>
      <div className="mt-2">
        <div className="flex flex-wrap items-center">
          {!fileActionsIcons && (
            <div className={`flex-initial w-3/4 ${isSop ? "" : "mb-4"}`}>
              <div className="flex flex-col justify-end">
                {tempNav}
                {typeof title === "string" ? (
                  <PrimaryHeader className="ml-0.5">{title}</PrimaryHeader>
                ) : (
                  title
                )}

                {!isSop && !hideDropdownContainer && (
                  <>
                    {dropdownRoutes && (
                      <div className="h-8 mt-2">
                        <InnerPageSwitcher
                          innerRoutes={dropdownRoutes}
                          currentPath={currentRoute}
                          history={history}
                          timeOffDisableSort={timeOffDisableSort}
                          {...props}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {/* avoid showing blank actions button */}
          {!fileActionsIcons && buttons && (
            <div className="flex-initial w-1/4 mb-4">
              <div className="flex justify-end h-12">{buttons}</div>
            </div>
          )}

          {alphabetIndex && <>{alphabetIndex}</>}

          <div className="flex-initial w-1/2 my-auto items-center">
            <div className="flex flex-row items-center mb-2">
              <div className="flex">{viewOptions}</div>
              {switchView && (
                <div className={viewOptions ? "ml-4" : "ml-1"}>
                  {switchView}
                </div>
              )}
            </div>
            {fileActionsIcons && (
              <div
                className="flex justify-start"
                style={{
                  minHeight: alerts || search || filter ? "2.5rem" : "0rem",
                }}
              >
                {alerts}
                {search}
                {filter}
              </div>
            )}
          </div>
          <div className="flex-initial w-1/2">
            {fileActionsIcons || (
              <div
                className="flex justify-end pb-2"
                style={{
                  minHeight: alerts || search || filter ? "2.5rem" : "0rem",
                }}
              >
                {alerts}
                {search}
                {filter}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex justify-between items-center my-2 z-10 ${
          tabs && "border-b-2 border-gray-150"
        }`}
      >
        {customComponent && customComponent}
        {tabs && tabs}
      </div>
    </>
  );
};

SiteHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dropdownRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  buttons: PropTypes.element,
  viewOptions: PropTypes.element,
  alerts: PropTypes.element,
  search: PropTypes.element,
  filter: PropTypes.element,
  switchView: PropTypes.element,
  tempNav: PropTypes.element,
  alphabetIndex: PropTypes.element,
  /**
   * Tabs for siteHeader in Tabbed Container
   */
  tabs: PropTypes.arrayOf(PropTypes.node),
  isSop: PropTypes.bool,
  customComponent: PropTypes.element,
  timeOffDisableSort: PropTypes.bool,
  hideDropdownContainer: PropTypes.bool,
  fileActionsIcons: PropTypes.element,
};

SiteHeader.defaultProps = {
  title: "",
  dropdownRoutes: undefined,
  buttons: undefined,
  viewOptions: undefined,
  alerts: undefined,
  search: undefined,
  filter: undefined,
  switchView: undefined,
  tempNav: undefined,
  alphabetIndex: undefined,
  tabs: undefined,
  isSop: false,
  customComponent: undefined,
  timeOffDisableSort: false,
  hideDropdownContainer: false,
  fileActionsIcons: undefined,
};

export default SiteHeader;
