import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import CreateContactForm from "../ContactForm/CreateContactForm";

const CreateContactModal = ({
  contactType,
  isOpen,
  onSaveCallback,
  handleModalOpen,
  systemConfiguration,
  users,
  currentUser,
  directReportOptions,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const [companyContacts, setCompanyContacts] = useState([]);
  const [unassignedContacts, setUnassignedContacts] = useState([]);

  useEffect(() => {
    if (users?.length) {
      const companies = users
        ?.filter((user) => user?.kind === "company")
        ?.map((companyContact) => ({
          label: companyContact?.company?.value,
          value: companyContact?.reference,
        }));
      setCompanyContacts(companies);

      const unassignedUsers = users
        ?.filter((user) => user?.kind !== "company" && !user?.company?.value)
        ?.map((nonCompanyContact) => ({
          label: `${nonCompanyContact?.name?.firstName} ${nonCompanyContact?.name?.lastName}`,
          name: nonCompanyContact?.name,
          value: nonCompanyContact?.reference,
          avatar: nonCompanyContact?.avatar,
        }));
      setUnassignedContacts(unassignedUsers);
    }
  }, [users]);

  return (
    <Modal
      title="ADD VENDOR"
      isOpen={isOpen}
      onRequestModalClose={handleModalOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      minWidth="800px"
    >
      <CreateContactForm
        directReportOptions={directReportOptions}
        systemConfiguration={systemConfiguration}
        companyContacts={companyContacts}
        isCompanyContact={contactType === "company"}
        unassignedContacts={unassignedContacts}
        setUnassignedContacts={setUnassignedContacts}
        actionOverride={handleModalOpen}
        onSaveCallback={onSaveCallback}
        currentUser={currentUser}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
      />
    </Modal>
  );
};

CreateContactModal.propTypes = {
  /**
   * type of contact to create
   */
  contactType: PropTypes.string,
  /**
   * alternate cancel functionality when in modal
   */
  onSaveCallback: PropTypes.func,
  systemConfiguration: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.string,
      company: PropTypes.shape({
        value: PropTypes.string,
      }),
    })
  ),
  handleModalOpen: PropTypes.func,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    isAdmin: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  }),
  directReportOptions: PropTypes.arrayOf(PropTypes.shape({})),
  tmpAvatarImg: PropTypes.shape({
    image: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  setTmpAvatarImg: PropTypes.func,
};

CreateContactModal.defaultProps = {
  contactType: undefined,
  onSaveCallback: undefined,
  systemConfiguration: undefined,
  isOpen: false,
  users: [],
  handleModalOpen: undefined,
  currentUser: undefined,
  directReportOptions: [],
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
};

export default CreateContactModal;
