const useBillingContact = ({ dispatchBilling }) => {
  const onChange = (val, type) => {
    dispatchBilling({
      type,
      value: val,
    });
  };
  return { onChange };
};

export default useBillingContact;
