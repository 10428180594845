import { cloneDeep, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_ASSET,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
  EXPENSE_CREATE_MODAL,
  PROJECT_CREATE_MODAL,
} from "../../../constants";
import hasWritePermission from "../../../helpers/Permissions/hasWritePermissions";
import { onUpdateFile } from "../../../helpers/File";
import { toastError, toastMessage } from "../../../helpers/Toast";
import clearSearchField from "../../../helpers/clearSearchField";
import smoothScrollTo from "../../../helpers/smoothScroll";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import useAttributes from "../../../hooks/useAttributes";
import useEditModal from "../../../hooks/useEditModal";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import usePropertyFormReducer from "../../../hooks/usePropertyFormReducer";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import { useUsers } from "../../../hooks/useUsers.new";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";
import { useModalState } from "../../../state/modalState";
import FormAvatar from "../../../stories/Components/Avatar/FormAvatar";
import BetaTag from "../../../stories/Components/BetaTag";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import SpacesList from "../../../stories/Components/SpacesList/index";
import Widget from "../../../stories/Components/Widget/Widget";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import WorkflowTable from "../../../stories/Components/WorkflowTableNew";
import whiteCircleCheckIcon from "../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../stories/assets/images/whiteExclamationIcon.svg";
import CalendarView from "../../Calendar/CalendarView";
import ProjectList from "../../Project/ProjectList";
import ReportsOverview from "../../Reports/ReportOverview";
import Assets from "../Asset/Assets";
import ServiceRequestsOverview from "../ServiceRequests";
import TaskList from "../Task/TaskList";
import PropertyDeleteModal from "./PropertyDeleteModal";
import PropertyDetailView from "./PropertyDetailView";
import PropertyDocuments from "./PropertyDocuments";
import PropertyExpenses from "./PropertyExpenses";
import PropertyFinances from "./PropertyFinances";
import PropertyMaintenance from "./PropertyMaintenance";
import usePropertyViewData from "./usePropertyViewData";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { hasReadPermission } from "../../../helpers/Permissions";
import formatOwnedByForm from "../../../helpers/Format/formatOwnedByForm";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PropertyView = () => {
  const { data: currentUser } = useCurrentUser();
  const history = useHistory();

  const {
    propertyId,
    property,
    isLoading,
    getRequestButtonProps,
    getReportButtonProps,
    patchProperty,
    patchPropertyAsync,
    removeProperty,
  } = usePropertyViewData();

  const [{ modals }, modalDispatch] = useModalState();

  const { data } = useUsers();
  const userDict = data?.userDict;

  const params = useMemo(
    () => ({ association: `Property/${propertyId}` }),
    [propertyId]
  );
  const {
    associatedFiles,
    addFile,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);
  const [, setIsEditModalOpen] = useEditModal();

  const { data: managementConfiguration } = useManagementConfiguration();
  const [measurements] = useAttributes();

  const [editedProperty, dispatch] = usePropertyFormReducer();
  const [editing, setEditing] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);

  const [newAttributes, setNewAttributes] = useState([]);
  const [buttonActions, setButtonActions] = useState([]);

  const [isEditableTab, setIsEditableTab] = useState(false);

  const handleEditClick = useCallback(() => {
    if (!editing) {
      setEditing(true);
      smoothScrollTo("about-property-row-2");
      return;
    }
    setEditing(false);
  }, [editing]);

  const resetPropertyState = useCallback(() => {
    dispatch({
      type: "reset",
      property,
    });
  }, [property, dispatch]);

  useEffect(() => {
    if (property && !isEqual(property, editedProperty)) {
      resetPropertyState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, resetPropertyState]);

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...property.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        property.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProperty({ updatedProperty, property });
      // update associated files state
      addFiles(imageResources);
    },
    [addFiles, patchProperty, property]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      const updatedFiles = property.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(property?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : property.primaryImage;
      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage: primary,
      };

      patchProperty(
        { updatedProperty, property },
        {
          onSuccess: () => {
            // update associated files state
            removeFilesAndUpdateApi(imageRefs);
          },
          onError: () => {
            toastError("Error removing media", toastErrorIcon, toastCloseIcon);
          },
        }
      );
    },
    [patchProperty, property, removeFilesAndUpdateApi]
  );

  const updatePropertyFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = property.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(property?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : property.primaryImage;
      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchProperty({ updatedProperty, property });
    },
    [patchProperty, property]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedProperty = {
        ...property,
        primaryImage: imageRef,
      };
      // patch resource
      patchProperty({ updatedProperty, property });
    },
    [patchProperty, property]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const updatedFiles = [
        ...property.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage:
          property?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };
      // patch resource
      patchProperty({ updatedProperty, property });
    },
    [addFiles, patchProperty, property]
  );

  const onFinishEditing = useCallback(
    async (key, val) => {
      const finishedProperty = {
        ...editedProperty,
        ownedBy: formatOwnedByForm(editedProperty),
        tags:
          editedProperty?.currentTags?.map((tag) => tag?.value) ||
          editedProperty?.tags,
        [key]: val,
      };

      // remove unused properties
      delete finishedProperty.brokerRef;
      delete finishedProperty.inspectorRef;

      patchPropertyAsync({ updatedProperty: finishedProperty, property })
        .then(() => {
          toastMessage(
            `Successfully updated Property`,
            toastIcon,
            toastCloseIcon
          );
        })
        .catch(() => {
          toastError(
            "Failed to update property",
            toastErrorIcon,
            toastCloseIcon
          );
        });
    },
    [editedProperty, property, patchPropertyAsync]
  );

  const handleEdit = useCallback(
    (key, value) => {
      dispatch({
        type: "edit",
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleEditTwo = useCallback(
    (key1, key2, value) => {
      dispatch({
        type: "edit2",
        key1,
        key2,
        value,
      });
    },
    [dispatch]
  );

  /**
   * New Handlers
   */

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedProperty = {
            ...property,
            files: [
              ...property?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return patchPropertyAsync({ updatedProperty, property });
        })
        .then((resource) =>
          toastMessage(
            `Recent file successfully attached to ${resource?.title}`,
            toastIcon,
            toastCloseIcon
          )
        )
        .catch(() => {
          toastError(
            `Error attaching recent file`,
            toastErrorIcon,
            toastCloseIcon
          );
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, patchPropertyAsync, property, removeFilesAndUpdateApi]
  );

  const handlePrimaryImageChange = async (fileResource) => {
    const updatedProperty = {
      ...property,
      files: [
        ...property?.files,
        { ref: fileResource.reference, category: fileResource.category },
      ],
      primaryImage: fileResource.reference,
    };
    patchPropertyAsync({ updatedProperty, property })
      .then(() => {
        addFile(fileResource);
        toastMessage(`Primary Image Updated`, toastIcon, toastCloseIcon);
      })
      .catch(() => {
        toastError(
          `Error updating primary image`,
          toastErrorIcon,
          toastCloseIcon
        );
      });
  };

  const handleChangeTitle = useCallback(
    (title) => {
      dispatch({
        type: "title",
        title,
      });
      if (!editing) onFinishEditing("title", title);
    },
    [dispatch, editing, onFinishEditing]
  );

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  /**
   * New Handlers
   */
  // in editing mode, state of save button
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  // in editing mode, detect if resource object has changed
  const changesDetected = useCallback((resource) => {
    const originalProperty = cloneDeep(resource.originalResource);
    const editedProp = cloneDeep(resource);

    delete editedProp.originalResource;
    editedProp.tags = editedProp.currentTags;
    delete editedProp.currentTags;

    const changesFound = !isEqual(editedProp, originalProperty);

    return changesFound;
  }, []);

  // in editing mode, enable/disable save button base on whether resource object has changed
  useEffect(() => {
    if (!property) return;
    const changes = changesDetected(property);
    setDisableSaveBtn(!changes);
  }, [property, editedProperty, setDisableSaveBtn, changesDetected]);

  // set property view tabs
  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: "details",
          title: "Details",
          content: (
            <PropertyDetailView
              editedProperty={editedProperty}
              dispatch={dispatch}
              onChange={handleEdit}
              onChangeAlt={handleEditTwo}
              config={managementConfiguration?.management}
              setNewAttributes={setNewAttributes}
              measurements={measurements}
              newAttributes={newAttributes}
              editing={editing}
              loading={isLoading}
              memberLoading={memberLoading}
              setMemberLoading={setMemberLoading}
              disableEditing={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                )
              }
            />
          ),
        },
      ],
    };

    permissionedTabs.detailsIndex = 0;

    if (hasReadPermission(ASSET, currentUser)) {
      permissionedTabs.tabs.push({
        id: "assets",
        title: "Assets",
        content: <Assets />,
      });
      permissionedTabs.assetIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("project", "can_read")) {
      permissionedTabs.tabs.push({
        id: "projects",
        title: "Projects",
        content: (
          <ProjectList property={property} setActions={setButtonActions} />
        ),
      });
      permissionedTabs.projectsIndex = permissionedTabs.tabs.length - 1;
    }

    permissionedTabs.tabs.push({
      id: "spaces",
      title: "Spaces",
      content: <SpacesList setActions={setButtonActions} />,
    });

    permissionedTabs.spaceIndex = permissionedTabs.tabs.length - 1;

    permissionedTabs.tabs.push({
      id: "media",
      title: "Media",
      content: (
        <ImagesAndVideosWidget
          resource={property}
          disableEditing={
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )
          }
          hasWritePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_property"
          )}
          hasDeletePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_property"
          )}
          handleAddMedia={handleAddMedia}
          handleSetPrimaryMedia={handleSetPrimaryMedia}
          handleRemoveMedia={handleRemoveMedia}
          setActions={setButtonActions}
        />
      ),
    });

    permissionedTabs.mediaIndex = permissionedTabs.tabs.length - 1;

    if (
      currentUser?.hasPermission?.("event", "can_read") ||
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: "calendar",
        title: "Calendar",
        content: <CalendarView currentUser={currentUser} isTabView />,
      });

      permissionedTabs.calendarIndex = permissionedTabs.tabs.length - 1;
    }

    if (
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: "tasks",
        title: "Tasks",
        content: <TaskList />,
      });

      permissionedTabs.taskIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("ticket", "can_read")) {
      permissionedTabs.tabs.push({
        id: "requests",
        title: "Service Requests",
        content: (
          <ServiceRequestsOverview
            association={`Property/${propertyId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });

      permissionedTabs.ticketIndex = permissionedTabs.tabs.length - 1;
    }

    permissionedTabs.tabs.push({
      id: "files",
      title: "Files",
      content: (
        <Widget draggable={false} title={null} overflow backToTop>
          <FilesTable
            files={associatedFiles}
            onAddFilesCallback={onAddFilesCallback}
            removeFilesAndUpdateApi={removeFilesAndUpdateApi}
            onRemoveFilesCallback={updatePropertyFiles}
            setIsEditModalOpen={setIsEditModalOpen}
            resourceName="Property"
            association={`Property/${propertyId}`}
            hasDeletePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )}
            hasWritePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )}
            hasEditPermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )}
            handleFileClone={handleFileClone}
            handleUpdateFile={handleUpdateFile}
          />
        </Widget>
      ),
    });

    permissionedTabs.filesIndex = permissionedTabs.tabs.length - 1;

    if (currentUser?.hasPermission?.("expense", "can_read")) {
      permissionedTabs.tabs.push({
        id: "expenses",
        title: "Expenses",
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <PropertyExpenses
              property={property}
              isTabView
              setButtonActions={setButtonActions}
            />
          </Widget>
        ),
      });
      permissionedTabs.expenseIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("property", "can_read_financials")) {
      permissionedTabs.tabs.push({
        id: "finances",
        title: "Finances",
        content: (
          <PropertyFinances
            editedProperty={editedProperty}
            editing={editing}
            setEditing={setEditing}
            dispatch={dispatch}
            onFinishEditing={onFinishEditing}
          />
        ),
      });
      permissionedTabs.financesIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("workflow", "can_read")) {
      permissionedTabs.tabs.push({
        id: "workflows",
        title: "Workflows",
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <WorkflowTable
              associatedResource={`Property/${propertyId}`}
              setButtonActions={setButtonActions}
              isTabView
            />
          </Widget>
        ),
      });
      permissionedTabs.workflowIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("report", "can_read")) {
      permissionedTabs.tabs.push({
        id: "reports",
        title: (
          <div className="flex">
            <span>Reports</span>
            <BetaTag />
          </div>
        ),
        content: (
          <ReportsOverview
            association={`Property/${propertyId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });

      permissionedTabs.reportIndex = permissionedTabs.tabs.length - 1;
    }

    if (
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: "maintenance",
        title: "Maintenance",
        content: (
          <PropertyMaintenance currentUser={currentUser} userDict={userDict} />
        ),
        isHidden: true,
      });
    }

    permissionedTabs.tabs.push({
      id: "documents",
      title: "Documents",
      content: <PropertyDocuments />,
      isHidden: true,
    });

    return permissionedTabs;
  }, [
    associatedFiles,
    currentUser,
    dispatch,
    editedProperty,
    editing,
    handleAddMedia,
    handleEdit,
    handleEditTwo,
    handleFileClone,
    handleRemoveMedia,
    handleSetPrimaryMedia,
    handleUpdateFile,
    isLoading,
    managementConfiguration?.management,
    measurements,
    memberLoading,
    newAttributes,
    onAddFilesCallback,
    onFinishEditing,
    property,
    propertyId,
    removeFilesAndUpdateApi,
    setIsEditModalOpen,
    updatePropertyFiles,
    userDict,
  ]);

  const { activeTabIndex, setTabIndex } = useWidgetTabNavigation({
    tabs: tabs.tabs,
  });

  const { associationLock } = useRelativeAssociations();
  // Render Tab Specific CTA Button
  /**
   * @TODO - TechDebt: Convert All Create Modals legacy->ESModal(Can open modal from any component)
   * @summary - will remove the need for the button action pingpong
   * Parent (setter) -> Child (btnAction) -> Parent
   */

  const filterButtonActions = useCallback(
    (currentButtonActions) => {
      // Check if each modal is open and store the result
      const isEventModalOpen = modals?.some(
        (modal) => modal?.modalType === CREATE_EVENT_MODAL
      );
      const isTaskModalOpen = modals?.some(
        (modal) => modal?.modalType === CREATE_TASK_MODAL
      );

      // Filter out the button actions based on the modal states
      return currentButtonActions.filter((action) => {
        if (isEventModalOpen && action.title === "Add Event") return false;
        if (isTaskModalOpen && action.title === "Add Task") return false;

        return true;
      });
    },
    [modals]
  );

  const ActionButton = useMemo(() => {
    // By default, hide the edit pencil
    setIsEditableTab(false);
    const calendarButtonArray = [];
    // Spreadable Prop Object
    let buttonProps = {};
    switch (activeTabIndex) {
      case tabs.assetIndex:
        buttonProps = {
          className: !hasWritePermission(ASSET, currentUser) && "hidden",
          onClick: () => {
            const id = uuidv4();
            modalDispatch({
              type: ADD_OPEN_MODAL,
              modalData: {
                item: {
                  propertyId,
                  association: property?.reference,
                  associationType: "Property",
                  disableAssociation: true,
                },
              },
              ref: { id },
              modalType: CREATE_ASSET,
            });
          },
          addButton: true,
          resourceName: "Asset",
        };
        break;
      case tabs.projectsIndex:
        buttonProps = {
          className:
            !currentUser?.hasPermission(
              "administrative",
              "can_write_project"
            ) && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalData: { property },
              modalType: PROJECT_CREATE_MODAL,
            });
          },
          addButton: true,
          resourceName: "Project",
        };
        break;
      case tabs.calendarIndex:
        if (currentUser?.hasPermission?.("event", "can_write")) {
          calendarButtonArray.push({
            title: "Add Event",
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock: false },
                modalType: CREATE_EVENT_MODAL,
              }),
          });
        }
        if (currentUser?.hasPermission?.("task", "can_create")) {
          calendarButtonArray.push({
            title: "Add Task",
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock },
                modalType: CREATE_TASK_MODAL,
              });
            },
          });
        }

        if (calendarButtonArray?.length) {
          buttonProps = {
            dropdownItems: filterButtonActions(calendarButtonArray),
            title: "Actions",
            className: "dropdown-btn",
            large: true,
          };
        }
        break;
      case tabs.expenseIndex:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.("expense", "can_write") && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalType: EXPENSE_CREATE_MODAL,
              modalData: {
                viewMode: "create",
                associationLock: `Property/${propertyId}`,
              },
            });
          },
          addButton: true,
          resourceName: "Expense",
        };
        break;
      case tabs.workflowIndex: {
        buttonProps = {
          className:
            !currentUser?.hasPermission?.("workflow", "can_create") && "hidden",
          onClick: () => {
            buttonActions.find((opt) => opt.title === "Add Workflow").onClick();
          },
          addButton: true,
          resourceName: "Workflow",
        };
        break;
      }
      case tabs.taskIndex:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.("task", "can_create") && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalData: { associationLock, to: "task_list" },
              modalType: CREATE_TASK_MODAL,
            });
          },
          addButton: true,
          resourceName: "Task",
        };
        break;
      case tabs.spaceIndex:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            ) && "hidden",
          onClick: () => {
            buttonActions.find((opt) => opt.title === "Add Space").onClick();
          },
          addButton: true,
          resourceName: "Space",
        };
        break;
      case tabs.ticketIndex: {
        buttonProps = {
          ...getRequestButtonProps(buttonActions),
          resourceName: "Ticket",
          className:
            !currentUser?.hasPermission?.("ticket", "can_create") && "hidden",
        };
        break;
      }
      case tabs.reportIndex: {
        buttonProps = {
          ...getReportButtonProps(buttonActions),
          className:
            !currentUser?.hasPermission?.("report", "can_write") && "hidden",
          resourceName: "Report",
        };
        break;
      }
      case tabs.mediaIndex:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            ) && "hidden",
          addButton: true,
          resourceName: "Media",
          onClick: () => document.getElementById("upload-form-input").click(),
        };
        break;
      case tabs.filesIndex:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            ) && "hidden",
          addButton: true,
          resourceName: "File",
          onClick: () =>
            document
              .querySelector(`div.property-table-view .upload_area_click`)
              ?.click(),
        };
        break;
      case tabs.financesIndex: {
        if (currentUser?.hasPermission?.("property", "can_update_financials")) {
          setIsEditableTab(true);
        }

        return null;
      }
      case tabs.detailsIndex: {
        const options = [];
        if (
          currentUser?.hasPermission?.("administrative", "can_write_property")
        ) {
          setIsEditableTab(true);
        }

        if (
          currentUser?.hasPermission?.("administrative", "can_delete_property")
        ) {
          options.push({
            title: "Delete Property",
            onClick: () => setShowDeleteModal(true),
          });
        }
        buttonProps = {
          dropdownItems: options,
          title: "Actions",
          className: "dropdown-btn",
          large: true,
        };

        if (options.length === 0) return null;
        break;
      }
      default:
        buttonProps = {
          dropdownItems: [buttonActions.find((opt) => !opt.tabAction)],
          title: "Actions",
          className: "dropdown-btn",
          large: true,
        };
    }

    // buttonProps is {} if current user does not have permissions
    // for actions performed by the button. if so do not display button
    return !isEmpty(buttonProps) ? <PrimaryButton {...buttonProps} /> : null;
  }, [
    activeTabIndex,
    associationLock,
    buttonActions,
    currentUser,
    filterButtonActions,
    getReportButtonProps,
    getRequestButtonProps,
    modalDispatch,
    property,
    propertyId,
    tabs.assetIndex,
    tabs.calendarIndex,
    tabs.detailsIndex,
    tabs.expenseIndex,
    tabs.filesIndex,
    tabs.financesIndex,
    tabs.mediaIndex,
    tabs.projectsIndex,
    tabs.reportIndex,
    tabs.spaceIndex,
    tabs.taskIndex,
    tabs.ticketIndex,
    tabs.workflowIndex,
  ]);

  // set the return URL in localstorage
  const updateReturnUrl = useCallback(() => {
    const currentHref = {
      pathname: history?.location?.pathname,
      search: history?.location?.search,
    };
    window.localStorage.setItem(
      "propertyGoBackUrl",
      JSON.stringify(currentHref)
    );
  }, [history?.location?.pathname, history?.location?.search]);

  // set tab index and reset search field
  const handleTabClick = useCallback(
    (index, resourceTabs, resource) => {
      setTabIndex(index);
      clearSearchField(index, resourceTabs, resource);
      updateReturnUrl();
    },
    [setTabIndex, updateReturnUrl]
  );

  /**
   * Clean Up Actions CTA Menu
   */

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing={
                currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) && editing
              }
              disabled={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) || !editing
              }
              image={property?.primaryImage}
              loading={!property?.title}
              onChange={handlePrimaryImageChange}
              resourceName="Property"
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={property?.title}
              editing={editing}
              loading={!property?.title}
              disabled={!property?.title}
              fontWeight="bold"
              color="gray-650"
              onConfirm={handleChangeTitle}
              onChangeCallback={handleChangeTitle}
              hidePencil
              isHeaderTitle
              autoFocus
            />
          </div>
        }
        buttons={ActionButton}
      />

      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={isEditableTab && handleEditClick}
        onFinishEditing={onFinishEditing}
        tabs={tabs?.tabs}
        loading={isLoading}
        disableEditing={
          !currentUser?.hasPermission?.("administrative", "can_write_property")
        }
        activeIndex={activeTabIndex}
        onTabClick={(index) => {
          handleTabClick(index, tabs, "Property");
        }}
        resetResourceState={resetPropertyState}
        disableSaveBtn={disableSaveBtn}
        hideBottomCancelSaveButtons
      />
      <PropertyDeleteModal
        property={property}
        removeProperty={removeProperty}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

PropertyView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
  userEvents: PropTypes.shape({}),
  updateUserEvent: PropTypes.shape({}),
  reloadEvents: PropTypes.func,
};

PropertyView.defaultProps = {
  currentUser: undefined,
  userEvents: undefined,
  updateUserEvent: undefined,
  reloadEvents: undefined,
};

export default PropertyView;
