/* eslint-disable no-param-reassign */
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PTO_TYPE_DISPLAY } from "../../../../constants";
import Dropdown from "../../../../stories/Components/Dropdown/Dropdown";
import Avatar from "../../../../stories/Components/Message/Avatar";
import useTimeOffUserViewData from "./useTimeOffUserViewData";
/**
 * Graphic Assets
 */
import { useAppState } from "../../../../state/appState";
import arrowLeftIcon from "../../../../stories/assets/images/arrowLeftIcon.png";
import circleCheckGreenIcon from "../../../../stories/assets/images/circleCheckGreenIcon.png";
import clockIcon from "../../../../stories/assets/images/clockIconGreen.svg";
import editIcon from "../../../../stories/assets/images/editDarkenedGreenIcon.svg";
import redCircleCross from "../../../../stories/assets/images/redCircleCross.svg";
import deleteIcon from "../../../../stories/assets/images/trashDarkenedGreenIcon.svg";

import hideEditButtons from "../../../../helpers/Timesheets/hideEditButtons";
import { getUserFirstAndInitial } from "../../../../helpers/Utilities";
import usePagePersistance from "../../../../hooks/usePagePersistence";
import SaveButton from "../../../../stories/Components/Buttons/SaveButton";

import BudgetTableNotesCell from "../../../../stories/Components/BudgetTable/BudgetTableNotesCell";
import IconButton from "../../../../stories/Components/Buttons/IconButton";
import PtoButton from "../../../../stories/Components/TimeOff/PtoButton/PtoButton";
import PureTimeOffHistoryTable from "../../../../stories/Components/TimeOff/PureTimeOffHistoryTable";
import getDateRange from "../../../../helpers/Timesheets/getDateRange";
import convertFromCamelCase from "../../../../helpers/Format/convertFromCamelCase";

const currentYear = new Date().getFullYear();

const getStatusIcon = (status) => {
  if (["cancelled", "declined"].includes(status)) {
    return (
      <img
        src={redCircleCross}
        alt="warning"
        className="inline-block w-3 h-3 mb-1"
      />
    );
  }
  if (status === "approved") {
    return (
      <img
        src={circleCheckGreenIcon}
        alt="approved"
        className="inline-block w-3 h-3 mb-1"
      />
    );
  }
  return (
    <img src={clockIcon} alt="pending" className="inline-block w-3 h-3 mb-1" />
  );
};

const TimeOffUserView = () => {
  const {
    data,
    period,
    loading,
    ptoAvailBtns,
    timeSheetsAlreadyApproved,
    openGiveTimeOffModal,
    openRequest,
    openCancel,
  } = useTimeOffUserViewData();

  const [{ userDict }] = useAppState();
  const { pageState, setPersistentPageItem } = usePagePersistance();
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (pageState?.timesheet?.userId !== "" && userDict) {
      setSelectedUser(
        (prev) => userDict[`User/${pageState?.timesheet?.userId}`] ?? prev
      );
    }
  }, [pageState?.timesheet?.userId, userDict]);

  const [ptoFilterToggle, setPtoFilterToggle] = useState({
    type: null,
    selectedYear: {
      label: currentYear.toString(),
      value: currentYear.toString(),
    },
    toggle: (key, val) =>
      setPtoFilterToggle((prev) => ({
        ...prev,
        [key]: val === prev.type ? undefined : val,
      })),
  });

  const columns = useMemo(() => {
    const availableColumns = [
      {
        Header: "Date Requested",
        className: "",
        accessor: "dateRequested",
        minWidth: 250,
        Cell: ({ value }) => (
          <span className="truncate text-gray-300">
            {moment(value).format("LL")}
          </span>
        ),
      },
      {
        Header: "Date Range",
        className: "",
        minWidth: 390,
        accessor: ({ dates }) => getDateRange(dates),
        Cell: ({ value }) => <p className="truncate text-gray-300">{value}</p>,
      },
      {
        Header: "First Day",
        className: "",
        accessor: "firstDay",
        Cell: ({ value }) => <p className="truncate text-gray-300">{value}</p>,
      },
      {
        Header: "Amount",
        className: "",
        accessor: "totalHoursRequested",
        Cell: ({ value }) => (
          <p className="truncate text-gray-300">
            {value !== undefined ? `${value} hours` : "N/A"}
          </p>
        ),
      },
      {
        Header: "Type",
        className: "",
        accessor: "type",
        maxWidth: 100,
        minWidth: 200,
        Cell: ({ value }) => (
          <p className="truncate flex w-full justify-start text-gray-300">
            {PTO_TYPE_DISPLAY?.[value]}
          </p>
        ),
      },
      {
        Header: "Note",
        className: "",
        accessor: "note",
        maxWidth: 80,
        minWidth: 80,
        Cell: ({ value }) => <BudgetTableNotesCell value={value} left />,
      },
      {
        Header: "Status",
        className: "",
        maxWidth: 100,
        minWidth: 100,
        accessor: "statusInformation",
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => (
          <div className="inline-flex gap-2 relative capitalize moreInfo text-gray-300">
            {status}
            <div className="rounded-lg shadow-medium-lift text-gray-300 bg-white p-3 text-center">
              {getStatusIcon(status)}
              <br />
              {value}
            </div>
          </div>
        ),
      },
    ];

    if (pageState?.timesheet?.userId) {
      availableColumns.push({
        Header: "Action",
        accessor: "",
        className: "",
        maxWidth: 80,
        minWidth: 80,
        Cell: ({ row }) => {
          if (hideEditButtons(row?.original, timeSheetsAlreadyApproved))
            return null;

          return (
            <div className="flex gap-5 w-full justify-center mr-2">
              <IconButton
                type="button"
                onClick={() => {
                  openRequest(row?.original, data?.id, data?.metadata);
                }}
                icon={editIcon}
              >
                Modify
              </IconButton>
              <IconButton
                type="button"
                onClick={() => openCancel(row)}
                icon={deleteIcon}
              >
                Cancel
              </IconButton>
            </div>
          );
        },
      });
    }
    return availableColumns;
  }, [
    data?.id,
    data?.metadata,
    openCancel,
    openRequest,
    pageState?.timesheet?.userId,
    timeSheetsAlreadyApproved,
  ]);

  // Save only the years in a unique array
  const years = useMemo(() => {
    const yearsList = [];
    data?.requests?.forEach((entry) => {
      const firstDay = entry?.dates?.[0]?.date;
      const endDate =
        entry?.dateRange?.includes("to") &&
        moment(entry?.dateRange?.split(" to ")?.[1]).year();

      const firstDayYear = moment(firstDay).format("YYYY");
      if (endDate !== firstDay && !yearsList.includes(firstDayYear)) {
        yearsList.push(firstDayYear);
      }
      if (endDate !== firstDay && !yearsList.includes(endDate)) {
        yearsList.push(endDate);
      }
    });
    return yearsList?.filter((item) => item?.length);
  }, [data?.requests]);

  const handleBackToOverviewClick = useCallback(() => {
    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      userId: "",
    });
  }, [pageState?.timesheet, setPersistentPageItem]);

  return (
    // container
    <div className="flex flex-col w-full">
      {/* Container to apply consistent Gap to rows */}
      <div className="flex flex-col w-full h-full gap-3">
        {/* Header: Avatar and Save Button Row */}
        <div className="flex flex-row justify-between items-center h-full mt-6">
          <div className="flex gap-2 items-center">
            <Avatar
              className="w-14 h-14 mr-3"
              fontClassName="text-3xl"
              name={getUserFirstAndInitial(selectedUser)}
              avatar={selectedUser?.avatar}
              isExternalUser
              id={selectedUser?.id}
            />
            <p className="text-xl font-medium">
              {getUserFirstAndInitial(selectedUser)}
            </p>
            <div style={{ minWidth: "125px" }}>
              <Dropdown
                placeholder="Filter by year"
                options={years.map((x) => ({ label: x, value: x }))}
                value={ptoFilterToggle.selectedYear}
                onChange={(val) => {
                  ptoFilterToggle.toggle("selectedYear", val);
                }}
                disableClear
              />
            </div>
          </div>
          {pageState?.timesheet?.tab === "user-timeoff" ? (
            <div className="items-center justify-center flex">
              <SaveButton
                saveButtonTitle="Add Request"
                onClick={openRequest}
                large
                disabled={loading}
              />
            </div>
          ) : (
            <div className="flex gap-2 justify-center items-center">
              <button
                className="flex self-start items-center"
                style={{ width: "118.1px", height: "40px" }}
                onClick={handleBackToOverviewClick}
                type="button"
              >
                <img
                  src={arrowLeftIcon}
                  alt="Back"
                  style={{ width: "10px", height: "10px" }}
                />
                <span
                  className="ml-2 font-semibold"
                  style={{ color: "#101010" }}
                >
                  Back
                </span>
              </button>

              <SaveButton
                saveButtonTitle="Give Time Off"
                onClick={openGiveTimeOffModal}
                large
                disabled={loading}
              />

              <SaveButton
                saveButtonTitle="Add Request"
                onClick={openRequest}
                large
                disabled={loading}
              />
            </div>
          )}
        </div>

        <div className="flex flex-wrap justify-end gap-2">
          {Object.values(ptoAvailBtns).map((item) => {
            return (
              <PtoButton
                title={convertFromCamelCase(item?.name)}
                key={item?.id}
                value={item?.available ?? 0}
                active={ptoFilterToggle.type === `${item?.name}`}
                onClick={() => ptoFilterToggle.toggle("type", `${item?.name}`)}
              />
            );
          })}
        </div>
      </div>
      <PureTimeOffHistoryTable
        data={data?.requests}
        columns={columns}
        year={ptoFilterToggle.selectedYear.value}
        type={ptoFilterToggle.type}
        payrollPeriod={period}
      />
    </div>
  );
};

export default TimeOffUserView;
