import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { isEqual } from "lodash";
import { useProjectsPost } from "../../../hooks/projects";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import { toastError } from "../Toast/Toast";
import { PROJECT_EMPTY, PROJECT_STATUS_TYPES } from "../../../constants";
import useCreateProjectReducer from "../../../hooks/useCreateProjectReducer";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useFilesPost from "../../../hooks/useFilesPost";
import uploadAvatar from "../../../helpers/uploadAvatar";

const useProjectCreateModalData = ({ modalData }) => {
  const { data: currentUser } = useCurrentUser();

  const history = useHistory();
  const { mutate } = useProjectsPost();

  // Mutation hook to post files
  const { mutateAsync: postFiles } = useFilesPost();

  const { data: managementConfiguration } = useManagementConfiguration();

  const [project, dispatch] = useCreateProjectReducer();

  const [showConfirm, setShowConfirm] = useState(false);
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});

  useEffect(() => {
    const isSame = isEqual(PROJECT_EMPTY, project);
    setShowConfirm(!isSame);
  }, [project]);

  /**
   * Create project in the backend
   * @param {Object} newProject - Project to create
   */
  const addProject = (newProject) => {
    mutate(newProject, {
      onSuccess: (data) => {
        // Redirect to project single view
        if (!modalData?.isQuickAdd)
          history.push(getSingleResourcePath(data.reference));
      },
      onError: () => {
        toastError("Project could not be created. Please try again");
      },
    });
  };

  const createButtonHandler = async () => {
    const projectManagerRole =
      managementConfiguration?.management?.project?.memberPositions?.find(
        (elem) => !elem?.custom && elem?.display === "Project Manager"
      )?.id;

    const projectData = Object.entries(project)
      .filter(([, value]) => !!value)
      .reduce((obj, entry) => {
        const [entryKey, entryValue] = entry;
        let newVal;
        if (entryKey === "hoursOfOperation") {
          newVal = Object.entries(entryValue)
            .map(([day, value]) => {
              return {
                [day]: value ?? { active: false, from: "", to: "" },
              };
            })
            .reduce((body, item) => {
              const [key, value] = Object.entries(item)[0];
              return { ...body, [key]: value };
            }, {});
        } else if (entryKey === "retainage") {
          newVal = [
            ...entryValue?.map((item) => {
              return {
                percentComplete: item.percentComplete,
                percentRetainage: item.percentRetainage,
              };
            }),
          ];
        } else if (entryKey === "insurance") {
          newVal = {
            ...entryValue,
            requirements: entryValue?.requirements?.map((req) => req.text),
          };
        } else if (entryKey === "members") {
          newVal = [
            ...entryValue,
            { user: currentUser.reference, position: projectManagerRole },
          ];
        } else if (entryKey === "duration") {
          return {
            ...obj,
            duration: { ...obj?.duration, value: entryValue },
          };
        } else if (entryKey === "durationType") {
          return {
            ...obj,
            duration: { ...obj?.duration, typeOfDuration: entryValue },
          };
        }
        return { ...obj, [entryKey]: newVal ?? entryValue };
      }, {});

    // save project as draft by default
    projectData.status = PROJECT_STATUS_TYPES.draft;

    const newProjectData = await uploadAvatar({
      tmpAvatarImg,
      resourceState: projectData,
      postFiles,
    });

    addProject(newProjectData);
  };

  return {
    project,
    dispatch,
    addProject,
    showConfirm,
    createButtonHandler,
    tmpAvatarImg,
    setTmpAvatarImg,
  };
};

export default useProjectCreateModalData;
