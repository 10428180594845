import addToCalendarStore from "../../../../helpers/Calendar/addToCalendarStore";

const addToCalendarResponder = (queryClient, calendarData) => {
  return {
    onSuccess: (returnedData) => {
      return addToCalendarStore({
        queryClient,
        calendarData,
        item: returnedData.data,
      });
    },
    onError: (err) => {
      console.error("Failed to create event.", err);
    },
  };
};

export default addToCalendarResponder;
