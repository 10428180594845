import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useGetFile } from "../../../../hooks/useGetFile";

const useChangeStateOfEmploymentModalData = ({ user }) => {
  const { data } = useGetFile(user?.avatar);

  const [isChanged, setIsChanged] = useState(false);
  const [origUser] = useState(user);

  useEffect(() => {
    const itemChanged = !isEqual(origUser, { ...user });
    setIsChanged(itemChanged);
  }, [user, origUser]);

  return { data, origUser, isChanged };
};

export default useChangeStateOfEmploymentModalData;
