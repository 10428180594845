import React from "react";

const Header = ({ buttons, extras, search, className }) => {
  return (
    <div className={`${className} flex`}>
      <div className="flex flex-col w-1/2"> {extras}</div>
      <div className="flex flex-col w-1/2 self-end">
        <div className="flex justify-end">{buttons}</div>
        <div className="self-end">{search}</div>
      </div>
    </div>
  );
};

export default Header;
