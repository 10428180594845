import { useMutation, useQueryClient } from "react-query";
import editCalendarStore from "../../../helpers/Calendar/editCalendarStore";
import { useAppState } from "../../../state/appState";
import { editEventHandler } from "./handlers";
import editEventResponder from "./responders/editEventResponder";

const useEditCalendar = () => {
  const queryClient = useQueryClient();
  const [{ calendarData }] = useAppState();

  const editMutation = useMutation(
    editEventHandler,
    editEventResponder(queryClient, calendarData)
  );

  /**
   * Currently there is no use case to delete from a task list view, so no new requests need to be made. if that changes this file is set up to be modified
   *
   * @param {Props} Item can be Task or Event
   * @param {boolean} newRequest optional defaults to true
   * @returns {Response} the updated item
   */
  const editInCalendar = async (props, newRequest = true) => {
    if (!props.args.originalItem) {
      return console.error(
        "editInCalendar | props.args.originalItem is required: populate with item you are updating"
      );
    }

    if (!props.args.editedItem) {
      return console.error(
        "editInCalendar | props.args.editedItem is required: populate with the original version of the item you are updating"
      );
    }

    if (!props.operation) {
      return console.error(
        "editInCalendar | props.operation is required: populate with $non-recurring, $all, $single or $future"
      );
    }

    if (newRequest === false) {
      return editCalendarStore({
        queryClient,
        variables: props,
        calendarData,
        item: props.args.editedItem, // editedItem is where we will put the new task
      });
    }

    const response = await editMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: editCalendarLoading,
    isError: hasEditCalendarError,
    error: editCalendarError,
    isSuccess: editCalendarSuccess,
  } = editMutation;

  return {
    editInCalendar,
    editCalendarLoading,
    hasEditCalendarError,
    editCalendarError,
    editCalendarSuccess,
  };
};

export default useEditCalendar;
