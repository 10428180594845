import React from "react";
import PropTypes from "prop-types";

import useTaskRelatedTo from "./useTaskRelatedTo";

const TaskRelatedTo = ({ taskRef, setDisableEditRecurrence }) => {
  const { data, onPress, getIcon, getName } = useTaskRelatedTo({
    taskRef,
    setDisableEditRecurrence,
  });

  const renderItem = ({ item }) => {
    return (
      <button
        key={item?.id}
        type="button"
        className="flex flex-row justify-start w-full items-center"
        onClick={() => onPress(item)}
      >
        <div className="flex flex-row gap-2">
          <img src={getIcon(item)} alt={`Navigate to ${getName(item)}`} />
          <p className="text-es-normal text-es-medium-grey">{getName(item)}</p>
        </div>
      </button>
    );
  };

  if (!taskRef || !data?.length) return null;

  return (
    <div>
      <div className="flex flex-col gap-2">
        {data?.length && data?.map((item) => renderItem({ item }))}
      </div>
    </div>
  );
};

TaskRelatedTo.propTypes = {
  taskRef: PropTypes.string,
  setDisableEditRecurrence: PropTypes.func,
};
TaskRelatedTo.defaultProps = {
  taskRef: undefined,
  setDisableEditRecurrence: () => {},
};

export default TaskRelatedTo;
