/**
 * Creates a debounced function that delays invoking the provided asynchronous function until after delay milliseconds have elapsed since the last time the debounced function was invoked.
 * @param {Promise} func - The function to debounce.
 * @param {number} [delay=200] - The number of milliseconds to delay.
 * @returns {Function} - Returns the new debounced function.
 *
 * @example
 * const [textState, setTextState] = useState("")
 * const fetchResults = async (term) => {
 *     const response = await fetch(`https://api.example.com/${term}`);
 *     const data = await response.json();
 *   };
 *
 * const debouncedFetchResults = debounceAsync(fetchResults, 300);
 *
 * const handleChange = (text) = {
 *  setTextState(text)
 *  debouncedFetchResults(text)
 * }
 */

const debounceAsync = (func, delay = 200) => {
  // Declare timeoutId undefined; If its ever defined we need to cancel to not trigger a request
  let timeoutId;
  // Return a function that takes args from the parent function
  return (...args) => {
    return new Promise((res, rej) => {
      if (timeoutId) clearTimeout(timeoutId);
      // Set a timeout that will call the function after the specified delay
      timeoutId = setTimeout(async () => {
        try {
          const result = await func(...args);
          res(result);
        } catch (error) {
          rej(error);
        }
      }, delay);
    });
  };
};

export default debounceAsync;
