import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import useTableGroupReducer from "../../../hooks/useTableGroupReducer";
import FilterPopOver from "../Table/FilterPopOver";
import IconLabel from "../IconLabel/IconLabel";
import filterIconDarkenedGreen from "../../assets/images/filterIconDarkenedGreen.svg";
import crossIconGreen from "../../assets/images/crossIconGreen.svg";
import { useAppState } from "../../../state/appState";

const SiteHeaderFilter = ({
  filterPopOverClassName,
  enableGrouping,
  allColumns,
  tableData,
  filters,
  setAllFilters,
  groupOptions,
  setGroupBy,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [hasActiveFilter, setHasActiveFilter] = useState(false);
  const [groups, groupDispatch] = useTableGroupReducer();
  const ref = useRef(null);
  const btnRef = useRef(null);
  const [{ mainContainerRef }] = useAppState();

  useEffect(() => {
    if (filters.length > 0 || groups.length) setHasActiveFilter(true);
    else setHasActiveFilter(false);
  }, [filters.length, groups.length]);

  const clearAllFiltersAndGroups = () => {
    setAllFilters([]);
    groupDispatch({
      type: "updateAll",
      groups: [],
    });
    setGroupBy([]);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      clearAllFiltersAndGroups();
    }
  };

  /* btnRef handles case where a user clicks the filter button while the popover is open;
   * defers closing popover to button click instead of it being an outside modal click
   */
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        !btnRef.current ||
        btnRef.current.contains(event.target) ||
        ref.current.contains(event.target)
      ) {
        return;
      }
      setIsFilterOpen(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  /**
   * Track scroll position
   * @Summary - keep filter popover fixed under button
   */
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    const position = mainContainerRef?.scrollTop;
    setScrollPosition(position);
  }, [mainContainerRef?.scrollTop]);

  useEffect(() => {
    mainContainerRef?.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      mainContainerRef?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, mainContainerRef]);

  const popoverPos = useMemo(() => {
    try {
      const {
        x,
        y,
        height: btnHeight,
      } = btnRef?.current?.getBoundingClientRect();
      const { width, height } = document.body.getBoundingClientRect();
      const styles = {
        top: `${btnHeight + y}px`,
        maxHeight: `${height - (btnHeight + y) - 20}px`,
      };
      if (x > width / 2) {
        styles.right = `${width - x}px`;
      } else {
        styles.left = `${x}px`;
      }

      return { ...styles };
    } catch (e) {
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition, ref, btnRef?.current?.getBoundingClientRect()?.x]);

  /**
   * Track scroll position
   */

  return (
    <div className="flex justify-center items-center relative">
      <div className="flex relative">
        <div ref={btnRef}>
          {hasActiveFilter && (
            <div
              className="flex items-center"
              onClick={clearAllFiltersAndGroups}
              onKeyDown={onKeyDown}
              role="button"
              tabIndex={0}
            >
              <img className="h-4 mr-3" src={crossIconGreen} alt="Clear" />
              <p className="font-semibold text-sm text-darkenedGreen">Clear</p>
            </div>
          )}
          {!hasActiveFilter && (
            <IconLabel
              icon={filterIconDarkenedGreen}
              iconStyle={{ width: "16px", height: "18px", marginRight: "4px" }}
              text="Filter"
              onClick={() =>
                btnRef.current ? setIsFilterOpen((prev) => !prev) : () => {}
              }
              textClassName="text-darkenedGreen text-base"
            />
          )}
        </div>
        <div
          className="fixed flex z-20"
          ref={ref}
          style={{
            ...popoverPos,
            transitionProperty: "all",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            transitionDuration: "15ms",
          }}
        >
          {isFilterOpen && (
            <FilterPopOver
              className={filterPopOverClassName}
              enableFiltering
              enableGrouping={enableGrouping}
              onRequestClose={() => setIsFilterOpen(false)}
              allColumns={allColumns}
              tableData={tableData}
              filters={filters}
              setAllFilters={setAllFilters}
              groupOptions={groupOptions}
              setGroupBy={setGroupBy}
              groups={groups}
              groupDispatch={groupDispatch}
              clearAllFiltersAndGroups={clearAllFiltersAndGroups}
              hidePreview
            />
          )}
        </div>
      </div>
    </div>
  );
};

const filtersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  })
);

SiteHeaderFilter.propTypes = {
  filterPopOverClassName: PropTypes.string,
  enableGrouping: PropTypes.bool,
  tableData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    )
  ),
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  filters: filtersPropType,
  setAllFilters: PropTypes.func,
  groupOptions: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      )
    ),
    nonHierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  setGroupBy: PropTypes.func,
};

SiteHeaderFilter.defaultProps = {
  filterPopOverClassName: undefined,
  enableGrouping: false,
  tableData: [],
  allColumns: [],
  filters: [],
  setAllFilters: undefined,
  groupOptions: undefined,
  setGroupBy: () => {},
};

export default SiteHeaderFilter;
