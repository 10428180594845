import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Warning from "../Warning";
import useSubscriptionCancelledWarningData from "./useSubscriptionCancelledWarningData";

const SubscriptionCancelledWarning = ({
  subscriptionDetails,
  dispatchBilling,
}) => {
  const { onClick } = useSubscriptionCancelledWarningData({
    subscriptionDetails,
    dispatchBilling,
  });

  return (
    <Warning
      prompt={
        <span className="flex flex-row text-es-dark-grey text-es-normal font-es-medium tracking-es-wide whitespace-pre pl-3 flex-wrap">
          <p className="text-wrap">
            <span className="text-wrap font-es-semibold">
              Subscription De-activated:
            </span>
            <span className="text-wrap">{` You will lose access to your EstateSpace account permanently in `}</span>
            <span className="text-wrap font-es-semibold">{`${moment(
              subscriptionDetails?.expireDate
            ).diff(moment(), "days")} days`}</span>
            <span className="text-wrap">{` on `}</span>
            <span className="text-wrap font-es-semibold">{`${moment(
              subscriptionDetails?.expireDate
            ).format("MMMM D, YYYY")}\n`}</span>
            <span className="text-wrap">{`You can still `}</span>
            <button
              type="button"
              className="underline tracking-es-wide font-es-medium text-wrap"
              onClick={onClick}
            >
              reactivate your subscription
            </button>
            <span className="text-wrap">{` before it ends. `}</span>
          </p>
        </span>
      }
    />
  );
};

SubscriptionCancelledWarning.propTypes = {
  subscriptionDetails: PropTypes.shape({
    expireDate: PropTypes.string,
    subscriptionId: PropTypes.string,
  }),
  dispatchBilling: PropTypes.func,
};

SubscriptionCancelledWarning.defaultProps = {
  subscriptionDetails: undefined,
  dispatchBilling: undefined,
};

export default SubscriptionCancelledWarning;
