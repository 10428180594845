import React, { useState, useEffect } from "react";
import { NavigationProvider } from "@griffingroupglobal/lumina-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { SettingAPI } from "@griffingroupglobal/eslib-api";
import { AUTH_TOKEN_STORAGE_KEY, TENANT_ID_STORAGE_KEY } from "../../constants";

function LilyProvider({ children }) {
  const { LILY_API_HOST } = window.runConfig;
  const history = useHistory();
  const params = useParams();
  const [authToken, setAuthToken] = useState(
    localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)
  );
  const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);

  useEffect(() => {
    const handleAuthStorageChange = (event) => {
      try {
        const { newAuthToken } = event.detail;
        setAuthToken(newAuthToken);
      } catch (error) {
        console.error(
          `Error reading newAuthToken: ${
            error instanceof Error ? error.message : error
          }`
        );
      }
    };

    window.addEventListener("authStorageChange", handleAuthStorageChange);

    return () => {
      window.removeEventListener("authStorageChange", handleAuthStorageChange);
    };
  }, []);

  return (
    <NavigationProvider
      setNavigate={(to, options) => history.push(to, options)}
      setUseParams={() => params}
      authToken={authToken}
      setUseLocation={useLocation}
      tenantId={tenantId}
      LILY_API_HOST={LILY_API_HOST}
      onBeforeChat={async () => SettingAPI.get()}
    >
      {children}
    </NavigationProvider>
  );
}

export default LilyProvider;
