import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  RECURRENCE_FORM_POPUP,
  recurrenceOptions,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import describeRruleFromString from "../../../../helpers/Calendar/describeRruleFromString";
import getParentAssociationTimezone from "../../../../helpers/Calendar/getParentAssociationTimezone";
import generateRruleFromSelection from "../../../../helpers/Date/generateRruleFromSelection";
import updateTimeValueOnDate from "../../../../helpers/Date/updateTimeValueOnDate";
import { useProjectsOverview } from "../../../../hooks/projects";
import { usePropertiesOverview } from "../../../../hooks/properties";
import useAssociatedEvents from "../../../../hooks/useAssociatedEvents";
import useAssociatedMembersAndContactsForEventTask from "../../../../hooks/useAssociatedMembersAndContactsForEventTask";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useGetTags } from "../../../../hooks/useTags";
import { useAppState } from "../../../../state/appState";

const useCreateEditEventFormData = ({
  formData,
  recurrenceDropDownRef,
  isEdit,
}) => {
  const { formState, updateFormState } = formData;
  const { data: createdBy } = useCurrentUser();
  const initialDayDif = moment(formState.endDate).diff(
    moment(formState.startDate),
    "days"
  );
  const [durationDays, setDurationDays] = useState(initialDayDif || 0);
  const [durationMinutes, setDurationMinutes] = useState(30);

  const [recurrenceString, setRecurrenceString] = useState(
    describeRruleFromString(formState?.recurrence) || ""
  );

  const [attendeePopupOpen, setAttendeePopupOpen] = useState(false);
  const [fullOptions, setFullOptions] = useState([]);
  const [associationLocked, setAssociationLocked] = useState(false);

  const { data: tagsData } = useGetTags();

  const INITIAL_FILES = {
    mediaFilesToAdd: [],
    nonMediaFilesToAdd: [],
  };

  const [filesState, setFilesState] = useState(INITIAL_FILES);
  const [{ userDict, userEvents }, appStateDispatch] = useAppState();
  const { propertiesDict, propertiesDD } = usePropertiesOverview();
  const { projectDict, projectsDD: projectDD } = useProjectsOverview();

  const { association, associationRef, spaceLock, timezoneLock } =
    useAssociatedEvents(userEvents);

  // Get association members and all system contacts
  const invitesForDropdown = useAssociatedMembersAndContactsForEventTask({
    eventForm: formState,
  });

  // the effect to check a locked association should only happen once
  const triggerRef = useRef(false);

  useEffect(() => {
    // in create modal
    // if association is not an empty object, lock the association dropdown
    if (
      !isEdit &&
      Object.keys(association).length > 0 &&
      triggerRef.current === false
    ) {
      triggerRef.current = true;

      setAssociationLocked(true);

      if (spaceLock) {
        updateFormState({
          association: associationRef,
          spaces: [
            {
              id: spaceLock,
              isCompleted: true,
            },
          ],
          timezone: timezoneLock,
        });
      } else {
        updateFormState({
          association: associationRef,
          timezone: timezoneLock,
        });
      }
    }
  }, [
    association,
    associationRef,
    isEdit,
    spaceLock,
    timezoneLock,
    updateFormState,
  ]);

  useEffect(() => {
    // set the full options for the association dropdown
    setFullOptions(propertiesDD.concat(projectDD));
  }, [projectDD, propertiesDD]);

  // function to help tell the difference in days
  const calculateDaysDifference = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "days");
  };

  // function to help tell the difference in minutes
  const calculateMinutesDifference = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), "minutes");
  };

  const handleRecurrenceSubmit = (value) => {
    updateFormState({ recurrence: value.value.value.split("\n")[1] });
  };

  const openCustomRecurrencePopup = () => {
    const rect = recurrenceDropDownRef.current.getBoundingClientRect();
    const { x, y, width, height } = rect;

    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        x: x - width / 2,
        y: y - height / 2,
      },
      popupData: {
        dispatch: handleRecurrenceSubmit,
        noMaxHeight: true,
        setRecurrenceString,
        item: formState,
      },
      popupType: RECURRENCE_FORM_POPUP,
    });
  };

  // function to handle all form changes
  const handleFormChange = (type, value) => {
    switch (type) {
      case "name":
        updateFormState({ name: value });
        break;
      case "description":
        updateFormState({ description: value });
        break;
      case "startDate": {
        // preserve the time value already selected by user
        const updatedValue = updateTimeValueOnDate(
          value,
          formState.startDate,
          formState.timezone
        );
        const newOffset = moment
          .tz(updatedValue, formState.timezone)
          .utcOffset();
        const formattedDate = moment(updatedValue)
          .utcOffset(newOffset, true)
          .format();
        const formattedEndDate = moment(updatedValue)
          .utcOffset(newOffset, true)
          .add(durationDays, "days")
          .add(durationMinutes, "minutes")
          .format();

        const startDate = moment.tz(formattedDate, formState.timezone);
        let endDate = moment.tz(formattedEndDate, formState.timezone);

        // Event must end after it starts.
        // `endDate` will be set to the same `startDate` but with 1 hour more
        if (moment(endDate).isBefore(startDate)) {
          endDate = moment.tz(startDate, formState.timezone).add(1, "hours");
        }

        updateFormState({
          startDate,
          endDate,
        });
        break;
      }
      case "startTime": {
        const newOffset = moment.tz(value, formState.timezone).utcOffset();
        const formattedDate = moment(value).utcOffset(newOffset, true).format();
        const formattedEndDate = moment(value)
          .utcOffset(newOffset, true)
          .add(durationMinutes, "minutes")
          .format();

        updateFormState({
          startDate: formattedDate,
          endDate: formattedEndDate,
        });
        break;
      }

      case "endDate": {
        // preserve the time value already selected by user
        const updatedValue = updateTimeValueOnDate(
          value,
          formState.endDate,
          formState.timezone
        );

        const newOffset = moment
          .tz(updatedValue, formState.timezone)
          .utcOffset();
        const formattedDate = moment(updatedValue)
          .utcOffset(newOffset, true)
          .format();

        setDurationDays(
          calculateDaysDifference(formState.startDate, formattedDate)
        );

        let startDate = moment.tz(formState.startDate, formState.timezone);
        const endDate = moment.tz(formattedDate, formState.timezone);

        // Event must end after it starts.
        // `startDate` will be set to the same `endDate` but with 1 hour less
        if (moment(endDate).isBefore(startDate)) {
          startDate = moment
            .tz(endDate, formState.timezone)
            .subtract(1, "hours");
        }

        updateFormState({ startDate, endDate });
        break;
      }

      case "endTime": {
        const newOffset = moment.tz(value, formState.timezone).utcOffset();
        const formattedDate = moment(value).utcOffset(newOffset, true).format();

        setDurationMinutes(
          calculateMinutesDifference(formState.startDate, formattedDate)
        );
        updateFormState({ endDate: formattedDate });
        break;
      }

      case "association": {
        // set timezone
        const parentTimezone = getParentAssociationTimezone(
          value.value,
          propertiesDict,
          projectDict
        );

        updateFormState({
          association: value.value,
          invitees: [],
          spaces: [],
          // set timezone when association changes
          timezone: parentTimezone,
        });
        break;
      }
      case "add step":
        updateFormState({
          steps: [
            ...formState.steps,
            {
              name: "",
              description: "",
              sop: "",
              date: moment().format(),
              id: uuidv4(),
            },
          ],
        });
        break;
      case "space":
        updateFormState({ space: value });
        break;
      case "select recurrence":
        if (value.value === "custom repetition") {
          openCustomRecurrencePopup();
          break;
        } else {
          updateFormState({
            recurrence: generateRruleFromSelection(value),
          });
          setRecurrenceString("");
        }

        break;
      default:
        break;
    }
  };

  const startDate = useMemo(() => {
    const { isAllDay } = formData.allDayData;
    // if this is not an all da event return data as is
    if (!isAllDay) {
      return new Date(
        moment.tz(formState.startDate, formState.timezone).format()
      );
    }

    // if this is an all day event return the start of the day
    return new Date(
      moment.tz(formState.startDate, formState.timezone).startOf("day").format()
    );
  }, [formData.allDayData, formState]);

  const endDate = useMemo(() => {
    const { isAllDay } = formData.allDayData;

    // if this is not an all da event return data as is
    if (!isAllDay) {
      return new Date(
        moment.tz(formState.endDate, formState.timezone).format()
      );
    }
    // if this is an all day event return the start of the day
    return new Date(
      moment.tz(formState.endDate, formState.timezone).startOf("day").format()
    );
  }, [formData.allDayData, formState.endDate, formState.timezone]);

  // Current Members Invited to Event
  const currentInvitees = useMemo(() => {
    return formState.invitees.map((userRef) => userDict?.[userRef]);
  }, [formState.invitees, userDict]);

  const handleRemoveInvitee = (userRef) => {
    updateFormState({
      invitees: formState.invitees.filter((ref) => ref !== userRef),
    });
  };

  const handleAddInvitee = (userData) => {
    updateFormState({ invitees: [...formState.invitees, userData.reference] });
  };

  const locationsAvailable = useMemo(() => {
    return (
      formState?.association?.includes("Project") ||
      formState?.association?.includes("Property")
    );
  }, [formState?.association]);

  const resourceDropdownValue = () => {
    return fullOptions.find((option) => option.value === formState.association);
  };

  const removeFormFileDuplicates = useCallback(() => {
    // get all objects from files state
    const allFilesObjects = [
      ...filesState.nonMediaFilesToAdd,
      ...filesState.mediaFilesToAdd,
    ];

    // get all ids from files state
    const allFilesIds = allFilesObjects.map((file) => file.id);

    // filter out files from form that are in files state
    const newFiles = formState?.files?.filter(
      (file) => !allFilesIds.includes(file.id)
    );

    // return form with new files
    return {
      ...formState,
      files: newFiles,
    };
  }, [filesState, formState]);

  const handleFilesToAdd = (files, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = [...prev.mediaFilesToAdd, ...files];
      } else {
        updatedFilesState.nonMediaFilesToAdd = [
          ...prev.nonMediaFilesToAdd,
          ...files,
        ];
      }

      return updatedFilesState;
    });

    updateFormState({
      files: [...formState.files, ...files],
    });
  };

  // handle removing files from media selector
  const handleFilesToRemove = (fileToRemove, type = "media") => {
    setFilesState((prev) => {
      const updatedFilesState = { ...prev };

      if (type === "media") {
        updatedFilesState.mediaFilesToAdd = prev.mediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      } else {
        updatedFilesState.nonMediaFilesToAdd = prev.nonMediaFilesToAdd.filter(
          (file) => file !== fileToRemove
        );
      }

      if (fileToRemove?.reference) {
        const newFormFiles = formState?.files.filter(
          (file) => file?.reference !== fileToRemove?.ref
        );

        updateFormState({
          files: newFormFiles,
        });
      }

      return updatedFilesState;
    });

    updateFormState({
      files: formState.files.filter((file) => file !== fileToRemove),
    });
  };

  const handleRemoveSpaces = (space) => {
    const spaces = formState?.spaces?.filter((item) => item?.id !== space);
    updateFormState({ spaces: [...(spaces ?? [])] });
  };

  const handleSelectSpaces = (space) => {
    const spaces = [
      ...(formState?.spaces ?? []),
      { id: space, isCompleted: false },
    ];
    updateFormState({ spaces: [...spaces] });
  };

  const handleTagsDispatch = (value) => {
    const { currentTags } = value;
    updateFormState({ tags: currentTags });
  };

  // format tags to have text and value
  const updatedTags = formState?.tags?.map((tag) => {
    if (tagsData?.tagsDict[tag]) {
      const labelText = tagsData?.tagsDict[tag].label;
      return { label: labelText, value: tag };
    }
    // if the tag is not in the tags dictionary, return the tag as is tag/<id>
    return tag;
  });

  // update form state with the updated tags for save and editing
  formState.tags = updatedTags;

  // Handle Links
  const handleAddLink = (value) => {
    // update formState.links with link {id : uuid, name: "", url: ""}
    updateFormState({ links: [...formState?.links, { ...value }] });
  };

  const handleRemoveLink = (id) => {
    // receives link.id from useLinksModalWidget.js
    const updatedLinks = formState?.links?.filter((link) => link.id !== id);
    // update formState without giving it the previous links and only the updated
    updateFormState({ links: [...updatedLinks] });
  };

  return {
    formState,
    startDate,
    endDate,
    recurrenceOptions,
    recurrenceString,
    currentInvitees,
    attendeePopupOpen,
    invitesForDropdown,
    projectDD,
    propertiesDD,
    locationsAvailable,
    allDayData: formData.allDayData,
    filesState,
    associationLocked,
    handleTagsDispatch,
    handleFilesToRemove,
    handleFilesToAdd,
    removeFormFileDuplicates,
    resourceDropdownValue,
    handleAddInvitee,
    handleRemoveInvitee,
    setAttendeePopupOpen,
    handleFormChange,
    handleSelectSpaces,
    handleRemoveSpaces,
    handleAddLink,
    handleRemoveLink,
    createdBy,
  };
};

export default useCreateEditEventFormData;
