import { useMutation, useQueryClient } from "react-query";
import editTsListStatusHandler from "./timesheetHandlers/editTsListStatusHandler";
import usePagePersistence from "../usePagePersistence";
import editTsListStatusResponder from "./timesheetResponders/editTsListStatusResponder";
import getTsListParams from "../../helpers/Timesheets/getTsListParams";

const useEditTimesheetsList = () => {
  const { pageState } = usePagePersistence();
  const queryClient = useQueryClient();

  const editMutation = useMutation(
    editTsListStatusHandler,
    editTsListStatusResponder(queryClient, getTsListParams(pageState))
  );

  const editTimesheetsListStatus = async (props, newRequest = true) => {
    if (!newRequest) return null;

    const response = await editMutation.mutateAsync(props);
    return response;
  };

  return {
    editTimesheetsListStatus,
  };
};

export default useEditTimesheetsList;
