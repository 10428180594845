import taskKeys from "../../hooks/api/tasks/taskKeys";
import getTaskListReqParams from "./getTaskListReqParams";

const editTaskListStore = ({ queryClient, item, variables }) => {
  queryClient.setQueryData(
    taskKeys.taskList(getTaskListReqParams()),
    (oldData) => {
      if (variables.operation === "$non-recurring") {
        const taskId = item.id;
        const startDate = item.startData;

        const newItem = {
          uri: `/api/Task/${taskId}`,
          resource: item,
          search: { mode: "match" },
        };

        if (oldData === undefined) {
          return [newItem];
        }

        const newTaskArray = oldData.map((task) => {
          if (
            task.resource.id === taskId &&
            task.resource.startData === startDate
          ) {
            return newItem;
          }
          return task;
        });

        return newTaskArray;
      }

      if (
        variables.operation === "$future" ||
        variables.operation === "$all" ||
        variables.operation === "$single"
      ) {
        return oldData;
      }

      return oldData || [];
    }
  );
};

export default editTaskListStore;
