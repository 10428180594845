/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CALENDAR_PATH,
  MY_PROFILE,
  PAGES,
  TENANT_ID_STORAGE_KEY,
} from "../../constants";
import { useESChatContext } from "../../context/ChatContext/ESChatContext";
import { createChannelId } from "../../helpers/Chat";
import ContactDetail from "../../stories/Components/ContactView/ContactView";
import { useCompanyMembers, useUsers } from "../../hooks/useUsers.new";
import { useUserById } from "../../hooks/useUserById";
import useCurrentUser from "../../hooks/useCurrentUser";
import useGoBack from "../../hooks/useGoBack";
import { isEmployeeOrAdmin } from "../../helpers/Permissions";
import BackButton from "../../stories/Components/WidgetEditorView/Header/BackButton";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";

/**
 * This is a single contact/user/company Page
 */
const ContactView = ({
  setShowMessages,
  usersStreamInfo,
  allRoles,
  originalSubscription,
  systemConfiguration,
}) => {
  const { contactId } = useParams();

  const { data: usersData, isLoading, isFetching } = useUsers();
  const { data: currentUser } = useCurrentUser();
  const users = usersData?.users;
  const userDict = usersData?.userDict;

  const { data: assignedUsers } = useCompanyMembers(contactId);
  const [viewContacts, setViewContacts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [availableSeats, setAvailableSeats] = useState();
  const [states, setStates] = useState([]);
  const [ptoCategory, setPtoCategory] = useState();

  const { clientChat: client, setSelectedChannel } = useESChatContext();
  const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);
  const history = useHistory();
  const { navigateBack } = useGoBack();
  const location = useLocation();
  const { setCurrentResourceScreen } = useAppPersistence();

  if (
    !location.pathname.includes(MY_PROFILE) &&
    !isEmployeeOrAdmin(currentUser)
  ) {
    navigateBack(CALENDAR_PATH.split("/")[1]);
  }

  // Get single user
  const { data: user } = useUserById(contactId);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/contacts/123", currentPage will be "contacts"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.CONTACTS) {
      setCurrentResourceScreen(PAGES.CONTACTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  useEffect(() => {
    setPtoCategory(systemConfiguration?.system?.pto?.category);
    if (systemConfiguration?.system?.pto?.locations?.length) {
      setStates(
        systemConfiguration?.system?.pto?.locations?.map((loc) => ({
          label: loc.location,
          value: loc?.id,
        }))
      );
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (users && originalSubscription) {
      const activeMemberCount = users?.filter(
        (usr) =>
          usr?.kind === "member" && usr?.active && !usr?.metadata?.deletedAt
      )?.length;
      const seats = originalSubscription?.userCount;
      setAvailableSeats(
        seats > activeMemberCount ? seats - activeMemberCount : 0
      );
    }
  }, [originalSubscription, users]);

  useEffect(() => {
    if (allRoles?.length) {
      setRoles(
        allRoles.map((role) => ({
          label: role.name,
          value: role.reference,
        }))
      );
    }
  }, [allRoles]);
  /**
   * TODO: "Optimize performance"
   */
  useEffect(() => {
    if (
      assignedUsers?.length &&
      usersStreamInfo &&
      userDict &&
      !isFetching &&
      !isLoading
    ) {
      const streamUser = usersStreamInfo?.find(
        (su) => su.userId === userDict?.[`User/${contactId}`]?.id
      );
      setViewContacts(
        assignedUsers?.map((usr) => {
          usr.company = {
            ...usr?.company,
            label: usr?.companyName,
          };
          usr.lastActive = streamUser?.lastActive;
          usr.isOnline = streamUser?.isOnline;

          return usr;
        })
      );
    }
  }, [
    contactId,
    users,
    usersStreamInfo,
    assignedUsers,
    userDict,
    history,
    isFetching,
    isLoading,
  ]);

  const openChatTimerRef = useRef();

  const openChat = useCallback(async () => {
    setShowMessages(true);
    openChatTimerRef.current = setTimeout(async () => {
      const selectedUsersIds = [
        usersStreamInfo?.find((usr) => usr.userId === user?.id)?.streamId,
      ];

      if (!selectedUsersIds.length) return;

      const channelData = {
        members: [...selectedUsersIds, currentUser.streamId],
        team: tenantId,
      };

      // creating conversations (with unique channelId)
      const channelId = createChannelId(tenantId, channelData.members);
      // create new or use existing conversation
      const conversation = client?.channel("messaging", channelId, channelData);

      await conversation.watch();

      setSelectedChannel(conversation);
    }, 500);
  }, [
    setShowMessages,
    usersStreamInfo,
    currentUser,
    tenantId,
    client,
    setSelectedChannel,
    user,
  ]);

  useEffect(() => {
    return () => {
      if (openChatTimerRef.current) clearTimeout(openChatTimerRef.current);
    };
  }, []);

  const handleNavigationBack = () => {
    history.goBack();
  };

  return (
    <>
      <BackButton onBackPressed={handleNavigationBack} />
      <ContactDetail
        openChat={openChat}
        contacts={viewContacts}
        currentUser={currentUser}
        roles={roles}
        availableSeats={availableSeats}
        states={states}
        ptoCategory={ptoCategory}
      />
    </>
  );
};

ContactView.propTypes = {
  setShowMessages: PropTypes.func,
  currentUser: PropTypes.shape({
    streamId: PropTypes.string,
  }),
  usersStreamInfo: PropTypes.arrayOf(PropTypes.shape({})),
  systemConfiguration: PropTypes.shape({
    system: PropTypes.shape({
      pto: PropTypes.shape({
        category: PropTypes.string,
        locations: PropTypes.arrayOf(PropTypes.string),
      }),
      preferences: PropTypes.shape({}),
    }),
  }),
  managementConfiguration: PropTypes.shape({
    management: PropTypes.shape({
      contact: PropTypes.shape({
        disciplines: PropTypes.arrayOf({ id: PropTypes.string }),
      }),
    }),
  }),
  client: PropTypes.shape({ channel: PropTypes.func }),
  originalSubscription: PropTypes.shape({ userCount: PropTypes.number }),
  allRoles: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
};

ContactView.defaultProps = {
  setShowMessages: undefined,
  currentUser: undefined,
  usersStreamInfo: undefined,
  systemConfiguration: undefined,
  client: undefined,
  originalSubscription: undefined,
  allRoles: undefined,
  managementConfiguration: undefined,
};

export default ContactView;
