import debounceAsync from "../debounceAsync";

/**
 * Modifies the search text of a specific tab on a page.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page where the tab is located.
 * @param {string} params.tab - The tab to modify.
 * @param {string} params.text - The new search text.
 * @param {boolean} params.resourceId - Resource id
 * @param {Object} params.userSettings - The current user settings.
 * @param {Function} params.editSettings - The function to edit the user settings.
 * @returns {Function} A debounced function that modifies the page settings.
 */

const modifyTabSearchText = ({
  page,
  tab,
  text,
  resourceId,
  userSettings,
  stableEditSettings,
}) => {
  // Create a new copy of userSettings to avoid mutating the original object
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings?.web,
      [page]: {
        ...userSettings?.web?.[page],
      },
    },
  };

  const pageSettings = newSettings?.web[page];

  // If resourceId exists, modify resource tab settings
  if (resourceId) {
    pageSettings.resource = {
      ...pageSettings.resource,
      id: resourceId,
      tab: {
        ...pageSettings.resource?.tab,
        [tab]: {
          ...pageSettings.resource?.tab[tab],
          search: text,
        },
      },
    };
  } else {
    // Modify non-resource tab settings
    pageSettings.tab = {
      ...pageSettings.tab,
      [tab]: {
        ...pageSettings.tab[tab],
        search: text,
      },
    };
  }

  return debounceAsync(stableEditSettings(newSettings));
};

export default modifyTabSearchText;
