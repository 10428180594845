import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ToggleButton from "../Buttons/ToggleButton";

const LilySettings = ({ settings = {}, dispatchSettings = () => {} }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (settings) {
      setIsChecked(settings.lily.isEnabled);
    }
  }, [settings]);

  const onToggle = () => {
    dispatchSettings({
      type: "toggleLilyOnOff",
    });
  };
  return (
    <div className="py-4 flex flex-col gap-2">
      <div className="flex flex-row gap-2 items-center">
        <ToggleButton isChecked={isChecked} onToggle={onToggle} />
        <p className="text-sm font-medium">Enable Lily AI</p>
      </div>
      <p className="text-sm font-sm">
        Please be advised that Lily relies extensively on AI-generated content.
        While every effort is made to ensure the accuracy and quality of the
        information provided, users are encouraged to independently verify all
        content and edits. By using Lily, you acknowledge and accept this
        disclaimer, understanding that the AI&apos;s output may occasionally
        contain inaccuracies or incomplete information.
      </p>
    </div>
  );
};

LilySettings.propTypes = {
  settings: PropTypes.shape({
    lily: PropTypes.shape({
      isEnabled: PropTypes.bool,
      date: PropTypes.string,
    }),
  }),
  dispatchSettings: PropTypes.func,
};

LilySettings.defaultProps = {
  settings: undefined,
  dispatchSettings: () => {},
};

export default LilySettings;
