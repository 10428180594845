import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import cntl from "cntl";
import { v4 as uuidv4 } from "uuid";
import FormAvatar from "../Avatar/FormAvatar";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";

const rowCN = cntl`
  flex
  flex-row
  justify-between
  min-w-full h-20
  items-center
`;

const labelCN = cntl`
  flex
  w-1/3
  text-gray-450
  font-semibold
`;

const asteriskCN = cntl`
  text-brandGreen
`;

const inputCN = cntl`
  flex
  w-2/3
`;

const inputItemCN = cntl`
  bg-green-50
  w-full
  px-4
`;

const formCN = cntl`
  flex
  flex-col
  border
  border-gray-450
  rounded
  py-4
  px-6
  h-full
`;

const dropdownCN = cntl`
  mt-3
  w-2/3
  relative
  self-center
`;

const SpaceForm = ({
  space,
  setInputOpen,
  changeName,
  spaceTypeOptions,
  changeType,
  levelTypeOptions,
  changeLevel,
  changeArea,
  canCreateType,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const newType = useMemo(
    () =>
      space?.isNewType
        ? { label: space?.type, value: space?.type, id: uuidv4() }
        : null,
    [space]
  );

  return (
    <form
      style={{ minWidth: 600, minHeight: 450 }}
      className="flex flex-col ml-2"
    >
      <div className="flex items-center pb-4">
        <FormAvatar
          isEditing
          image={space?.primaryImage}
          tmpAvatarImg={tmpAvatarImg}
          setTmpAvatarImg={setTmpAvatarImg}
          resourceName="Space"
        />
        <p className="text-gray-450 font-semibold text-sm ml-2">Add Image</p>
      </div>
      <div className={formCN}>
        <div className={rowCN}>
          <p className={labelCN}>
            Name<p className={asteriskCN}>*</p>
          </p>
          <div className={inputCN}>
            <Input
              placeholder="Name"
              mainWrapperClassName="w-full"
              inputClassName={inputItemCN}
              value={space.name}
              onChange={changeName}
              validation={yup.string().trim().required()}
              autoFocus
              showValidationErrorAtBottom
            />
          </div>
        </div>
        <div className={rowCN}>
          <p className={labelCN}>
            Type <p className={asteriskCN}>*</p>
          </p>
          <div className={inputCN}>
            <Dropdown
              placeholder="Select"
              className={dropdownCN}
              options={[...spaceTypeOptions]}
              value={
                space?.isNewType
                  ? newType
                  : spaceTypeOptions?.find(
                      (item) => item?.value === space?.type
                    )
              }
              onChange={(val) =>
                changeType(
                  val?.value,
                  // eslint-disable-next-line no-underscore-dangle
                  val?.__isNew__
                )
              }
              isCreatable={canCreateType}
            />
          </div>
        </div>
        {/* This code will be used this sprint 6/10 in ticket number ES-9992 */}
        {/*         <div className={rowCN}>
          <p className={labelCN}>Association</p>
          <div className={inputCN}>
            <Dropdown
              className={dropdownCN}
              options={[{ label: "", value: "" }]}
              value={null}
              onChange={() => {}}
              isCreatable={canCreateType}
              placeholder="Select"
            />
          </div>
        </div> */}
        <div className={rowCN}>
          <p className={labelCN}>Level</p>
          <div className={inputCN}>
            <Dropdown
              placeholder="Select"
              className={dropdownCN}
              options={levelTypeOptions}
              value={levelTypeOptions?.find(
                (item) => item?.value === space?.level
              )}
              onChange={(value) => changeLevel(value?.value)}
            />
          </div>
        </div>
        <div className={rowCN}>
          <p className={labelCN}>Square Footage</p>
          <div className={inputCN}>
            <Input
              mainWrapperClassName="w-full"
              type="text"
              minValue={0}
              inputClassName={inputItemCN}
              value={space.area?.value === 0 ? null : space?.area?.value}
              onChange={(val) => changeArea(val)}
              setOpen={setInputOpen}
              placeholder="(Optional)"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

SpaceForm.propTypes = {
  changeName: PropTypes.func,
  spaceTypeOptions: PropTypes.arrayOf({}),
  changeType: PropTypes.func,
  levelTypeOptions: PropTypes.arrayOf({}),
  changeLevel: PropTypes.func,
  changeArea: PropTypes.func,
  space: PropTypes.shape({
    name: PropTypes.string,
    level: PropTypes.string,
    type: PropTypes.string,
    primaryImage: PropTypes.string,
    area: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    isNewType: PropTypes.bool,
  }),
  canCreateType: PropTypes.bool,
  configuration: PropTypes.shape({
    spaces: PropTypes.shape({}),
    levels: PropTypes.shape({}),
  }),
  setInputOpen: PropTypes.func,
  tmpAvatarImg: PropTypes.shape({
    image: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  setTmpAvatarImg: PropTypes.func,
};

SpaceForm.defaultProps = {
  changeName: undefined,
  spaceTypeOptions: undefined,
  changeType: undefined,
  levelTypeOptions: undefined,
  changeLevel: undefined,
  changeArea: undefined,
  space: undefined,
  configuration: undefined,
  setInputOpen: false,
  canCreateType: undefined,
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
};

export default SpaceForm;
