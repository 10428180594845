/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import cntl from "cntl";
import { differenceBy as _differenceBy, pullAllBy as _pullAllBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router";
import { ScrollSync, ScrollSyncNode } from "scroll-sync-react";
import Sticky from "react-sticky-el";

import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { HashLink } from "react-router-hash-link";
import { useSticky } from "react-table-sticky";

import {
  FILTER_TYPES,
  SET_TABLE_FUNCTIONS,
  TABLE_COLUMN_WIDTH,
  TABLE_FILTER,
} from "../../../constants";

import useStateWithLocalStorage from "../../../hooks/useStateWithLocalStorage";

import Checkbox from "../Checkbox/Checkbox";
import IconLabel from "../IconLabel/IconLabel";
import SiteHeaderAlert from "../SiteHeader/SiteHeaderAlert";
import SiteHeaderChangeViewDD from "../SiteHeader/SiteHeaderChangeViewDD";
import SiteHeaderEditColumns from "../SiteHeader/SiteHeaderEditColumns";
import SiteHeaderEditRows from "../SiteHeader/SiteHeaderEditRows";
import SiteHeaderSearch from "../SiteHeader/SiteHeaderSearch";
import SiteHeaderTableBlock from "../SiteHeader/SiteHeaderTableBlock";
import SiteHeaderTableFilter from "../SiteHeader/SiteHeaderTableFilter";
import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import TagsFilter from "./Filters/TagFilter";
import UsersFilter from "./Filters/UsersFilter";

import crossIcon from "../../assets/images/crossIcon.svg";

import useTableGroupReducer from "../../../hooks/useTableGroupReducer";
import { useAppState } from "../../../state/appState";
import PrimaryButton from "../Buttons/PrimaryButton";
import GetStartedCircle from "../GetStartedCircle/GetStartedCircle";
import SiteHeaderPendignUpdatesQueue from "../SiteHeader/SiteHeaderPendingUpdatesQueue";
import GroupedFooterRow from "./GroupedFooterRow";
import Pagination from "./Pagination";
import "./Table.css";
import TableMiniMapView from "./TableMiniMapView";
import {
  BooleanFilter,
  DateFilter,
  IncludesExcludesFilter,
  IsOrNotFilter,
} from "./tableFilters";
import AddViewAndFilterPopover from "../AddViewAndFilterPopover/AddViewAndFilterPopover";
import useSettings from "../../../hooks/useSettings";
import useTableData from "./useTableData";
import { clearSearch } from "../../../helpers/clearSearchField";
import isISODate from "../../../helpers/Date/isISODate";

const containerCN = (className) => cntl`
  bg-white
  flex-1
  max-w-full
  mt-5
  relative
  ${className}
  `;

const tableFooterCN = (isGrouped) => cntl`
  relative
  border-gray-400
  ${isGrouped && cntl`border-t border-black`}
`;

const versionHeaderCN = (isFirstRow) => cntl`
  flex
  ${isFirstRow ? "mt-8 mb-2" : "my-2"}
  sticky
  left-0
`;

const totalFooterCN = cntl`
  absolute
  z-10
  text-gray-200
  text-sm
  font-semibold
  mt-1
`;

const badge = (hasAtleastOneSubRow, className) => cntl`
  rounded-full
  flex
  ${hasAtleastOneSubRow ? cntl`bg-brandGreen` : cntl`bg-gray-100`}
  mr-2
  mt-1
  ${className}
`;

const tableCN = (tableClassName) => cntl`
  flex
  flex-col
  max-w-full
  ${tableClassName}
`;

const headerCN = (step, className) => {
  const sharedCN = cntl`
    font-semibold
    uppercase
    ${className}
  `;

  switch (step) {
    case 0:
      return cntl`
        ${sharedCN}
        text-xl
      `;
    case 1:
      return cntl`
        ${sharedCN}
        text-lg
      `;
    case 2:
      return cntl`
        ${sharedCN}
        text-base
      `;
    default:
      return cntl`
        ${sharedCN}
        text-sm
      `;
  }
};

const miniViewHeaderCN = (step) => cntl`
  mb-1
  text-xs
  truncate
  ${step < 3 ? "font-bold text-gray-300" : "text-gray-200"}
`;
const defaultColumn = { minWidth: 120 };

const Table = ({
  searchOverRide,
  setSearchOverRide,
  lastRowReferenceForInfiniteScroll,
  fetchDataOnScroll,
  hasMoreData,
  filterPopOverClassName,
  setCreateExpenseModal,
  setCreateMaintenanceModal,
  className,
  tableClassName,
  resourceName,
  columns: columnsProp,
  data,
  onTaskClick,
  onRowClick,
  allowSelection,
  enableEditing,
  enableDeleting,
  onSaveRowClick,
  onEditRowCancelClick,
  onDeleteRowClick,
  onStartRowEditing,
  onEndRowEditing,
  enableAdding,
  disableHover,
  onAddRowClose,
  showFooter,
  groupOptions,
  hiddenColumns,
  templateSettings,
  updateUserTemplateSettings,
  onEditRowClose,
  enablePopOverEditing,
  inLineForm,
  onSelectedRowChange,
  rowAssociationMenuList,
  groupByCallback,
  columnsForGroupByCallback,
  showEmptyTableView,
  versionCloseCallback,
  hideGroupedHeader,
  hideGroupedFooter,
  rowHoverComponent,
  cellStyling,
  cellTextStyling,
  stopBodyScroll,
  defaultSort,
  initialSelectedRows,
  noDeleteConfirm,
  hideEditRowButton,
  hideDeleteRowButton,
  showConstantRowIndex,
  buttonActions,
  singleButton,
  onAlertWarningButtonClick,
  warningCount,
  alertCount,
  hideSiteHeader,
  warningToggle,
  alertToggle,
  setWarningToggle,
  setAlertToggle,
  rowBodyStyles,
  bannerComponent,
  tabs,
  showEditColumns,
  showSearch,
  showFilter,
  summaryComponent,
  hideSwitchView,
  initialTemplateViewName,
  deleteButtonStyle,
  deleteUserTemplateSettings,
  fixedSelection,
  allowEditingOnRowClick,
  onEditPopoverCloseCallback,
  onEditPopoverOpenCallback,
  showPendingUpdates,
  pendingUpdatesTotal,
  onPendingUpdatesQueueClick,
  disableAutoResetSelectedRows,
  hideLineNumber,
  disableSorting,
  overrideSelect,
  centerColumns,
  verticallyCenterColumns,
  disableSelectAll,
  getStartedClassName,
  getStartedTitle,
  onGetStartedClick,
  showGetStarted,
  isLoading,
  miniMapContainerId,
  hideMiniMap,
  customSiteHeader,
  disableCreateTemplate,
  disableClearView,
  headerCellStyle,
  filesView,
  fileActionsIcons,
  showColumnSettingsLeft,
  openEditTableColumn,
  hideSiteHeaderActionButtons,
  hideSiteHeaderDropdownContainer,
  hideSiteHeaderTitle,
  leftSearch,
  noHeader,
  cardGrid,
  cardGridComponent,
  showScrollShadow,
  pagination,
  addNestedRoute,
  setTableClearFunction,
  showReportButton,
  isEditingAllInlineEditForms,
  disableDragging,
  fullWidthSearch,
}) => {
  // Presenter hook
  const {
    resource,
    isEditTableColumn,
    canShowScrollShadow,
    setCanShowScrollShadow,
    setIsEditModalctionslOpen,
    handleGroupByTags,
    updateAssociationsQueryOnShowReportURL,
  } = useTableData({
    resourceName,
    openEditTableColumn,
    showScrollShadow,
  });
  const [selectedTemplateOption, setSelectedTemplateOption] = useState(null);
  const [selectedTemplateSetting, setSelectedTemplateSetting] = useState(null);
  const [defaultTemplateViewName, setDefaultTemplateViewName] = useState(
    initialTemplateViewName
  );

  useEffect(() => {
    if (initialTemplateViewName) {
      setDefaultTemplateViewName(initialTemplateViewName);
    }
  }, [initialTemplateViewName]);

  const [enableFiltering, setEnableFiltering] = useState(false);
  const [enableGrouping, setEnableGrouping] = useState(false);
  const [groups, groupDispatch] = useTableGroupReducer();
  const [isTemplateViewOpen, setIsTemplateViewOpen] = useState(false);
  const [initialGlobalFilter, setInitialGlobalFilter] =
    useStateWithLocalStorage(`${resourceName}-tableGlobalFilter`, "");
  const [rowsEditing, setRowsEditing] = useState([]);

  const [initialColumnOrdering, setInitialColumnOrdering] =
    useStateWithLocalStorage(`${resourceName}-tableColumnOrdering`, "");
  const [initialColumnSorting, setInitialColumnSorting] =
    useStateWithLocalStorage(
      `${resourceName}-tableColumnSorting`,
      JSON.stringify(defaultSort) || ""
    );
  const [userSettings, updateSettings] = useSettings();

  const [lastSelectedTemplate, setLastSelectedTemplate] = useState();
  const [totalSelectedRowsCount, setTotalSelectedRowsCount] = useState(0);
  const { pathname: currentRoute } = useLocation();

  const [showShadowStart, setShowShadowStart] = useState(false);
  const [showShadowEnd, setShowShadowEnd] = useState(false);

  const [, dispatch] = useAppState();
  const ref = useRef();

  const {
    scrollWidth: initScrollWidth = 0,
    scrollLeft: initScrollLeft = 0,
    offsetWidth: initOffsetWidth = 0,
  } = ref?.current || {};

  // set initial shadow scroll
  useEffect(() => {
    setShowShadowEnd(initScrollLeft + initOffsetWidth < initScrollWidth - 1);
  }, [initOffsetWidth, initScrollLeft, initScrollWidth]);

  const onScroll = useCallback(() => {
    const {
      scrollWidth = 0,
      scrollLeft = 0,
      offsetWidth = 0,
    } = ref?.current || {};

    setShowShadowStart(scrollLeft > 0);
    setShowShadowEnd(scrollLeft + offsetWidth < scrollWidth - 1);
  }, []);

  useEffect(() => {
    if (groupOptions) {
      setEnableGrouping(true);
    }

    columnsProp.some((currentCol) => {
      if (currentCol.filterOptions?.filterType) {
        setEnableFiltering(true);
        return true;
      }
      return false;
    });
  }, [columnsProp, groupOptions]);

  const columns = useMemo(() => {
    const cols = columnsProp.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        case FILTER_TYPES.includesExcludes: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.INCLUDES_EXCLUDES,
            Filter: IncludesExcludesFilter,
          };
        }
        case FILTER_TYPES.tags: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.INCLUDES_EXCLUDES,
            Filter: TagsFilter,
          };
        }
        case FILTER_TYPES.userRefs: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.INCLUDES_EXCLUDES,
            Filter: UsersFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });

    return cols;
  }, [columnsProp]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    setAllFilters,
    setGlobalFilter,
    setColumnOrder,
    visibleColumns,
    allColumns,
    rows,
    preFilteredRows,
    setGroupBy,
    setHiddenColumns,
    toggleRowExpanded,
    toggleAllRowsSelected,
    page,
    nextPage,
    previousPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    state: {
      globalFilter,
      sortBy,
      groupBy,
      filters,
      selectedRowIds,
      pageIndex,
    },
  } = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
      autoResetPage: true,
      autoResetFilters: false,
      autoResetGroupBy: false,
      autoResetGlobalFilter: false,
      autoResetSelectedRows: !disableAutoResetSelectedRows,
      initialState: {
        globalFilter: useMemo(
          () => initialGlobalFilter || "",
          [initialGlobalFilter]
        ),
        columnOrder:
          !initialColumnOrdering || initialColumnOrdering === ""
            ? []
            : initialColumnOrdering.split(","),
        sortBy:
          !initialColumnSorting || initialColumnSorting === ""
            ? []
            : JSON.parse(initialColumnSorting),
        hiddenColumns: [
          ...hiddenColumns,
          ...columns
            .filter((col) => col.show === false)
            .map((col) => col.accessor),
        ],
        selectedRowIds: initialSelectedRows.reduce((obj, row) => {
          const temp = { ...obj };
          temp[row] = true;
          return temp;
        }, {}),
      },
      globalFilter: useCallback((tableRows, ids, filterValue) => {
        return tableRows?.filter((row) => {
          return ids.some((id) => {
            let rowValue = row.values[id];
            // make exception for PA (Budget)
            if (id === "purchaseAuthorization") {
              rowValue = `PA ${rowValue}`;
            }
            // since every rowValue has an ISO date value
            // convert ISO date to date format typed by user
            if (isISODate(rowValue)) {
              rowValue = moment(rowValue).format("MMMM Do YYYY, h:mm a");
            }
            return String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase());
          });
        });
      }, []),
      defaultFilterMethod: useCallback((filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }, []),
      getRowId: useCallback((row, relativeIndex, parent) => {
        if (row?.id) {
          return row.id;
        }
        return parent ? [parent.id, relativeIndex].join(".") : relativeIndex;
      }, []),
      defaultColumn,
      autoResetExpanded: false,
      groupByFn: (tableRows, columnId) => {
        let reduced = tableRows
          // remove any version rows
          .filter((row) => !row.original.parentIdOfVersion)
          .reduce((prev, row) => {
            const previous = { ...prev };
            const resKey = `${row.values[columnId]}`;
            previous[resKey] = Array.isArray(previous[resKey])
              ? previous[resKey]
              : [];
            previous[resKey].push(row);
            return previous;
          }, {});

        // sort the groups by alphabetically
        reduced = Object.keys(reduced)
          .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
          .reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = reduced[key];
            return obj;
          }, {});

        // sort each row in the group alphabetically
        Object.keys(reduced).forEach((k) => {
          const allData = reduced[k];
          allData.sort((a, b) => {
            const sortByName = a.original.name;
            if (!sortByName) return 0;
            if (a.original.name.toLowerCase() > b.original.name.toLowerCase()) {
              return 1;
            }
            return -1;
          });
        });

        // check if the allowed templates are selected before applying manual grouping
        const isValid =
          groupByCallback &&
          selectedTemplateSetting &&
          !selectedTemplateSetting.filters.length &&
          selectedTemplateSetting.groups.length >= 1 &&
          columnsForGroupByCallback.indexOf(
            selectedTemplateSetting.groups[0].value
          ) !== -1;
        if (isValid) {
          reduced = groupByCallback(reduced, columnId);
        }

        // Apply custom grouping for "tags" column
        if (columnId === "tags") {
          // Use the custom grouping function with tag mapping from tagsDict
          reduced = handleGroupByTags(reduced, columnId);
        }

        /**
         * Option to add custom groupBy function from templateOptions
         * Ex: Combine rows from users for cumulative view
         */
        return selectedTemplateOption?.groupBy?.(reduced) ?? reduced;
      },
      orderByFn: (arr, funcs, dirs) => {
        // if subRows exist then do not sort them
        if (arr.length && arr[0].depth) {
          return arr;
        }
        return [...arr].sort((rowA, rowB) => {
          for (let i = 0; i < funcs.length; i += 1) {
            const sortFn = funcs[i];
            const desc = dirs[i] === false || dirs[i] === "desc";
            const sortInt = sortFn(rowA, rowB);
            if (sortInt !== 0) {
              return desc ? -sortInt : sortInt;
            }
          }
          return dirs[0] ? rowA.index - rowB.index : rowB.index - rowA.index;
        });
      },
      disableSortBy: disableSorting,
    },
    useFlexLayout,
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useSticky,
    (hooks) => {
      if (allowSelection) {
        hooks.visibleColumns.push((cols) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps, data: tableData }) => {
              if (fixedSelection) {
                return (
                  <Checkbox onChange={() => {}} disabled={disableSelectAll} />
                );
              }

              if (overrideSelect) {
                return (
                  <Checkbox
                    onChangeSendEvent
                    {...getToggleAllRowsSelectedProps()}
                    // Experimental
                    checked={
                      !!tableData?.length &&
                      tableData?.length === totalSelectedRowsCount
                    }
                    onChange={() => {
                      if (tableData?.length === totalSelectedRowsCount) {
                        toggleAllRowsSelected(false);
                      } else {
                        toggleAllRowsSelected(true);
                      }
                    }}
                    disabled={disableSelectAll}
                  />
                );
              }
              return (
                <Checkbox
                  onChangeSendEvent
                  {...getToggleAllRowsSelectedProps()}
                  disabled={disableSelectAll}
                />
              );
            },
            Cell: ({ row, state }) => {
              let elmt;
              if (fixedSelection) {
                elmt = (
                  <Checkbox
                    checked={initialSelectedRows?.includes(row.original.id)}
                    onChange={() => {}}
                  />
                );
              } else {
                elmt =
                  row?.original?.hideSelection || row?.values?.hideSelection ? (
                    <Checkbox disabled onChangeSendEvent />
                  ) : (
                    <Checkbox
                      onChangeSendEvent
                      stopEventPropagation={allowEditingOnRowClick}
                      {...row.getToggleRowSelectedProps()}
                      // Experimental
                      checked={
                        Object.keys(state?.selectedRowIds)?.includes(
                          row.original.id
                        ) || row.getToggleRowSelectedProps().checked
                      }
                    />
                  );
              }

              return elmt;
            },
            sticky: "left",
            width: TABLE_COLUMN_WIDTH,
            minWidth: 70,
            maxWidth: 70,
          },
          ...cols,
        ]);
      }

      const Cell = ({ row, flatRows }) => {
        const rowIndex = flatRows?.findIndex((fr) => fr?.id === row?.id);
        return (
          (showConstantRowIndex && (
            <p className="font-bold text-gray-300">{rowIndex + 1}</p>
          )) || (
            <p className="font-bold text-gray-300">
              {row.original?.overwriteLineNumber || row.index + 1}
            </p>
          )
        );
      };

      if (!hideLineNumber && !selectedTemplateOption?.hideLineNumber) {
        hooks.visibleColumns.push((cols) => [
          {
            Header: () => <div className="mr-4">#</div>,
            accessor: "_lineNumber",
            columnId: "_lineNumber",
            disableFilters: true,
            disableResizing: true,
            isPrimary: true,
            width: TABLE_COLUMN_WIDTH,
            minWidth: 60,
            maxWidth: 60,
            color: "#6a6a6a",
            Cell,
            Edit: Cell,
            sticky: "left",
            disableSortBy: showConstantRowIndex,
          },
          ...cols,
        ]);
      }
    }
  );

  useEffect(() => {
    // Must have resource name for key to access inner table functions
    // in appState - tableHelpers[resourceName][helperFunction]
    if (resourceName)
      dispatch({
        type: SET_TABLE_FUNCTIONS,
        table: resourceName,
        // Table functionality that's needed outside of table component
        functions: { toggleSelected: toggleAllRowsSelected },
      });
  }, [dispatch, resourceName, setTableClearFunction, toggleAllRowsSelected]);

  /**
   * Set pagination page size
   */
  useEffect(() => {
    setPageSize(typeof pagination === "number" ? pagination : 10);
  }, [pagination, setPageSize]);
  /**
   * Set pagination page size
   */

  useEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    const selectedRowsData = selectedIds
      .map((x) => data[x] || data.find((row) => row.id === x))
      .filter((x) => {
        return x != null;
      });

    setTotalSelectedRowsCount(selectedRowsData?.length);
    if (onSelectedRowChange) {
      onSelectedRowChange(selectedRowsData);

      if (showReportButton) {
        // Add the resources references selected in the URL
        updateAssociationsQueryOnShowReportURL(selectedRowsData);
      }
    }
  }, [
    data,
    onSelectedRowChange,
    selectedRowIds,
    showReportButton,
    updateAssociationsQueryOnShowReportURL,
  ]);

  useEffect(() => {
    setInitialColumnSorting(JSON.stringify(sortBy));
  }, [setInitialColumnSorting, sortBy]);

  const onSearchChange = useCallback(
    (search) => {
      if (searchOverRide !== "") setSearchOverRide("");

      setGlobalFilter(search || undefined);
      setInitialGlobalFilter(search);
    },
    [setGlobalFilter, setInitialGlobalFilter, setSearchOverRide, searchOverRide]
  );

  useEffect(() => {
    clearSearch(resourceName);
    setGlobalFilter(undefined);
  }, [resourceName, setGlobalFilter]);

  const hideColumns = useCallback(
    (selectedColumns) => {
      const hidden = _differenceBy(
        allColumns
          .filter(
            (column) =>
              column.id !== "selection" &&
              column.id !== "flag" &&
              column.accessor !== "_lineNumber"
          )
          .map((c) => ({
            label: c.Header,
            value: c.id,
          })),
        selectedColumns,
        "value"
      )
        .map((col) => col.value)
        .filter((col) => !!col);

      setHiddenColumns(hidden);
    },
    [allColumns, setHiddenColumns]
  );

  // If user has custom columns saved in settings API the table will show these columns by default.
  useEffect(() => {
    if (!selectedTemplateSetting && userSettings && userSettings[resource]) {
      hideColumns(userSettings[resource].columns);
    }
  }, [selectedTemplateSetting, userSettings, resource, hideColumns]);

  const clearAllFiltersAndGroups = useCallback(() => {
    setAllFilters([]);
    setGroupBy([]);
    setHiddenColumns([]);
    groupDispatch({
      type: "updateAll",
      groups: [],
    });
  }, [groupDispatch, setAllFilters, setGroupBy, setHiddenColumns]);

  const applyTemplate = useCallback(
    (template, isHistoryOpenClick) => {
      if (template) {
        setSelectedTemplateSetting(template);
        setAllFilters(template.filters);
        setGroupBy(template.groups.map((g) => g.value));
        const selectedExceptGrouped = _pullAllBy(
          template?.columns,
          template?.groups,
          "value"
        );
        hideColumns(selectedExceptGrouped);
        setSelectedTemplateOption({
          ...template,
          label: template.name,
          value: template.id,
        });
        setLastSelectedTemplate(template);
      } else {
        setSelectedTemplateSetting(null);
        setSelectedTemplateOption(null);
        clearAllFiltersAndGroups();
        if (!isHistoryOpenClick) {
          setLastSelectedTemplate(null);
        }
      }
    },
    [
      clearAllFiltersAndGroups,
      hideColumns,
      setAllFilters,
      setGroupBy,
      setLastSelectedTemplate,
    ]
  );

  const deleteTemplate = useCallback(
    (template) => {
      applyTemplate(null);
      deleteUserTemplateSettings(template);
    },
    [applyTemplate, deleteUserTemplateSettings]
  );

  const toggleTemplateView = useCallback(() => {
    setIsTemplateViewOpen((prev) => !prev);
  }, []);

  const onTemplateChange = useCallback(
    (selectedOption) => {
      if (selectedOption.value === "create") {
        applyTemplate(null);
        if (!isTemplateViewOpen) {
          toggleTemplateView();
        }
      } else if (selectedOption.value === "edit") {
        toggleTemplateView();
      } else if (selectedOption.value === undefined) {
        applyTemplate(null);
        if (isTemplateViewOpen) {
          toggleTemplateView();
        }
      } else if (selectedOption.type === "editFromDropDown") {
        toggleTemplateView();
        const found = templateSettings.find(
          (view) => view.id === selectedOption?.value
        );

        applyTemplate(found);
        setDefaultTemplateViewName(null);
      } else {
        const found = templateSettings.find(
          (view) => view.id === selectedOption?.value
        );
        applyTemplate(found);
        setDefaultTemplateViewName(null);
      }
    },
    [applyTemplate, isTemplateViewOpen, templateSettings, toggleTemplateView]
  );

  useEffect(() => {
    if (defaultTemplateViewName) {
      const found = templateSettings.find(
        (template) => template.name === defaultTemplateViewName
      );
      if (found) {
        onTemplateChange({ label: found?.name, value: found?.id });
      }
    }
  }, [defaultTemplateViewName, onTemplateChange, templateSettings]);

  const onCancelRowsClick = () => {
    rowsEditing.forEach((rowId) => {
      onEditRowCancelClick(rows.find((row) => row.id === rowId));
      onEndRowEditing();
    });
    setRowsEditing([]);
  };

  const versionHeader = useCallback((row, step) => {
    const currentSize = 18;
    const maxStepCount = 4;
    const minSize = currentSize - 2 * maxStepCount;
    const isParent = row?.original?.isParent;
    return (
      <div className={versionHeaderCN(isParent)} id={row.id}>
        <div className="w-10 flex justify-center">
          <span
            className={badge(true, "mr-0")}
            style={{
              width: currentSize,
              height: currentSize,
              minWidth: minSize,
              minHeight: minSize,
            }}
          />
        </div>
        <div className={headerCN(step, "font-bold mt-1 text-gray-400")}>
          {moment(row?.original?.modifiedDate).isSame(new Date(), "day")
            ? "TODAY"
            : new Date(row?.original?.modifiedDate).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
        </div>
      </div>
    );
  }, []);

  const onVersionCloseClick = useCallback(
    (row) => {
      toggleRowExpanded(row.original.parentIdOfVersion, false);
      versionCloseCallback(row.original.parentIdOfVersion);
      applyTemplate(lastSelectedTemplate);
    },
    [
      applyTemplate,
      lastSelectedTemplate,
      toggleRowExpanded,
      versionCloseCallback,
    ]
  );

  const getTableRow = useCallback(
    (row, isLastRow) => (
      <>
        {row?.original?.showDate &&
          row?.original?.parentIdOfVersion &&
          versionHeader(row)}
        <TableRow
          enableAddOverlay={enableAdding}
          key={row.id}
          row={row}
          lastRowReferenceForInfiniteScroll={
            isLastRow ? lastRowReferenceForInfiniteScroll : undefined
          }
          onClick={
            (onRowClick &&
              (() => {
                onRowClick(row);
              })) ||
            onTaskClick
          }
          center={centerColumns}
          enableEditOverlay={enableEditing}
          enableDeleteOverlay={enableDeleting}
          enableClickableHoverOverlay={!disableHover}
          isEditing={!!rowsEditing.find((r) => r === row.id)}
          onEditClick={() => {
            setRowsEditing((prev) => [...prev, row.id]);
            onStartRowEditing();
          }}
          onEditSaveClick={() => {
            onSaveRowClick(row);
            setRowsEditing((prev) => {
              const index = prev.indexOf(row.id);
              if (index > -1) {
                prev.splice(index, 1);
              }
              return prev;
            });
            onEndRowEditing();
          }}
          onEditCancelClick={() => {
            onEditRowCancelClick(row);
            setRowsEditing((prev) => {
              const index = prev.indexOf(row.id);
              if (index > -1) {
                prev.splice(index, 1);
              }
              return prev;
            });
            onEndRowEditing();
          }}
          onDeleteClick={onDeleteRowClick}
          onAddRowClose={onAddRowClose}
          onEditRowClose={onEditRowClose}
          enablePopOverEditing={enablePopOverEditing}
          inLineForm={inLineForm}
          isEditingAllInlineEditForms={isEditingAllInlineEditForms}
          rowAssociationMenuList={
            typeof rowAssociationMenuList === "function"
              ? rowAssociationMenuList(row)
              : rowAssociationMenuList
          }
          groupBy={groupBy}
          rowHoverComponent={rowHoverComponent}
          isVersion={row?.original?.parentIdOfVersion && !row.original.isParent}
          isVersionDateRow={row?.original?.showDate}
          toggleRowExpanded={toggleRowExpanded}
          className={cellStyling}
          textClassName={cellTextStyling}
          noDeleteConfirm={noDeleteConfirm}
          hideEditRowButton={hideEditRowButton}
          hideDeleteRowButton={hideDeleteRowButton}
          deleteButtonStyle={deleteButtonStyle}
          applyTemplate={applyTemplate}
          allowEditingOnRowClick={allowEditingOnRowClick}
          onEditPopoverCloseCallback={onEditPopoverCloseCallback}
          onEditPopoverOpenCallback={onEditPopoverOpenCallback}
        />
        {row?.original?.showButton && row?.original?.parentIdOfVersion && (
          <div className="p-2 sticky left-0 mb-8">
            <PrimaryButton
              title="Close"
              className="w-10"
              onClick={() => onVersionCloseClick(row)}
            />
          </div>
        )}
      </>
    ),
    [
      versionHeader,
      enableAdding,
      lastRowReferenceForInfiniteScroll,
      onRowClick,
      onTaskClick,
      centerColumns,
      enableEditing,
      enableDeleting,
      disableHover,
      rowsEditing,
      onDeleteRowClick,
      onAddRowClose,
      onEditRowClose,
      enablePopOverEditing,
      inLineForm,
      isEditingAllInlineEditForms,
      rowAssociationMenuList,
      groupBy,
      rowHoverComponent,
      toggleRowExpanded,
      cellStyling,
      cellTextStyling,
      noDeleteConfirm,
      hideEditRowButton,
      hideDeleteRowButton,
      deleteButtonStyle,
      applyTemplate,
      allowEditingOnRowClick,
      onEditPopoverCloseCallback,
      onEditPopoverOpenCallback,
      onStartRowEditing,
      onSaveRowClick,
      onEndRowEditing,
      onEditRowCancelClick,
      onVersionCloseClick,
    ]
  );

  const groupedFooter = useCallback(
    (row) => {
      prepareRow(row);
      return (
        <GroupedFooterRow
          row={row}
          onClick={onRowClick || onTaskClick}
          cellTextStyling={cellTextStyling}
        />
      );
    },
    [cellTextStyling, onTaskClick, prepareRow, onRowClick]
  );

  const groupedHeader = useCallback((row, step, rowIndex) => {
    const defaultSize = 18;
    const maxStepCount = 4;
    const currentSize = defaultSize - 2 * step;
    const minSize = defaultSize - 2 * maxStepCount;
    const hasAtleastOneSubRow =
      row.subRows?.length && !row.subRows[0].isManualGrouped;
    const headerColor =
      row.isManualGrouped ||
      (row.subRows?.length && !row.subRows[0].isManualGrouped)
        ? "text-black"
        : "text-gray-200";

    return (
      <div
        className={`sticky left-0 flex bg-white z-10 w-1/2 mb-2 ${
          rowIndex ? "mt-12" : "mt-2"
        }`}
        id={row.id}
      >
        <div className="w-10 flex justify-center min-w-10">
          <span
            className={badge(hasAtleastOneSubRow)}
            style={{
              width: currentSize,
              height: currentSize,
              minWidth: minSize,
              minHeight: minSize,
            }}
          />
        </div>
        <div
          className={headerCN(step, headerColor)}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.groupByVal === "undefined" || !row.groupByVal
            ? "Not Specified"
            : row.groupByVal}
        </div>
      </div>
    );
  }, []);

  const initialRow = useCallback(() => {
    const row = {
      id: "initial",
      isManualGrouped: true,
      original: {},
      values: {},
      groupByVal: "Get Started ...",
    };
    return (
      <>
        {groupedHeader(row, 0)}
        {getTableRow(row)}
      </>
    );
  }, [getTableRow, groupedHeader]);

  const groupedMiniViewHeader = useCallback(
    (row, step) => {
      const currentSize = 15 * step;
      return (
        <div
          style={{
            marginLeft: currentSize,
          }}
        >
          <HashLink
            className={miniViewHeaderCN(step)}
            to={`#${row.id}`}
            smooth
            replace
            duration={100}
            scroll={(el) => {
              // get the main scroll view
              // TODO: this won't work anywhere but tables directly nested in the MainContainer
              // for example, a model won't handle this scrolling properly right now
              const con = document.getElementById(
                miniMapContainerId || "mainContainerScroll"
              );
              const eleRect = el.getBoundingClientRect();
              const targetRect = con.getBoundingClientRect();
              const top = eleRect.top - targetRect.top;
              // need to apply an offset to put content under sticky header
              const yOffset = -55;
              con.scrollTo({
                top: con.scrollTop + (top + yOffset),
                behavior: "smooth",
              });
            }}
          >
            {row.groupByVal}
          </HashLink>
        </div>
      );
    },
    [miniMapContainerId]
  );

  const getTableGroups = useCallback(
    (tableRows, step = 0) => {
      return tableRows.map((row, index) => {
        if (row.isGrouped) {
          return (
            <React.Fragment key={row.id}>
              {!(
                hideGroupedHeader || selectedTemplateOption?.hideGroupedHeader
              ) && groupedHeader(row, step, index)}
              {getTableGroups(row.subRows, step + 1)}
              {!(
                hideGroupedFooter || selectedTemplateOption?.hideGroupedFooter
              ) && groupedFooter(row)}
            </React.Fragment>
          );
        }
        prepareRow(row);
        return getTableRow(row, index === tableRows?.length - 1);
      });
    },
    [
      getTableRow,
      groupedFooter,
      groupedHeader,
      hideGroupedFooter,
      hideGroupedHeader,
      prepareRow,
      selectedTemplateOption?.hideGroupedFooter,
      selectedTemplateOption?.hideGroupedHeader,
    ]
  );

  const moveColumn = useCallback(
    (from, to) => {
      const array = visibleColumns.map((d) => d.id);
      array.splice(to, 0, array.splice(from, 1)[0]);
      setColumnOrder(array);
      setInitialColumnOrdering(array.toString());
    },
    [setColumnOrder, setInitialColumnOrdering, visibleColumns]
  );

  const recursiveMiniMap = (tableRows, step = 0) => {
    return tableRows.map((row) => {
      if (row.isGrouped) {
        return (
          <Fragment key={row?.id}>
            {groupedMiniViewHeader(row, step)}
            {recursiveMiniMap(row.subRows, step + 1)}
          </Fragment>
        );
      }
      return <Fragment key={row?.id} />;
    });
  };

  const onfilterRequestClose = useCallback(() => {
    toggleTemplateView();
  }, [toggleTemplateView]);

  useEffect(() => {
    const cancelCriteria = !setSearchOverRide || searchOverRide === "";

    if (cancelCriteria) return;

    if (searchOverRide !== "") {
      onSearchChange(searchOverRide);
      setSearchOverRide("");
    }
  }, [fullWidthSearch, onSearchChange, searchOverRide, setSearchOverRide]);

  return (
    // minHeight to 75% of View Height keeps ability to use paging, and not allow table to cover filter popups
    <div style={{ minHeight: "75vh" }}>
      {customSiteHeader &&
        customSiteHeader({
          filter: (
            <SiteHeaderTableFilter
              filterPopOverClassName={filterPopOverClassName}
              preFilteredRows={preFilteredRows}
              allColumns={allColumns}
              groupOptions={groupOptions}
              setGroupBy={setGroupBy}
              groups={groups}
              groupDispatch={groupDispatch}
              enableGrouping={enableGrouping}
              setAllFilters={setAllFilters}
              hideColumns={hideColumns}
              setHiddenColumns={setHiddenColumns}
              onFilterRequestClose={onfilterRequestClose}
              enableFiltering={enableFiltering}
              filters={filters}
              data={data}
              isTemplateViewOpen={isTemplateViewOpen}
              updateUserTemplateSettings={updateUserTemplateSettings}
              templateSettings={templateSettings}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate}
              selectedTemplateSetting={selectedTemplateSetting}
              clearAllFiltersAndGroups={clearAllFiltersAndGroups}
            />
          ),
          viewOptions: (
            <SiteHeaderChangeViewDD
              selectedTemplateOption={selectedTemplateOption}
              onTemplateChange={onTemplateChange}
              templateSettings={templateSettings}
              disableCreateTemplate={disableCreateTemplate}
              disableClearView={disableClearView}
            />
          ),
          search: (
            <SiteHeaderSearch
              globalFilter={globalFilter}
              handleSearch={onSearchChange}
              fullWidthSearch={fullWidthSearch}
            />
          ),
          fileActionsIcons,
        })}
      {!hideSiteHeader && !customSiteHeader && (
        <SiteHeaderTableBlock
          setCreateExpenseModal={setCreateExpenseModal}
          setCreateMaintenanceModal={setCreateMaintenanceModal}
          selectedRowIds={selectedRowIds}
          currentRoute={
            addNestedRoute ? currentRoute + addNestedRoute : currentRoute
          }
          buttonActions={buttonActions}
          singleButton={singleButton}
          showReportButton={showReportButton && !cardGrid}
          viewOptions={
            <>
              <SiteHeaderChangeViewDD
                selectedTemplateOption={selectedTemplateOption}
                onTemplateChange={onTemplateChange}
                templateSettings={templateSettings}
                disableCreateTemplate={disableCreateTemplate}
                disableClearView={disableClearView}
              />
              {showColumnSettingsLeft && <SiteHeaderEditColumns />}
              <SiteHeaderEditRows
                rows={rows}
                setRowsEditing={setRowsEditing}
                rowsEditing={rowsEditing}
                onSaveRowClick={onSaveRowClick}
                onEndRowEditing={onEndRowEditing}
                onEditRowCancelClick={onEditRowCancelClick}
              />
            </>
          }
          alerts={
            <>
              {!!showPendingUpdates && (
                <SiteHeaderPendignUpdatesQueue
                  onPendingUpdatesQueueClick={onPendingUpdatesQueueClick}
                  total={pendingUpdatesTotal}
                />
              )}
              <div
                onClick={onAlertWarningButtonClick}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
                className={resourceName === "assets" ? "hidden" : "flex"}
                aria-label="Alerts"
              >
                <SiteHeaderAlert
                  total={warningCount}
                  isRed
                  onClick={() => {
                    setWarningToggle((el) => !el);
                  }}
                  buttonState={warningToggle}
                />
                <SiteHeaderAlert
                  total={alertCount}
                  onClick={() => {
                    setAlertToggle((el) => !el);
                  }}
                  buttonState={alertToggle}
                />
              </div>
            </>
          }
          search={
            <SiteHeaderSearch
              globalFilter={globalFilter}
              handleSearch={onSearchChange}
              showFullWidthSearch={fullWidthSearch}
            />
          }
          filter={
            <SiteHeaderTableFilter
              filterPopOverClassName={filterPopOverClassName}
              preFilteredRows={preFilteredRows}
              allColumns={allColumns}
              groupOptions={groupOptions}
              setGroupBy={setGroupBy}
              groups={groups}
              groupDispatch={groupDispatch}
              enableGrouping={enableGrouping}
              setAllFilters={setAllFilters}
              hideColumns={hideColumns}
              setHiddenColumns={setHiddenColumns}
              onFilterRequestClose={onfilterRequestClose}
              enableFiltering={enableFiltering}
              filters={filters}
              data={data}
              isTemplateViewOpen={isTemplateViewOpen}
              updateUserTemplateSettings={updateUserTemplateSettings}
              templateSettings={templateSettings}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate}
              selectedTemplateSetting={selectedTemplateSetting}
              clearAllFiltersAndGroups={clearAllFiltersAndGroups}
              filesView={filesView}
            />
          }
          tabs={tabs}
          hideSwitchView={hideSwitchView}
          onTemplateChange={onTemplateChange}
          filesView={filesView}
          fileActionsIcons={fileActionsIcons}
          showColumnSettingsLeft={showColumnSettingsLeft}
          hideSiteHeaderActionButtons={hideSiteHeaderActionButtons}
          hideDropdownContainer={hideSiteHeaderDropdownContainer}
          hideSiteHeaderTitle={hideSiteHeaderTitle}
          noHeader={noHeader}
        />
      )}
      {(showEditColumns || showSearch || showFilter) && (
        <div
          className={`flex justify-between ${leftSearch && "flex-row-reverse"}`}
        >
          {showEditColumns && <SiteHeaderEditColumns />}
          {!showEditColumns && <div className="mr-auto" />}
          <div className="flex items-center">
            {showSearch && (
              <SiteHeaderSearch
                globalFilter={globalFilter}
                handleSearch={onSearchChange}
                fullWidthSearch={fullWidthSearch}
              />
            )}
            {showFilter && (
              <SiteHeaderTableFilter
                filterPopOverClassName={filterPopOverClassName}
                preFilteredRows={preFilteredRows}
                allColumns={allColumns}
                groupOptions={groupOptions}
                setGroupBy={setGroupBy}
                groups={groups}
                groupDispatch={groupDispatch}
                enableGrouping={enableGrouping}
                setAllFilters={setAllFilters}
                hideColumns={hideColumns}
                setHiddenColumns={setHiddenColumns}
                onFilterRequestClose={onfilterRequestClose}
                enableFiltering={enableFiltering}
                filters={filters}
                data={data}
                isTemplateViewOpen={isTemplateViewOpen}
                updateUserTemplateSettings={updateUserTemplateSettings}
                templateSettings={templateSettings}
                applyTemplate={applyTemplate}
                deleteTemplate={deleteTemplate}
                selectedTemplateSetting={selectedTemplateSetting}
                clearAllFiltersAndGroups={clearAllFiltersAndGroups}
              />
            )}
          </div>
        </div>
      )}
      {bannerComponent}
      {summaryComponent}
      {/* Cog wheel icon action to save include columns on settings api */}
      {isEditTableColumn && (
        <AddViewAndFilterPopover
          isOpen={isEditTableColumn}
          onRequestModalClose={() => setIsEditModalctionslOpen(false)}
          allColumns={allColumns}
          groupOptions={groupOptions}
          groups={groups}
          filters={filters}
          tableData={data}
          updateSettings={updateSettings}
          containerClassName="right-0 top-12"
          userSettings={userSettings}
          resourceName={resource}
          isQuickView={false}
        />
      )}
      {!cardGrid && (
        <ScrollSync proportional={false}>
          <div className={containerCN(className)}>
            <div>
              {rowsEditing.length > 0 && (
                <IconLabel
                  icon={crossIcon}
                  text="Cancel Edit Rows"
                  className="ml-5"
                  onClick={onCancelRowsClick}
                />
              )}
            </div>
            <InfiniteScroll
              dataLength={rows.length}
              next={fetchDataOnScroll}
              hasMore={hasMoreData}
              scrollableTarget="mainContainerScroll"
            >
              <div
                {...getTableProps({ style: { minWidth: 0 } })}
                className={tableCN(tableClassName)}
              >
                <Sticky
                  scrollElement="#mainContainerScroll"
                  stickyClassName="z-100"
                >
                  <ScrollSyncNode group="a">
                    <div className="sticky -top-6 z-0 overflow-x-auto bg-white scrollbar-hidden::-webkit-scrollbar scrollbar-hidden border rounded-md border-b-gray-300">
                      {headerGroups.map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <div {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, index) => (
                            <TableHeader
                              disableDragging={disableDragging}
                              key={column.id}
                              id={column.id}
                              column={column}
                              index={index}
                              isLast={headerGroup.headers.length - 1 === index}
                              center={centerColumns}
                              isPrimary={column.isPrimary}
                              disableSortBy={column.disableSortBy}
                              moveColumn={moveColumn}
                              headerCellStyle={headerCellStyle}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  </ScrollSyncNode>
                </Sticky>

                {showGetStarted && data?.length === 0 && !isLoading && (
                  <GetStartedCircle
                    className={getStartedClassName}
                    title={getStartedTitle}
                    onGetStartedClicked={onGetStartedClick}
                  />
                )}
                <ScrollSyncNode group="a">
                  <div
                    onScroll={onScroll}
                    ref={ref}
                    {...getTableBodyProps()}
                    className={
                      stopBodyScroll
                        ? "pb-20"
                        : `pb-20 overflow-x-scroll ${
                            canShowScrollShadow &&
                            "hScrollShadowLeft-hide hScrollShadowRight-hide"
                          } ${showShadowStart && "hScrollShadowLeft"} ${
                            showShadowEnd && "hScrollShadowRight"
                          }`
                    }
                    style={rowBodyStyles}
                    role="presentation"
                    onMouseEnter={() => setCanShowScrollShadow(false)}
                    onMouseLeave={() => setCanShowScrollShadow(true)}
                  >
                    {(groupBy.length &&
                      getTableGroups(pagination ? page : rows)) ||
                      (preFilteredRows.length &&
                        (pagination ? page : rows).map((row, index) => {
                          // hide expanded row as it will be shown in the versions subRows
                          if (!row.isExpanded) {
                            prepareRow(row);
                            return (
                              <React.Fragment key={row.id}>
                                {getTableRow(row, index === rows?.length - 1)}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })) ||
                      (showEmptyTableView && initialRow())}
                  </div>
                </ScrollSyncNode>
                {!!preFilteredRows.length && (
                  <ScrollSyncNode group="a">
                    <div className="overflow-x-auto">
                      {showFooter &&
                        footerGroups.map((footerGroup) => (
                          // eslint-disable-next-line react/jsx-key
                          <div
                            className={tableFooterCN(groupBy.length)}
                            {...footerGroup.getFooterGroupProps()}
                          >
                            {!!groupBy.length && (
                              <div className={totalFooterCN}>TOTAL</div>
                            )}
                            {footerGroup.headers.map(
                              (column) =>
                                !column.isGrouped && (
                                  <TableFooter
                                    key={column.id}
                                    center={centerColumns}
                                    verticalCenter={verticallyCenterColumns}
                                    column={column}
                                  />
                                )
                            )}
                          </div>
                        ))}
                    </div>
                  </ScrollSyncNode>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </ScrollSync>
      )}
      {cardGrid &&
        cardGridComponent &&
        cardGridComponent(pagination ? page : rows)}
      {pagination && pageCount > 1 && (
        <Pagination
          next={nextPage}
          prev={previousPage}
          count={pageCount}
          page={pageIndex}
          goto={gotoPage}
          canNext={canNextPage}
          canPrev={canPreviousPage}
        />
      )}
      {!!groupBy.length && !hideMiniMap && (
        <TableMiniMapView>
          <div className="pl-5 max-h-96 overflow-y-scroll overflow-x-hidden">
            <ul>{recursiveMiniMap(rows)}</ul>
          </div>
        </TableMiniMapView>
      )}
    </div>
  );
};

Table.propTypes = {
  setSearchOverRide: PropTypes.func,
  searchOverRide: PropTypes.string,
  /**
   * addNestedRoute: String ex. "/assets", "/projects"
   * if nestedResource is not being passed through route params
   * add it here to decide switchView of nested table
   */
  addNestedRoute: PropTypes.string,
  /**
   * styles to pass to the containerCN
   */
  className: PropTypes.string,
  /**
   * Styles to pass to the tableCN
   */
  tableClassName: PropTypes.string,
  /**
   * name of the resource the table is displaying. This is used to perserve state for this specific table
   */
  resourceName: PropTypes.string.isRequired,
  /**
   * arrow of columns to display on the table
   * Header is the text to display on the column
   * accessor is the key
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      /**
       * if true, prevents a column can be hidden and moved
       */
      isPrimary: PropTypes.bool,
      /**
       * the element that will be present if the row is changed to an edit state
       */
      Edit: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    })
  ),
  /**
   * the data to display on the table
   * each key in the object should be an accessor for a column
   * and the value for each key should be a string
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * function called when a row is clicked
   * params: content of clicked row
   */
  onTaskClick: PropTypes.func,
  /**
   * function called when a task is clicked
   * params: content of clicked item
   */
  onRowClick: PropTypes.func,
  /**
   * if true, shows a checkbox as the first column of the table
   */
  allowSelection: PropTypes.bool,
  /**
   * enables the edit overlay on all rows
   */
  enableEditing: PropTypes.bool,
  /**
   * enables the delete overlay on all rows
   */
  enableDeleting: PropTypes.bool,
  enablePopOverEditing: PropTypes.bool,
  onEditRowClose: PropTypes.func,
  /**
   * enables the add overlay on a row
   */
  enableAdding: PropTypes.bool,
  /**
   * enables the hover overlay on a row
   */
  disableHover: PropTypes.bool,
  /**
   * function called when a row is saved
   */
  onSaveRowClick: PropTypes.func,
  /**
   * function called when a row is deleted
   */
  onDeleteRowClick: PropTypes.func,
  /**
   * function called when the editing of a row is cancelled
   */
  onEditRowCancelClick: PropTypes.func,
  /**
   * function called when editing begins on a row
   */
  onStartRowEditing: PropTypes.func,
  /**
   * function called when editing ends on a row
   */
  onEndRowEditing: PropTypes.func,
  /**
   * function that returns JSX element
   */
  inLineForm: PropTypes.func,
  /**
   * function to call on add row component is closed
   */
  onAddRowClose: PropTypes.func,
  /*
   * if true, shows a column footer
   */
  showFooter: PropTypes.bool,
  /**
   * Columns to be hidden initially
   */
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  /**
   * Object storing hierarchical and non-hierarchical column list for grouping
   */
  groupOptions: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      )
    ),
    nonHierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  updateUserTemplateSettings: PropTypes.func,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  siteHeaderCreateNewClick: PropTypes.func,
  hideSaveButton: PropTypes.bool,
  hideCreateNewButton: PropTypes.bool,
  /*
   * function called when row selected
   */
  onSelectedRowChange: PropTypes.func,
  rowAssociationMenuList: PropTypes.oneOf([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
    PropTypes.func,
  ]),
  /*
   * function called to append extra grouped headers for columns values that do not exist in table data
   */
  groupByCallback: PropTypes.func,
  showEmptyTableView: PropTypes.bool,
  /*
   * list of columns in grouped template view that will show all possible values as grouped headers
   */
  columnsForGroupByCallback: PropTypes.arrayOf(PropTypes.string),
  /**
   * function called when close button is clicked on a lineitem's version history
   */
  versionCloseCallback: PropTypes.func,
  /**
   * custom cell styling
   */
  cellStyling: PropTypes.string,
  /**
   * custom cell text styling
   */
  cellTextStyling: PropTypes.string,
  /**
   * prevent row container scroll
   */
  stopBodyScroll: PropTypes.bool,
  /**
   * Default sorting for a table if a localstorage value does not exist
   */
  defaultSort: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool,
  }),
  /**
   * Ids of initial selected rows
   */
  initialSelectedRows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  /**
   * bypass delete confirmation modal
   */
  noDeleteConfirm: PropTypes.bool,
  /**
   * Boolean to show constant row index
   */
  showConstantRowIndex: PropTypes.bool,
  /**
   * function called when warning and alert icons are clicked
   */
  onAlertWarningButtonClick: PropTypes.func,
  warningCount: PropTypes.number,
  alertCount: PropTypes.number,
  warningToggle: PropTypes.bool,
  alertToggle: PropTypes.bool,
  setWarningToggle: PropTypes.func,
  setAlertToggle: PropTypes.func,
  /**
   * Boolean to hide grouped row header
   */
  hideGroupedHeader: PropTypes.bool,
  /**
   * Boolean to hide grouped row total footer
   */
  hideGroupedFooter: PropTypes.bool,
  /**
   * Boolean to hide site header
   */
  hideSiteHeader: PropTypes.bool,
  /**
   * style for row container
   */
  rowBodyStyles: PropTypes.shape({}),
  /**
   * Tabs for site header in tabbed container
   */
  tabs: PropTypes.arrayOf(PropTypes.node),
  showEditColumns: PropTypes.bool,
  showSearch: PropTypes.bool,
  showFilter: PropTypes.bool,
  summaryComponent: PropTypes.element,
  hideSwitchView: PropTypes.bool,
  deleteButtonStyle: PropTypes.shape({}),
  deleteUserTemplateSettings: PropTypes.func,
  fixedSelection: PropTypes.bool,
  allowEditingOnRowClick: PropTypes.bool,
  onEditPopoverCloseCallback: PropTypes.func,
  onEditPopoverOpenCallback: PropTypes.func,
  onPendingUpdatesQueueClick: PropTypes.func,
  disableAutoResetSelectedRows: PropTypes.bool,
  showPendingUpdates: PropTypes.bool,
  hideLineNumber: PropTypes.bool,
  disableSorting: PropTypes.bool,
  /**
   * Override Default Select Props
   * Implemented Successfully: TimeSheetApprovalTable
   */
  overrideSelect: PropTypes.bool,
  centerColumns: PropTypes.bool,
  verticallyCenterColumns: PropTypes.bool,
  disableSelectAll: PropTypes.bool,
  getStartedClassName: PropTypes.string,
  getStartedTitle: PropTypes.string,
  onGetStartedClick: PropTypes.func,
  showGetStarted: PropTypes.bool,
  isLoading: PropTypes.bool,
  miniMapContainerId: PropTypes.string,
  hideMiniMap: PropTypes.bool,
  pendingUpdatesTotal: PropTypes.number,
  disableCreateTemplate: PropTypes.bool,
  disableClearView: PropTypes.bool,
  hideSiteHeaderActionButtons: PropTypes.bool,
  hideSiteHeaderDropdownContainer: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
  showColumnSettingsLeft: PropTypes.bool,
  fetchDataOnScroll: PropTypes.func,
  hasMoreData: PropTypes.bool,
  /**
   * Props for nesting card grid inside table
   * - performant
   */
  cardGrid: PropTypes.bool,
  cardGridComponent: PropTypes.func,
  showScrollShadow: PropTypes.bool,
  // Pagination
  // Boolean or number of items per page
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /**
   * Show create report button in table header
   */
  showReportButton: PropTypes.bool,
  /**
   * To open inline edit forms from outside table
   */
  isEditingAllInlineEditForms: PropTypes.bool,
  fullWidthSearch: PropTypes.bool,
};

Table.defaultProps = {
  setSearchOverRide: () => {},
  searchOverRide: "",
  fetchDataOnScroll: () => {},
  className: null,
  addNestedRoute: undefined,
  tableClassName: null,
  columns: [],
  data: [],
  onTaskClick: () => {},
  onRowClick: () => {},
  allowSelection: false,
  enableEditing: false,
  enableDeleting: false,
  enableAdding: false,
  disableHover: false,
  onSaveRowClick: undefined,
  onDeleteRowClick: undefined,
  onEditRowCancelClick: undefined,
  onStartRowEditing: undefined,
  onEndRowEditing: undefined,
  onAddRowClose: undefined,
  showFooter: false,
  hiddenColumns: [],
  groupOptions: undefined,
  templateSettings: [],
  updateUserTemplateSettings: () => {},
  siteHeaderCreateNewClick: undefined,
  onEditRowClose: undefined,
  enablePopOverEditing: false,
  inLineForm: undefined,
  hideCreateNewButton: false,
  hideSaveButton: false,
  onSelectedRowChange: undefined,
  rowAssociationMenuList: [],
  groupByCallback: undefined,
  columnsForGroupByCallback: [],
  showEmptyTableView: false,
  versionCloseCallback: undefined,
  cellStyling: undefined,
  cellTextStyling: undefined,
  stopBodyScroll: false,
  defaultSort: undefined,
  initialSelectedRows: [],
  noDeleteConfirm: false,
  showConstantRowIndex: false,
  onAlertWarningButtonClick: undefined,
  warningCount: 0,
  alertCount: 0,
  warningToggle: false,
  alertToggle: false,
  setWarningToggle: undefined,
  setAlertToggle: undefined,
  hideGroupedHeader: false,
  hideGroupedFooter: false,
  hideSiteHeader: false,
  rowBodyStyles: undefined,
  tabs: undefined,
  showEditColumns: false,
  showSearch: false,
  showFilter: false,
  summaryComponent: undefined,
  hideSwitchView: false,
  deleteButtonStyle: undefined,
  deleteUserTemplateSettings: undefined,
  fixedSelection: false,
  allowEditingOnRowClick: false,
  onEditPopoverCloseCallback: undefined,
  onEditPopoverOpenCallback: undefined,
  onPendingUpdatesQueueClick: undefined,
  disableAutoResetSelectedRows: false,
  showPendingUpdates: false,
  hideLineNumber: false,
  disableSorting: false,
  overrideSelect: false,
  centerColumns: false,
  verticallyCenterColumns: false,
  disableSelectAll: false,
  getStartedClassName: "",
  getStartedTitle: "",
  onGetStartedClick: undefined,
  showGetStarted: false,
  isLoading: false,
  miniMapContainerId: undefined,
  hideMiniMap: false,
  pendingUpdatesTotal: undefined,
  disableCreateTemplate: false,
  disableClearView: false,
  hideSiteHeaderActionButtons: false,
  hideSiteHeaderDropdownContainer: false,
  hideSiteHeaderTitle: false,
  showColumnSettingsLeft: true,
  hasMoreData: false,
  cardGrid: undefined,
  cardGridComponent: undefined,
  showScrollShadow: true,
  pagination: 25,
  showReportButton: false,
  isEditingAllInlineEditForms: undefined,
  fullWidthSearch: false,
};

export default Table;
