import React from "react";
import PropTypes from "prop-types";
import useTrayContainerData from "./useTrayContainerData";

const TrayContainer = ({ isOpen, children, width, height, close }) => {
  const { dynamicHeigth, wrapperRef } = useTrayContainerData({ height, close });

  return (
    <div
      ref={wrapperRef}
      className="shadow-lg transition-all rounded-bl-lg duration-300 ease-in-out bg-backgroundGreyTrays overflow-hidden"
      style={{
        width,
        position: "absolute",
        zIndex: 9998,
        right: isOpen ? 0 : width * -1,
        top: "72px",
        height: dynamicHeigth,
        minHeight: "600px",
      }}
    >
      {children}
    </div>
  );
};

TrayContainer.defaultProps = {
  isOpen: false,
  width: 420,
  height: undefined,
  children: undefined,
  close: undefined,
};

TrayContainer.propTypes = {
  isOpen: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  close: PropTypes.func,
};

export default TrayContainer;
