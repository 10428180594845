/* eslint no-underscore-dangle: 0 */
import { BudgetAPI, LineitemAPI } from "@griffingroupglobal/eslib-api";
import * as yup from "yup";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_ASSET,
  CREATE_EVENT_MODAL,
  CREATE_SPACE_MODAL,
  CREATE_TASK_MODAL,
  EXPENSE_CREATE_MODAL,
  REPORT_CREATE_MODAL,
  SERVICE_REQUEST_CREATE_MODAL,
  PROJECT_TABS,
  SUBMITTAL_CREATE_MODAL,
  WORKFLOW_CREATE_MODAL,
  MARK_AS_COMPLETE_BUTTON_TITLE,
  PAGES,
} from "../../constants";
import { onUpdateFile } from "../../helpers/File";
import { projectBudgetInfoSchema } from "../../helpers/FormValidations";
import { toastError, toastMessage } from "../../helpers/Toast";
import useAssociatedFiles from "../../hooks/useAssociatedFiles";
import useAttributes from "../../hooks/useAttributes";
import useBudgetLineItems from "../../hooks/useBudgetLineItems";
import useEditModal from "../../hooks/useEditModal";
import useManagementConfiguration from "../../hooks/useManagementConfiguration";
import useProjectBudgetFormReducer from "../../hooks/useProjectBudgetFormReducer";
import useRelativeAssociations from "../../hooks/useRelativeAssociations";
import useWidgetTabNavigation from "../../hooks/useWidgetTabNavigation";
import { useModalState } from "../../state/modalState";
import ActivateProjectForm from "../../stories/Components/ActivateProjectForm/ActivateProjectForm";
import MaintenanceScheduleModal from "../../stories/Components/AssetForms/MaintenanceScheduleModal";
import FormAvatar from "../../stories/Components/Avatar/FormAvatar";
import BetaTag from "../../stories/Components/BetaTag";
import BudgetTable from "../../stories/Components/BudgetTable/BudgetTable";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import ExpenseTable from "../../stories/Components/ExpenseTable/ExpenseTable";
import FilesTable from "../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import Modal from "../../stories/Components/Modal/Modal";
import RequestTable from "../../stories/Components/RequestTable/RequestTable";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import UploadFile from "../../stories/Components/UploadFile/UploadFile";
import Widget from "../../stories/Components/Widget/Widget";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import WorkflowTable from "../../stories/Components/WorkflowTableNew";
import whiteCircleCheckIcon from "../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../stories/assets/images/whiteExclamationIcon.svg";
import CalendarView from "../Calendar/CalendarView";
import Assets from "../Overviews/Asset/Assets";
import ServiceRequestsOverview from "../Overviews/ServiceRequests";
import TaskList from "../Overviews/Task/TaskList";
import ReportsOverview from "../Reports/ReportOverview";
import Spaces from "../Spaces/Spaces";
import ProjectDeleteModal from "./DeleteModal";
import ProjectDetailView from "./ProjectDetailView";
import ProjectDocuments from "./ProjectDocuments";
import ProjectFinances from "./ProjectFinances";
import useProjectViewData from "./useProjectViewData";
import {
  hasReadPermission,
  hasWritePermission,
} from "../../helpers/Permissions";
import useFilesPost from "../../hooks/useFilesPost";
import uploadAvatar from "../../helpers/uploadAvatar";
import useEditingResourceState from "../../hooks/useEditingResourceState";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const ProjectView = ({ currentUser }) => {
  const { projectId } = useParams();

  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  // Mutation hook to post files
  const { mutateAsync: postFiles } = useFilesPost();

  const {
    isEditing,
    project,
    isLoading,
    nameExists,
    editedProject,
    isProjectEdited,
    validRateSheet,
    handlePatchProject,
    handleActivateProject,
    handleCompleteProject,
    dispatch,
    setIsEditing,
    fetchProjectByName,
  } = useProjectViewData(projectId);

  const { data: managementConfiguration } = useManagementConfiguration();

  const params = useMemo(
    () => ({ association: `Project/${projectId}` }),
    [projectId]
  );
  const {
    associatedFiles,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);
  const { associationLock } = useRelativeAssociations();
  const [, setIsEditModalOpen] = useEditModal();

  const [reloadMeasurements] = useAttributes();

  const [projectBudget, dispatchProjectBudget] = useProjectBudgetFormReducer();
  const { reloadBudgetLineItems } = useBudgetLineItems(
    project?.budget.split("/")[1]
  );

  const [isSaving, setIsSaving] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isReconcileBudgetValid, setIsReconcileBudgetValid] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  const [completeProjectModal, setCompleteProjectModal] = useState(false);
  const [activateProjectModal, setActivateProjectModal] = useState(false);
  const [isActivatingProject, setIsActivatingProject] = useState(false);
  const [budgetButtonActions, setBudgetButtonActions] = useState([]);
  const [draftBudgetLineItems, setDraftBudgetLineItems] = useState([]);
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});

  const [buttonActions, setButtonActions] = useState([]);

  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [isEditableTab, setIsEditableTab] = useState(false);
  const isDraft = editedProject.status === "draft";
  const isActive = editedProject.status === "active";

  // handle editing state of resource being edited
  useEditingResourceState({
    editing: isEditing,
    resource: "Project",
  });

  const handleEditClick = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    setIsEditing(false);
  }, [isEditing, setIsEditing]);

  const resetProjectState = useCallback(() => {
    setTmpAvatarImg({});
    dispatch({
      type: "reset",
      project,
    });
  }, [project, dispatch]);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/projects/123", currentPage will be "projects"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.PROJECTS) {
      setCurrentResourceScreen(PAGES.PROJECTS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  useEffect(() => {
    if (project) {
      resetProjectState();
    }
  }, [project, resetProjectState]);

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...project.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        project.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      handlePatchProject(updatedProject, project);
      // update associated files state
      addFiles(imageResources);
    },
    [addFiles, handlePatchProject, project]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      const updatedFiles = project.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(project?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : project.primaryImage;
      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };

      handlePatchProject(updatedProject, project)
        .then(() => {
          // update associated files state
          removeFilesAndUpdateApi(imageRefs);
        })
        .catch(() => {
          toastError("Error removing media", toastErrorIcon, toastCloseIcon);
        });
    },
    [handlePatchProject, project, removeFilesAndUpdateApi]
  );

  const updateProjectFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = project.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(project?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : project.primaryImage;
      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      handlePatchProject(updatedProject, project);
    },
    [handlePatchProject, project]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedProject = {
        ...project,
        primaryImage: imageRef,
      };
      // patch resource
      handlePatchProject(updatedProject, project);
    },
    [handlePatchProject, project]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const updatedFiles = [
        ...project.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedProject = {
        ...project,
        files: updatedFiles,
        primaryImage:
          project?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };
      // patch resource
      handlePatchProject(updatedProject, project);
    },
    [addFiles, handlePatchProject, project]
  );

  const onFinishEditingRef = useRef(null);

  useEffect(() => {
    return () => {
      if (onFinishEditingRef.current) clearTimeout(onFinishEditingRef.current);
    };
  }, []);

  const handleEdit = useCallback(
    (key, value) => {
      dispatch({
        type: "edit",
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleEditTwo = useCallback(
    (key1, key2, value) => {
      dispatch({
        type: "edit2",
        key1,
        key2,
        value,
      });
    },
    [dispatch]
  );

  const onFinishEditing = useCallback(
    async (key, val) => {
      setIsSaving(true);
      // duration value must be a number >=0. set it to 0 otherwise
      const validDuration = !editedProject?.duration.value
        ? { ...editedProject?.duration, value: 0 }
        : {
            ...editedProject?.duration,
            value: Math.round(Number(editedProject?.duration.value) ?? 0),
          };

      const newEditedProject = await uploadAvatar({
        tmpAvatarImg,
        resourceState: editedProject,
        postFiles,
      });

      const finishedProject = {
        ...newEditedProject,
        duration: validDuration,
        tags:
          newEditedProject?.currentTags?.map((tag) => tag?.value) ||
          newEditedProject?.tags,
        [key]: val,
      };

      delete finishedProject?.originalResource;
      delete finishedProject?.currentTags;

      await handlePatchProject(finishedProject, project);

      const SavingDelay = () => {
        return new Promise((resolve) => {
          onFinishEditingRef.current = setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      const posts = [SavingDelay];

      const arrayOfPosts = posts?.map((post) => post());

      if (arrayOfPosts?.length) {
        arrayOfPosts.push(() => {
          reloadMeasurements();
        });
      }
      Promise.all(arrayOfPosts);
      setIsSaving(false);
    },
    [
      project,
      editedProject,
      handlePatchProject,
      reloadMeasurements,
      postFiles,
      tmpAvatarImg,
    ]
  );

  const handleOnCloseModal = useCallback(async () => {
    setShowMaintenanceModal(false);
  }, []);

  /**
   * New Handlers
   */

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedProject = {
            ...project,
            files: [
              ...project?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return handlePatchProject(updatedProject, project);
        })
        .then((resource) =>
          toastMessage(
            `Recent file successfully attached to ${resource?.name}`,
            toastIcon,
            toastCloseIcon
          )
        )
        .catch(() => {
          toastError(
            `Error attaching recent file`,
            toastErrorIcon,
            toastCloseIcon
          );
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, handlePatchProject, project, removeFilesAndUpdateApi]
  );

  const timeoutId = useRef(null);

  const handleChangeName = (name) => {
    dispatch({
      type: "name",
      name,
    });

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // debounce check if project name is in use
    timeoutId.current = setTimeout(async () => {
      await fetchProjectByName(name);
      clearTimeout(timeoutId.current);
    }, 300);
  };

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    checkValidation(projectBudget, projectBudgetInfoSchema);
  }, [projectBudget, checkValidation]);

  useEffect(() => {
    const isAnyUnselected = draftBudgetLineItems.find(
      (li) => !li.isHighRangeSelected && !li.isLowRangeSelected
    );
    setIsReconcileBudgetValid(!isAnyUnselected);
  }, [draftBudgetLineItems]);

  const onCreateBudget = useCallback(async () => {
    try {
      setIsActivatingProject(true);

      // reconcile budget
      Promise.all(
        draftBudgetLineItems.map(async (li) => {
          if (li?.isHighRangeSelected) {
            await LineitemAPI.patch(
              li.id,
              {
                ...li,
                costPerUnit: li?.highRange?.costPerUnit,
                quantity: li?.highRange?.quantity,
                adjustment: {
                  arithmeticUnit: li?.highRange?.arithmeticUnit,
                  adjustmentUnit: li?.highRange?.adjustmentUnit,
                  amount: li?.highRange?.amount,
                },
                isCostRange: false,
              },
              li
            );
          }
          return li;
        })
      );

      const { data } = await BudgetAPI.getById(project.budget.split("/")[1]);
      // set budget type
      await BudgetAPI.patch(
        project.budget.split("/")[1],
        {
          ...data,
          ...projectBudget,
        },
        data
      );

      reloadBudgetLineItems();

      // update project status
      await handleActivateProject();

      dispatch({
        type: "changeStatus",
        value: "active",
      });
    } catch (err) {
      toastError(
        `Error Activating Project. ${err.message || ""}.`,
        toastErrorIcon,
        toastCloseIcon
      );
    } finally {
      setIsActivatingProject(false);
      setActivateProjectModal(false);
    }
  }, [
    handleActivateProject,
    draftBudgetLineItems,
    setActivateProjectModal,
    project,
    projectBudget,
    reloadBudgetLineItems,
    dispatch,
  ]);

  const handleContinue = async () => {
    await onCreateBudget();
  };

  /**
   * New Handlers
   */

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: PROJECT_TABS.DETAILS_ID,
          title: PROJECT_TABS.DETAILS_TITLE,
          content: (
            <ProjectDetailView
              editedProject={editedProject}
              loading={isLoading}
              isEditing={isEditing}
              config={managementConfiguration?.management}
              dispatch={dispatch}
              handleEdit={handleEdit}
              handleEditTwo={handleEditTwo}
              onFinishEditing={onFinishEditing}
              originalResource={project}
              setMemberLoading={setMemberLoading}
              memberLoading={memberLoading}
              disableEditing={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_project"
                )
              }
            />
          ),
        },
      ],
    };

    if (currentUser?.hasPermission?.("budget", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.BUDGET_ID,
        title: (
          <div className="flex">
            <span>{PROJECT_TABS.BUDGET_TITLE}</span>
            <BetaTag />
          </div>
        ),
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <BudgetTable
              budgetId={project?.budget.split("/")[1]}
              projectId={projectId}
              projectData={editedProject}
              setButtonActions={setBudgetButtonActions}
              hideSiteHeaderTitle
            />
          </Widget>
        ),
      });
    }

    permissionedTabs.tabs.push({
      id: PROJECT_TABS.FINDOCS_ID,
      title: (
        <div className="flex">
          <span>{PROJECT_TABS.FINDOCS_TITLE}</span>
          <BetaTag />
        </div>
      ),
      content: (
        <Widget draggable={false} title={null} overflow backToTop>
          <ProjectDocuments currentUser={currentUser} />
        </Widget>
      ),
    });

    if (currentUser?.hasPermission?.("expense", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.EXPENSES_ID,
        title: PROJECT_TABS.EXPENSES_TITLE,
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <ExpenseTable
              association={editedProject}
              createExpenseModal={createExpenseModal}
              setCreateExpenseModal={setCreateExpenseModal}
              isTabView
              hideSwitchView
              hideSiteHeaderTitle
            />
          </Widget>
        ),
      });
    }

    if (currentUser?.hasPermission?.("project", "can_read_financials")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.FINANCES_ID,
        title: PROJECT_TABS.FINANCES_TITLE,
        content: (
          <ProjectFinances
            editedProject={editedProject}
            isEditing={isEditing}
            dispatch={dispatch}
          />
        ),
      });
    }

    if (
      currentUser?.hasPermission?.("event", "can_read") ||
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.CALENDAR_ID,
        title: PROJECT_TABS.CALENDAR_TITLE,
        content: <CalendarView currentUser={currentUser} isTabView />,
      });
    }

    if (
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.TASKS_ID,
        title: PROJECT_TABS.TASKS_TITLE,
        content: <TaskList />,
      });
    }

    if (currentUser?.hasPermission?.("ticket", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.REQUESTS_ID,
        title: PROJECT_TABS.REQUESTS_TITLE,
        content: (
          <ServiceRequestsOverview
            association={`Project/${projectId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });
    }

    if (currentUser?.hasPermission?.("submittal", "can_see")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.SUBMITTALS_ID,
        title: PROJECT_TABS.SUBMITTALS_TITLE,
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <RequestTable
              associatedResource={`Project/${projectId}`}
              isTabView
            />
          </Widget>
        ),
      });
    }

    if (currentUser?.hasPermission?.("workflow", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.WORKFLOWS_ID,
        title: PROJECT_TABS.WORKFLOWS_TITLE,
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <WorkflowTable
              associatedResource={`Project/${projectId}`}
              isTabView
            />
          </Widget>
        ),
      });
    }

    permissionedTabs.tabs.push({
      id: PROJECT_TABS.FILES_ID,
      title: PROJECT_TABS.FILES_TITLE,
      content: (
        <Widget draggable={false} title={null} overflow backToTop>
          <FilesTable
            files={associatedFiles}
            onAddFilesCallback={onAddFilesCallback}
            removeFilesAndUpdateApi={removeFilesAndUpdateApi}
            onRemoveFilesCallback={updateProjectFiles}
            setIsEditModalOpen={setIsEditModalOpen}
            resourceName="Project"
            association={`Project/${projectId}`}
            hasDeletePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_project"
            )}
            hasWritePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_project"
            )}
            hasEditPermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_project"
            )}
            handleFileClone={handleFileClone}
            handleUpdateFile={handleUpdateFile}
          />
        </Widget>
      ),
    });

    permissionedTabs.tabs.push({
      id: PROJECT_TABS.MEDIA_ID,
      title: PROJECT_TABS.MEDIA_TITLE,
      content: (
        <ImagesAndVideosWidget
          resource={project}
          disableEditing={
            !currentUser?.hasPermission?.("administrative", "can_write_project")
          }
          hasWritePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_project"
          )}
          hasDeletePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_project"
          )}
          handleAddMedia={handleAddMedia}
          handleSetPrimaryMedia={handleSetPrimaryMedia}
          handleRemoveMedia={handleRemoveMedia}
        />
      ),
    });

    if (hasReadPermission(ASSET, currentUser)) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.ASSETS_ID,
        title: PROJECT_TABS.ASSETS_TITLE,
        content: <Assets />,
      });
    }

    permissionedTabs.tabs.push({
      id: PROJECT_TABS.SPACES_ID,
      title: PROJECT_TABS.SPACES_TITLE,
      content: <Spaces />,
    });

    if (currentUser?.hasPermission?.("report", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROJECT_TABS.REPORTS_ID,
        title: (
          <div className="flex">
            <span>{PROJECT_TABS.REPORTS_TITLE}</span>
            <BetaTag />
          </div>
        ),
        content: <ReportsOverview association={`Project/${projectId}`} />,
      });
    }

    return permissionedTabs;
  }, [
    associatedFiles,
    createExpenseModal,
    currentUser,
    dispatch,
    editedProject,
    handleAddMedia,
    handleEdit,
    handleEditTwo,
    handleFileClone,
    handleRemoveMedia,
    handleSetPrimaryMedia,
    handleUpdateFile,
    isEditing,
    isLoading,
    managementConfiguration?.management,
    memberLoading,
    onAddFilesCallback,
    onFinishEditing,
    project,
    projectId,
    removeFilesAndUpdateApi,
    setIsEditModalOpen,
    updateProjectFiles,
  ]);

  const { activeTab, setActiveTab } = useWidgetTabNavigation({
    page: PAGES.PROJECTS,
    resourceId: projectId,
  });

  // required fields validation
  const requiredFieldsFilled =
    Boolean(editedProject?.name) &&
    Boolean(editedProject?.timezone) &&
    Boolean(editedProject?.projectType);

  // validation for unique name
  const nameIsUnique =
    project?.name?.toLowerCase() === editedProject?.name?.toLowerCase()
      ? true
      : !nameExists;

  // validation for Details tab
  const isTabDetails =
    !isEditing || nameIsUnique
      ? !isProjectEdited || !requiredFieldsFilled
      : !nameIsUnique;

  // validation for Details tab
  const isTabFinance =
    !isEditing || nameIsUnique
      ? !isProjectEdited || !requiredFieldsFilled || !validRateSheet
      : !nameIsUnique;

  // save button validation
  const disableSaveButton = !isEditing || isTabDetails || isTabFinance;

  // Render Tab Specific CTA Button
  const [{ modals }, modalDispatch] = useModalState();
  /**
   * @TODO - TechDebt: Convert All Create Modals legacy->ESModal(Can open modal from any component)
   * @summary - will remove the need for the button action pingpong
   * Parent (setter) -> Child (btnAction) -> Parent
   */

  const ActionButton = useMemo(() => {
    // By default, hide the edit pencil
    setIsEditableTab(false);

    // Spreadable Prop Object
    let buttonProps = { dropdownItems: [] };

    switch (activeTab) {
      case PROJECT_TABS.DETAILS_ID: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          setIsEditableTab(true);

          if (isDraft) {
            buttonProps = {
              ...buttonProps,
              dropdownItems: [
                ...buttonProps?.dropdownItems,
                {
                  title: "Activate Project",
                  onClick: () => {
                    setActivateProjectModal(true);
                  },
                },
              ],
            };
          }
        }

        if (
          currentUser?.hasPermission?.("administrative", "can_delete_project")
        ) {
          buttonProps = {
            ...buttonProps,
            dropdownItems: [
              ...buttonProps?.dropdownItems,
              {
                title: "Delete Project",
                onClick: () => {
                  setShowDeleteModal(true);
                },
              },
            ],
          };
        }
        break;
      }
      case PROJECT_TABS.BUDGET_ID: {
        if (!editedProject) {
          return null;
        }

        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          if (isDraft) {
            buttonProps = {
              ...buttonProps,
              dropdownItems: [
                ...buttonProps?.dropdownItems,
                {
                  title: "Activate Project",
                  onClick: () => {
                    setActivateProjectModal(true);
                  },
                },
              ],
            };
          }

          buttonProps = {
            dropdownItems: [
              ...buttonProps?.dropdownItems,
              ...budgetButtonActions,
            ],
            title: "Actions",
            className: "dropdown-btn",
            large: true,
          };
        }
        break;
      }
      case PROJECT_TABS.EXPENSES_ID: {
        if (currentUser?.hasPermission?.("expense", "can_write")) {
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalType: EXPENSE_CREATE_MODAL,
                modalData: {
                  viewMode: "create",
                  associationLock: `Project/${projectId}`,
                },
              });
            },
            addButton: true,
            resourceName: "Expense",
            disabled: modals.find(
              (item) => item.modalType === EXPENSE_CREATE_MODAL
            ),
          };
        }
        break;
      }
      case PROJECT_TABS.FINANCES_ID: {
        if (currentUser?.hasPermission?.("property", "can_update_financials")) {
          setIsEditableTab(true);
        }
        break;
      }
      case PROJECT_TABS.CALENDAR_ID: {
        const newDDItems = [];
        const disabledAddEventBtn = modals.find(
          (item) => item.modalType === CREATE_EVENT_MODAL
        );
        const disabledAddTaskBtn = modals.find(
          (item) => item.modalType === CREATE_TASK_MODAL
        );

        if (currentUser?.hasPermission?.("task", "can_create")) {
          if (!disabledAddEventBtn)
            newDDItems.push({
              title: "Add Event",
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalData: { associationLock: false },
                  modalType: CREATE_EVENT_MODAL,
                }),
            });

          if (!disabledAddTaskBtn)
            newDDItems.push({
              title: "Add Task",
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalData: {
                    associationLock,
                  },
                  modalType: CREATE_TASK_MODAL,
                }),
              disabled: modals.find(
                (item) => item.modalType === CREATE_TASK_MODAL
              ),
            });

          buttonProps = {
            dropdownItems: newDDItems,
            title: "Actions",
            className: "dropdown-btn",
            large: true,
          };
        }
        break;
      }
      case PROJECT_TABS.TASKS_ID: {
        if (currentUser?.hasPermission?.("task", "can_create")) {
          buttonProps = {
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock, to: "task_list" },
                modalType: CREATE_TASK_MODAL,
              }),
            addButton: true,
            resourceName: "Task",
            disabled: modals.find(
              (item) => item.modalType === CREATE_TASK_MODAL
            ),
          };
        }
        break;
      }
      case PROJECT_TABS.REQUESTS_ID: {
        const actionButton = buttonActions?.[0] || {};
        if (
          actionButton.title === MARK_AS_COMPLETE_BUTTON_TITLE &&
          currentUser?.hasPermission?.("ticket", "can_update")
        ) {
          // SR is rendered in Project association Tab
          buttonProps = {
            ...buttonActions?.[0],
            addButton: true,
            resourceName: "Ticket",
            buttonTitle: actionButton.title,
          };
        } else if (currentUser?.hasPermission?.("ticket", "can_create")) {
          // SR is rendered on its own page
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: {
                  viewMode: "create",
                  associationLock: `Project/${projectId}`,
                },
                modalType: SERVICE_REQUEST_CREATE_MODAL,
              });
            },
            addButton: true,
            resourceName: "Ticket",
            disabled: modals.find(
              (item) => item.modalType === SERVICE_REQUEST_CREATE_MODAL
            ),
          };
        }
        break;
      }
      case PROJECT_TABS.SUBMITTALS_ID: {
        if (
          isActive &&
          currentUser?.hasPermission?.("submittal", "can_create")
        ) {
          buttonProps = {
            onClick: () => {
              return modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: "create-submittal-modal-project" },
                modalData: {
                  id: "create-submittal-modal-project",
                  item: {
                    association: project?.reference,
                    disableAssociation: true,
                    documents: project?.documents ?? [],
                  },
                },
                modalType: SUBMITTAL_CREATE_MODAL,
              });
            },
            addButton: true,
            resourceName: "Submittal",
            disabled: modals.find(
              (item) => item.modalType === SUBMITTAL_CREATE_MODAL
            ),
          };
        }
        break;
      }
      case PROJECT_TABS.WORKFLOWS_ID: {
        if (
          isActive &&
          currentUser?.hasPermission?.("workflow", "can_create")
        ) {
          buttonProps = {
            onClick: () => {
              return modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: "create-workflow-modal-project" },
                modalData: {
                  id: "create-workflow-modal-project",
                  association: project?.reference,
                  isQuickAdd: false,
                },
                modalType: WORKFLOW_CREATE_MODAL,
              });
            },
            addButton: true,
            resourceName: "Workflow",
            disabled: modals.find(
              (item) => item.modalType === WORKFLOW_CREATE_MODAL
            ),
          };
        }
        break;
      }
      case PROJECT_TABS.FILES_ID: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          buttonProps = {
            addButton: true,
            resourceName: "Files",
            onClick: () =>
              document
                .querySelector(`div.project-table-view .upload_area_click`)
                ?.click(),
          };
        }
        break;
      }
      case PROJECT_TABS.MEDIA_ID: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          buttonProps = {
            addButton: true,
            resourceName: "Media",
            onClick: () => document.getElementById("upload-form-input").click(),
          };
        }
        break;
      }
      case PROJECT_TABS.ASSETS_ID: {
        if (hasWritePermission(ASSET, currentUser)) {
          buttonProps = {
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                modalData: {
                  item: {
                    projectId,
                    association: project?.reference,
                    associationType: "Project",
                    disableAssociation: true,
                  },
                },
                modalType: CREATE_ASSET,
                ref: { id: uuidv4() },
              }),
            addButton: true,
            resourceName: "Asset",
            disabled: modals.find((item) => item.modalType === CREATE_ASSET),
          };
        }
        break;
      }
      case PROJECT_TABS.SPACES_ID: {
        if (
          currentUser?.hasPermission?.("administrative", "can_write_project")
        ) {
          buttonProps = {
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: {
                  viewMode: "create",
                  associationLock: `Project/${projectId}`,
                },
                modalType: CREATE_SPACE_MODAL,
              }),
            addButton: true,
            resourceName: "Space",
            disabled: modals.find(
              (item) => item.modalType === CREATE_SPACE_MODAL
            ),
          };
        }
        break;
      }
      case PROJECT_TABS.REPORTS_ID: {
        if (currentUser?.hasPermission?.("report", "can_write")) {
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: {
                  viewMode: "create",
                  association: `Project/${projectId}`,
                },
                modalType: REPORT_CREATE_MODAL,
              });
            },
            addButton: true,
            resourceName: "Report",
            disabled: modals.find(
              (item) => item.modalType === REPORT_CREATE_MODAL
            ),
          };
        }
        break;
      }
      default:
        break;
    }

    if (!buttonProps?.dropdownItems?.length && !buttonProps.addButton) {
      return null;
    }

    return <PrimaryButton {...buttonProps} />;
  }, [
    buttonActions,
    modals,
    activeTab,
    associationLock,
    budgetButtonActions,
    currentUser,
    editedProject,
    isActive,
    isDraft,
    modalDispatch,
    project?.reference,
    project?.documents,
    projectId,
  ]);

  /**
   * Clean Up Actions CTA Menu
   */

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing={
                currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_project"
                ) && isEditing
              }
              disabled={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_project"
                ) || !isEditing
              }
              image={editedProject?.primaryImage}
              tmpAvatarImg={tmpAvatarImg}
              setTmpAvatarImg={setTmpAvatarImg}
              resourceName="Project"
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={editedProject?.name}
              editing={isEditing}
              loading={isLoading || isSaving}
              disabled={!isEditing}
              fontWeight="bold"
              color="gray-650"
              onChangeCallback={handleChangeName}
              hidePencil
              isHeaderTitle
              validation={
                isEditing &&
                yup
                  .string()
                  .trim()
                  .required("Name cannot be blank.")
                  .test(
                    "",
                    "Name must be unique",
                    () => !(isEditing && !nameIsUnique)
                  )
              }
            />
          </div>
        }
        buttons={ActionButton}
      />

      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={isEditing}
        handleEditClick={isEditableTab && handleEditClick}
        onFinishEditing={onFinishEditing}
        tabs={tabs?.tabs}
        loading={isLoading || isSaving}
        disableEditing={
          !currentUser?.hasPermission?.("administrative", "can_write_project")
        }
        activeTab={activeTab}
        onTabClick={(t) => setActiveTab(t)}
        resetResourceState={resetProjectState}
        disableSaveBtn={disableSaveButton}
        hideBottomCancelSaveButtons
      />
      <UploadFile
        id="project-details"
        association={`Project/${projectId}`}
        onAddFilesCallback={onAddFilesCallback}
      />
      <ProjectDeleteModal
        project={project}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />

      {
        /**
         * @deprecated
         */
        showMaintenanceModal && (
          <MaintenanceScheduleModal
            onCloseModal={handleOnCloseModal}
            association={{
              reference: editedProject?.reference,
              name: editedProject?.name,
              project: editedProject?.reference,
            }}
          />
        )
      }

      <Modal
        title="Activate Project"
        isOpen={activateProjectModal}
        alert
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        primaryButtonTitle="Activate"
        tertiaryButtonTitle="Cancel"
        primaryButtonOnClick={handleContinue}
        onRequestModalClose={() => setActivateProjectModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        disabled={!isInputValid || !isReconcileBudgetValid}
        hideFooter
        childContainerClassName={`modal-content-class-no-padding
          ${isActivatingProject && "loading"}`}
      >
        <div className="ml-7 mt-5">
          <ActivateProjectForm
            projectData={editedProject}
            projectBudget={projectBudget}
            dispatchProjectBudget={dispatchProjectBudget}
            setActivateProjectModal={setActivateProjectModal}
            setDraftBudgetLineItems={setDraftBudgetLineItems}
          />
        </div>
      </Modal>
      <Modal
        title="Complete Project"
        isOpen={completeProjectModal}
        alert
        titleStyle={{
          color: "#FFFFFF",
        }}
        headerStyle={{
          background: "#626262",
          color: "#FFF",
          border: "none",
        }}
        primaryButtonTitle="Yes, Complete"
        tertiaryButtonTitle="Cancel"
        primaryButtonOnClick={() => {
          setCompleteProjectModal(false);
          handleCompleteProject();
        }}
        onRequestModalClose={() => setCompleteProjectModal(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want complete {editedProject.name}?
          </p>
        </>
      </Modal>
    </>
  );
};

ProjectView.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
  userEvents: PropTypes.shape({}),
  updateUserEvent: PropTypes.shape({}),
  isTest: PropTypes.bool,
  reloadEvents: PropTypes.func,
  usersDict: PropTypes.shape({}),
  setOpenNewExpenseModal: PropTypes.bool,
};

ProjectView.defaultProps = {
  currentUser: undefined,
  userEvents: undefined,
  updateUserEvent: undefined,
  isTest: false,
  reloadEvents: undefined,
  usersDict: {},
  setOpenNewExpenseModal: false,
};

export default ProjectView;
