import {
  TIMESHEET_NO_CATEGORIES_ERROR,
  TIMESHEET_NO_PROJECTS_ERROR,
  TIMESHEET_NO_RATES_ERROR,
} from "../../../../../constants";

const useTsViewDdSectionData = ({
  entriesForEditing,
  projectsState,
  projectDict,
  projectsDD,
  financialsConfiguration,
  filteredCategoriesByProjectRate,
  csiCodesMap,
  filterRatesByProject,
}) => {
  const tsEntryData = Object.entries(entriesForEditing || {})?.map(
    ([key, values]) => {
      const projectReference = values.find((value) => value.project)?.project;

      const financialCodes = values.find(
        (value) => value.financialCode
      )?.financialCode;

      const financialCodeString =
        financialCodes.division !== ""
          ? `${financialCodes?.division}-${financialCodes?.code}-${financialCodes?.subcode}`
          : "";

      const rates = values.find((value) => value.rate)?.rate;
      let ratesArr =
        projectsState[projectReference]?.codes?.map((code) => code.rate) || [];

      if (
        ratesArr.length === 0 &&
        projectsState[projectReference]?.useall === false
      ) {
        ratesArr = financialsConfiguration?.financials?.rateSheet?.rates || [];
      }

      if (projectsState[projectReference]?.useall === true) {
        ratesArr = projectDict[projectReference]?.rateSheet?.rates || [];
      }

      let rateValue = rates
        ? {
            label: ratesArr?.find((r) => r?.id === rates)?.category,
            value: rates,
          }
        : undefined;

      if (projectsState[projectReference]?.useall === true) {
        const foundRate = ratesArr?.find((r) => r?.id === rates);

        rateValue = rates
          ? {
              label: foundRate?.category,
              value: foundRate?.id,
            }
          : undefined;
      }

      const categoryValue = financialCodeString
        ? {
            label: csiCodesMap[financialCodeString],
            value: financialCodeString,
          }
        : undefined;

      const projectsValue = projectReference
        ? {
            label: projectDict[projectReference]?.name,
            value: projectReference,
          }
        : undefined;

      const ddErrors = [];

      if (projectsDD?.length === 0) {
        ddErrors.push(TIMESHEET_NO_PROJECTS_ERROR);
      }

      if (projectsValue && ratesArr?.length === 0) {
        ddErrors.push(TIMESHEET_NO_RATES_ERROR);
      }

      const categoryDD =
        filteredCategoriesByProjectRate(projectReference, rates) || [];

      if (rateValue && categoryDD?.length === 0) {
        ddErrors.push(TIMESHEET_NO_CATEGORIES_ERROR);
      }

      return {
        projectDDValue: projectsValue,
        projectsDD,
        projectReference,
        dropDownStyles: "col-span-1 text-gray-700 text-sm font-light",
        rateValue,
        ratesDD: filterRatesByProject(projectReference) || [],
        categoryDD:
          filteredCategoriesByProjectRate(projectReference, rates) || [],
        rates,
        categoryValue,
        error: ddErrors[0],
        key,
      };
    }
  );

  return { tsEntryData };
};

export default useTsViewDdSectionData;
