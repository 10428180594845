import { useParams } from "react-router";
import { cloneDeep, isEqual } from "lodash";
import { useMemo } from "react";
import {
  useCheckDuplicatedTitle,
  usePropertiesPatch,
  usePropertyById,
} from "../../../hooks/properties";
import useServiceRequestTopButtons from "../../../hooks/useServiceRequestTopButtons";
import useReportTopButtons from "../../../hooks/useReportTopButtons";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";
import usePropertyFormReducer from "../../../hooks/usePropertyFormReducer";

const usePropertyViewData = () => {
  const { propertyId } = useParams();

  const { data: property, isLoading, error } = usePropertyById(propertyId);

  const [editedProperty, dispatch] = usePropertyFormReducer();

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  // Hook to calculate buttons for SR tab
  const { getRequestButtonProps } = useServiceRequestTopButtons();

  // Hook to calculate buttons for Report tab
  const { getReportButtonProps } = useReportTopButtons();

  const { mutate: patchProperty, mutateAsync: patchPropertyAsync } =
    usePropertiesPatch();

  // async function and state to check duplicated property title
  const { fetchPropertyByTitle, titleExists } = useCheckDuplicatedTitle();

  // take a copy of original edited Property to validate changes
  const origEditedProperty = useMemo(
    () =>
      cloneDeep({
        ...property,
        title: property?.title.toLowerCase(),
        currentTags: [],
        originalResource: { ...property },
      }),
    [property]
  );

  // check if editedProperty has been modified
  const isPropertyEdited = useMemo(() => {
    const sameProperty = isEqual(origEditedProperty, {
      ...editedProperty,
      title: editedProperty?.title?.toLowerCase(),
    });
    return !sameProperty;
  }, [editedProperty, origEditedProperty]);

  return {
    isPropertyEdited,
    titleExists,
    propertyId,
    property,
    isLoading,
    editedProperty,
    getRequestButtonProps,
    getReportButtonProps,
    patchProperty,
    patchPropertyAsync,
    fetchPropertyByTitle,
    dispatch,
  };
};

export default usePropertyViewData;
