import isPermissionSelected from "./isPermissionSelected";

/**
 * Handles the selection and deselection of permissions for a given resource.
 * Also if the "can_read" permission (or "can_see" for "submittal")
 * is deselected, all other permissions for that resource are also deselected.
 * @param {Object} selectedPermissions - current state of selected permissions.
 * @param {string} resource - resource for which the permission is being modified.
 * @param {string} permission - specific permission being toggled (ex: "can_read", "can_write_project").
 * @returns {Object} the updated permissions object with the new state.
 */
const handlePermissionsSelected = (
  selectedPermissions,
  resource,
  permission
) => {
  const newPermissions = { ...selectedPermissions };
  newPermissions[resource] = newPermissions[resource] || {};

  newPermissions[resource][permission] = !isPermissionSelected(newPermissions)(
    resource,
    permission
  );

  // If can_read is unchecked, then uncheck all other permissions for that resource
  if (
    (permission === "can_read" ||
      permission === "can_only_read_assigned" ||
      (resource === "submittal" && permission === "can_see")) &&
    !newPermissions[resource][permission]
  ) {
    Object.keys(newPermissions[resource]).forEach((perm) => {
      newPermissions[resource][perm] = false;
    });
  }

  // If task.can_read is checked, then uncheck task.can_only_read_assigned
  if (
    resource === "task" &&
    permission === "can_read" &&
    newPermissions[resource][permission]
  ) {
    newPermissions[resource].can_only_read_assigned = false;
  }

  // If task.can_only_read_assigned is checked, then uncheck task.can_read
  if (
    resource === "task" &&
    permission === "can_only_read_assigned" &&
    newPermissions[resource][permission]
  ) {
    newPermissions[resource].can_read = false;
  }

  // check task.can_update when one of the below task permissions are checked
  if (
    resource === "task" &&
    (permission === "can_read" ||
      permission === "can_only_read_assigned" ||
      permission === "can_create") &&
    newPermissions[resource][permission]
  ) {
    newPermissions[resource].can_update = true;
  }

  return newPermissions;
};

export default handlePermissionsSelected;
