import React from "react";
import "./ptoButtonStyles.css";
import cntl from "cntl";
import PropTypes from "prop-types";

const buttonCN = (active) => cntl`
flex
flex-col
items-center
justify-center
rounded-md 
${!active && "bg-white border-gray-450 border"}
hover:bg-backgroundGrey
my-auto
text-gray-450
${active && "bg-backgroundGreen border-darkenedGreen border"}
`;

const fontSize = { fontSize: "12px" };

const PtoButton = ({ value, title, onClick, active, key }) => {
  return (
    <button
      className={buttonCN(active)}
      style={{
        transform: active ? "scale(1.025)" : "",
        width: "9rem",
        minWidth: "9rem",
        height: "3.5rem",
        minHeight: "3.5rem",
      }}
      onClick={() => onClick()}
      key={key}
      type="button"
    >
      <p style={fontSize} className="font-semibold text-sm whitespace-nowrap">
        {title}
      </p>
      <p style={fontSize} className="font-normal">
        {value} Hours Available
      </p>
    </button>
  );
};

PtoButton.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  key: PropTypes.string,
};

PtoButton.defaultProps = {
  value: "0",
  title: "",
  onClick: () => {},
  active: false,
  key: undefined,
};

export default PtoButton;
