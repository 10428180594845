import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Widget from "../Widget/Widget";
import ResourceInformationForm from "./ResourceInformationForm";
import BillingAddress from "../AddressInput/BillingAddress";
import { getTimeZoneOptions } from "../../../helpers/Time";

const PropertyDetailsForm = ({
  property,
  dispatch,
  config,
  handleForwardRef,
  inputRefs,
  test,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const [propertyAddress, setPropertyAddress] = useState([]);

  useEffect(() => {
    setPropertyAddress(property?.address);
  }, [property?.address]);

  const propertyTypeOptions = React.useMemo(() => {
    const optionList = [];
    config?.property?.types?.forEach((type) => {
      if (type.selected) {
        optionList.push({ label: type.display, value: type.id });
      }
    });
    return optionList;
  }, [config]);

  const handleSimpleInput = (key, val) => {
    dispatch({
      type: key,
      [key]: val,
    });
  };

  const handleAddressInput = (val) => {
    dispatch({
      type: "address",
      address: val,
    });
  };

  const handleMailingSameAsPhysicalInput = (val) => {
    dispatch({
      type: "mailingSameAsPhysical",
      mailingSameAsPhysical: val,
    });

    if (val) {
      dispatch({
        type: "address",
        address: [
          property?.address?.[0],
          {
            use: "Mailing",
            street: "",
            street2: "",
            city: "",
            state: "",
            country: "",
            zip: "",
          },
        ],
      });
    }
  };

  const timezoneOptions = getTimeZoneOptions();

  return (
    <>
      <ResourceInformationForm
        resource={property}
        resourceInformation="Property Information"
        resourceTypes={propertyTypeOptions}
        handleInput={handleSimpleInput}
        onForwardRef={handleForwardRef}
        timezoneOptions={timezoneOptions}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
      />
      <Widget draggable={false} title="Address" className="mt-2" overflow>
        <BillingAddress
          address={propertyAddress}
          mailingSameAsPhysical={property.mailingSameAsPhysical}
          onChange={handleAddressInput}
          handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
          onForwardRef={handleForwardRef}
          inputRefs={inputRefs}
          test={test}
          rowGap="gap-4"
        />
      </Widget>
    </>
  );
};

PropertyDetailsForm.propTypes = {
  /**
   * property currently being created or edited
   */
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  /**
   * function called to make changes to the property.
   * Generally will be the usePropertyFormReducer
   */
  dispatch: PropTypes.func,
  /**
   * management config data
   */
  config: PropTypes.objectOf({
    property: PropTypes.objectOf({
      types: PropTypes.arrayOf({
        selected: PropTypes.bool,
        display: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }),
  handleForwardRef: PropTypes.func,
  inputRefs: PropTypes.shape({
    current: PropTypes.shape({
      description: PropTypes.string,
      propertyName: PropTypes.arrayOf(PropTypes.string),
      propertyType: PropTypes.string,
    }),
  }),
  test: PropTypes.bool,
  tmpAvatarImg: PropTypes.shape({
    image: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  setTmpAvatarImg: PropTypes.func,
};

PropertyDetailsForm.defaultProps = {
  property: {},
  dispatch: undefined,
  config: undefined,
  handleForwardRef: () => {},
  inputRefs: { current: {} },
  test: false,
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
};

export default PropertyDetailsForm;
