import { useState } from "react";
import { useQueryClient } from "react-query";
import useSettings from "../../../hooks/useSettings";
import {
  REACTIVATE_SUBSCRIPTION_POPUP,
  TOGGLE_POSITIONED_POPUP,
  USER_SETTINGS_KEYS,
} from "../../../constants";
import { useAppState } from "../../../state/appState";
import SignUpAPI from "../../../Pages/SignUp/SignUpAPI";

const useBillingDashboard = ({ billingFormData, dispatchBilling }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [billingSettings, updateSettings] = useSettings(
    USER_SETTINGS_KEYS.BILLING.KEY
  );

  const queryClient = useQueryClient();
  const [, appStateDispatch] = useAppState();

  // handles closing notification popup, not reactivate subscription popup
  const onClose = () => {
    updateSettings({
      key: USER_SETTINGS_KEYS.BILLING.KEY,
      subKey: USER_SETTINGS_KEYS.BILLING.SUBKEY_1,
      value: true,
    });
  };

  const handleSubmit = async () => {
    const res = await SignUpAPI.reactivateSubscription(
      billingFormData?.subscriptionId,
      queryClient
    );
    // Update `toCancelAtPeriodEnd` to false
    dispatchBilling({
      type: "cancelSubscription",
      toCancelAtPeriodEnd: res?.cancel_at_period_end,
      expireDate: res?.cancel_at
        ? new Date(res?.cancel_at * 1000).toISOString()
        : undefined,
    });
  };

  const onReactivateClick = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        popupWidth: 425,
        handleSubmit,
      },
      popupType: REACTIVATE_SUBSCRIPTION_POPUP,
    });
  };

  return {
    isOpen,
    setIsOpen,
    billingSettings,
    onClose,
    onReactivateClick,
    updateSettings,
  };
};

export default useBillingDashboard;
