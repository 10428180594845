import { useMemo, useRef } from "react";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import useNotifications from "../../../../hooks/useNotifications";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useTodos from "../../../../hooks/useTodos";
import { useAppState } from "../../../../state/appState";
import { SET_REQUEST_MODAL_STATUS } from "../../../../constants";

const useTopNavBar = ({ onLogoClick, closeMenu }) => {
  const { clientChat: client, unread } = useESChatContext();
  const { data: todos } = useTodos();
  const { data: notifications = [] } = useNotifications();

  const { data: systemConfiguration } = useSystemConfiguration();
  const [, dispatch] = useAppState();
  const popupRef = useRef(null);
  // Detect click outside of the Tooltip popup to close
  useOutsideAlerter(popupRef, () => closeMenu());
  const todosCount = useMemo(() => todos?.length ?? 0, [todos?.length]);

  const notificationCount = useMemo(() => {
    return notifications.reduce((count, item) => {
      const addAmount = item.read ? 0 : 1;
      return count + addAmount;
    }, 0);
  }, [notifications]);

  const onLogoKeyDown = (event) => {
    if (event.key === "Enter") {
      onLogoClick();
    }
  };

  const openTicketRequest = () => {
    dispatch({
      type: SET_REQUEST_MODAL_STATUS,
      open: true,
    });
  };

  return {
    onLogoKeyDown,
    systemConfiguration,
    dispatch,
    todosCount,
    notificationCount,
    unread,
    client,
    popupRef,
    openTicketRequest,
  };
};

export default useTopNavBar;
