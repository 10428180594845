import React from "react";
import cntl from "cntl";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import HelpGrid from "../../stories/Components/Help";
import HelpSingleCategoryView from "./HelpSingleCategory";
import searchIcon from "../../stories/assets/images/magnoliaSearchIcon.svg";
import useHelpPageData from "./useHelpPageData";
import SearchBar from "../../stories/Components/SearchBar/SearchBar";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const searchStyle = {
  width: "470px",
};

const HelpPage = () => {
  const {
    helpGridItems,
    categoryView,
    helpData,
    setCategoryView,
    handleSearchChange,
    searchTerm,
    videoData,
  } = useHelpPageData();

  return (
    <div className={PageCN}>
      <SiteHeader title="HOW CAN WE HELP?" />

      {categoryView && (
        <HelpSingleCategoryView
          videoData={videoData?.[categoryView]}
          helpData={helpData}
          category={categoryView}
          setCategoryView={setCategoryView}
        />
      )}
      {!categoryView && (
        <div className="py-8 w-full">
          <div style={searchStyle} className="h-10 mb-10">
            <SearchBar
              searchIcon={searchIcon}
              placeholder="Search"
              onSearchChange={handleSearchChange}
              globalFilter={searchTerm}
              disableClear
            />
          </div>
          <HelpGrid
            helpGridItems={helpGridItems}
            setCategoryView={setCategoryView}
          />
        </div>
      )}
    </div>
  );
};

export default HelpPage;
