import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import { uniq as _uniq } from "lodash";
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector";
import Dropdown from "../Dropdown/Dropdown";
import { INCLUDES_EXCLUDES_OPTIONS } from "../../../constants";
import RadioButton from "../Buttons/RadioButton";
import removeIcon from "../../assets/images/crossWhiteBackgroundGreenShapeCircle.svg";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { convertArrayToObjectSameKeyValue } from "../../../helpers/Utilities";

const rowItemCN = cntl`
  pb-2
  flex
  min-w-0
`;

const stagedFiltersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  })
);

export const IsOrNotFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
  data,
  getNestedColumnOptions,
}) => {
  const parentColumn = stagedFilters.find(
    (fil) => fil?.value?.nestedColumnId === currentCol.id
  );

  let options = [];
  // filter options is parentColumn is set
  if (getNestedColumnOptions && parentColumn?.id) {
    const nestedColumnOptions = getNestedColumnOptions(
      parentColumn?.value?.values
    );
    options = nestedColumnOptions;
  } else {
    options = _uniq(data.map((d) => d[currentCol.id]))
      .map((opt) => ({
        label: opt,
        value: opt,
      }))
      .filter((opt) => opt.value !== undefined);
  }

  const thisFilter =
    stagedFilters.find((fil) => fil.id === currentCol.id) ?? {};

  const valueLabels = convertArrayToObjectSameKeyValue(
    thisFilter?.value?.values
  );

  const onRemoveFilter = (id) => {
    setStagedFilters((prev) => prev.filter((filter) => filter.id !== id));
  };

  return (
    <div className={rowItemCN} key={currentCol.id}>
      <div className="flex w-1/2">
        <Dropdown
          placeholder="Choose"
          options={allColumns.reduce((acc, col) => {
            if (
              col?.filterOptions?.filterType &&
              !stagedFilters.some((filter) => filter.id === col.id)
            ) {
              acc.push({
                label: col?.filterOptions?.label || col.Header,
                value: col.id,
                equals: col?.filterOptions?.equals || true,
              });
            }
            return acc;
          }, [])}
          value={{
            label: currentCol?.filterOptions?.label || currentCol.Header,
            value: currentCol.id,
            equals: currentCol?.filterOptions?.equals || true,
          }}
          onChange={({ value, equals }) => {
            if (value) {
              setStagedFilters((prev) => [
                ...prev.filter((filter) => filter.id !== currentCol.id),
                {
                  id: value,
                  value: {
                    value: equals,
                    type: "is",
                    isTrue: true,
                    from: moment().toISOString(),
                    to: moment().toISOString(),
                  },
                },
              ]);
            } else {
              setStagedFilters((prev) => {
                return [
                  ...prev.filter((filter) => filter.id !== currentCol.id),
                ];
              });
            }
          }}
          disableClear
        />
      </div>

      <div className="flex w-1/2 pl-2">
        <div className="flex w-5/6">
          <Dropdown
            menuPlacement="bottom"
            isMulti
            options={options}
            onChange={(values) => {
              setStagedFilters((prev) => {
                const tmpFilters = [
                  ...prev.map((filter) => {
                    if (filter.id === currentCol.id) {
                      return {
                        ...filter,
                        value: {
                          ...filter.value,
                          values: values?.map((v) => v?.value),
                        },
                      };
                    }
                    return filter;
                  }),
                ];

                return tmpFilters;
              });
            }}
            value={thisFilter?.value?.values?.map((val) => {
              return {
                label: valueLabels?.[val],
                value: val,
              };
            })}
            disableClear
          />
        </div>
        <div className="flex pl-2">
          <button type="button" onClick={() => onRemoveFilter(currentCol.id)}>
            <img src={removeIcon} alt="delete" />
          </button>
        </div>
      </div>
    </div>
  );
};

IsOrNotFilter.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  stagedFilters: stagedFiltersPropType,
  setStagedFilters: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    )
  ),
  currentCol: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({})]),
  // function that returns options for the nested column based on selected parent column value(s)
  getNestedColumnOptions: PropTypes.func,
};

IsOrNotFilter.defaultProps = {
  allColumns: [],
  stagedFilters: [],
  setStagedFilters: undefined,
  data: [],
  currentCol: undefined,
  getNestedColumnOptions: undefined,
};

export const IncludesExcludesFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
}) => {
  const thisFilter =
    stagedFilters.find((fil) => fil.id === currentCol.id) ?? {};

  const valueLabels = convertArrayToObjectSameKeyValue(
    thisFilter?.value?.values
  );

  const onRemoveFilter = (id) => {
    setStagedFilters((prev) => prev.filter((filter) => filter.id !== id));
  };

  return (
    <div className={rowItemCN} key={currentCol.id}>
      <div className="flex w-1/2">
        <Dropdown
          placeholder="Choose"
          options={allColumns.reduce((acc, col) => {
            if (
              col?.filterOptions?.filterType &&
              !stagedFilters.some((filter) => filter.id === col.id)
            ) {
              acc.push({
                label: col?.filterOptions?.label || col.Header,
                value: col.id,
                equals: col?.filterOptions?.equals || true,
              });
            }
            return acc;
          }, [])}
          value={{
            label: currentCol?.filterOptions?.label || currentCol.Header,
            value: currentCol.id,
            equals: currentCol?.filterOptions?.equals || true,
          }}
          onChange={({ value, equals }) => {
            if (value) {
              setStagedFilters((prev) => [
                ...prev.filter((filter) => filter.id !== currentCol.id),
                {
                  id: value,
                  value: {
                    value: equals,
                    type: "includes",
                    isTrue: true,
                    from: moment().toISOString(),
                    to: moment().toISOString(),
                  },
                },
              ]);
            } else {
              setStagedFilters((prev) => {
                return [
                  ...prev.filter((filter) => filter.id !== currentCol.id),
                ];
              });
            }
          }}
          disableClear
        />
      </div>
      <div className="flex w-1/2">
        <div className="flex w-1/3 pl-2">
          <Dropdown
            options={INCLUDES_EXCLUDES_OPTIONS}
            onChange={({ value }) =>
              setStagedFilters((prev) => [
                ...prev.map((filter) => {
                  if (filter.id === currentCol.id) {
                    return {
                      ...filter,
                      value: {
                        ...filter.value,
                        type: value,
                      },
                    };
                  }
                  return filter;
                }),
              ])
            }
            disableClear
            value={{
              label: thisFilter?.value?.type,
              value: thisFilter?.value?.type,
            }}
          />
        </div>

        <div className="flex w-2/3 min-w-0 pl-2">
          <div className="flex w-3/4">
            <Dropdown
              isMulti
              options={thisFilter.value?.customOptions ?? []}
              onChange={(values) => {
                setStagedFilters((prev) => {
                  const tmpFilters = [
                    ...prev.map((filter) => {
                      if (filter.id === currentCol.id) {
                        return {
                          ...filter,
                          value: {
                            ...filter.value,
                            values: values?.map((v) => v?.value),
                          },
                        };
                      }
                      return filter;
                    }),
                  ];

                  return tmpFilters;
                });
              }}
              value={thisFilter?.value?.values?.map((val) => {
                return {
                  label: valueLabels?.[val],
                  value: val,
                };
              })}
              disableClear
            />
          </div>
          <div className="flex pl-2">
            <button type="button" onClick={() => onRemoveFilter(currentCol.id)}>
              <img src={removeIcon} alt="delete" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

IncludesExcludesFilter.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  stagedFilters: stagedFiltersPropType,
  setStagedFilters: PropTypes.func,
  currentCol: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({})]),
};

IncludesExcludesFilter.defaultProps = {
  allColumns: [],
  stagedFilters: [],
  setStagedFilters: undefined,
  currentCol: undefined,
};

export const BooleanFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
}) => {
  const thisFilter =
    stagedFilters.find((fil) => fil.id === currentCol.id) ?? {};

  const onRemoveFilter = (id) => {
    setStagedFilters((prev) => prev.filter((filter) => filter.id !== id));
  };

  return (
    <div className={rowItemCN} key={currentCol.id}>
      <div className="flex w-1/2">
        <Dropdown
          placeholder="Choose"
          options={allColumns.reduce((acc, col) => {
            if (
              col?.filterOptions?.filterType &&
              !stagedFilters.some((filter) => filter.id === col.id)
            ) {
              acc.push({
                label: col?.filterOptions?.label || col.Header,
                value: col.id,
                equals: col?.filterOptions?.equals || true,
              });
            }
            return acc;
          }, [])}
          value={{
            label: currentCol?.filterOptions?.label || currentCol.Header,
            value: currentCol.id,
            equals: currentCol?.filterOptions?.equals || true,
          }}
          onChange={({ value, equals }) => {
            if (value) {
              setStagedFilters((prev) => [
                ...prev.filter((filter) => filter.id !== currentCol.id),
                {
                  id: value,
                  value: {
                    value: equals,
                    isTrue: true,
                    type: "is",
                    from: moment().toISOString(),
                    to: moment().toISOString(),
                  },
                },
              ]);
            } else {
              setStagedFilters((prev) => [
                ...prev.filter((filter) => filter.id !== currentCol.id),
              ]);
            }
          }}
          disableClear
        />
      </div>
      <div className="flex w-1/2 flex-row pl-2">
        <div className="flex w-5/6">
          <div className="flex items-center">
            <RadioButton
              value="Yes"
              label="Yes"
              isChecked={thisFilter?.value?.isTrue}
              onChange={(value) =>
                setStagedFilters((prev) => [
                  ...prev.map((filter) => {
                    if (filter.id === currentCol.id) {
                      return {
                        ...filter,
                        value: {
                          ...filter.value,
                          isTrue: value?.target?.value === "Yes",
                        },
                      };
                    }
                    return filter;
                  }),
                ])
              }
            />
          </div>
          <div className="flex items-center">
            <RadioButton
              value="No"
              label="No"
              isChecked={!thisFilter?.value?.isTrue}
              onChange={(value) =>
                setStagedFilters((prev) => [
                  ...prev.map((filter) => {
                    if (filter.id === currentCol.id) {
                      return {
                        ...filter,
                        value: {
                          ...filter.value,
                          isTrue: value?.target?.value === "Yes",
                        },
                      };
                    }
                    return filter;
                  }),
                ])
              }
            />
          </div>
        </div>
        <div className="flex pl-2">
          <button type="button" onClick={() => onRemoveFilter(currentCol.id)}>
            <img src={removeIcon} alt="delete" />
          </button>
        </div>
      </div>
    </div>
  );
};

BooleanFilter.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  stagedFilters: stagedFiltersPropType,
  setStagedFilters: PropTypes.func,
  currentCol: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({})]),
};

BooleanFilter.defaultProps = {
  allColumns: [],
  stagedFilters: [],
  setStagedFilters: undefined,
  currentCol: undefined,
};

export const DateFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
}) => {
  const thisFilter =
    stagedFilters.find((fil) => fil.id === currentCol.id) ?? {};

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [dateFormat, setDateFormat] = useState("");

  useEffect(() => {
    const fromDate = thisFilter?.value?.from;
    const toDate = thisFilter?.value?.to;

    if (fromDate) {
      setStartDate(new Date(fromDate));
      if (!toDate || fromDate === toDate) {
        setEndDate(null);
      } else {
        setEndDate(new Date(toDate));
      }
    }
  }, [thisFilter?.value]);

  useEffect(() => {
    setDateFormat(
      `${moment(startDate).format("MM/DD/YYYY")} - ${moment(
        endDate || startDate
      ).format("MM/DD/YYYY")}`
    );
  }, [startDate, endDate]);

  const handleChangeDates = (dates) => {
    const [start = new Date(), end] = dates;

    setStagedFilters((prev) =>
      prev.map((filter) => {
        if (filter.id === currentCol.id) {
          return {
            ...filter,
            value: {
              ...filter.value,
              value: !!start,
              from: new Date(start).toISOString(),
              to: end ? new Date(end).toISOString() : undefined,
            },
          };
        }
        return filter;
      })
    );
  };

  const onRemoveFilter = (id) => {
    setStagedFilters((prev) => prev.filter((filter) => filter.id !== id));
  };

  return (
    <div className={rowItemCN} key={currentCol.id}>
      <div className="flex w-1/2 items-center">
        <Dropdown
          placeholder="Choose"
          options={allColumns.reduce((acc, col) => {
            if (
              col?.filterOptions?.filterType &&
              !stagedFilters.some((filter) => filter.id === col.id)
            ) {
              acc.push({
                label: col?.filterOptions?.label || col.Header,
                value: col.id,
                equals: col?.filterOptions?.equals || true,
              });
            }
            return acc;
          }, [])}
          value={{
            label:
              thisFilter?.value?.overwriteLabel ||
              currentCol?.filterOptions?.label ||
              currentCol.Header,
            value: currentCol.id,
            equals: currentCol?.filterOptions?.equals || true,
          }}
          onChange={({ value, equals, hideCalendar, overwriteLabel }) => {
            if (value) {
              setStagedFilters((prev) => [
                ...prev.filter((filter) => filter.id !== currentCol.id),
                {
                  id: value,
                  value: {
                    value: equals,
                    type: "is",
                    isTrue: undefined,
                    from: moment().toISOString(),
                    to: moment().toISOString(),
                    hideCalendar,
                    overwriteLabel,
                  },
                },
              ]);
            } else {
              setStagedFilters((prev) => [
                ...prev.filter((filter) => filter.id !== currentCol.id),
              ]);
            }
          }}
          disableClear
        />
      </div>

      <div className="flex w-1/2 pl-2">
        <div className="flex w-5/6">
          <DateRangeSelector
            startDate={startDate}
            endDate={endDate}
            value={dateFormat}
            onChange={handleChangeDates}
          />
        </div>
        <div className="flex pl-2">
          <button type="button" onClick={() => onRemoveFilter(currentCol.id)}>
            <img src={removeIcon} alt="delete" />
          </button>
        </div>
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  stagedFilters: stagedFiltersPropType,
  setStagedFilters: PropTypes.func,
  currentCol: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({})]),
};

DateFilter.defaultProps = {
  allColumns: [],
  stagedFilters: [],
  setStagedFilters: undefined,
  currentCol: undefined,
};
