import React, { useState } from "react";

import ExpenseTable from "../../../stories/Components/ExpenseTable/ExpenseTable";
import Widget from "../../../stories/Components/Widget/Widget";

const AssetExpences = ({
  isTabView,
  setButtonActions,
  associationLock,
  assetLock,
}) => {
  const [createExpenseModal, setCreateExpenseModal] = useState(false);

  return (
    <Widget draggable={false} title={null} overflow backToTop>
      <ExpenseTable
        associationLock={associationLock}
        assetLock={assetLock}
        hideSiteHeaderTitle
        hideSwitchView
        isTabView={isTabView}
        setButtonActions={setButtonActions}
        createExpenseModal={createExpenseModal}
        setCreateExpenseModal={setCreateExpenseModal}
      />
    </Widget>
  );
};

export default AssetExpences;
