import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import useAccountAndAdminViewData from "./useAccountAndAdminViewData";
import AccountAndAdminContent from "../../../stories/Components/AccountAndAdmin/AccountAndAdminContent";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const AccountAndAdminView = ({ currentIndex }) => {
  const {
    isSaving,
    setIsSaving,
    isEditing,
    setIsEditing,
    isCancel,
    setIsCancel,
    activeIndex,
    setActiveIndex,
    onSave,
    onCancel,
    dropDownItems,
    isCancellingSubscription,
    setIsCancellingSubscription,
    isExportingMemberList,
    setIsExportingMemberList,
  } = useAccountAndAdminViewData({ currentIndex });

  return (
    <div className={PageCN}>
      <SiteHeader
        title="Account &amp; Admin"
        showPrimaryButton
        primaryButtonText="Save Changes"
        isPrimaryButtonDisabled={isSaving}
        showTertiaryButton
        tertiaryButtonText="Cancel"
        buttons={
          dropDownItems(activeIndex).length ? (
            <PrimaryButton
              title="Actions"
              className="dropdown-btn"
              large
              dropdownItems={dropDownItems(activeIndex)}
            />
          ) : null
        }
      />
      <AccountAndAdminContent
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        isCancel={isCancel}
        setIsCancel={setIsCancel}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        onSave={onSave}
        onCancel={onCancel}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isCancellingSubscription={isCancellingSubscription}
        setIsCancellingSubscription={setIsCancellingSubscription}
        isExportingMemberList={isExportingMemberList}
        setIsExportingMemberList={setIsExportingMemberList}
      />
    </div>
  );
};

AccountAndAdminView.propTypes = {
  currentIndex: PropTypes.number,
};

AccountAndAdminView.defaultProps = {
  currentIndex: 0,
};

export default AccountAndAdminView;
