import { isEqual } from "lodash";
// Framework Tools
import { useEffect, useMemo, useState } from "react";

// hooks
import { useAppState } from "../../../../state/appState";
import usePostWorkflow from "../../../../hooks/useWorkflowPost";

// components

// constants
import {
  SET_WORKFLOW_MODAL_STATUS,
  WORKFLOW_EMPTY,
} from "../../../../constants";
import { getFullName } from "../../../../helpers/Formatters";

import useWorkflowFormReducer from "../../../../hooks/useWorkflowFormReducer";
import { toastError } from "../../Toast/Toast";
import { usePatchWorkflow } from "../../../../hooks/useWorkflowById.new";
import { usePropertiesOverview } from "../../../../hooks/properties";
import { useProjectsOverview } from "../../../../hooks/projects";

const useWorkflowCreateModalData = () => {
  /* Hooks */
  const [{ workflowModalStatus, userDict }, dispatch] = useAppState([]);

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  /* tracking request form for workflow post call */
  const [requestForm, requestFormDispatch] = useWorkflowFormReducer();

  /* create workflow hook for posting to workflow api */
  const { mutate: createWorkflow, isLoading: isSaving } = usePostWorkflow();

  /* patch aasociation workflow's associated artifacts list */
  const { mutateAsync: patchWorkflow } = usePatchWorkflow(
    workflowModalStatus?.artifactFor?.id
  );

  /* state controls */
  const [distroSelectOpen, setDistroSelectOpen] = useState(false);
  const [distroSelectOptions, setDistroSelectOptions] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const initialWorflow = useMemo(
    () => ({
      ...WORKFLOW_EMPTY,
      association: requestForm?.association ?? "",
      startDate: {
        actual: requestForm?.startDate?.actual,
      },
    }),
    [requestForm?.startDate?.actual, requestForm?.association]
  );

  useEffect(() => {
    const isSame = isEqual(initialWorflow, requestForm);
    setShowConfirm(!isSame);
  }, [initialWorflow, requestForm]);

  /* when creating a submittal from a project, set the association */
  useEffect(() => {
    if (workflowModalStatus?.association) {
      requestFormDispatch({
        type: "association",
        payload: workflowModalStatus?.association,
      });
    }

    if (workflowModalStatus?.documents) {
      requestFormDispatch({
        type: "documents",
        documents: [...workflowModalStatus?.documents],
      });
    }
  }, [
    requestFormDispatch,
    workflowModalStatus?.association,
    workflowModalStatus?.documents,
  ]);

  /* all fields are required before save is enabled */
  useEffect(() => {
    if (
      requestForm?.customName &&
      requestForm?.association &&
      requestForm?.startDate?.actual
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    requestForm?.association,
    requestForm?.customName,
    requestForm?.startDate?.actual,
  ]);

  /* sets the current project after userDictionary, and project dictionary are loaded */
  useEffect(() => {
    if (userDict && projectDict && propertiesDict && requestForm.association) {
      const resource = requestForm.association?.split("/")[0];
      let currentAssociation;

      if (resource === "Project") {
        currentAssociation = projectDict[requestForm.association];
      } else if (resource === "Property") {
        currentAssociation = propertiesDict[requestForm.association];
      }

      const options = currentAssociation?.members?.map((mem) => {
        const member = userDict?.[mem?.user];
        const fullName = getFullName(member?.name);
        const ref = member?.reference;
        return { ...member, label: fullName, value: ref };
      });
      setDistroSelectOptions(options);
    }
  }, [projectDict, propertiesDict, requestForm.association, userDict]);

  /* when a form value changes dispatches to reducer by key and value */
  const handleChange = (key, value) => {
    return requestFormDispatch({
      type: key,
      payload: value,
    });
  };

  /* closes create modal */
  const handleModalClose = () => {
    requestFormDispatch({ type: "reset" });

    dispatch({
      type: SET_WORKFLOW_MODAL_STATUS,
      action: {
        ...workflowModalStatus,
        open: false,
        isQuickAdd: false,
        association: undefined,
      },
    });
  };

  const handleDeleteFromDistro = (ref) => {
    requestFormDispatch({ type: "removeDistroMember", payload: ref });
  };
  const resetDistroList = () => {
    requestFormDispatch({ type: "resetDistroList", payload: [] });
  };

  const handlePostWorkflow = async () => {
    createWorkflow(requestForm, {
      onSuccess: (response) => {
        try {
          // if WF is association, then patch the associated WF associated artifacts list
          // Always set tabIndex to 0 after creating a new submittal
          if (workflowModalStatus?.artifactFor?.reference) {
            const updatedWf = {
              ...workflowModalStatus?.artifactFor,
              associatedArtifacts: [
                ...workflowModalStatus?.artifactFor?.associatedArtifacts,
                `Workflow/${response?.id}`,
              ],
            };
            // patch WF
            patchWorkflow({
              updatedResource: updatedWf,
              originalResource: workflowModalStatus?.artifactFor,
            });
          }
        } catch (error) {
          toastError("Unable to update associated artifact list");
        } finally {
          // update app state and navigate to new WF
          dispatch({
            type: SET_WORKFLOW_MODAL_STATUS,
            activeTabIndex: 0,
          });
        }
      },
      onSettled: () => {
        handleModalClose();
      },
    });
  };

  return {
    showConfirm,
    handlePostWorkflow,
    resetDistroList,
    handleDeleteFromDistro,
    handleChange,
    distroSelectOpen,
    setDistroSelectOpen,
    userDict,
    propertiesDict,
    projectDict,
    distroSelectOptions,
    disableSave,
    isSaving,
    requestFormDispatch,
    handleModalClose,
    workflowModalStatus,
    requestForm,
    hasAssociationData: propertiesDict && projectDict && userDict,
  };
};
export default useWorkflowCreateModalData;
