import React, { useMemo } from "react";
import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import Chevron from "../Chevron/Chevron";
import thisWeekIcon from "../../assets/images/tsThisWeekIcon.svg";
import historyIcon from "../../assets/images/historyIcon.svg";
import historyIconDisabled from "../../assets/images/historyIconDisabled.svg";
import CommentButton from "../CommentButton";
import usePagePersistence from "../../../hooks/usePagePersistence";

const wrapperCN = cntl`
  flex 
  flex-col
  pl-2
`;

const TimeSheetDatePicker = ({
  showNextWeek,
  showPrevWeek,
  showThisWeek,
  approvalView,
  saving,
  loading,
  onHistoryClick,
  timesheet,
  handleOpenComments,
  primaryControlButton,
  saveControls,
}) => {
  const { pageState } = usePagePersistence();

  const formattedStartDate = useMemo(() => {
    return moment(pageState?.timesheet?.periodStart).format("DD");
  }, [pageState?.timesheet?.periodStart]);

  const formattedEndData = useMemo(() => {
    return moment(pageState?.timesheet?.periodEnd).format("DD MMM YYYY");
  }, [pageState?.timesheet?.periodEnd]);

  const keyDownShowNextWeek = (event) => {
    if (event.key === "Enter") {
      showNextWeek();
    }
  };
  const keyDownShowPrevWeek = (event) => {
    if (event.key === "Enter") {
      showPrevWeek();
    }
  };

  const forwardButtonDisabled = useMemo(() => {
    return moment(pageState?.timesheet?.periodEnd).isAfter(moment(), "day");
  }, [pageState?.timesheet?.periodEnd]);

  return (
    <div className={wrapperCN}>
      <div className="flex flex-row justify-between w-full">
        <div className="flex gap-3">
          {!approvalView && (
            <button
              aria-label="History Button"
              type="button"
              onClick={onHistoryClick}
              className="flex pr-2 items-center"
              disabled={timesheet?.new}
            >
              {timesheet.new ? (
                <img
                  src={historyIconDisabled}
                  className="h-5.5 w-5.5"
                  alt="history-button"
                />
              ) : (
                <img
                  src={historyIcon}
                  className="h-5.5 w-5.5"
                  alt="history-button"
                />
              )}
            </button>
          )}
          <div
            className="select-none cursor-pointer flex items-center h-full"
            onClick={!saving && !loading ? showPrevWeek : undefined}
            role="button"
            onKeyDown={!saving && !loading ? keyDownShowPrevWeek : undefined}
            tabIndex="0"
            aria-label="Previous button"
          >
            <Chevron color="#191919" className="h-4 transform rotate-180" />
          </div>

          <div
            className="select-none cursor-pointer flex items-center h-full"
            onClick={!saving && !loading ? showNextWeek : undefined}
            role="button"
            onKeyDown={!saving && !loading ? keyDownShowNextWeek : undefined}
            tabIndex="0"
            aria-label="Next button"
          >
            <Chevron
              className="h-4"
              color={!forwardButtonDisabled ? "#191919" : "#b6b6b6"}
            />
          </div>
          <div
            className="flex h-full items-center whitespace-nowrap"
            style={{
              maxWidth: "180px",
              minWidth: "180px",
            }}
          >
            {!loading ? (
              <h3 className="w-full text-center text-xl text-gray-600 font-semibold">
                {formattedStartDate} - {formattedEndData}
              </h3>
            ) : (
              <h3 className="w-full text-center relative">
                <span className="inline-block loadingInline" />
                Loading...
              </h3>
            )}
          </div>

          <button
            type="button"
            onClick={!saving ? showThisWeek : undefined}
            disabled={loading || saving}
          >
            <img src={thisWeekIcon} alt="this-week-icon" />
          </button>
        </div>
        {!approvalView && (
          <div className="flex flex-row self-end gap-2">
            {saveControls}
            {primaryControlButton}
            {!timesheet?.new && <CommentButton onClick={handleOpenComments} />}
          </div>
        )}
      </div>
    </div>
  );
};
TimeSheetDatePicker.propTypes = {
  /**
   * function called that displays next weeks timesheets
   */
  /**
   * function called that displays next weeks timesheets
   */
  showNextWeek: PropTypes.func,
  /**
   * function called that displays previous weeks timesheets
   */
  showPrevWeek: PropTypes.func,
  /**
   * function called that opens the comments modal
   */
  handleOpenComments: PropTypes.func,
  /**
   * function called that displays this weeks timesheets
   */
  showThisWeek: PropTypes.func,
  /**
   * boolean that indicates if an approver is viewing the page
   */
  approvalView: PropTypes.bool,
  /**
   * Saving Time Sheet
   */
  saving: PropTypes.bool,
  /**
   * Loading default: false
   */
  loading: PropTypes.bool,
  /**
   * Timesheet history navigation
   */
  onHistoryClick: PropTypes.func,
  /**
   * New timesheet boolean
   */
  timesheet: PropTypes.shape({
    new: PropTypes.bool,
    reference: PropTypes.string,
    entries: PropTypes.shape({}),
  }),
  /**
   * Primary Control Button Submit or Approve
   */
  primaryControlButton: PropTypes.element,
  saveControls: PropTypes.element,
};
TimeSheetDatePicker.defaultProps = {
  showNextWeek: undefined,
  showPrevWeek: undefined,
  showThisWeek: undefined,
  approvalView: false,
  saving: false,
  loading: false,
  onHistoryClick: undefined,
  handleOpenComments: undefined,
  timesheet: { newSheet: true },
  primaryControlButton: null,
  saveControls: undefined,
};
export default TimeSheetDatePicker;
