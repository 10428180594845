import describeRruleFromString from "../helpers/Calendar/describeRruleFromString";
import { taskRecurrenceOptions } from "../helpers/Time";

const useDescribeRruleFromString = () => {
  /**
   * Transform rrule string into human readable
   * @param {string} rruleString rrule in human readable (ex: Weekly or DTSTART:20241126T151151Z\nRRULE:FREQ=WEEKLY;...)
   * @return {string} getRruleInHumanReadable: human readable rrule string (ex: weekly)
   */
  const getRruleInHumanReadable = (rruleString = "") => {
    // rrule string
    if (rruleString.includes("DTSTART"))
      return describeRruleFromString(rruleString);

    // not rrule
    const rrObject = taskRecurrenceOptions.find(({ value }) =>
      value?.includes(rruleString)
    );
    if (rrObject?.label) return rrObject?.label;

    // default value
    return "Does not repeat";
  };

  return {
    getRruleInHumanReadable,
  };
};

export default useDescribeRruleFromString;
