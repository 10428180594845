import { EventAPI, TaskAPI } from "@griffingroupglobal/eslib-api";

const fetchCalendarHandler = async (params, signal) => {
  const response = await Promise.all([
    EventAPI.get({ ...params, signal }),
    TaskAPI.get({ ...params, signal }),
  ]);

  const fullList = [...response[0].data.entries, ...response[1].data.entries];

  return fullList;
};

export default fetchCalendarHandler;
