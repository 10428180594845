import { TimeoffAPI, TimesheetAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  GIVE_TIMEOFF_MODAL,
  TIMEOFF_REQUEST_MODAL,
} from "../../../../constants";
import { payrollCheck } from "../../../../helpers/TimeSheet";
import usePayrollApprovedTimesheets from "../../../../hooks/timesheets/usePayrollApprovedTimesheets";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import usePagePersistence from "../../../../hooks/usePagePersistence";
import usePto from "../../../../hooks/usePto.jsx";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";

export default () => {
  const [
    {
      payrollPeriod,
      userDict,
      systemConfiguration,
      timesheetstate: { ptoUserId },
    },
  ] = useAppState();
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();
  const { timeSheetsAlreadyApproved } = usePayrollApprovedTimesheets();
  const [ptoAvailBtns, setPtoAvailBtn] = useState({});
  const { pageState } = usePagePersistence();

  const {
    base: { isFetching: isLoading, ...base },
    actions: {
      patch: { mutateAsync: patch },
    },
  } = usePto();

  const userResource = useMemo(() => {
    if (ptoUserId) {
      return userDict?.[`User/${ptoUserId}`];
    }
    return null;
  }, [ptoUserId, userDict]);

  const ptoData = useMemo(() => {
    const tableData =
      base?.data?.timeoffDict?.[`User/${pageState?.timesheet?.userId}`]
        ?.original;
    return tableData;
  }, [base?.data?.timeoffDict, pageState?.timesheet?.userId]);

  useEffect(() => {
    const pageStateUserId = pageState?.timesheet?.userId;
    const selectedUserReference = `User/${pageStateUserId}`;

    (async () => {
      const extras = {
        params: {
          user: selectedUserReference,
        },
      };

      try {
        const response = await TimeoffAPI.get(extras);
        const ptoAmounts = response?.data?.entries[0].resource.pto;

        if (ptoAmounts) {
          const filteredObject = {};

          /**
           * The unlimited PTO type has not been fully thought out and created a lot of questions
           * after the implementation.
           * When we are ready to bring back the feature, remove the `key === "unlimited"`
           */
          Object.entries(ptoAmounts).forEach(([key, item]) => {
            if (item.selected === true && key !== "unlimited") {
              filteredObject[key] = { ...item, name: key };
            }
          });

          setPtoAvailBtn(filteredObject);
        }
      } catch (error) {
        console.error(
          "useTimeOffUserViewData: there was an error making the request",
          error
        );
      }
    })();
  }, [
    base?.data?.timeoffDict,
    pageState?.timesheet?.userId,
    systemConfiguration?.system?.pto?.general,
    systemConfiguration?.system?.pto?.locations,
    userResource,
  ]);

  const userCanWriteTimesheet = currentUser?.permissions?.timesheet?.can_write;

  const companyId = ptoUserId
    ? userResource?.company?.value?.split("/")[1]
    : currentUser?.company?.value?.split("/")[1];

  const handleCancelPto = async ({ id: rowId, original: { dateRange } }) => {
    const query = dateRange.includes("to")
      ? dateRange.split("to").join(",").replaceAll(" ", "")
      : dateRange;

    TimesheetAPI.getWOP("$isopen", {
      params: {
        user: currentUser.id,
        [dateRange.includes("to") ? "range" : "dates"]: query,
      },
    })
      .then(({ data }) => payrollCheck(data))
      .then(() => {
        const id = ptoData?.id;
        const op = "$cancel";
        const body = {
          ...ptoData,
          metadata: {
            lastUpdated: ptoData?.metadata?.lastUpdated,
          },
        };
        const originalBody = {
          metadata: {
            lastUpdated: ptoData?.metadata?.lastUpdated,
          },
        };
        const extras = {
          params: { requestId: rowId },
        };

        patch({ id, op, body, originalBody, extras });
      });
  };

  /**
   * CRUD Operations
   */

  /**
   * Handlers
   */
  /**
   *
   * @param {Object} pto - request to be modified
   * @param {String} parentId - id of parent
   * @param {Object} metadata - metadata of parent
   */

  const openRequestModal = (pto, parentId, metadata) => {
    const editCriteria = pto?.id && parentId && metadata?.lastUpdated;
    const pageStateUserId = pageState?.timesheet?.userId;
    const currentUserId = currentUser?.id;
    const isCurrentUser = pageStateUserId === currentUserId;

    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: editCriteria ? `modify-${pto.id}` : uuidv4() },
      modalData: {
        item: pto?.id ? pto : undefined,
        parentId,
        lastUpdated: metadata?.lastUpdated,
        ptoAvailBtns,
        setPtoAvailBtn,
        isCurrentUser,
      },
      modalType: TIMEOFF_REQUEST_MODAL,
    });
  };

  const openConfirmCancelModal = (row) => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: `${row?.original?.id}-confirm-cancel` },
      modalData: {
        item: {
          prompt:
            "Are you sure you want to cancel, this action cannot be undone?",
          confirm: "Yes",
          cancel: "No",
          title: "Cancel Time Off",
          onConfirm: () => handleCancelPto(row),
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  const openGiveTimeOffModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalType: GIVE_TIMEOFF_MODAL,
      title: "Give Time Off",
      modalData: {
        item: {
          id: uuidv4(),
          userId: pageState?.timesheet?.userId,
          ptoAvailBtns,
          setPtoAvailBtn,
        },
      },
    });
  }, [modalDispatch, pageState?.timesheet?.userId, ptoAvailBtns]);

  return {
    user: userResource,
    data: ptoData,
    userId: ptoUserId,
    company: companyId,
    currentUser,
    userCanWriteTimesheet,
    period: payrollPeriod,
    loading: isLoading,
    ptoAvailBtns,
    timeSheetsAlreadyApproved,
    openGiveTimeOffModal,
    openRequest: openRequestModal,
    openCancel: openConfirmCancelModal,
  };
};
