import React from "react";
import cntl from "cntl";
import * as yup from "yup";
import { capitalize } from "lodash";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import Input from "../../Input/Input";
import BillingBaseAddressInput from "../BillingBaseAddressInput";
import usePaymentInformation from "./usePaymentInformation";

const cardInputCN = cntl`
  p-2.5
  ESInputContainer
`;

const labelCN = "text-es-dark-grey text-es-normal font-es-semibold pb-1.5";
const textCN = "text-es-dark-grey text-es-normal font-es-normal";

const innerCN = (className) => cntl`
  flex
  ${className}
`;

const errorMessageCN = cntl`
  capitalize-first
  text-es-sm
  font-es-normal
  text-es-red
`;

const cardElementStyles = {
  style: {
    base: {
      fontSize: "16px",
      color: "#1A1A1A",
      fontWeight: 100,
      fontFamily: "Arial, Helvetica, sans-serif",
      "::placeholder": {
        color: "#A6A6A6",
        fontWeight: "normal",
      },
    },
    invalid: {
      color: "black",
    },
  },
};

const PaymentInformation = ({
  isEditing,
  billingFormData,
  dispatchBilling,
}) => {
  const { onChange, billingInfoComplete, formAddress, onChangeAddress } =
    usePaymentInformation({ billingFormData, dispatchBilling });
  return (
    <div
      className="flex w-full border border-es-light-grey p-6 h-fit"
      style={{ borderRadius: "10px" }}
    >
      <div className="flex flex-col gap-6 w-full">
        <p
          className="text-es-dark-grey text-es-lg font-es-semibold"
          style={{ letterSpacing: "1.13px" }}
        >
          Billing Information
          {!billingInfoComplete && (
            <p className={errorMessageCN}>Invalid card or address details</p>
          )}
        </p>
        {(isEditing && (
          <div className="flex flex-row w-full">
            <div
              className="flex flex-col gap-4 h-fit pr-10"
              style={{ width: "431px" }}
            >
              <p
                className="text-es-dark-grey font-es-semibold tracking-es-wide"
                style={{ fontSize: "20px" }}
              >
                Credit Card Information
              </p>
              <div className="flex flex-col gap-3">
                <div>
                  <p className={labelCN}>Name on Card</p>
                  <Input
                    placeholder="Name on card"
                    value={billingFormData.nameOnCard}
                    onChange={(val) => onChange(val, "nameOnCard")}
                    validation={yup.string().required()}
                  />
                </div>
                <div>
                  <p className={labelCN}>Card Number</p>
                  <CardNumberElement
                    className={cardInputCN}
                    options={cardElementStyles}
                    onChange={(val) => onChange(val?.brand, "cardType")}
                  />
                </div>
                <div className="flex flex-row items-end w-full gap-8">
                  <div className="w-1/2">
                    <p className={labelCN}>Expiration Date</p>
                    <CardExpiryElement
                      className={cardInputCN}
                      options={cardElementStyles}
                      placeholder="MM/YY"
                    />
                  </div>
                  <div className="w-1/2">
                    <p className={labelCN}>CVC</p>
                    <CardCvcElement
                      className={cardInputCN}
                      options={cardElementStyles}
                      placeholder="Enter CVC"
                      value="hello"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col border-l pl-6">
              <p
                className="text-es-dark-grey font-es-semibold tracking-es-wide"
                style={{ fontSize: "20px" }}
              >
                Billing Address
              </p>
              <div className="py-4">
                <div className="flex flex-col" style={{ width: "648px" }}>
                  <BillingBaseAddressInput
                    addressGroupCN="flex flex-row gap-4 w-full"
                    address={formAddress}
                    onChange={onChangeAddress}
                  />
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div className={innerCN("flex-row w-full h-full items-start")}>
            <div className="" style={{ width: "431px" }}>
              <p
                className="text-es-dark-grey font-es-semibold tracking-es-wide"
                style={{ fontSize: "20px" }}
              >
                Credit Card Information
              </p>
              <div className="flex flex-col w-full gap-4">
                <div className="flex flex-col gap-2 w-full pt-4">
                  <p className={labelCN}>Name on Card</p>
                  <p className={textCN}>{billingFormData.nameOnCard}</p>
                </div>
                <div className="flex flex-col w-full">
                  <p className={labelCN}>Card Type</p>
                  <p className={textCN}>
                    {capitalize(billingFormData.cardType)}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col gap-4 border-l pl-6 h-full"
              style={{ width: "655px" }}
            >
              <p
                className="text-es-dark-grey font-es-semibold tracking-es-wide"
                style={{ fontSize: "20px" }}
              >
                Billing Address
              </p>
              <div className="flex flex-col gap-4 w-full">
                {/* Row will contain 2 columns of label && input/value */}
                <div className="flex flex-row grid-cols-2 col-span-2 gap-6 w-full">
                  {/* Column for label input/value */}
                  <div className="flex flex-col gap-2 col-start-1 w-full">
                    <p className={labelCN}>Street Address</p>
                    <p className={textCN}>{billingFormData.billingStreet}</p>
                  </div>
                  <div className="flex flex-col gap-2 col-start-2 w-full">
                    <p className={labelCN}>Apt, Suite, etc</p>
                    <p className={textCN}>{billingFormData.billingStreet2}</p>
                  </div>
                </div>
                {/* Row will contain 2 columns of label && input/value */}
                <div className="flex flex-row grid-cols-2 col-span-2 gap-6 w-full">
                  {/* Column for label input/value */}
                  <div className="flex flex-col gap-2 col-start-1 w-full">
                    <p className={labelCN}>City</p>
                    <p className={textCN}>{billingFormData.billingCity}</p>
                  </div>
                  <div className="flex flex-col gap-2 col-start-2 w-full">
                    <p className={labelCN}>State</p>
                    <p className={textCN}>{billingFormData.billingState}</p>
                  </div>
                </div>
              </div>
              {/* Row will contain 2 columns of label && input/value */}
              <div className="flex flex-row grid-cols-2 col-span-2 gap-6 w-full">
                {/* Column for label input/value */}
                <div className="flex flex-col gap-2 col-start-1 w-full">
                  <p className={labelCN}>Postal Code</p>
                  <p className={textCN}>{billingFormData.billingZipCode}</p>
                </div>
                <div className="flex flex-col gap-2 col-start-2 w-full">
                  <p className={labelCN}>Country</p>
                  <p className={textCN}>
                    {billingFormData.billingCountry?.label}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInformation;
