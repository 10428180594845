import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { debounce } from "lodash";

import editIcon from "../../assets/images/editDarkenedGreenIcon.svg";
import TrashIcon from "../../assets/images/trashDarkenedGreenIcon.svg";
import { ADD_OPEN_MODAL, CONFIRM_MODAL } from "../../../constants";
import { useModalState } from "../../../state/modalState";

const editRemoveContainerCN = (className) => cntl`
    flex
    flex-row
    items-center
    ${className}
`;

const deleteButtonCN = () => cntl`
  ml-2
  w-6
  h-6
`;

const editButtonCN = () => cntl`
  ml-2
  w-6
  h-6
`;

const EditRemoveActions = ({
  index,
  secondIndex,
  toggleEditing,
  handleRemove,
  className,
  setIsAdding,
  children,
  hideRemove,
  hideEdit,
}) => {
  const [showActionIcons, setShowActionIcons] = useState(false);
  const [, modalDispatch] = useModalState();

  const handleMouseEnter = useMemo(
    () => debounce(() => setShowActionIcons(true), 300),
    [setShowActionIcons]
  );

  const handleMouseLeave = useMemo(
    () => debounce(() => setShowActionIcons(false), 300),
    [setShowActionIcons]
  );

  return (
    <div
      className={editRemoveContainerCN(className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showActionIcons && (
        <>
          {!hideEdit && (
            <div className="flex-0 ml-1">
              <button
                className={editButtonCN()}
                type="button"
                onClick={() => {
                  setIsAdding(false);
                  toggleEditing(index, secondIndex);
                }}
              >
                <img
                  className="w-5 cursor-pointer"
                  src={editIcon}
                  alt="edit data"
                />
              </button>
            </div>
          )}
          {!hideRemove && (
            <button
              className={deleteButtonCN()}
              type="button"
              onClick={() => {
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  modalType: CONFIRM_MODAL,
                  ref: { id: `${index}-confirm-delete` },
                  modalData: {
                    item: {
                      prompt: "Are you sure you want to delete this item?",
                      confirm: "Yes, delete",
                      cancel: "Cancel",
                      title: "Delete Item",
                      onConfirm: () => {
                        handleRemove(index, secondIndex);
                      },
                    },
                  },
                });
              }}
            >
              <img
                className="w-5 cursor-pointer"
                src={TrashIcon}
                alt="remove data"
              />
            </button>
          )}
        </>
      )}
    </div>
  );
};

EditRemoveActions.propTypes = {
  index: PropTypes.number,
  secondIndex: PropTypes.number,
  toggleEditing: PropTypes.func,
  handleRemove: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  setIsAdding: PropTypes.func,
  hideRemove: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

EditRemoveActions.defaultProps = {
  index: undefined,
  secondIndex: undefined,
  toggleEditing: undefined,
  handleRemove: undefined,
  className: undefined,
  setIsAdding: undefined,
  hideRemove: false,
  hideEdit: false,
};

export default EditRemoveActions;
