import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GET_SPACE_VIEW_PATH, PAGES, SPACE_TABS } from "../../../constants";
import { getSpaceConfiguration } from "../../../helpers/Formatters";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";
import { useProjectsOverview } from "../../../hooks/projects";
import { usePropertiesOverview } from "../../../hooks/properties";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useSpaces from "../../../hooks/useSpaces";
import { useSpaceSwitchView } from "../../../hooks/useSwitchView";
import { useGetTags } from "../../../hooks/useTags";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";

const useSpacesListViewData = ({ setActions }) => {
  const history = useHistory();
  const { propertyId, projectId } = useParams();
  const { data: tagsData } = useGetTags();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();
  const { getActiveTab, getPageSettings } = useAppPersistence();
  const pageSettings = getPageSettings(PAGES.SPACES);

  const { data: currentUser } = useCurrentUser();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const [isShowingSpacesTable] = useSpaceSwitchView();

  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateSettings, setTemplateSettings] = useState([]);
  const [modals, setModals] = useState({
    create: false,
    edit: false,
    set: (key, value) => setModals((prev) => ({ ...prev, [key]: value })),
  });
  const { data: managementConfiguration } = useManagementConfiguration();

  /**
   * Hook Dependencies
   */
  const hookDeps = useMemo(() => {
    if (propertyId) {
      return {
        resource: "propertiesDict",
        route: "property",
        cacheKey: "properties",
        id: propertyId,
        ref: `Property/${propertyId}`,
        api: "PropertyAPI",
      };
    }
    return {
      resource: "projectDict",
      route: "project",
      cacheKey: "projects",
      id: projectId,
      ref: `Project/${projectId}`,
      api: "ProjectAPI",
    };
  }, [projectId, propertyId]);

  /**
   * Hooks
   */
  const {
    base: {
      data: { spaces = [] },
      isLoading,
      isFetching,
      refetch: reloadSpaces,
    },
    actions: {
      post: { mutate: postSpace },
      removeMany: { mutateAsync: removeMany },
    },
  } = useSpaces(hookDeps);

  const spaceConfiguration = useMemo(
    () => getSpaceConfiguration(managementConfiguration),
    [managementConfiguration]
  );

  const dicts = useCallback(() => {
    return {
      Property: propertiesDict,
      Project: projectDict,
    };
  }, [projectDict, propertiesDict]);

  useEffect(() => {
    if (spaces?.length && spaceConfiguration && managementConfiguration) {
      setTableData(
        spaces?.map((space) => {
          const type = space?.association?.split("/")[0];
          const title = type?.includes("Project") ? "name" : "title";
          return {
            ...space,
            associatedResource: type,
            associatedName: dicts()?.[type]?.[space?.association]?.[title],
            type: spaceConfiguration?.spaces?.[space?.type]?.display,
            level: spaceConfiguration?.levels?.[space?.level]?.display,
            image: space?.primaryImage,
          };
        })
      );
    }
  }, [dicts, managementConfiguration, spaceConfiguration, spaces]);

  useEffect(() => {
    if (templatesConfiguration?.templates?.space) {
      setTemplateSettings(
        templatesConfiguration?.templates?.space?.map((template) => ({
          ...template,
          isAdmin: !template.custom,
        }))
      );
    }
  }, [templatesConfiguration]);

  const handleOpenCreate = useMemo(() => {
    if (modals.create || isLoading || isFetching) {
      return () => {};
    }
    return () => modals.set("create", true);
  }, [isFetching, isLoading, modals]);

  useEffect(() => {
    if (setActions) {
      setActions((prev) => [
        {
          title: "Add Space",
          onClick: handleOpenCreate,
          tabAction: true,
        },
        ...prev.filter((opt) => opt.title !== "Add Space"),
      ]);
    }
  }, [handleOpenCreate, isFetching, isLoading, modals, setActions]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "space",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return {
              ...view,
              custom: true,
            };
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "space",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const handleDeleteSpaces = () => {
    removeMany(selectedRows?.map(({ id }) => id));
    setShowDeleteModal(false);
  };

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const onRowClick = (id) => {
    const activeTab = getActiveTab({ page: PAGES.SPACES, resourceId: id });

    const isCurrentPersistedResource = pageSettings?.resource?.id === id;
    const activeTabOrDefault = isCurrentPersistedResource
      ? activeTab
      : SPACE_TABS.DETAILS_ID;
    history.push(
      `${GET_SPACE_VIEW_PATH[hookDeps.route](
        hookDeps.id,
        id
      )}?tab=${activeTabOrDefault}`
    );
  };
  const handleCreate = useCallback(
    (newSpace) => {
      modals.set("create", false);

      const { isNewType, originalResource, ...rest } = newSpace;
      const newS = { ...rest };

      postSpace(newS);
    },

    [modals, postSpace]
  );
  const hasWritePermission = useCallback(() => {
    if (propertyId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_property"
      );
    }

    if (projectId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_project"
      );
    }
    return false;
  }, [currentUser, projectId, propertyId]);

  // Perm for patching SnC for new type
  const canCreateType = currentUser?.isAdmin;

  return {
    handleCreate,
    selectedRows,
    onRowClick,
    handleRowSelect,
    handleDeleteSpaces,
    deleteUserTemplateSettings,
    updateUserTemplateSettings,
    tableData,
    modals,
    showDeleteModal,
    templateSettings,
    setShowDeleteModal,
    isShowingSpacesTable,
    hasWritePermission,
    spaceConfiguration,
    reloadSpaces,
    isLoading,
    isFetching,
    tagsData,
    canCreateType,
  };
};

export default useSpacesListViewData;
