// Framework Tools
import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";

// components
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import DatePicker from "../../DatePicker/DatePicker";
import Input from "../../Input/Input";
import Modal from "../../Modal/Modal";
import RequestGridViewDistroListCell from "../../Requests/RequestGridView/RequestGridViewDistroListCell";
import Widget from "../../Widget/Widget";
import DistroListSelectMembers from "../../DistroList/DistroListSelectMembers";
import useWorkflowCreateModalData from "./useWorkflowCreateModalData";
import ResourceDropDown from "../../WorkflowCreation/ResourceDropDown";

const labelCN = cntl`text-es-normal font-es-semibold text-es-medium-grey w-48`;
export default function WorkflowCreateModal({ disableAssociation }) {
  const {
    handlePostWorkflow,
    resetDistroList,
    handleDeleteFromDistro,
    handleChange,
    distroSelectOpen,
    setDistroSelectOpen,
    hasAssociationData,
    distroSelectOptions,
    disableSave,
    isSaving,
    requestFormDispatch,
    handleModalClose,
    workflowModalStatus,
    requestForm,
    showConfirm,
  } = useWorkflowCreateModalData();

  return (
    <>
      <Modal
        buttonCnClass="-ml-3"
        onRequestModalClose={() => {
          requestFormDispatch({ type: "reset" });
          handleModalClose();
        }}
        primaryButtonTitle="Save"
        title="Create Workflow"
        isOpen={
          workflowModalStatus?.open &&
          !workflowModalStatus?.edit &&
          !workflowModalStatus?.complete
        }
        hideFooter
        primaryButtonOnClick={handlePostWorkflow}
        tertiaryButtonTitle="Cancel"
        disabled={disableSave || isSaving}
        titleClassName="w-full"
        modalAction="Workflow creation"
        showConfirm={showConfirm}
      >
        <div
          style={{ width: "886px" }}
          className="flex flex-col items-start gap-6 min-w-full"
        >
          <div className="w-full">
            <Input
              inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
              placeholder="Name (Required)"
              value={requestForm.customName}
              validation={yup.string().required()}
              onChange={(val) => handleChange("customName", val)}
              required
            />
          </div>
          <Widget
            childClassName="relative w-full flex"
            title="Workflow Information"
            draggable={false}
            overflow
          >
            <div className="flex flex-col gap-6 items-start self-stretch w-full ">
              <div className="flex flex-col justify-between items-end gap-7 w-full">
                <div className="flex items-center w-full">
                  <p className={labelCN}>
                    Association<span className="text-brandGreen">*</span>
                  </p>
                  {distroSelectOpen && (
                    <div className="absolute left-44 -top-16 flex-1">
                      <DistroListSelectMembers
                        members={distroSelectOptions}
                        selectedMembers={requestForm?.distribution}
                        placeholder="Select Member"
                        onAdd={(val) =>
                          handleChange("addDistroMember", val.value)
                        }
                        onRemove={handleDeleteFromDistro}
                        setDistroSelectOpen={setDistroSelectOpen}
                      />
                    </div>
                  )}
                  <div className="flex-1">
                    <ResourceDropDown
                      noLabel
                      disableErrorMessage
                      resource={
                        requestForm.association ||
                        workflowModalStatus.association
                      }
                      setResource={(val) => {
                        resetDistroList();
                        handleChange("association", val);
                      }}
                      validation={yup.mixed().required()}
                      disableAssociation={
                        !(hasAssociationData && !disableAssociation)
                      }
                      placeholder="Select"
                      bottomMessage
                      onlyActiveProjects
                    />
                  </div>
                </div>

                <div className="flex items-start w-full">
                  <p className={labelCN}>Description</p>
                  <Input
                    mainWrapperClassName="flex-1"
                    placeholder="Description"
                    className="w-full"
                    inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride w-full"
                    inputContainerClassName="bg-lightGreen text-gray-450"
                    value={requestForm.description}
                    onChange={(val) => handleChange("description", val)}
                    isTextarea
                  />
                </div>
                <div className="flex items-center w-full">
                  <p className={labelCN}>
                    Start Date
                    <span className="text-brandGreen">*</span>
                  </p>
                  <div className="flex-1">
                    <DatePicker
                      className="w-48"
                      onChange={(val) =>
                        handleChange("startDate", {
                          data: moment(val).utc().format(),
                        })
                      }
                      value={requestForm?.startDate?.actual}
                      minDate={new Date()}
                    />
                  </div>
                </div>

                <div className="flex items-start w-full">
                  <p className={labelCN}>Distribution</p>
                  <div className="relative inline-flex flex-col justify-end items-start gap-3">
                    {!!requestForm?.distribution?.length && (
                      <div className="flex items-end gap-1">
                        <RequestGridViewDistroListCell
                          distroList={requestForm?.distribution}
                          hideTile
                          handleDeleteFromDistro={handleDeleteFromDistro}
                          className="flex-col"
                        />
                      </div>
                    )}
                    <PlusCircleButton
                      className="mt-0"
                      noHover
                      style={{ color: "#027D61" }}
                      title="Add"
                      disabled={!requestForm.association}
                      onClick={() => setDistroSelectOpen(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-6 w-full">
                {/* ** commented out per ES-9360: Schedule Reference value will be automated ** */}

                {/* <div
                  id="right-column"
                  className="flex flex-col items-start w-1/2"
                >
                  <div className="flex justify-between items-center w-full py-6">
                    <p className={labelCN}>Schedule Reference</p>
                    <div className="w-2/3">
                      <Input
                        placeholder="Enter Description"
                        inputClassName="ESInput bg-lightGreen text-gray-450 placeholderOverride"
                        inputContainerClassName="bg-lightGreen text-gray-450"
                        value={requestForm.schedule.description}
                        onChange={(val) =>
                          handleChange("scheduleDescription", val)
                        }
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </Widget>
        </div>
      </Modal>
    </>
  );
}

// prop types
WorkflowCreateModal.propTypes = {
  /* if a submittal is created within a project association should be disabled, pass disableAssociation */
  disableAssociation: PropTypes.bool,
};

// default props
WorkflowCreateModal.defaultProps = {
  disableAssociation: false,
};
