import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { cloneDeep, isEqual } from "lodash";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useAssetFormReducer from "../../../hooks/useAssetFormReducer";
import {
  ASSET_CREATE_TAB_ORDER,
  ASSET_EMPTY,
  REMOVE_MODAL_INSTANCE,
} from "../../../constants";
import useSpaces from "../../../hooks/useSpaces";
import { assetInfoSchema } from "../../../helpers/FormValidations";
import { uploadFileWithData } from "../../../helpers/File";
import {
  filePaginatedKeys,
  tagKeys,
} from "../../../config/reactQuery/queryKeyFactory";
import { formatServerErrorMessage } from "../../../helpers/Formatters";
import { useModalState } from "../../../state/modalState";
import { useAssetsPost } from "../../../hooks/assets";
import { toastError, toastMessage } from "../Toast/Toast";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { getTagOptions } from "../../../helpers/Tag";
import { useGetTags } from "../../../hooks/useTags";
import formatOwnedByForm from "../../../helpers/Format/formatOwnedByForm";
import useFilesPost from "../../../hooks/useFilesPost";
import uploadAvatar from "../../../helpers/uploadAvatar";
import { useAppState } from "../../../state/appState";

const useAssetModal = ({ modalData }) => {
  const [{ editingResource }] = useAppState();
  const { data: tagsData } = useGetTags();

  const { data: currentUser } = useCurrentUser();
  const { mutateAsync: postAsset } = useAssetsPost();
  const { mutateAsync: postFiles } = useFilesPost();
  const { data: managementConfiguration } = useManagementConfiguration();
  const queryClient = useQueryClient();
  const [, modalDispatch] = useModalState();
  const [isFormReset, setIsFormReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptionsMap, setSubCatOptionsMap] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [newAttributes, setNewAttributes] = useState([]);
  const [tmpAvatarImg, setTmpAvatarImg] = useState(
    modalData?.formData?.tmpAvatarImg ?? {}
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [associatedResource, setAssociatedResource] = useState(
    modalData?.item?.association || modalData?.formData?.association
  );
  const subAssociation = modalData?.item?.subAssociation;
  const associationType = modalData?.item?.associationType;
  const disableAssociation = modalData?.item?.disableAssociation;

  const formattedTags =
    modalData?.formData?.currentTags?.map((tag) => tag.value) || [];

  // If creating a new asset, format tags to just have reference.
  const dataForReducer = {
    ...modalData?.formData,
    tags: formattedTags,
  };

  delete dataForReducer?.tmpAvatarImg;
  delete dataForReducer?.originalResource?.originalResource;

  const [asset, dispatch] = useAssetFormReducer(dataForReducer);

  const handleRequestModalClose = () => {
    modalDispatch({
      type: REMOVE_MODAL_INSTANCE,
      id: modalData.id,
    });
  };
  const initialAsset = useMemo(() => {
    const cloned = cloneDeep(ASSET_EMPTY);
    const { originalResource, ...originalResourceAsset } = asset;

    const clonedEmptyWithOriginal = {
      ...cloned,
      originalResource: originalResourceAsset,
      currentTags: getTagOptions(cloned, tagsData?.tagsDict),
    };

    return (
      (associationType
        ? {
            ...clonedEmptyWithOriginal,
            [associationType.toLowerCase()]: associatedResource,
          }
        : clonedEmptyWithOriginal) ?? ""
    );
  }, [asset, associatedResource, associationType, tagsData?.tagsDict]);

  useEffect(() => {
    const isSame = isEqual(initialAsset, asset);
    setShowConfirm(!isSame);
  }, [asset, initialAsset]);

  const hookDeps = useMemo(() => {
    if (associatedResource?.includes("Property")) {
      return {
        resource: "propertiesDict",
        route: "property",
        cacheKey: "properties",
        id: associatedResource?.split("/")[1],
        ref: associatedResource,
        api: "PropertyAPI",
      };
    }
    return {
      resource: "projectDict",
      route: "project",
      cacheKey: "projects",
      id: associatedResource?.split("/")[1],
      ref: associatedResource,
      api: "ProjectAPI",
    };
  }, [associatedResource]);

  const {
    base: {
      data: { spaces },
      isLoading,
    },
  } = useSpaces(hookDeps);
  const spacesDD = useMemo(() => {
    if (isLoading) return [];
    return spaces?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  }, [isLoading, spaces]);

  const modalRef = useRef(null);
  const currentWindowWidth = modalRef?.current?.clientWidth;

  useEffect(() => {
    setWindowWidth(currentWindowWidth);
  }, [currentWindowWidth, setWindowWidth]);

  useEffect(() => {
    if (!associatedResource) {
      if (
        modalData?.item?.propertyId &&
        !modalData?.item?.propertyId.includes("propertyId")
      ) {
        dispatch({
          type: "property",
          value: `Property/${modalData?.item?.propertyId}`,
        });
      }
      if (
        modalData?.item?.projectId &&
        !modalData?.item?.projectId.includes("projectId")
      ) {
        dispatch({
          type: "project",
          value: `Project/${modalData?.item?.projectId}`,
        });
      }
    } else {
      dispatch({
        type: "association",
        value: associatedResource,
      });
    }
  }, [
    associatedResource,
    modalData?.item?.projectId,
    modalData?.item?.propertyId,
    dispatch,
  ]);

  useEffect(() => {
    if (modalData?.item?.spaceId) {
      dispatch({
        type: "editSpace",
        value: modalData?.item?.spaceId,
      });
    }
  }, [modalData?.item?.spaceId, dispatch]);

  useEffect(() => {
    if (managementConfiguration) {
      const categories = managementConfiguration.management?.asset?.category;
      const subcatMap = {};
      setCatOptions(() => {
        const catList = categories
          ?.filter((cat) => cat.selected)
          .map((cat) => {
            subcatMap[cat.display] = cat.subcategories
              .filter((subcat) => subcat.selected)
              .map((subcat) => {
                return { label: subcat.display, value: subcat.id };
              });
            return { label: cat.display, value: cat.id };
          });
        return catList;
      });
      setSubCatOptionsMap(subcatMap);
    }
  }, [managementConfiguration]);

  const handleSetAssociation = (val) => {
    let ref = val ?? associatedResource;
    if (val && typeof val !== "string") {
      ref = val.value;
    }
    setAssociatedResource(!val ? undefined : ref);
    dispatch({
      type: !val ? "clearAssociation" : "association",
      value: ref,
    });
  };

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid && !!formData.subcategory);
  }, []);

  useEffect(() => {
    checkValidation(asset, assetInfoSchema());
  }, [asset, checkValidation]);

  const inputRefs = useRef({ assetLinks: {} });

  /**
   * Automatically moves cursor to next input field on pressing Enter/Tab
   */
  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      event.preventDefault();

      const currentIndex = ASSET_CREATE_TAB_ORDER.indexOf(
        event.target.id || event.target.name
      );
      const nextField = ASSET_CREATE_TAB_ORDER?.[currentIndex + 1];

      if (nextField) {
        inputRefs?.current?.[nextField]?.focus();
      }
    }
  }, []);

  const onAddFile = async (doc, info = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      doc,
      info,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    return fileResource;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const fileData = {
          name,
          docType,
          isFavorited,
          contentType: original?.type,
          size: original?.size,
        };
        const resource = await onAddFile(original, fileData, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return resource;
      })
    );

    return result;
  }, []);

  const removeAttachedFile = useCallback(
    (id) => {
      const remainingFiles = filesToUpload?.filter(
        (_file, index) => index !== id
      );
      setFilesToUpload(remainingFiles);
    },
    [filesToUpload]
  );

  const handleFilesAdded = useCallback(
    async (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );

  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = filesToUpload.filter((file) => !file.isEditing);
    const res = await onUpload(filteredFiles, () => {});

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);

    setFilesToUpload([]);
    return res;
  }, [filesToUpload, onUpload, queryClient]);

  useDidMountEffect(() => {
    if (isFormReset && !isEqual(initialAsset, asset)) {
      setIsFormReset(false);
    }
  }, [asset]);

  const resetForm = () => {
    if (!associationType) {
      setAssociatedResource();
    }

    setFilesToUpload([]);
    setNewAttributes([]);

    // Prevent duplicate originalResource while keeping initialAsset equal to asset
    const { originalResource, ...restInitialAsset } = initialAsset;

    setTmpAvatarImg({});

    dispatch({
      type: "reset",
      asset: restInitialAsset,
    });

    setIsFormReset(true);
  };

  // ref to handleTimer for handleCreate function
  const handleCreateRef = useRef(null);

  useEffect(() => {
    // clear timer on unmount
    if (handleCreateRef.current) clearTimeout(handleCreateRef.current);
  }, []);

  const handleCreate = async (addAnotherAsset) => {
    const newAsset = await uploadAvatar({
      tmpAvatarImg,
      resourceState: asset,
      postFiles,
    });

    const finishedAsset = {
      ...newAsset,
      description: newAsset?.description?.trim(),
      tags: newAsset?.currentTags?.map((tag) => tag?.value) || newAsset?.tags,
      ownedBy: formatOwnedByForm(newAsset),
    };

    setErrorMessage(undefined);
    setIsSaving(true);

    let response;

    try {
      response = await postAsset(finishedAsset);

      const messageAndLinkProps = {
        resource: response,
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
      setIsSaving(false);
    } catch (err) {
      const serverMsg = formatServerErrorMessage(err);
      setErrorMessage(serverMsg);
      toastError("Asset could not be created. Please try again.");
    } finally {
      queryClient.invalidateQueries(tagKeys.tags);
      setIsSaving(false);
      if (addAnotherAsset) {
        resetForm();
      } else {
        handleRequestModalClose();
      }
    }
  };
  return {
    handleCreate,
    isInputValid,
    isSaving,
    showConfirm,
    modalRef,
    errorMessage,
    currentUser,
    asset,
    dispatch,
    catOptions,
    subCatOptionsMap,
    associatedResource,
    handleSetAssociation,
    handleKeyUp,
    inputRefs,
    filesToUpload,
    handleFilesAdded,
    handleFilesUpdated,
    handleFilesUploaded,
    removeAttachedFile,
    windowWidth,
    spacesDD,
    newAttributes,
    setNewAttributes,
    handleRequestModalClose,
    disableAssociation,
    subAssociation,
    isFormReset,
    setIsFormReset,
    tmpAvatarImg,
    setTmpAvatarImg,
  };
};

export default useAssetModal;
