import React from "react";
import Dropdown from "../../../Dropdown/Dropdown";
import requiredIcon from "../../../../assets/images/requiredIcon.svg";
import useTsViewDdSectionData from "./useTsViewDdSectionData";

const TsViewDDSection = ({
  entriesForEditing,
  projectsState,
  projectDict,
  projectsDD,
  financialsConfiguration,
  filteredCategoriesByProjectRate,
  csiCodesMap,
  handleProjectChange,
  handleRateChange,
  handleCategoryChange,
  filterRatesByProject,
}) => {
  const { tsEntryData } = useTsViewDdSectionData({
    entriesForEditing,
    projectsState,
    projectDict,
    projectsDD,
    financialsConfiguration,
    filteredCategoriesByProjectRate,
    csiCodesMap,
    filterRatesByProject,
  });

  return (
    <>
      {tsEntryData.map(
        ({
          projectDDValue,
          dropDownStyles,
          rateValue,
          ratesDD,
          rates,
          categoryDD,
          categoryValue,
          projectReference,
          error,
          key,
        }) => (
          <React.Fragment key={key}>
            <Dropdown
              className={dropDownStyles}
              placeholder="Select"
              value={projectDDValue}
              options={projectsDD}
              onChange={(val) => handleProjectChange(val, key)}
            />
            <Dropdown
              placeholder="Select"
              className={dropDownStyles}
              value={rateValue}
              options={ratesDD}
              onChange={(val) => handleRateChange(val, key)}
              isDisabled={!projectReference}
            />
            <Dropdown
              placeholder="Select"
              className={dropDownStyles}
              value={categoryValue}
              options={categoryDD}
              onChange={(event) => handleCategoryChange(event, key)}
              isDisabled={!rates}
            />

            {error && (
              <div className="flex items-center col-span-3 bg-es-light-red p-2 rounded-lg">
                <img
                  src={requiredIcon || ""}
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  className="mr-2"
                  alt="alert"
                />
                <h1 className="es-dark-grey es-medium">{error}</h1>
              </div>
            )}
          </React.Fragment>
        )
      )}
    </>
  );
};

export default TsViewDDSection;
