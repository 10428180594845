import { TimeoffAPI, TimesheetAPI } from "@griffingroupglobal/eslib-api";
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { COA_CODE_TYPES } from "../constants";
import { parseCSICodes } from "../helpers/Budget";
import {
  checkCache,
  checkEmployeePtoCache,
  formatHistory,
  unzipEmployeePto,
  unzipTimesheets,
} from "../helpers/TimeSheet";
import handleHistoryReqSplit from "../helpers/Utilities/handleHistoryReqSplit";
import { useAppState } from "../state/appState";
import useFinancialsConfiguration from "./useFinancialsConfiguration";
import usePagePersistence from "./usePagePersistence";

const { TimeSheets } = TimesheetUtil;

export default (userRef, startInput) => {
  /**
   * @summary Financials Configuration
   */
  const payrollConfig = useRef(null);

  /**
   * @summary Financials Config State
   */
  const [financials, setFinancials] = useState();

  /**
   * @summary TimeSheet Util
   */
  const util = useRef(null);

  /**
   * @summary Employee Timesheets
   */
  const [timesheets, setTimesheets] = useState();

  /**
   * @summary Emplyee Pto
   */
  const [approvedPto, setApprovedPto] = useState();

  /**
   * @summary Loading State
   */
  const [loading, setLoading] = useState(true);

  /**
   * @summary list of usersInfo
   */
  const [{ userDict: usersList }] = useAppState() ?? [{ usersList: [] }];
  /**
   * @summary Cache
   */

  const cache = useRef(null);

  /**
   * @summary Cached PTO
   * note: Appends every request (unique + updates after patch)
   */
  const localPtoCache = useRef(null);

  /**
   * Revenue Accounting Codes
   */
  const revenueAccountingCodes = useRef(null);

  /**
   * Expense Accounting Codes
   */
  const expenseAccountingCodes = useRef(null);

  /**
   * @summary Current Payroll Period
   */
  const [periodFrame, setPeriodFrame] = useState();

  /**
   * @summary CSI Codes Dict
   */
  const [csiCodes, setCsiCodes] = useState();

  /**
   * @Summary Timesheet history
   */
  const { pageState } = usePagePersistence();

  const [timesheetHistory, setTimesheetHistory] = useState();
  const { data: finSettings } = useFinancialsConfiguration();

  const clearCache = () => {
    cache.current = null;
    localPtoCache.current = null;
  };

  const reload = useCallback(
    async (start, end) => {
      setTimesheets();
      setApprovedPto();
      setLoading(true);
      if (!payrollConfig.current) {
        payrollConfig.current = finSettings?.financials;
        util.current = new TimeSheets(
          payrollConfig.current?.payroll?.period,
          payrollConfig.current?.period?.start,
          3,
          end
        );

        setFinancials({
          period: payrollConfig.current?.payroll?.period,
          start: payrollConfig.current?.period?.start,
        });
        setPeriodFrame(util.current.currentPeriod);
        revenueAccountingCodes.current =
          payrollConfig.current?.chartOfAccounts?.filter(
            (code) => code.codeType === COA_CODE_TYPES[1].value
          );
        expenseAccountingCodes.current =
          payrollConfig.current?.chartOfAccounts?.filter(
            (code) => code.codeType === COA_CODE_TYPES[0].value
          );
        parseCSICodes(
          payrollConfig.current,
          setCsiCodes,
          revenueAccountingCodes.current,
          expenseAccountingCodes.current
        );
      }

      if (!localPtoCache.current) {
        /**
         * @TODO - Request API update for range & implement cache
         */

        const { data: ptoRes } = await TimeoffAPI.get({
          params: { status: "approved", user: userRef },
        });

        localPtoCache.current = unzipEmployeePto(
          ptoRes?.entries?.reduce((arr, { resource }) => {
            if (resource?.requests) {
              resource?.requests?.forEach((item) => {
                // eslint-disable-next-line no-param-reassign
                arr = arr.concat({ ...item, userRef: resource?.user });
              });
            }
            return arr;
          }, []),
          usersList
        );
      }

      if (start && !startInput) {
        const cachedSheets = checkCache(cache.current, start, end, true);
        const cachedPto = checkEmployeePtoCache(
          localPtoCache.current,
          start,
          end
        );
        if (cachedPto?.length > 0) {
          setApprovedPto(cachedPto);
        }

        if (cachedSheets?.length > 0) {
          setTimesheets(cachedSheets);
          setLoading(false);
          return;
        }
      }

      /**
       * Initial Cache Fetch
       */
      const query = {
        periodStart: start ?? util.current.cacheLimit,
        periodEnd: end ?? util.current?.currentPeriod?.periodEnd,
        userRef,
        status: "open,submitted,approved,approved-with-changes,locked,rejected",
      };

      const {
        data: { entries },
      } = await TimesheetAPI.get({ params: query });

      if (entries?.length > 0) {
        cache.current = cache.current?.length
          ? _.uniqBy(
              [...cache.current].concat(unzipTimesheets(entries, usersList)),
              (item) => {
                return `${item?.resource?.project}|${item?.resource?.financialCode}|${item?.resource?.rate}|${item?.resource?.periodStart}|${item?.resource?.periodEnd}|${item?.resource?.userRef}`;
              }
            )
          : unzipTimesheets(entries, usersList);
      }

      const queryString = entries?.reduce((str, entry) => {
        const concat = `${str}${entry?.resource?.reference},`;
        return concat;
      }, "?association=");

      const { data } = await handleHistoryReqSplit(queryString);

      setTimesheetHistory((prev) => {
        return prev
          ? { ...prev, ...formatHistory(data?.entries, usersList), queryString }
          : { ...formatHistory(data?.entries, usersList), queryString };
      });

      if (start || startInput) {
        const { periodStart: begin, periodEnd: finish } =
          TimeSheets.getPeriodFrame(
            payrollConfig.current?.payroll?.period,
            payrollConfig.current?.period?.start,
            start ?? startInput
          );
        const cachedSheets = checkCache(cache.current, begin, finish, true);
        const cachedPto = checkEmployeePtoCache(
          localPtoCache.current,
          begin,
          finish
        );
        if (cachedSheets?.length > 0) {
          setTimesheets(cachedSheets);
        }
        if (cachedPto?.length > 0) {
          setApprovedPto(cachedPto);
        }

        setLoading(false);
        return;
      }

      if (!start && !startInput) {
        const cachedSheets = checkCache(
          cache.current,
          util.current?.currentPeriod?.periodStart,
          util.current?.currentPeriod?.periodEnd,
          true
        );
        const cachedPto = checkEmployeePtoCache(
          localPtoCache.current,
          util.current?.currentPeriod?.periodStart,
          util.current?.currentPeriod?.periodEnd
        );

        if (cachedSheets?.length > 0) {
          setTimesheets(cachedSheets);
        }
        if (cachedPto?.length > 0) {
          setApprovedPto(cachedPto);
        }
      }

      setLoading(false);
    },
    [startInput, userRef, usersList, finSettings]
  );

  const updateCache = (sheets) => {
    sheets?.forEach((sheet) => {
      const index = cache.current?.findIndex(
        (item) => item?.resource?.id === sheet?.id
      );

      const refreshSheet = { ...sheet, index };
      cache.current[index] = {
        resource: refreshSheet,
      };
    });
  };

  const reloadHistory = useCallback(async () => {
    try {
      if (timesheetHistory?.queryString) {
        const { data } = await handleHistoryReqSplit(
          timesheetHistory?.queryString
        );

        setTimesheetHistory((prev) => ({
          ...prev,
          ...formatHistory(data?.entries, usersList),
        }));
      }
    } catch {
      console.error("Error Loading History");
    }
  }, [timesheetHistory?.queryString, usersList]);

  useEffect(() => {
    clearCache();
    if (usersList && finSettings?.financials) {
      reload(
        pageState?.timesheet?.periodStart,
        pageState?.timesheet?.periodEnd
      );
    }
  }, [finSettings, pageState, reload, usersList]);

  return {
    timesheets,
    timesheetHistory,
    pto: approvedPto,
    loading,
    financials,
    csiCodes,
    periodFrame,
    reload,
    reloadHistory,
    updateCache,
  };
};
