import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppState } from "../../../state/appState";
import useServiceRequestPost from "../../../hooks/useServiceRequestPost";
import useFilesPost from "../../../hooks/useFilesPost";
import useServiceRequestDispatcher from "../../../hooks/useServiceRequestDispatcher";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";
import { usePropertiesOverview } from "../../../hooks/properties";
import {
  SERVICE_REQUEST_EMPTY,
  SET_REQUEST_MODAL_STATUS,
} from "../../../constants";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import getTicketTypesDD from "../../../helpers/Types/getTicketTypesDD";
import { useProjectsOverview } from "../../../hooks/projects";
import { useAssetsOverview } from "../../../hooks/assets";

const useServiceRequestModalData = () => {
  const { data: config } = useManagementConfiguration();

  const ticketTypeOptions = useMemo(() => getTicketTypesDD(config), [config]);

  const [{ requestsModalStatus }, dispatchAppState] = useAppState();

  const { assetsDict, assetsDD } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  // SR dispatcher hook
  const {
    requestForm,
    setRequestForm,
    handleChangeForm,
    handleTags,
    handleAddFiles,
    handleRemoveFile,
  } = useServiceRequestDispatcher(SERVICE_REQUEST_EMPTY);

  // Helper hook to get parent association
  const { getParentAssociation } = useAssociationsHelper();

  // Mutation hook to create SR on backend
  const { mutate: createServiceRequest, isLoading: isCreating } =
    useServiceRequestPost();

  const { mutate: postFiles, isLoading: isUploadingFiles } = useFilesPost();

  // State to track modal visibility
  const [isOpen, setIsOpen] = useState(false);

  // State to track if SR comes with a default association
  // If `asset` means the modal was opened from an Asset Single Page under Service Request Tab. Both Association and Asset dropdown will be shown.
  // If `association` means the modal was opened from P/P. Only Association dropdown will be shown
  // If both `undefined` means the modal was opened from the left side menu or the top nav bar icon. Only Association dropdown will be shown (DD will show P/P/A)
  const lockAssociation = useMemo(
    () => ({
      association: requestsModalStatus?.associationLock,
      asset: requestsModalStatus?.assetLock,
    }),
    [requestsModalStatus]
  );

  // State to handle if form needs to be disabled
  const [disableForm, setDisableForm] = useState(true);

  // Inform if association dropdown needs to be disabled
  const disableAssociationDropdown = !!lockAssociation?.association;

  const [showConfirm, setShowConfirm] = useState(false);
  const [associationAsset, setAssociationAsset] = useState();

  const initialRequest = useMemo(
    () => ({
      ...SERVICE_REQUEST_EMPTY,
      association: lockAssociation?.association,
      asset: lockAssociation?.asset,
    }),
    [lockAssociation]
  );

  // is SR association Project or Property?
  const isProjOrPropAssociation =
    requestForm?.association?.startsWith("Property") ||
    requestForm?.association?.startsWith("Project");

  // build the assets options DD associted with Project or Property association
  const assetOptions = useMemo(() => {
    const association = requestForm?.association;
    if (!isProjOrPropAssociation) return [];
    const filtered = assetsDD?.filter((option) => {
      const asset = assetsDict[option.value];
      if (asset.property) return asset.property === association;
      return asset.project === association;
    });
    return filtered;
  }, [assetsDD, assetsDict, isProjOrPropAssociation, requestForm?.association]);

  // handle the association Assets DD selection
  const handleSelectAsset = useCallback(
    (val) => {
      setAssociationAsset(val);
    },
    [setAssociationAsset]
  );

  useEffect(() => {
    const isSame = isEqual(initialRequest, requestForm);
    setShowConfirm(!isSame);
  }, [requestForm, initialRequest]);

  useEffect(() => {
    setIsOpen(requestsModalStatus.open);
  }, [requestsModalStatus]);

  /**
   * This effect sets the dropdown with the current association
   * This is done if the request service modal is opened from P/P/A details page
   */
  useEffect(() => {
    if (lockAssociation?.association) {
      setRequestForm((current) => ({
        ...current,
        association: lockAssociation.association,
        asset: lockAssociation?.asset,
      }));
    }
  }, [
    lockAssociation,
    propertiesDict,
    projectDict,
    assetsDict,
    setRequestForm,
  ]);

  /**
   * This effect disable form if required fields are not populated or if
   * mutation indicators are true (when creating SR or posting files)
   */
  useEffect(() => {
    setDisableForm(
      !requestForm.issue.trim() ||
        !requestForm.association ||
        isCreating ||
        isUploadingFiles
    );
  }, [
    requestForm.issue,
    isCreating,
    isUploadingFiles,
    requestForm.association,
  ]);

  const handleCloseModal = () => {
    dispatchAppState({
      type: SET_REQUEST_MODAL_STATUS,
      open: false,
      isQuickAdd: false,
    });
  };

  /**
   * It closes the modal and, if the user is creating
   * from P/P/A, it redirects the user to the SR single page.
   * @param {Object} data response data (SR) from backend
   */
  const onSuccessCallback = () => {
    handleCloseModal();
  };

  /**
   * Create the new service request, if success redirect to SR details page
   */
  const handleCreateServiceRequest = () => {
    // Add Asset selected from the associated Asset DD
    const finalRequestForm = isProjOrPropAssociation
      ? { ...requestForm, asset: associationAsset?.value }
      : requestForm;
    if (requestForm?.files?.length) {
      // Create SR with files
      postFiles(requestForm.files, {
        onSuccess: (data) => {
          // Get files response from backend and transform to files map for SR
          const files = data.map(({ reference: ref, category }) => ({
            ref,
            category,
          }));

          // Create SR
          createServiceRequest(
            { ...finalRequestForm, files },
            { onSuccess: onSuccessCallback }
          );
        },
      });
    }

    // Create SR if not files
    else {
      createServiceRequest(finalRequestForm, { onSuccess: onSuccessCallback });
    }
  };

  /**
   * Handles the change of association based on the provided reference
   */
  const handleChangeAssociation = (reference) => {
    if (reference?.startsWith("Asset")) {
      const parentResource = getParentAssociation(reference);
      setRequestForm((current) => ({
        ...current,
        association: parentResource?.reference,
        asset: reference,
      }));
    } else {
      setRequestForm((current) => ({
        ...current,
        association: reference,
        asset: undefined,
      }));

      // reset associationAsset state
      setAssociationAsset();
    }
  };

  return {
    isOpen,
    requestForm,
    disableForm,
    isCreating,
    isUploadingFiles,
    disableAssociationDropdown,
    showConfirm,
    ticketTypeOptions,
    assetOptions,
    associationAsset,
    handleSelectAsset,
    handleChangeAssociation,
    handleCloseModal,
    handleChangeForm,
    handleTags,
    handleCreateServiceRequest,
    handleAddFiles,
    handleRemoveFile,
  };
};

export default useServiceRequestModalData;
