import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import * as yup from "yup";

import moment from "moment";
import cntl from "cntl";
import Checkbox from "../../../../stories/Components/Checkbox/Checkbox";
import DatePicker from "../../../../stories/Components/DatePicker/DatePicker";
import Dropdown from "../../../../stories/Components/Dropdown/Dropdown";
import trashDarkenedGreenIcon from "../../../../stories/assets/images/trashDarkenedGreenIcon.svg";

import {
  DEFAULT_ATTRIBUTES,
  NO_ATTRIBUTE_OPTION_MSG,
} from "../../../../constants";
import useAttributeModalWidget from "./useAttributeModalWidget";
import InlineInput from "../../../../stories/Components/Input/InlineInput";
import sortAndGroupByFieldType from "../../../../helpers/sortAndGroupByFieldType";
import EditItemAsset from "../../../../stories/Components/DetailListCard/EditItemAsset";
import IconButton from "../../../../stories/Components/Buttons/IconButton";
import PlusCircleButton from "../../../../stories/Components/Buttons/PlusCircleButton/PlusCircleButton";
import Widget from "../../../../stories/Components/Widget/Widget";

const splitRowCN = cntl`
  flex
  justify-between
  items-center
  mt-1
  font-normal
  text-gray-450
  text-xs
`;

const AttributeModalWidget = ({
  resource,
  dispatch,
  loading,
  defaultAttributes = [],
  disableEditing,
  setNewAttributes,
  newAttributes,
  editing,
}) => {
  const {
    selectedMeasurements,
    inputRef,
    handleDeleteElement,
    handleAddClick,
    wrapperRef,
  } = useAttributeModalWidget({
    setNewAttributes,
    dispatch,
    resource,
  });

  const onChange = useCallback(
    (id, value, unitId, optionId) => {
      dispatch({
        type: "measurement",
        id,
        value,
        unitId,
        optionId,
      });
    },
    [dispatch]
  );

  const filteredItems = useMemo(() => {
    if (selectedMeasurements?.length || newAttributes?.length) {
      const allMeasurements = [...selectedMeasurements];
      const { additionalInfo: info } = resource;
      const temp = allMeasurements?.reduce(
        (list, item) => {
          const found = (info ?? []).find((x) => item.id === x?.id);
          if (found) {
            const foundValue =
              found.value ||
              item.options.find(({ id }) => id === found.optionId)?.name;
            const foundUnit = item.unit.options.find(
              ({ id }) => id === found.unitId
            )?.name;

            const value =
              item?.fieldType === "date-picker"
                ? moment(found.value).format("MM/DD/YYYY")
                : `${foundValue}${foundUnit ? ` ${foundUnit}` : ""}`;

            list.items.push({
              title: item.detail,
              id: item.id,
              value: (
                <p
                  className={splitRowCN}
                  title={value}
                  aria-label={`${item.id}`}
                >
                  {`${value}`}
                </p>
              ),
              field: item?.fieldType,
            });
            list.measurementFields.push(item);
          }
          return list;
        },
        { items: [], measurementFields: [] }
      );
      return temp;
    }

    return { items: [], measurementFields: [] };
  }, [newAttributes, resource, selectedMeasurements]);

  const getMeasurementInput = useCallback(
    (
      detail,
      fieldType,
      options,
      units,
      defaultValue,
      defaultOnChange,
      defaultTitle,
      id
    ) => {
      const unitOptions = [];
      if (units?.options?.length) {
        units.options.forEach(({ id: unitOptionId, name }) =>
          unitOptions.push({ label: name, value: unitOptionId })
        );
      }

      const { value, unitId, optionId } =
        resource?.additionalInfo?.find((m) => m.id === id) ?? {};

      let returnValue;

      const determineTextFieldNeeded = (input) => {
        if (typeof input !== "string" || input === "") return false;
        if (input.length > 20) return true;

        return false;
      };

      switch (fieldType) {
        case "number-entry":
          returnValue = (
            <div
              key={id}
              className="flex flex-row w-full h-full justify-between items-center"
            >
              <div className="flex flex-row justify-start w-1/2">
                <h3
                  className="font-bold text-gray-450 text-md"
                  style={{ textAlign: "start" }}
                >
                  {defaultTitle ?? detail}
                </h3>
              </div>
              <div className="flex flex-row justify-end items-center content-center w-full">
                <InlineInput
                  editing
                  hidePencil
                  isTextarea={determineTextFieldNeeded(defaultValue ?? value)}
                  width="w-full"
                  size="xl"
                  color="gray-450"
                  rows={3}
                  inputStyle={{
                    height:
                      !determineTextFieldNeeded(defaultValue ?? value) &&
                      "38px",
                    fontSize: "15px",
                  }}
                  className="flex justify-end"
                  placeholder={detail}
                  onChange={(val) =>
                    defaultOnChange
                      ? defaultOnChange(val)
                      : onChange(id, val, unitId)
                  }
                  value={defaultValue ?? value}
                  validation={yup
                    .number()
                    .transform((v, o) => (o === "" ? undefined : v))}
                />
                {!!unitOptions.length && (
                  <div className="flex flex-row h-full w-40">
                    <Dropdown
                      noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                      className="w-full ml-2 text-left"
                      placeholder="Unit"
                      options={unitOptions}
                      onChange={({ value: changeValue }) =>
                        onChange(id, value, changeValue)
                      }
                      value={unitOptions?.find((opt) => opt.value === unitId)}
                      disableClear
                    />
                  </div>
                )}
              </div>
            </div>
          );
          break;
        case "date-picker":
          returnValue = (
            <div
              key={id}
              className="flex flex-row justify-between h-full w-full items-center"
            >
              <div className="flex flex-row w-1/2">
                <h3
                  className="flex flex-row justify-start font-bold text-gray-450 text-md w-full"
                  style={{ textAlign: "start" }}
                >
                  {defaultTitle ?? detail}
                </h3>
              </div>
              <div className="flex flex-row w-full">
                <DatePicker
                  className="w-full"
                  key={detail}
                  value={defaultValue ?? value}
                  onChange={(val) =>
                    defaultOnChange ? defaultOnChange(val) : onChange(id, val)
                  }
                  validation={yup.date()}
                />
              </div>
            </div>
          );
          break;
        case "ddl":
          {
            const optionsDDL = options?.map(({ id: optionDDLId, name }) => ({
              label: name,
              value: optionDDLId,
            }));

            returnValue = (
              <div
                key={id}
                className="flex flex-row justify-between h-full w-full items-center"
              >
                <div className="flex flex-row w-1/2">
                  <h3
                    className="flex flex-row justify-start font-bold text-gray-450 text-md w-full"
                    style={{ textAlign: "start" }}
                  >
                    {defaultTitle ?? detail}
                  </h3>
                </div>
                <div className="flex flex-row w-full">
                  <Dropdown
                    noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                    className="w-full text-left"
                    placeholder={detail}
                    options={optionsDDL}
                    onChange={({ value: changeValue }) =>
                      defaultOnChange
                        ? defaultOnChange(changeValue)
                        : onChange(id, null, null, changeValue)
                    }
                    value={optionsDDL?.find(
                      (opt) =>
                        opt.value === defaultValue || opt.value === optionId
                    )}
                  />
                </div>
              </div>
            );
          }
          break;
        case "check-box":
          returnValue = (
            <div key={id} className="flex flex-row w-full items-center">
              <h3
                className="flex justify-start font-bold text-gray-450 text-md w-1/3"
                style={{ textAlign: "start" }}
              >
                {defaultTitle ?? detail}
              </h3>
              <Checkbox
                className="flex items-center justify-start w-3/4 ml-5"
                value={defaultValue ?? value}
                checked={value === "Yes"}
                size={5}
                onChange={(isChecked) =>
                  defaultOnChange
                    ? defaultOnChange(isChecked ? "Yes" : "No")
                    : onChange(id, isChecked ? "Yes" : "No")
                }
              />
            </div>
          );
          break;
        case "text-entry":
        default:
          returnValue = (
            <div key={id} className="flex flex-row w-full items-center">
              <h3
                className="flex justify-start font-bold text-gray-450 text-md w-1/2"
                style={{ textAlign: "start" }}
              >
                {defaultTitle ?? detail}
              </h3>
              <InlineInput
                background="transparent"
                toggleFocusRef={inputRef}
                inputStyle={{
                  height:
                    !determineTextFieldNeeded(defaultValue ?? value) && "38px",
                  fontSize: "15px",
                }}
                value={defaultValue ?? value}
                editing
                textarea={determineTextFieldNeeded(defaultValue ?? value)}
                width="w-full"
                size="xl"
                color="gray-450"
                rows={3}
                placeholder={detail}
                onConfirm={(val) => onChange("description", val)}
                onChangeCallback={(val) =>
                  defaultOnChange
                    ? defaultOnChange(val)
                    : onChange(id, val, unitId)
                }
                hidePencil
              />
              {!!unitOptions.length && (
                <Dropdown
                  noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                  className="pl-2 w-1/2"
                  label="Unit"
                  placeholder="Unit"
                  options={unitOptions}
                  onChange={({ value: changeValue }) =>
                    onChange(id, value, changeValue)
                  }
                  value={unitOptions?.find((opt) => opt.value === unitId)}
                  disableClear
                />
              )}
            </div>
          );
      }

      return (
        <div key={id} className="flex w-full text-center">
          <div className="flex flex-row w-full">{returnValue}</div>
        </div>
      );
    },
    [inputRef, resource?.additionalInfo, onChange]
  );

  const attributes = useMemo(() => {
    const combinedAttributes = [
      ...defaultAttributes,
      ...filteredItems?.measurementFields,
    ];
    const sortedAttributes = sortAndGroupByFieldType(combinedAttributes);

    return sortedAttributes
      ?.map(
        ({
          detail,
          fieldType,
          options,
          unit: units,
          value: defaultValue,
          onChange: defaultOnChange,
          title: defaultTitle,
          id,
        }) => {
          return {
            input: getMeasurementInput(
              detail,
              fieldType,
              options,
              units,
              defaultValue,
              defaultOnChange,
              defaultTitle,
              id
            ),
            display: defaultTitle
              ? { title: defaultTitle, value: defaultValue, field: fieldType }
              : filteredItems?.items?.find((item) => item.id === id),
            defaultAttribute: defaultTitle,
          };
        }
      )
      .filter((el) => !!el);
  }, [
    defaultAttributes,
    filteredItems?.items,
    filteredItems?.measurementFields,
    getMeasurementInput,
  ]);

  const AttributeValues = useMemo(() => {
    return attributes.map(({ input, display, defaultAttribute }, index) => {
      const id = display?.id;
      const iconWrapper = handleDeleteElement && editing ? "" : "invisible";
      const iconSpacing = "p-4 flex";

      return (
        <div key={display?.title} className="flex flex-row w-full">
          <EditItemAsset
            index={index}
            input={input}
            display={display}
            isEditing={editing}
            defaultAttribute={DEFAULT_ATTRIBUTES.includes(display?.title)}
          />
          {!defaultAttribute && editing && (
            <IconButton
              wrapperClassName={`${iconWrapper} ${iconSpacing}`}
              imgClassName="w-4 h-4"
              iconClassName="w-4 h-4"
              onClick={() => handleDeleteElement(id)}
              icon={trashDarkenedGreenIcon}
            />
          )}
        </div>
      );
    });
  }, [attributes, handleDeleteElement, editing]);
  return (
    <div className="">
      <Widget
        title="Attributes"
        draggable={false}
        isEditing={editing}
        disableEditing={disableEditing}
        loading={loading}
        overflow
      >
        <div className="h-full w-full" ref={wrapperRef}>
          {AttributeValues}
          {editing && (
            <div className="col-span-2 flex items-end h-full">
              <PlusCircleButton
                title="Add Attribute"
                onClick={handleAddClick}
                className="flex w-full items-center h-16"
                style={{ color: "#027D61", fontSize: 16 }}
                noHover
              />
            </div>
          )}
        </div>
      </Widget>
    </div>
  );
};

AttributeModalWidget.propTypes = {
  resource: PropTypes.shape({
    additionalInfo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  editing: PropTypes.bool,
  loading: PropTypes.bool,
  disableEditing: PropTypes.bool,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  setNewAttributes: PropTypes.func,
  dispatch: PropTypes.func,
  defaultAttributes: PropTypes.arrayOf(PropTypes.shape({})),
};

AttributeModalWidget.defaultProps = {
  dispatch: undefined,
  setNewAttributes: undefined,
  resource: undefined,
  editing: false,
  loading: false,
  disableEditing: false,
  newAttributes: undefined,
  defaultAttributes: [],
};

export default AttributeModalWidget;
