import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useURLQueryParameter from "./useURLQueryParameter";

/**
 * Hooks for managing tab navigation with URL query parameters
 * @param {Object} options - Configuration options.
 * @param {boolean} [options.useTabIndex] - indicates whether to use tab index in URL parameters. By default it will use the tab id
 * @param {Object[]} options.tabs - Array of widget tabs
 */
const useWidgetTabNavigation = ({ useTabIndex = false, tabs } = {}) => {
  const history = useHistory();
  const tabParam = useURLQueryParameter("tab");

  const tabsIds = tabs?.map((tab) => tab.id);

  const currentTabIndex =
    tabParam && tabsIds ? tabsIds?.findIndex((tab) => tab === tabParam) : 0;

  const [activeTabIndex, setActiveTabIndex] = useState(currentTabIndex);

  useEffect(() => {
    // Component wants to show the `id` in the url. Ex url?tab=assets
    if (!useTabIndex && tabParam) {
      const index = tabsIds?.findIndex((t) => t === tabParam);
      setActiveTabIndex(index);
    }

    // Component wants to show the `index` in the url. Ex url?tab=3
    else if (tabParam) {
      const tabIndex = parseInt(tabParam, 10);
      if (tabIndex >= 0) {
        setActiveTabIndex(tabIndex);
      }
    }
  }, [tabParam, useTabIndex, tabsIds]);

  const setTabIndex = useCallback(
    (index) => {
      if (useTabIndex) {
        setActiveTabIndex(index);
        // Push tab `index` in the url as query parameter
        history.push(`?tab=${index}`);
      } else {
        const tabTitle = tabsIds[index];
        setActiveTabIndex(index);
        // Push tab `id` in the url as query parameter
        history.push(`?tab=${tabTitle}`);
      }
    },
    [history, useTabIndex, tabsIds]
  );

  return {
    activeTabIndex,
    setTabIndex,
  };
};

export default useWidgetTabNavigation;
