import _ from "lodash";
import capitalizeFirstLetter from "./Utilities/capitalizeFirstLetter";
import capitalizeFNameFirstChar from "./Utilities/capitalizeFNameFirstChar";
import getEnvironment from "./Environment";
import { ENVIRONMENTS } from "../constants";

/**
 * Merge two objects
 * - if property is array concat
 * - otherwise replace left with right
 */
export const ObjectMerge = (obj, src) => {
  function customizer(left, right) {
    if (_.isArray(left)) {
      return left.concat(right);
    }
    if (_.isObject(left)) {
      return { ...left, ...right };
    }
    return right;
  }

  return _.mergeWith(obj, src, customizer);
};

/**
 * Flatten an array of arrays, inserting the given separator element between the
 * given arrays.
 */
export default function flattenWithSeparator(arrOfArr, separator) {
  let result = [];

  for (let idx = 0; idx < arrOfArr.length; idx += 1) {
    const arr = arrOfArr[idx];

    if (arr.length > 0) {
      result = result.concat(arr);

      if (idx < arrOfArr.length - 1) {
        result.push(separator);
      }
    }
  }

  return result;
}

const getResoureName = (resourceName) => `${resourceName?.split("Id")[0]}`;

const isNotFirstOrLastItem = (array, index) => {
  return array?.length - 1 !== index && array?.length !== 1 && index !== 0;
};
const isNotLastItem = (array, index) => {
  return array?.length - 1 !== index;
};

function Sort(alphabet) {
  return (a, b) => {
    const indexA = alphabet.indexOf(a[0]);
    const indexB = alphabet.indexOf(b[0]);

    if (indexA === indexB) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }
    return indexA - indexB;
  };
}

const sortWithSpecialCharacters = Sort("#*!@_.()^&%-=+");

const isNumeric = (str) => {
  if (typeof str !== "string") return false; // we only process strings!
  return (
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
/**
 *
 * @param {*} arr List of items
 * @param {*} key Object key to sort with if array is a list of objects
 * @returns sorted list
 * @see https://www.tutorialspoint.com/sorting-numbers-in-ascending-order-and-strings-in-alphabetical-order-in-an-array-in-javascript
 */
const sortLettersAndThenNumbers = (arr = [], key) => {
  const sorter = (a, b) => {
    const aValue = key ? a[key] : a;
    const bValue = key ? b[key] : b;

    if (!aValue.charAt(0).match(/[a-z]/i) && bValue.charAt(0).match(/[a-z]/i)) {
      return 1;
    }
    if (aValue.charAt(0).match(/[a-z]/i) && !bValue.charAt(0).match(/[a-z]/i)) {
      return -1;
    }
    if (
      !aValue.charAt(0).match(/[a-z]/i) &&
      !bValue.charAt(0).match(/[a-z]/i)
    ) {
      return parseInt(aValue.charAt(0), 10) - parseInt(bValue.charAt(0), 10);
    }
    return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
  };

  return arr.sort(sorter);
};

const sortNumbersBeforeLetters = (a, b) =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });

/**
 * @param {String} uuid
 * @returns - {Boolean} if string is valid UUID
 */
function isUUID(uuid) {
  let s = uuid;

  if (uuid) {
    s = s?.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    );
  }
  if (s === null) {
    return false;
  }
  return true;
}

/**
 * @param {Number} val number
 * @param {Boolean} strict boolean
 * @returns {Boolean} val > 0, or val >= 0
 */
const isPositiveNumber = (val, strict = true) => {
  const number = parseFloat(val);
  return Number.isFinite(number) && (strict ? number > 0 : number >= 0);
};

const getWorkflowNameFromReference = (reference, workflows = []) => {
  // take a tasks ref and parse Workflows/steps[]/tasks[]
  // return workflow name if found task ref
  /* ** could be a candidate for a BE query? ** */
  let workflowName = "";
  try {
    if (workflows.length === 0) return workflowName;
    workflows?.every((workflow) => {
      const request = workflow?.requestWorkflow[0];
      const steps = request.steps || [];
      if (steps.length > 0) {
        // flaten all tasks arrays in a single array
        const allTasks = steps?.reduce((acc, step) => {
          return [...acc, ...step?.tasks];
        }, []);
        const found = allTasks?.includes(reference);
        if (found) {
          // set Workflow name and exit
          workflowName = workflow?.customName;
          return false;
        }
        return true;
      }
      return true;
    });
    return workflowName;
  } catch (error) {
    console.warn("error", error);
    return workflowName;
  }
};

const getAssociationNameFromReference = (
  reference,
  propertiesDict,
  projectDict,
  assetsDict,
  sopsDict,
  eventsDict,
  submittalDict,
  expensesDict,
  workflowsDict,
  ticketsDict,
  reportsDict
) => {
  if (reference) {
    const resource = reference?.split("/")[0];
    switch (resource) {
      case "Property": {
        const name = propertiesDict?.[reference]?.title;
        return name;
      }
      case "Project": {
        const name = projectDict?.[reference]?.name;
        return name;
      }
      case "Asset": {
        const name = assetsDict?.[reference]?.name;
        return name;
      }
      case "Sop": {
        const name = sopsDict?.[reference]?.name;
        return name;
      }
      case "Event": {
        const name = eventsDict?.[reference]?.name;
        return name;
      }
      case "Task": {
        const name = eventsDict?.[reference]?.name;
        return name;
      }
      case "Submittal": {
        const name = submittalDict?.[reference]?.name;
        return name;
      }
      case "Workflow": {
        const name = workflowsDict?.[reference]?.name;
        return name;
      }
      case "Report": {
        const name = reportsDict?.[reference]?.title;
        return name;
      }
      case "Ticket": {
        const association = ticketsDict?.[reference]?.association;
        const name = getAssociationNameFromReference(
          association,
          propertiesDict,
          projectDict,
          assetsDict
        );
        return name;
      }
      case "Expense": {
        const association = expensesDict?.[reference]?.association;
        const name = getAssociationNameFromReference(
          association,
          propertiesDict,
          projectDict,
          assetsDict,
          sopsDict,
          eventsDict,
          submittalDict,
          expensesDict
        );
        return name;
      }
      default:
        return reference;
    }
  }
  return undefined;
};

const argsThroughPromise = (promise, promiseParams, ...args) => {
  return new Promise((resolve, reject) => {
    promise(...promiseParams)
      .then((res) => resolve({ response: res, passed: [...args] }))
      .catch((err) => reject(err));
  });
};

const getUserFirstAndInitial = (user) =>
  user?.kind === "company"
    ? `${capitalizeFirstLetter(user?.company?.value || "")}`
    : `${capitalizeFirstLetter(user?.name?.firstName || "")} ${
        user?.name?.lastName?.[0]
          ? // eslint-disable-next-line prettier/prettier, prefer-template
            (user?.name?.lastName?.[0]).toUpperCase() + "."
          : ""
      }`.trim();

const getUserFirstAndLast = (user) =>
  // (Parker) -> noticed some companies did not have .companyName, but company.value is set by backend
  user?.kind === "company"
    ? `${capitalizeFNameFirstChar(user?.company?.value || "")}`
    : `${capitalizeFNameFirstChar(user?.name?.firstName || "")} ${
        user?.name?.lastName ? user?.name?.lastName : ""
      }`.trim();

const getFirstAndLastFromFullName = (fullName) => {
  const [first, second] = fullName.split(" ");
  let firstName = "";
  let lastName = "";
  if (first) {
    firstName = first.trim();
  }

  if (second) {
    lastName = second.trim();
  }

  return { firstName, lastName };
};

const handleLinkClick = (link) => {
  let url = typeof link === "string" ? link : link?.url;
  if (!url.match(/^https?:\/\//i)) {
    url = `http://${url}`;
  }
  return window.open(url, "_blank");
};

const getTitle = () => {
  const envName = getEnvironment();
  switch (envName) {
    case ENVIRONMENTS.production:
      return "ESTATESPACE";
    case ENVIRONMENTS.feature:
      return "ESTATESPACE (Feature)";
    case ENVIRONMENTS.staging:
      return "ESTATESPACE (Staging)";
    case ENVIRONMENTS.localhost:
      return "ESTATESPACE (localhost)";
    default:
      return "ESTATESPACE";
  }
};

/**
 * convert an array to an object that has key the array element and value the same array element
 * @param {arr} Array of items [a,b, c, ...]
 * @returns Object {a: a, b: b, c: c, ...}
 */
const convertArrayToObjectSameKeyValue = (arr) => {
  if (!Array.isArray(arr)) {
    return {};
  }

  const valueLabels = arr.reduce((obj, item) => {
    return { ...obj, [item]: item };
  }, {});

  return valueLabels;
};

export {
  isNotLastItem,
  isNotFirstOrLastItem,
  flattenWithSeparator,
  sortWithSpecialCharacters,
  isNumeric,
  isPositiveNumber,
  sortLettersAndThenNumbers,
  getResoureName,
  isUUID,
  getAssociationNameFromReference,
  getWorkflowNameFromReference,
  sortNumbersBeforeLetters,
  argsThroughPromise,
  getUserFirstAndInitial,
  getUserFirstAndLast,
  getFirstAndLastFromFullName,
  handleLinkClick,
  getTitle,
  convertArrayToObjectSameKeyValue,
};
