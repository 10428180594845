/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../Table/Table";
import FileButtons from "../FileButtons/FileButtons";
import FavoriteButton from "../Buttons/FavoriteButton";

// Removed for 3.0
// import IconButton from "../Buttons/IconButton";
// import warningIcon from "../../assets/images/warning.svg";
import {
  fileButtonPositionsMap,
  FILTER_TYPES,
  TABLE_COLUMN_WIDTH,
} from "../../../constants";
import RowDeleteButton from "../FileButtons/RowDeleteButton";

const PureDocumentTable = ({
  resourceName,
  className,
  data,
  favorites,
  selected,
  onFileSelect,
  onRowClick,
  onFileEdit,
  onShowHistory,
  onSendToWorkflow,
  onUploadVersion,
  onFileDelete,
  onFavoriteClick,
  hideSiteHeader,
  hideChangeView,
  hideSaveButton,
  disableHover,
  enableDeleting,
  noDeleteConfirm,
  showEditColumns,
  showSearch,
  showFooter,
  allowSelection,
  extraColumns,
  hideButtons,
  buttonActions,
  bannerComponent,
  showDeleteButton,
  docTypeOptionsMap,
  handleRowSelect,
}) => {
  const rowBodyStyles = { minHeight: hideButtons ? "" : "270px" };

  const tableColumns = React.useMemo(() => {
    let columns = [
      {
        id: "favorited",
        Header: "",
        editColumnModalHeader: "Favorite",
        isPrimary: true,
        accessor: "isFavorited",
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
        width: 20,
        minWidth: 90,
        maxWidth: TABLE_COLUMN_WIDTH,
        Cell: ({ row }) => {
          return (
            <FavoriteButton
              isSelected={favorites.some((fav) => {
                return fav.item.reference.includes(
                  row.original.reference?.split("/")[1]
                );
              })}
              onClick={() =>
                onFavoriteClick(row.original.reference?.split("/")[1])
              }
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "resource",
        isPrimary: true,
        Cell: ({ row }) => {
          const docType =
            docTypeOptionsMap[row.original.docType]?.label ??
            row.original.docType;
          return (
            <button
              className="truncate capitalize"
              type="button"
              onClick={onFileSelect ? () => {} : () => onRowClick(row.original)}
            >
              {row.original.customName || row.original.resource}
              {docType === "Vendor Invoice" && ` - ${row.original?.vendorName}`}
            </button>
          );
        },
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "docType",
        Cell: ({ row }) => {
          return (
            <button
              className="truncate"
              type="button"
              onClick={onFileSelect ? () => {} : () => onRowClick(row.original)}
            >
              {docTypeOptionsMap[row.original.docType]?.label ??
                row.original.docType}
            </button>
          );
        },
      },
      {
        Header: "Created By",
        accessor: (row) => {
          const { firstName = "", lastName = "" } = row?.creator?.name ?? {};
          return (
            <button
              className="truncate"
              type="button"
              onClick={onFileSelect ? () => {} : () => onRowClick(row)}
            >
              {`${firstName} ${lastName}`}
            </button>
          );
        },
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: (row) => {
          return (
            <button
              className="truncate"
              type="button"
              onClick={onFileSelect ? () => {} : () => onRowClick(row)}
            >
              {moment(row.metadata?.lastUpdated).format("MMM DD, YYYY, h:mm A")}
            </button>
          );
        },
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
        minWidth: 170,
      },
    ];

    if (extraColumns?.length) {
      columns = [...columns, ...extraColumns];
    }

    columns.push({
      Header: "",
      accessor: "actions",
      width: TABLE_COLUMN_WIDTH,
      minWidth: 90,
      maxWidth: TABLE_COLUMN_WIDTH,
      Cell: ({ data: rows, row: { original } }) => {
        let openAbove = false;
        const filterThreshold = 3;
        const dataLength = rows.length;
        if (dataLength > 5) {
          const tempData = rows.filter((item, idx) => {
            if (idx > dataLength - filterThreshold - 1) {
              return !!item;
            }
            return false;
          });
          openAbove = tempData.some((item) => item.id === original.id);
        }

        if (onRowClick && !hideButtons) {
          return (
            <FileButtons
              {...original}
              className="absolute -mt-1"
              onFileEdit={() => onFileEdit(original)}
              onShowHistory={onShowHistory}
              onSendToWorkflow={() => onSendToWorkflow(original)}
              onUploadVersion={onUploadVersion}
              onFileDelete={onFileDelete}
              fileButtonPosition={
                openAbove
                  ? fileButtonPositionsMap.below
                  : fileButtonPositionsMap.above
              }
            />
          );
        }

        if (showDeleteButton) {
          return <RowDeleteButton onClick={() => onFileDelete(original)} />;
        }

        return <></>;
      },
    });

    return columns;
  }, [
    docTypeOptionsMap,
    extraColumns,
    favorites,
    hideButtons,
    onFavoriteClick,
    onFileDelete,
    onFileEdit,
    onFileSelect,
    onRowClick,
    onSendToWorkflow,
    onShowHistory,
    onUploadVersion,
    showDeleteButton,
  ]);

  return (
    <Table
      className={className}
      rowBodyStyles={rowBodyStyles}
      resourceName={resourceName || "document-table"}
      data={data}
      showHeader={false}
      onRowClick={() => {}}
      columns={tableColumns}
      initialSelectedRows={selected}
      buttonActions={buttonActions}
      onSelectedRowChange={handleRowSelect}
      hideSiteHeader={hideSiteHeader}
      bannerComponent={bannerComponent}
      hideCreateNewButton
      //
      disableHover={disableHover}
      enableDeleting={enableDeleting}
      noDeleteConfirm={noDeleteConfirm}
      hideChangeView={hideChangeView}
      hideSaveButton={hideSaveButton}
      showEditColumns={showEditColumns}
      showSearch={showSearch}
      showFooter={showFooter}
      //
      allowSelection={allowSelection}
      onDeleteRowClick={onFileDelete}
    />
  );
};

PureDocumentTable.propTypes = {
  /**
   * className to pass to the table
   */
  className: PropTypes.string,
  /**
   * originalDocuments to display on the table, as they come from the server
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of documents that have been favorited
   */
  // eslint-disable-next-line react/forbid-prop-types
  favorites: PropTypes.arrayOf(PropTypes.object),
  /**
   * array of selected row ids
   */
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.arrayOf(PropTypes.object),
  /**
   * function for handling selection of a document
   */
  onFileSelect: PropTypes.func,
  /**
   * run on table row click
   */
  onRowClick: PropTypes.func,
  /**
   * function for handling file edit
   */
  onFileEdit: PropTypes.func,
  /**
   * function for handling file show history
   */
  onShowHistory: PropTypes.func,
  /**
   * function for handling file send to workflow
   */
  onSendToWorkflow: PropTypes.func,
  /**
   * function for handling file send upload version
   */
  onUploadVersion: PropTypes.func,
  /**
   * run on table row delete
   */
  onFileDelete: PropTypes.func,
  /**
   * function that handles the favorite action for a document
   */
  onFavoriteClick: PropTypes.func,
  hideSiteHeader: PropTypes.bool,
  hideChangeView: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  hideButtons: PropTypes.bool,
  showEditColumns: PropTypes.bool,
  showSearch: PropTypes.bool,
  showFooter: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  allowSelection: PropTypes.bool,
  disableHover: PropTypes.bool,
  enableDeleting: PropTypes.bool,
  noDeleteConfirm: PropTypes.bool,
  /**
   * array of SiteHeader button actions
   */
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  resourceName: PropTypes.string,
};

PureDocumentTable.defaultProps = {
  className: undefined,
  data: [],
  favorites: [],
  selected: [],
  onFileSelect: undefined,
  onRowClick: undefined,
  onFileEdit: undefined,
  onShowHistory: undefined,
  onSendToWorkflow: undefined,
  onUploadVersion: undefined,
  onFileDelete: undefined,
  onFavoriteClick: undefined,
  hideSiteHeader: false,
  hideChangeView: false,
  hideSaveButton: false,
  hideButtons: false,
  showEditColumns: false,
  showSearch: false,
  showFooter: false,
  showDeleteButton: false,
  allowSelection: true,
  disableHover: false,
  enableDeleting: false,
  noDeleteConfirm: true,
  buttonActions: undefined,
  resourceName: undefined,
};

export default PureDocumentTable;
