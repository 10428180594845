import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { PropTypes } from "prop-types";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  SET_SOP_MODAL_STATUS,
  PAGES,
  SOP,
  SOP_TABS,
} from "../../../constants";
import { onUpdateFile } from "../../../helpers/File";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import { usePatchSop } from "../../../hooks/useSopById";
import useSopFormReducer from "../../../hooks/useSopFormReducer";
import { useAppState } from "../../../state/appState";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import BackButton from "../../../stories/Components/WidgetEditorView/Header/BackButton";
import SopDeleteModal from "./SopDeleteModal";
import SopDetailsView from "./SopDetailsView";
import SopHistory from "./History";
import TableActionsIconsGroup from "../../../stories/Components/Table/TableActionsIconsGroup";
import SopActivity from "./Activity";
import useSopDetailsData from "./Details/useSopDetailsData";
import { useModalState } from "../../../state/modalState";
import { hasWritePermission } from "../../../helpers/Permissions";
import useEditingResourceState from "../../../hooks/useEditingResourceState";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const SopDetails = ({ currentUser, reloadEvents }) => {
  const {
    loading,
    sopId,
    sop,
    version,
    showActivity,
    disableEditing,
    disableUploadMedia,
    handleNavigationBack,
    setShowActivity,
    handleCloneSop,
  } = useSopDetailsData(currentUser);

  const [, modalDispatch] = useModalState();
  const [sopState, sopDispatch] = useSopFormReducer();
  const { setCurrentResourceScreen } = useAppPersistence();
  const location = useLocation();

  const [{ systemConfiguration, sopModalStatus }, dispatch] = useAppState();

  const params = useMemo(() => ({ association: `Sop/${sopId}` }), [sopId]);

  const { mutate: patchSop } = usePatchSop();

  const {
    associatedFiles,
    addFiles,
    cloneFile,
    removeFilesAndUpdateApi,
    patchFile,
  } = useAssociatedFiles(params);

  const [catOptions, setCatOptions] = useState([]);

  const [editing, setEditing] = useState(false);
  const [addNewStep, setAddNewStep] = useState(false);
  const [canDeleteSop, setCanDeleteSop] = useState(false);
  const [someSopInUse] = useState(false);

  // handle editing state of resource being edited
  useEditingResourceState({
    editing,
    resource: "Sop",
  });
  // focus state on last new step added
  const [stepFocus, setStepFocus] = useState(false);

  const resetSopState = useCallback(() => {
    sopDispatch({
      type: "reset",
      sop,
    });
  }, [sop, sopDispatch]);

  const reloadSop = useCallback(() => {
    sopDispatch({
      type: "initialize",
      payload: sop,
    });
  }, [sop, sopDispatch]);

  // This effect runs whenever the location (route) changes
  useEffect(() => {
    // Extracting the main segment of the current path, e.g., if the path is "/sops/123", currentPage will be "sops"
    const currentPage = location.pathname.split("/")[1];

    if (currentPage === PAGES.SOPS) {
      setCurrentResourceScreen(PAGES.SOPS, window.location.href);
    }
  }, [location.pathname, setCurrentResourceScreen]);

  useEffect(() => {
    reloadSop();
  }, [reloadSop]);

  useEffect(() => {
    const categories = systemConfiguration?.system?.sop?.category;
    setCatOptions(() => {
      const catList = categories?.map((cat) => {
        return { label: cat.display, value: cat.id };
      });
      return catList;
    });
  }, [sop?.category, systemConfiguration?.system?.sop?.category]);

  const onEditSubmit = useCallback(() => {
    patchSop({
      id: sopId,
      prevSop: sop,
      updatedSop: {
        ...sopState,
        tags: sopState?.currentTags?.map((tag) => tag?.value) || [],
        steps: sopState?.steps?.filter((step) => step?.description !== ""),
      },
      onAdditionalSuccessCallback: () => {
        // Use setTimeout to simulate a delayed action
        setTimeout(() => {
          // reload events/tasks after 10sec
          reloadEvents();
        }, 10000);
      },
    });
  }, [patchSop, reloadEvents, sop, sopId, sopState]);

  const onFinishEditing = useCallback(async () => {
    if (!isEqual(sop, sopState)) {
      // open warning modal
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: `edit-sop-${sop.id}` },
        modalData: {
          item: {
            prompt:
              "Are you sure you want to update this SOP, by updating this you will be updating all scheduled tasks where this SOP is being used? This action can not be undone",
            onConfirm: () => onEditSubmit(),
            confirm: "Yes, update",
            cancel: "Cancel",
            title: "Update SOP",
            modalWidth: "780px",
          },
        },
        modalType: CONFIRM_MODAL,
      });
    }
  }, [modalDispatch, onEditSubmit, sop, sopState]);

  const buttonActions = useMemo(() => {
    const options = [];

    if (
      /*
       * remove sopIsActive when SOP isActive prop is enabled in 3.6
       */
      // sopIsActive &&
      // !currentSop?.isActive &&
      currentUser.hasPermission("sop", "can_delete")
    ) {
      options.push({
        onClick: () => setCanDeleteSop(true),
        title: "Delete Sop",
      });
    }
    if (currentUser.hasPermission("sop", "can_write")) {
      options.push({
        onClick: () => handleCloneSop(sopId),
        title: "Clone Sop",
        disabled: !sop?.category,
      });
    }

    return options;
  }, [currentUser, setCanDeleteSop, handleCloneSop, sopId, sop?.category]);

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...sop.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        sop.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedSop = {
        ...sop,
        files: updatedFiles,
        primaryImage: primary,
      };
      patchSop({
        id: sopId,
        prevSop: sop,
        updatedSop,
        onAdditionalSuccessCallback: () => {
          // update associated files state
          addFiles(imageResources);
        },
      });
    },
    [addFiles, patchSop, sop, sopId]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedSop = {
        ...sop,
        primaryImage: imageRef,
      };
      // patch resource
      patchSop({
        id: sopId,
        prevSop: sop,
        updatedSop,
      });
    },
    [patchSop, sop, sopId]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      const updatedFiles = sop.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(sop?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : sop.primaryImage;
      const updatedSop = {
        ...sop,
        files: updatedFiles,
        primaryImage: primary,
      };

      patchSop({
        id: sopId,
        prevSop: sop,
        updatedSop,
        onAdditionalSuccessCallback: () => {
          // update associated files state
          removeFilesAndUpdateApi(imageRefs);
        },
      });
    },
    [patchSop, removeFilesAndUpdateApi, sop, sopId]
  );

  const updateSopFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = sop.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(sop?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : sop.primaryImage;
      const updatedSop = {
        ...sop,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      patchSop({
        id: sopId,
        prevSop: sop,
        updatedSop,
      });
    },
    [patchSop, sop, sopId]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      const updatedFiles = [
        ...sop.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedSop = {
        ...sop,
        files: updatedFiles,
        primaryImage:
          sop?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };

      patchSop({
        id: sopId,
        prevSop: sop,
        updatedSop,
        onAdditionalSuccessCallback: () => {
          // update associated files state
          addFiles(filesUploaded);
        },
      });
    },
    [sop, patchSop, sopId, addFiles]
  );

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId).then((clonedFile) => {
        const updatedSop = {
          ...sop,
          files: [
            ...(sop?.files || []),
            { ref: clonedFile.reference, category: clonedFile.category },
          ],
        };

        patchSop({
          id: sopId,
          prevSop: sop,
          updatedSop,
          onAdditionalErrorCallback: () => {
            // remove created files if PATCH fails
            removeFilesAndUpdateApi([`File/${fileId}`]);
          },
        });
      });
    },
    [cloneFile, patchSop, removeFilesAndUpdateApi, sop, sopId]
  );

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  const tabs = useMemo(
    () =>
      [
        {
          id: SOP_TABS.DETAILS_ID,
          title: SOP_TABS.DETAILS_TITLE,
          content: (
            <SopDetailsView
              loading={loading}
              sop={sopState}
              sopDispatch={sopDispatch}
              isEditing={editing}
              stepData={sopState?.steps}
              categoryOptionsList={catOptions}
              addNewStep={addNewStep}
              setAddNewStep={setAddNewStep}
              stepFocus={stepFocus}
              setStepFocus={setStepFocus}
            />
          ),
        },
        {
          id: SOP_TABS.MEDIA_ID,
          title: SOP_TABS.MEDIA_TITLE,
          content: (
            <ImagesAndVideosWidget
              border={false}
              resource={sop}
              disableEditing={disableUploadMedia}
              hasWritePermission={hasWritePermission(SOP, currentUser)}
              hasDeletePermission={hasWritePermission(SOP, currentUser)}
              handleAddMedia={handleAddMedia}
              handleSetPrimaryMedia={handleSetPrimaryMedia}
              handleRemoveMedia={handleRemoveMedia}
            />
          ),
        },
        {
          id: SOP_TABS.FILES_ID,
          title: SOP_TABS.FILES_TITLE,
          content: (
            <FilesTable
              onAddFilesCallback={onAddFilesCallback}
              removeFilesAndUpdateApi={removeFilesAndUpdateApi}
              onRemoveFilesCallback={updateSopFiles}
              files={associatedFiles}
              resourceName="Sop"
              association={`Sop/${sopId}`}
              hasDeletePermission={
                !version && currentUser?.hasPermission?.("sop", "can_delete")
              }
              hasWritePermission={
                !version && currentUser?.hasPermission?.("sop", "can_write")
              }
              handleFileClone={handleFileClone}
              hasEditPermission
              handleUpdateFile={handleUpdateFile}
            />
          ),
        },
        !version && {
          id: SOP_TABS.HISTORY_ID,
          title: SOP_TABS.HISTORY_TITLE,
          content: <SopHistory sop={sop} />,
        },
      ].filter(Boolean),
    [
      loading,
      sopState,
      sopDispatch,
      editing,
      catOptions,
      addNewStep,
      sop,
      currentUser,
      handleAddMedia,
      handleSetPrimaryMedia,
      handleRemoveMedia,
      onAddFilesCallback,
      removeFilesAndUpdateApi,
      updateSopFiles,
      associatedFiles,
      sopId,
      version,
      disableUploadMedia,
      handleFileClone,
      handleUpdateFile,
      stepFocus,
    ]
  );

  const { activeTab, setActiveTab } = useWidgetTabNavigation({
    page: PAGES.SOPS,
    resourceId: sopId,
  });

  const showEditIcon = activeTab === SOP_TABS.DETAILS_ID;

  // TODO: This should be replaced with query parameters when using `useWidgetTab`
  useEffect(() => {
    if (version) {
      setActiveTab(SOP_TABS.DETAILS_ID);
    }
  }, [version, setActiveTab]);

  useEffect(() => {
    if (sopModalStatus.activeTabIndex === SOP_TABS.DETAILS_ID) {
      setActiveTab(SOP_TABS.DETAILS_ID);
      dispatch({
        type: SET_SOP_MODAL_STATUS,
        activeTabIndex: undefined,
      });
    }
  }, [dispatch, sopModalStatus, setActiveTab]);

  const currentlyOnEditableTab = activeTab === SOP_TABS.DETAILS_ID;

  const handleEditClick = useCallback(() => {
    setStepFocus(false);

    if (!editing && currentlyOnEditableTab) {
      setEditing(true);
      return;
    }
    setEditing(false);
  }, [editing, currentlyOnEditableTab]);

  const handleChangeName = useCallback(
    (name) => {
      sopDispatch({
        type: "editName",
        payload: name,
      });
    },
    [sopDispatch]
  );

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={sop?.name}
              editing={editing}
              loading={!sop?.name}
              disabled={!sop?.name}
              fontWeight="bold"
              color="gray-650"
              onConfirm={handleChangeName}
              onChangeCallback={handleChangeName}
              hidePencil
              isHeaderTitle
            />
          </div>
        }
        buttons={
          !version &&
          !!buttonActions?.length && (
            <PrimaryButton
              title="Actions"
              dropdownItems={buttonActions}
              large
            />
          )
        }
        hideDropdownContainer
      />

      <BackButton onBackPressed={handleNavigationBack} />

      {showActivity ? (
        <SopActivity sopId={sopId} />
      ) : (
        <WidgetContainer
          className="p-4 border-gray-200 shadow-lg border rounded-md"
          style={{ minWidth: "903px" }}
          isEditing={editing}
          handleEditClick={handleEditClick}
          onFinishEditing={onFinishEditing}
          tabs={tabs}
          loading={loading}
          disableEditing={disableEditing}
          activeTab={activeTab}
          onTabClick={(t) => setActiveTab(t)}
          resetResourceState={resetSopState}
          actionsButtons={
            <TableActionsIconsGroup
              style={{ height: "100%" }}
              canEdit={!disableEditing && showEditIcon}
              showActivityIcon={!version}
              handleEdit={handleEditClick}
              onActivityClicked={() => setShowActivity(true)}
            />
          }
        />
      )}

      <SopDeleteModal
        someSopInUse={someSopInUse}
        sopArray={[sopState]}
        canDeleteSop={canDeleteSop}
        setCanDeleteSop={setCanDeleteSop}
      />
    </>
  );
};

SopDetails.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.bool,
    reference: PropTypes.string,
  }),
  reloadEvents: PropTypes.func,
};

SopDetails.defaultProps = {
  currentUser: {},
  reloadEvents: () => {},
};

export default SopDetails;
