import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import ManagementSvg from "../../assets/images/management.svg";
import DocumentSvg from "../../assets/images/document.svg";
import FinancialsSvg from "../../assets/images/financials.svg";
import WorkflowsSvg from "../../assets/images/workflows.svg";
import SystemSvg from "../../assets/images/purpleSystem.svg";
import LilySvg from "../../assets/images/lilyIcon.svg";

const ManagementIcon = (
  // images are given a fixed width and height to maintain consistency
  // with the design on figma.
  <img
    style={{
      width: "19.85px",
      height: "17px",
    }}
    src={ManagementSvg}
    alt="Management icon"
  />
);
const DocumentIcon = (
  <img
    style={{
      width: "16px",
      height: "20px",
    }}
    src={DocumentSvg}
    alt="Document icon"
  />
);
const FinancialsIcon = (
  <img
    style={{
      width: "9.09px",
      height: "18px",
    }}
    src={FinancialsSvg}
    alt="Financials icon"
  />
);
const WorkflowsIcon = (
  <img
    style={{
      width: "22px",
      height: "20px",
    }}
    src={WorkflowsSvg}
    alt="Workflow icon"
  />
);
const SystemIcon = (
  <img
    style={{
      width: "16px",
      height: "16px",
    }}
    src={SystemSvg}
    alt="Management icon"
  />
);

const LilyIcon = (
  <img
    style={{
      width: "48px",
      minWidth: "48px",
      height: "48px",
      minHeight: "48px",
      margin: "-16px",
    }}
    src={LilySvg}
    alt="Lily icon"
  />
);

const typeCN = (type) => cntl`
${type === "System" || type === "Lily" ? "text-es-lily" : ""}
${type === "Management" ? "text-es-orange" : ""}
${type === "Workflows" ? "text-es-deep-pink" : ""}
${type === "Documents" ? "text-es-blue" : ""}
${type === "Financial" ? "text-es-green" : ""}
`;

const SettingsCard = ({ className, item, onClick }) => {
  const { id, title, type, description } = item;

  const icon = () => {
    switch (type) {
      case "Management":
        return ManagementIcon;
      case "System":
        return SystemIcon;
      case "Documents":
        return DocumentIcon;
      case "Workflows":
        return WorkflowsIcon;
      case "Financial":
        return FinancialsIcon;
      case "Lily":
        return LilyIcon;
      default:
        return null;
    }
  };

  return (
    <div
      id={id}
      className={`border-gray-200 border resourceCN rounded-lg cursor-pointer ${className}`}
      style={{
        width: "calc(25% - 1.25rem)",
      }}
    >
      <div
        className="flex flex-col py-7 px-7"
        onClick={() => onClick?.(item)}
        role="presentation"
      >
        <div className="flex flex-row">
          {icon() && <div className="pr-2 self-center">{icon()}</div>}
          <div className={typeCN(item.type)}>
            <p className="text-es-sm tracking-es-normal font-es-semibold">
              {item.type}
            </p>
          </div>
        </div>
        <div className="w-full pt-3 pb-5">
          <hr className="w-full" />
        </div>
        <div className="pb-2">
          <p className="text-es-black text-es-normal tracking-es-wide font-es-semibold">
            {title}
          </p>
        </div>
        <div>
          <p
            className="text-es-black text-es-sm tracking-es-normal font-es-normal"
            style={{
              lineHeight: "16px",
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

SettingsCard.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

SettingsCard.defaultProps = {
  id: undefined,
  className: undefined,
  item: undefined,
  onClick: undefined,
};

export default SettingsCard;
