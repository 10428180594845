import { useCallback } from "react";
import { useEditSettings, useSettings } from "../api/setting";
import returnPageSettings from "../../helpers/persistence/returnPageSettings";
import modifyPageSettings from "../../helpers/persistence/modifyPageSettings";
import modifyTabSettings from "../../helpers/persistence/modifyTabSettings";
import modifyTabSearchText from "../../helpers/persistence/modifyTabSearchText";
import setCurrentPage from "../../helpers/persistence/setCurrentPage";

const useAppPersistence = () => {
  const { userSettings, settingsLoading, settingsError } = useSettings();
  const { editSettings } = useEditSettings();

  /**
   * This Promise will take a page as an argument and return the data for that page
   *
   * @param {string} page
   * @returns {Object} An object containing data for the page
   */
  const getPageSettings = useCallback(
    (page) => returnPageSettings({ page, userSettings }),
    [userSettings]
  );

  /**
   * This Promise will edit the page settings
   *
   * @param {string} page
   * @param {data} data an object with all properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editPageSettings = useCallback(
    async (page, data) => {
      return modifyPageSettings({
        page,
        data,
        editSettings,
        userSettings,
      });
    },
    [userSettings, editSettings]
  );

  /**
   * This Promise will edit the page tab settings
   * this Promise will also keep track of the active tab you are on within a page
   *
   * @param {string} page
   * @param {string} tab
   * @param {data} data an object with all page properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editTabSettings = useCallback(
    (page, tab, data) => {
      return modifyTabSettings({ page, tab, data, editSettings, userSettings });
    },
    [editSettings, userSettings]
  );

  /**
   * This Promise will edit the page tabs search settings
   *
   * @param {string} page
   * @param {string} tab
   * @param {data} data an object with all page properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editTabSearchText = useCallback(
    (page, tab, text) => {
      return modifyTabSearchText({
        page,
        text,
        editSettings,
        userSettings,
        tab,
      });
    },
    [editSettings, userSettings]
  );

  /**
   * This Promise sets the current page you are on
   *
   * @param {string} page
   * @returns {void}
   */

  const setActivePage = useCallback(
    (page) => {
      return setCurrentPage({ page, editSettings, userSettings });
    },
    [editSettings, userSettings]
  );

  return {
    userSettings,
    getPageSettings,
    editPageSettings,
    editTabSettings,
    editTabSearchText,
    setActivePage,
    settingsLoading,
    activePage: userSettings?.activePage || "",
    settingsError,
  };
};

export default useAppPersistence;
