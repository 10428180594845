import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { MEMBERS_INVITE_PATH } from "../../../constants";

export default function useAccountAndAdminViewData({ currentIndex }) {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isEditing, setIsEditing] = useState();
  const [activeIndex, setActiveIndex] = useState(currentIndex || 0);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const [isExportingMemberList, setIsExportingMemberList] = useState(false);
  const [isInvitingMember, setIsInvitingMember] = useState(false);

  const onSave = useCallback(() => {
    setIsSaving(true);
  }, [setIsSaving]);

  const onCancel = () => {
    setIsCancel(true);
  };

  const dropDownItems = useCallback(
    (index) => {
      switch (index) {
        case 0:
          return [];

        case 1:
          return [
            {
              title: "Invite Member",
              onClick: () => {
                history.push(MEMBERS_INVITE_PATH);
              },
            },
            {
              title: "Export Member List",
              onClick: () => {
                setIsExportingMemberList(true);
              },
            },
          ];
        case 2:
          return [];
        case 3: {
          return [];
        }
        default:
          return [];
      }
    },
    [history]
  );

  return {
    isSaving,
    setIsSaving,
    isEditing,
    setIsEditing,
    isCancel,
    setIsCancel,
    activeIndex,
    setActiveIndex,
    onSave,
    onCancel,
    dropDownItems,
    isCancellingSubscription,
    setIsCancellingSubscription,
    isExportingMemberList,
    setIsExportingMemberList,
    isInvitingMember,
    setIsInvitingMember,
  };
}
