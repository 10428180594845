/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import addPhotoIcon from "../../assets/images/magnoliaEditIcon.svg";
import resourceDefault from "../../assets/images/diamond.svg";
import userDefault from "../../assets/images/userIcon.svg";
import companyDefault from "../../assets/images/companyWhiteIcon.svg";
import projectDefault from "../../assets/images/projectIcon.svg";
import propertyDefault from "../../assets/images/propertyIcon.svg";

import "./Avatar.css";
import { uploadFileWithData } from "../../../helpers/File";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../assets/images/whiteExclamationIcon.svg";
import { toastError } from "../../../helpers/Toast";
import { useGetFile } from "../../../hooks/useGetFile";
import { filePaginatedKeys } from "../../../config/reactQuery/queryKeyFactory";
import { ACCEPTED_PRIMARY_IMAGE_TYPES } from "../../../constants";

const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

// TODO (Remove this todo in ES-6815) This is a dummy component
/**
 * @param {image} image could be a s3 url (deprecated) or file reference
 */
const FormAvatar = ({
  isEditing,
  onChange,
  image,
  loading,
  resourceName,
  user = false,
  company = false,
  width = "85px",
  disabled,
  setLoading,
  onBoarding,
  borderWidth = "0.5",
}) => {
  const { data } = useGetFile(image);
  const avatarInput = useRef();
  const queryClient = useQueryClient();

  const [state, setState] = useState({
    image: undefined,
    loading,
    set: (key, value) => setState((prev) => ({ ...prev, [key]: value })),
  });
  const id = uuidv4();

  const placeholder = useMemo(() => {
    if (user) {
      return userDefault;
    }
    if (company) {
      return companyDefault;
    }
    if (resourceName === "Property") {
      return propertyDefault;
    }
    if (resourceName === "Project") {
      return projectDefault;
    }
    return resourceDefault;
  }, [company, user, resourceName]);

  useEffect(() => {
    // image?.startsWith caused errors in onboarding flow - tested and wortks without
    if (!loading && !onBoarding) {
      setState((prev) => ({
        ...prev,
        // TODO (Josymar) this is temporal in order to keep supporting components using s3 urls
        image: image?.startsWith("File/") ? undefined : image,
        loading,
      }));
    }
    return () => {};
  }, [image, loading, onBoarding]);

  const addPhoto = useCallback(() => {
    if (!loading) avatarInput.current.click();
  }, [loading]);

  const handleChange = useCallback(
    async (e) => {
      state.set("loading", true);
      if (setLoading) setLoading(true);
      const reader = new FileReader();
      const primary = e.target.files[0];
      reader.readAsDataURL(primary);
      const tempImage = URL.createObjectURL(primary);
      state.set("image", tempImage);
      uploadFileWithData(
        primary,
        {},
        undefined,
        undefined,
        true,
        undefined,
        true
      )
        .then((fileResource) => {
          // update files in overview
          queryClient.invalidateQueries(filePaginatedKeys.allFiles);

          onChange({
            fileRef: fileResource?.reference,
            image: tempImage,
            ...fileResource,
          });
          state.set("loading", false);
          if (setLoading) setLoading(false);
        })
        .catch(() => {
          toastError(`Error adding photo...`, toastErrorIcon, toastCloseIcon);
          state.set("loading", false);
        });
    },
    [onChange, queryClient, setLoading, state]
  );

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      addPhoto();
    }
  };
  return (
    <>
      <svg
        viewBox="0 0 10 10"
        width={width}
        preserveAspectRatio="xMidYMid meet"
        aria-label="avatar-image"
        className={`${disabled ? "cursor-default" : "cursor-pointer"} ${
          !loading ? "avatar-container" : ""
        } rounded-full`}
        style={{ minWidth: "85px" }}
        role="button"
        onClick={() => (disabled ? {} : addPhoto())}
        onKeyDown={(e) => (disabled ? {} : handleKeyPress(e))}
        tabIndex={0}
      >
        {/* Green border circle */}
        <circle
          r="4.5"
          cx="5"
          cy="5"
          fill="none"
          stroke="#1CC39D"
          strokeWidth={borderWidth}
        />
        <clipPath id={`clipCircle-${id}`}>
          <circle r="4.7" cx="5" cy="5" />
        </clipPath>
        <defs>
          <linearGradient id={`myGradient-${id}`}>
            <stop offset="0%" stopColor="rgba(0,0,0,0)" />
            <stop offset="20%" stopColor="#1DC29D" />
            <stop offset="50%" stopColor="#90D8C7" />
            <stop offset="80%" stopColor="#1DC29D" />
            <stop offset="100%" stopColor="rgba(0,0,0,0)" />
          </linearGradient>
        </defs>
        {(user || company) && (
          <g id="user-avi-form-background">
            {/* Green border circle */}
            <circle
              r="4.5"
              cx="5"
              cy="5"
              fill="none"
              stroke="#1CC39D"
              strokeWidth="0.5"
            />

            {/* Inner filled circle */}
            <circle r="4.7" cx="5" cy="5" fill="#585858" />
          </g>
        )}
        <circle r="4.5" cx="5" cy="5" fill="#585858" strokeWidth="1" />
        {/* Avatar Image */}
        <image
          href={state.image ?? (data?.contentsUrl || placeholder)}
          alt="avatar-image"
          x="0"
          y="0"
          height="10"
          width="10"
          clipPath={`url(#clipCircle-${id})`}
          preserveAspectRatio="xMaxYMax slice"
        />
        {/* Avatar Image */}

        {/* Add Photo Button */}
        {isEditing && (
          <image
            href={addPhotoIcon}
            alt="add-icon"
            className={!isEditing && "addIcon"}
            x="3.75"
            y="3.75"
            height="2.5"
            width="2.5"
          />
        )}
        {/* Add Photo Button */}

        {/* Loading Circle */}
        {state.loading ? (
          <path
            d="
    M 5.5, 5
    m 4.425, 0
    a 4.425,4.425 0 1,0 -9.85,0
    "
            fill="none"
            strokeWidth="0.15"
            stroke={`url('#myGradient-${id}')`}
            strokeLinecap="round"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 5 5"
              to="360 5 5"
              repeatCount="indefinite"
            />
          </path>
        ) : null}
        {/* Loading Circle */}
      </svg>
      {/* Input */}
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        ref={avatarInput}
        accept={ACCEPTED_PRIMARY_IMAGE_TYPES}
      />
      {/* Input */}
    </>
  );
};

FormAvatar.propTypes = {
  isEditing: PropTypes.bool,
  // onBoarding: Bool - skip images?.startsWith if true
  onBoarding: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  image: PropTypes.string,
  resourceName: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.bool,
  company: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  borderWidth: PropTypes.string,
};

FormAvatar.defaultProps = {
  isEditing: false,
  onBoarding: false,
  disabled: false,
  onChange: () => {},
  setLoading: () => {},
  image: undefined,
  resourceName: undefined,
  loading: false,
  user: false,
  company: false,
  width: "85px",
  borderWidth: "0.5",
};

export default FormAvatar;
