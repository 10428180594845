import React from "react";

const planLabel = ({ userText = "", planName = "" }, isBold) => {
  return (
    <div className="flex flex-row gap-2">
      <p
        className={`text-es-dark-grey text-es-normal ${
          isBold && "font-es-semibold"
        }`}
      >
        {planName}
      </p>
      <p className="text-es-dark-grey text-es-normal font-es-normal">
        ({userText})
      </p>
    </div>
  );
};

export default planLabel;
