import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";
import { PAGES, PROJECT_TABS, PROPERTY_TABS } from "../../../constants";

/**
 * @param {associationName} String name for the associated resource / title for the link
 * @param {associationRef} String ref string of association
 * @returns Text Link of the association Name with onclick to the association
 */
const AssociationLink = ({ associationName, associationRef }) => {
  const history = useHistory();
  const { setActiveTab: setActiveTabPersistence } = useAppPersistence();

  const navigateToAssociation = () => {
    const path = getSingleResourcePath(associationRef);

    const associationResource = associationRef.split("/")[0];
    const associationId = associationRef.split("/")[1];

    if (path) {
      switch (associationResource) {
        case "Property": {
          try {
            setActiveTabPersistence({
              page: PAGES.PROPERTIES,
              tab: PROPERTY_TABS.DETAILS_ID,
              resourceId: associationId,
            });
            history.push(`${path}?tab=${PROPERTY_TABS.DETAILS_ID}`);
          } catch {
            console.error("Error navigating to project, don't push history");
          }
          break;
        }
        case "Project": {
          try {
            setActiveTabPersistence({
              page: PAGES.PROJECTS,
              tab: PROJECT_TABS.DETAILS_ID,
              resourceId: associationId,
            });
            history.push(`${path}?tab=${PROJECT_TABS.DETAILS_ID}`);
          } catch {
            console.error("Error navigating to project, don't push history");
          }
          break;
        }
        default:
          break;
      }
    }
  };

  return (
    <button type="button" onClick={() => navigateToAssociation()}>
      <p className="text-es-green underline text-es-normal font-es-normal">
        {associationName}
      </p>
    </button>
  );
};

AssociationLink.defaultProps = {
  associationRef: PropTypes.string,
  associationName: PropTypes.string,
};

AssociationLink.propTypes = {
  associationRef: undefined,
  associationName: undefined,
};

export default AssociationLink;
