import taskKeys from "../../hooks/api/tasks/taskKeys";
import getTaskListReqParams from "./getTaskListReqParams";

const addToTaskListStore = ({ queryClient, task }) => {
  queryClient.setQueryData(
    taskKeys.taskList(getTaskListReqParams()),
    (oldData) => {
      const formattedItem = {
        uri: `/api/Task/${task.id}`,
        resource: task,
        search: { mode: "search" },
      };

      // Note: Important, tasklist cache hasn't been initiated yet it will be an empty array
      const newTasksArr = [formattedItem];

      if (oldData) newTasksArr.push(...oldData);

      return newTasksArr;
    }
  );
};

export default addToTaskListStore;
