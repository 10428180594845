import { useCallback } from "react";
import { useEditSettings, useSettings } from "../api/setting";
import returnPageSettings from "../../helpers/persistence/returnPageSettings";
import modifyPageSettings from "../../helpers/persistence/modifyPageSettings";
import modifyTabSettings from "../../helpers/persistence/modifyTabSettings";
import modifyTabSearchText from "../../helpers/persistence/modifyTabSearchText";
import modifyCurrentResourceScreen from "../../helpers/persistence/modifyCurrentResourceScreen";
import modifyPageSearchText from "../../helpers/persistence/modifyPageSearchText";
import returnTabSettings from "../../helpers/persistence/returnTabSettings";
import modifyActiveTab from "../../helpers/persistence/modifyActiveTab";
import returnActiveTab from "../../helpers/persistence/returnActiveTab";
import clearAllSettings from "../../helpers/persistence/clearAllSettings";
import returnCurrentResourceScreen from "../../helpers/persistence/returnCurrentResourceScreen";
import removePageSettings from "../../helpers/persistence/removePageSettings";
import removeTabSettings from "../../helpers/persistence/removeTabSettings";

const useAppPersistence = () => {
  const { userSettings, settingsLoading, settingsError } = useSettings();
  const { editSettings } = useEditSettings();

  const stableEditSettings = useCallback(editSettings, [editSettings]);

  /**
   * This Promise will take a page as an argument and return the data for that page
   *
   * @param {string} page
   * @returns {Object} An object containing data for the page
   */
  const getPageSettings = useCallback(
    (page) => returnPageSettings({ page, userSettings }),
    [userSettings]
  );

  /**
   * This Promise will return the data for that page with that tab
   *
   * @param {string} page
   * @param {string} tab
   * @param {string} resourceId
   * @returns {Object} An object containing data for the page
   */
  const getTabSettings = useCallback(
    (page, tab, resourceId) =>
      returnTabSettings({ page, tab, resourceId, userSettings }),
    [userSettings]
  );

  /**
   * This Promise will edit the page settings
   *
   * @param {string} page
   * @param {data} data an object with all properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editPageSettings = useCallback(
    async ({ page, data }) => {
      return modifyPageSettings({
        page,
        data,
        stableEditSettings,
        userSettings,
      });
    },
    [userSettings, stableEditSettings]
  );

  /**
   * This Promise will edit the page settings
   *
   * @param {string} page
   * @param {data} data an object with all properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const deletePageSettings = useCallback(
    async ({ page, keys }) => {
      return removePageSettings({
        page,
        keys,
        stableEditSettings,
        userSettings,
      });
    },
    [userSettings, stableEditSettings]
  );

  /**
   * This Promise will edit the page settings
   *
   * @param {string} page
   * @param {data} data an object with all properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const deleteTabSettings = useCallback(
    async ({ page, tab, resourceId, keys }) => {
      return removeTabSettings({
        page,
        tab,
        resourceId,
        keys,
        stableEditSettings,
        userSettings,
      });
    },
    [userSettings, stableEditSettings]
  );

  /**
   * This Promise will edit the page search settings
   *
   * @param {string} page
   * @param {string} text
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editPageSearchText = useCallback(
    (page, text) => {
      return modifyPageSearchText({
        page,
        text,
        stableEditSettings,
        userSettings,
      });
    },
    [stableEditSettings, userSettings]
  );

  /**
   * This Promise will edit the page tab settings
   *
   * @param {string} page
   * @param {string} tab
   * @param {data} data an object with all page properties matching the modeling in IKT
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editTabSettings = useCallback(
    ({ page, tab, resourceId, data }) => {
      return modifyTabSettings({
        page,
        tab,
        resourceId,
        data,
        stableEditSettings,
        userSettings,
      });
    },
    [stableEditSettings, userSettings]
  );

  /**
   * This Promise will edit the page tabs search settings
   *
   * @param {string} page
   * @param {string} tab
   * @param {string} resourceId
   * @param {string} text
   * @returns {Promise} a promise to be resolved using try / catch or .then().catch()
   */
  const editTabSearchText = useCallback(
    ({ page, tab, resourceId, text }) => {
      return modifyTabSearchText({
        page,
        tab,
        resourceId,
        text,
        stableEditSettings,
        userSettings,
      });
    },
    [stableEditSettings, userSettings]
  );

  /**
   * This Promise gets the active tab you are on
   *
   * @param {string} page
   * @param {string} resourceId
   * @returns {void}
   */

  const getActiveTab = useCallback(
    ({ page, resourceId }) => {
      return returnActiveTab({
        page,
        resourceId,
        userSettings,
      });
    },
    [userSettings]
  );

  /**
   * This Promise sets the active tab you are on
   *
   * @param {string} page
   * @param {string} tab
   * @param {string} resourceId
   * @returns {void}
   */

  const setActiveTab = useCallback(
    ({ page, tab, resourceId }) => {
      return modifyActiveTab({
        page,
        tab,
        resourceId,
        stableEditSettings,
        userSettings,
      });
    },
    [stableEditSettings, userSettings]
  );

  /**
   * This Promise sets the current screen you are on by resource
   *
   * @param {string} url
   * @returns {void}
   */

  const setCurrentResourceScreen = useCallback(
    (page, url) => {
      return modifyCurrentResourceScreen({
        page,
        url,
        stableEditSettings,
        userSettings,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings]
  );

  /**
   * This Promise gets the current screen you are on
   *
   * @param {string} url
   * @returns {void}
   */

  const getCurrentResourceScreen = useCallback(
    (page) => {
      return returnCurrentResourceScreen({
        page,
        userSettings,
      });
    },
    [userSettings]
  );

  /**
   * This Promise clears all the persistence items in IKT that should be cleared on log out
   *
   * @returns {void}
   */

  const clearPersistence = useCallback(async () => {
    return clearAllSettings({ stableEditSettings, userSettings });
  }, [stableEditSettings, userSettings]);

  return {
    userSettings,
    getPageSettings,
    editPageSettings,
    editPageSearchText,
    deletePageSettings,
    getTabSettings,
    editTabSettings,
    editTabSearchText,
    deleteTabSettings,
    getActiveTab,
    setActiveTab,
    setCurrentResourceScreen,
    getCurrentResourceScreen,
    clearPersistence,
    settingsLoading,
    currentScreen: userSettings?.currentScreen || "",
    settingsError,
  };
};

export default useAppPersistence;
