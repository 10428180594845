/**
 * Returns the settings for a specific page.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page for which to return the settings.
 * @param {Object} params.userSettings - The user's settings.
 * @returns {Object} The settings for the specified page.
 */

const returnPageSettings = ({ page, userSettings }) => {
  return userSettings.web?.[page];
};

export default returnPageSettings;
