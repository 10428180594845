import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import SwitcherButton from "../Buttons/SwitcherButton";

const switcherContainer = (className, noZindex) => cntl`
  flex
  items-center
  h-10
  ${!noZindex && "z-20"}
  ${className}
`;

const SiteHeaderSwitchView = ({
  isShowingTable,
  setIsShowingTable,
  className,
  noZindex,
}) => {
  return (
    <div className={switcherContainer(className, noZindex)}>
      <SwitcherButton
        isShowingTable={isShowingTable}
        setIsShowingTable={setIsShowingTable}
      />
    </div>
  );
};

SiteHeaderSwitchView.propTypes = {
  isShowingTable: PropTypes.bool,
  setIsShowingTable: PropTypes.func,
  className: PropTypes.string,
  noZindex: PropTypes.bool,
};

SiteHeaderSwitchView.defaultProps = {
  isShowingTable: false,
  setIsShowingTable: undefined,
  className: undefined,
  noZindex: false,
};

export default SiteHeaderSwitchView;
