import React from "react";
import ModalWrapper from "../ModalWrapper";
import useSopCreateModalData from "./useSopCreateModalData";
import SopInfoForm from "../../SopInfoForm/SopInfoForm";

export default function CreateSopModal({ modalData }) {
  const {
    sopState,
    sopDispatch,
    currentUser,
    stepData,
    setStepData,
    inputRefs,
    handleEnter,
    handleCreate,
    catOptions,
    filesToUpload,
    handleFilesAdded,
    handleFilesUpdated,
    handleFilesUploaded,
    removeAttachedFile,
  } = useSopCreateModalData();
  return (
    <ModalWrapper
      modalData={modalData}
      primaryButtonOnClick={handleCreate}
      resourceName="SOPs"
      title="Create SOP"
      width="56rem"
      className="px-8"
      expanded
    >
      <SopInfoForm
        editedSop={sopState}
        sopDispatch={sopDispatch}
        currentUser={currentUser}
        canEdit
        stepData={stepData}
        setStepData={setStepData}
        inputRefs={inputRefs}
        handleEnter={handleEnter}
        categoryOptionsList={catOptions}
        filesToUpload={filesToUpload}
        handleFilesAdded={handleFilesAdded}
        handleFilesUpdated={handleFilesUpdated}
        handleFilesUploaded={handleFilesUploaded}
        removeAttachedFile={removeAttachedFile}
      />
    </ModalWrapper>
  );
}
