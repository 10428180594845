import { useMutation, useQueryClient } from "react-query";
import spacesKeys from "./spacesKeys";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import postSpaceToProject from "../../helpers/Spaces/postSpaceToProject";
import { useAppState } from "../../state/appState";

const useSpacesPostToProject = (mutationKey) => {
  const [{ editingResource }] = useAppState();

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: postSpaceToProject,
    onSuccess: (data) => {
      const createdSpace = data.spaces[data.spaces.length - 1];
      const messageAndLinkProps = {
        resource: {
          ...createdSpace,
          reference: `Space/${createdSpace.id}`,
          association: data?.reference,
        },
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
    },
    onError: (error) => {
      console.error("useAddSpaceToProperty", error);
      toastError("Space could not be created. Please try again");
    },
    onSettled: () => {
      queryClient.invalidateQueries(spacesKeys.spaces);
    },
  });
};

export default useSpacesPostToProject;
