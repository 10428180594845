import React from "react";
import BaseButton from "../Buttons/BaseButton";
import Checkbox from "../Checkbox/Checkbox";
import { RSVP_STATUSES } from "../../../constants";
import useRsvpRow from "./useRsvpRow";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";

const buttonStyles = (currentStatus, buttonType) => {
  // basic 'unselected' styles
  const basicButtonStyle =
    "bg-transparent border-es-light-grey border-2 rounded-md text-es-medium-grey tracking-es-wide";
  switch (buttonType) {
    case RSVP_STATUSES.accept:
      return currentStatus === RSVP_STATUSES.accept
        ? "bg-es-green rounded-md text-es-white" // Selected / Accepted Styles
        : basicButtonStyle;
    case RSVP_STATUSES.maybe:
      return currentStatus === RSVP_STATUSES.maybe
        ? "bg-es-yellow rounded-md text-es-white" // Selected / Maybe Styles
        : basicButtonStyle;
    case RSVP_STATUSES.decline:
      return currentStatus === RSVP_STATUSES.decline
        ? "bg-es-red rounded-md text-es-white" // Selected / Declined Styles
        : basicButtonStyle;
    default:
      return basicButtonStyle;
  }
};

const RsvpRow = ({
  isRecurring,
  reference,
  instanceStartDate,
  isEvent = true,
}) => {
  const {
    currentStatus,
    onButtonPress,
    single,
    setSingle,
    all,
    setAll,
    onCheckboxPress,
    isLoading,
  } = useRsvpRow({
    reference,
    instanceStartDate,
  });

  return (
    <div
      className="flex flex-row py-4 px-8 gap-8 items-center justify-evenly border border-es-light-grey bg-es-extra-light-grey w-full rounded-md"
      style={{ height: "109px" }}
    >
      <div
        className="flex flex-row items-center justify-start gap-10"
        style={{ height: "44px", minWidth: "48%" }}
      >
        <p className="text-es-normal text-es-medium-grey font-es-semibold w-1/4">
          RSVP
        </p>
        <div className="flex flex-row gap-4 w-full">
          {Object.values(RSVP_STATUSES).map((item) => {
            return (
              <BaseButton
                title={capitalizeFirstLetter(item)}
                style={{ width: "103px", height: "44px" }}
                className={buttonStyles(currentStatus, item)}
                noMaxWidth
                onClick={() => onButtonPress(item)}
                // disble if user does not have update permission
                disabled={isLoading}
                key={item}
              />
            );
          })}
        </div>
      </div>
      <div
        className="flex flex-row items-center w-full justify-start gap-10 ml-2 bg-es-white rounded-md"
        style={{ height: "67px" }}
      >
        <p className="ml-6 w-1/5 text-es-normal text-es-medium-grey font-es-semibold justify-self-start">
          Occurrence
        </p>
        <div className="flex flex-row gap-4">
          <Checkbox
            size={6}
            label={isEvent ? "This Event" : "This Task"}
            labelClassName="text-es-normal text-black"
            checked={!isRecurring ? true : single}
            disabled={!isRecurring || isLoading}
            onChange={(val) => {
              setSingle(val);
              setAll(!val);
              if (val) {
                onCheckboxPress("$single");
              }
            }}
          />
          {isRecurring && (
            <Checkbox
              size={6}
              labelClassName="text-es-normal text-black"
              label={isEvent ? "All Events" : "All Tasks"}
              checked={all}
              onChange={(val) => {
                setAll(val);
                setSingle(!val);
                if (val) {
                  onCheckboxPress("$all");
                }
              }}
              disabled={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RsvpRow;
