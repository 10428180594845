import { useMutation, useQueryClient } from "react-query";
import deleteTaskHandler from "./handlers/deleteTaskHandler";
import deleteTaskResponder from "./responders/deleteTaskResponder";

const useTaskDelete = () => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    deleteTaskHandler,
    deleteTaskResponder(queryClient)
  );

  /**
   * Delete task from a single task cache store
   *
   * @param {Task}
   */
  const deleteTask = async (task) => {
    if (!task.id) {
      console.error("obj.id is required : pass a task id");
    }

    if (!task.startDate) {
      console.error("obj.startDate is required : pass a tasks startDate");
    }

    const response = await deleteMutation.mutateAsync(task);
    return response;
  };

  const {
    isLoading: deleteTaskLoading,
    isError: hasDeleteToTaskListError,
    error: deleteToTaskListError,
    isSuccess: deleteToTaskListSuccess,
  } = deleteMutation;

  return {
    deleteTask,
    deleteTaskLoading,
    hasDeleteToTaskListError,
    deleteToTaskListError,
    deleteToTaskListSuccess,
  };
};

export default useTaskDelete;
